import React, {memo, useMemo} from 'react'
import {Calendar} from 'react-date-range'
import Modal from 'react-modal'
import 'react-date-range/dist/styles.css'
import './style.css'
import {calendarModalStyle} from '../../data'
import {DatePickerType} from '../type'

const DatePicker = ({label, isOpen, setIsOpen, ...props}: DatePickerType) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      shouldCloseOnOverlayClick
      style={useMemo(() => calendarModalStyle, [calendarModalStyle])}
    >
      <div className="calendarWrapper">
        <div className="f-Exo-SemiBold calendarLabel">{label}</div>
        <Calendar color="#CF0B48" calendarFocus="backwards" {...props} />
      </div>
    </Modal>
  )
}

export default memo(DatePicker)
