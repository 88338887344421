import React from 'react'
import {Button} from '../../component'
import {PlayerManagementType} from '../../store/actions/types'
import {updateType} from './type'
import {useSelector} from '../../store'
import images from '../../assets/images'

const PlayerCard = ({
  item,
  onClick,
}: {
  item: PlayerManagementType
  onClick: ({id, command}: updateType) => void
}) => {
  const {playerPositionList} = useSelector(({appSetting}) => ({
    playerPositionList: appSetting.playerPositionList,
  }))
  return (
    <div className="mr-4 flex-center-h flex-d-column mb-4">
      <div className="flex flex-d-column">
        <img
          src={item?.medias_model[0]?.original_url || images.dummyUser}
          alt=""
          className="img_preview-player"
        />
      </div>
      <div className="mt-2 flex-1">
        <div className="f-Exo-Bold f-1 fn-14">
          {item.first_name} {item.last_name}
        </div>
        <div>
          <Button
            icon="c-icon--edit-gray"
            className=""
            onClick={() => onClick && onClick({id: item.id, command: 'update'})}
          />
        </div>
      </div>
      <div className="fn-14">{playerPositionList[item.position]}</div>
      <div className="fn-14">#{item.shirt_number}</div>
    </div>
  )
}

export default PlayerCard
