import React, {memo, useState, useEffect} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {format} from 'date-fns'
import UpdateContainer from './updateContainerRequest'
import {useSelector, useAppDispatch} from '../../store'
import {clubRequestActions, RootState} from '../../store/slices'
import {updateClubStatus} from '../../store/actions'
import {RegistrationStepsStatus} from '../clubManagement/type'

const UpdateClubRequest = () => {
  const {t} = useTranslation()
  const navigation = useNavigate()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const {clubRequestDetail, list} = useSelector(({clubRequest}: RootState) => ({
    clubRequestDetail: clubRequest.clubRequestDetail,
    list: clubRequest.clubRequestList,
  }))
  const params = useParams()
  const id = (params?.id && parseInt(params.id, 10)) || undefined

  const handleClubRequestDetails = async () => {
    const data = list.filter((item) => item.id === id)
    await dispatch(clubRequestActions.updateClubRequestDetail({clubRequestDetail: data[0]}))
  }
  useEffect(() => {
    if (id) {
      handleClubRequestDetails()
    }
  }, [])

  const requestDetails = [
    {
      title: 'clubDetails',
      detalisList: [
        {
          title: 'clubName',
          description: `${clubRequestDetail?.journey_club?.club_name ?? ''}`,
        },
        {
          title: 'league',
          description: `${clubRequestDetail?.journey_club?.leagues?.title ?? ''}`,
        },
      ],
    },
    {
      title: 'yourDetails',
      detalisList: [
        {
          title: 'fullName',
          description: `${clubRequestDetail?.first_name ?? ''} ${
            clubRequestDetail?.last_name ?? ''
          }`,
        },
        {
          title: 'contactNumber',
          description: `+${clubRequestDetail?.mobile_country?.dialling_code ?? ''} ${
            clubRequestDetail?.mobile_number ?? ''
          }`,
        },
        {
          title: 'emailAddress',
          description: `${clubRequestDetail?.email ?? ''}`,
        },
        {
          title: 'dateAndTime',
          description: `${
            (clubRequestDetail?.created_at &&
              format(new Date(`${clubRequestDetail?.created_at}`), 'dd MMM yyyy, hh:mm a')) ??
            ''
          }`,
        },
      ],
    },
    {
      title: 'clubLocation',
      detalisList: [
        {
          title: 'address',
          description: `${clubRequestDetail?.address_line_one ?? ''}`,
        },
        {
          title: 'city',
          description: `${clubRequestDetail?.city ?? ''}`,
        },
        {
          title: 'passcode',
          description: `${clubRequestDetail?.postcode ?? ''}`,
        },
        {
          title: 'country',
          description: `${clubRequestDetail?.address_country ?? ''}`,
        },
      ],
    },
  ]

  return (
    <div className="h-100">
      <UpdateContainer
        btn3Title={id ? 'back' : undefined}
        btn2Title="deny"
        btn1Title="approve"
        title="summary"
        description="pleaseCheckAndMakeSureTheDetalisBelowAreCoorect"
        loading={loading}
        onClick={async (command: string) => {
          setLoading(true)
          if (command === 'deny' && id) {
            const dataToSend = {
              registration_steps_status: RegistrationStepsStatus.denied,
            }
            const response = await dispatch(updateClubStatus(id, dataToSend))
            if (response) navigation(`/club-admin-request`)
          } else if (command === 'approve' && id) {
            const dataToSend = {
              registration_steps_status: RegistrationStepsStatus.approved,
            }
            const response = await dispatch(updateClubStatus(id, dataToSend))
            if (response) navigation(`/club-admin-request`)
          } else if (command === 'back') {
            navigation(`/club-admin-request`)
          }
          setLoading(false)
        }}
      >
        <div className="faqs-edit-box mt-0 w-100">
          <div className="flex f-0-3 flex-d-column">
            {requestDetails &&
              requestDetails.map((item) => (
                <div className="mr-3 f-1">
                  <div className="flex-1">
                    <div className="f-Exo-Bold f-1 summary-title">{t(`${item.title}`)}</div>
                    {/* <div className="flex-center summary-desc">
                  <i className="c-icon c-icon--edit-red mr-1" /> {t('edit')}
                </div> */}
                  </div>
                  <div className="mt-2 mb-3 border-light-gray" />
                  {Array.isArray(item.detalisList) &&
                    item.detalisList.length > 0 &&
                    item.detalisList.map(
                      (dateItem) =>
                        dateItem.description && (
                          <div className="flex-1 mb-3" key={`club-detalis-${dateItem.title}`}>
                            <div className="f-Exo-Bold f-1 summary-list-title">
                              {t(dateItem.title)}:
                            </div>
                            <div className="f-1 summary-detail-desc">{t(dateItem.description)}</div>
                          </div>
                        ),
                    )}
                </div>
              ))}
          </div>
        </div>
      </UpdateContainer>
    </div>
  )
}

export default memo(UpdateClubRequest)
