import React from 'react'
import WidgetFooter from './renderItem/widgetFooter'
import WidgetHeader from './renderItem/widgetHeader'
import {NewsStoryWidgetType} from './type'

const HowDidMatchAgainst = ({item, clubLogo, matchContentId}: NewsStoryWidgetType) => {
  return (
    <div className="c-card">
      <WidgetHeader item={item.info} clubLogo={clubLogo} />
      <WidgetFooter item={item.buttonList} updateId={matchContentId} />
    </div>
  )
}
export default HowDidMatchAgainst
