import React, {memo, useCallback, useEffect, useMemo, useState} from 'react'
import {createTable} from '@tanstack/react-table'
import {useNavigate, useParams} from 'react-router-dom'
import {UpdateListType} from '../../../component/type'
import {Button, List as ListView} from '../../../component'
import ListRight from '../../../component/listRight'
import {RolePermissionItem} from '../../../store/actions/types'
import {useAppDispatch, useSelector} from '../../../store'
import {changeRolePermissionAssignment, getPermissionsByRole} from '../../../store/actions/security'
import {SwitchChangeAssigned} from '../../../component/List/Commands'

const table = createTable().setRowType<RolePermissionItem>()

const RolesList = memo(() => {
  const params = useParams()
  const currentId = (params?.roleId && parseInt(params.roleId, 10)) || undefined

  const navigation = useNavigate()
  const {list, total, from} = useSelector(({roles}) => ({
    list: roles.permissionsList,
    total: roles.permissionsTotal,
    from: roles.permissionsFrom,
  }))

  const defaultOptions = useMemo(
    () => ({
      page: 1,
    }),
    [],
  )
  const [options, setOptions] = useState(defaultOptions)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!currentId) return
    dispatch(getPermissionsByRole({roleId: currentId, page: options.page}))
  }, [options, currentId])

  const columns = useMemo(
    () => [
      table.createDataColumn('id', {
        enableResizing: true,
        header: 'ID',
        cell: ({getValue}) => getValue(),
      }),

      table.createDataColumn('name', {
        header: 'Controller_Method',
        cell: ({row}) => <span>{row.original?.name}</span>,
      }),
      table.createDataColumn('is_assign', {
        header: 'Assigned',
        cell: ({row}) => <span>{row.original?.is_assign}</span>,
      }),
      table.createDataColumn('id', {
        header: '',
        cell: ({row}) =>
          row.original && (
            <ListRight
              row={row.original}
              onClick={onUpdatePress}
              switchList={[{...SwitchChangeAssigned, status: row.original?.is_assign === 1}]}
            />
          ),
      }),
    ],
    [from, list],
  )

  const onUpdatePress = useCallback(
    async ({id, command}: UpdateListType) => {
      if (command === 'changeassigned') {
        if (currentId) {
          const isAssigned = list.find((item) => item.id === id)?.is_assign === 1
          await dispatch(
            changeRolePermissionAssignment({
              roleId: currentId,
              permissionId: id,
              assign: !isAssigned,
            }),
          )
          dispatch(getPermissionsByRole({roleId: currentId, page: options.page}))
        }
      }
    },
    [list, options],
  )

  const onPaginate = useCallback(
    (page: number) => {
      setOptions({...options, page})
    },
    [options],
  )

  return (
    <div className="table-outer">
      <div className="table-header">
        <div className="c-carousal__header">
          <div className="c-carousal__header__rpart flex-center">
            <Button
              title="Go Back"
              classAddon="c-button--we-160 c-button--icons f-Exo-Medium"
              onClick={() => navigation(-1)}
            />
          </div>
        </div>
      </div>
      <ListView
        columns={columns}
        data={list}
        total={total}
        onPaginate={onPaginate}
        forcePageIndex={options.page - 1}
      />
    </div>
  )
})

export default RolesList
