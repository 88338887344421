import React, {memo, useState} from 'react'
import {Formik, Form} from 'formik'
import {useParams, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import UpdateContainer from '../common/updateContainer'
import {Input} from '../../../component'
import {addFaq, clearFaqDetail, updateFaq} from '../../../store/actions'
import {RootState} from '../../../store/slices'
import {useAppDispatch, useSelector} from '../../../store'
import {EventType, FaqType, FormActionType} from '../type'
import {serializeFormData} from '../../../functions/utils'

const FaqUpdate = () => {
  const {t} = useTranslation()
  const navigation = useNavigate()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const {faqDetail} = useSelector(({faq}: RootState) => ({faqDetail: faq.faqDetail}))
  const params = useParams()
  const id = (params?.id && parseInt(params.id, 10) && faqDetail?.id) || undefined
  if (!id) {
    dispatch(clearFaqDetail())
  }
  const initialAddValues = {
    title: '',
    description: '',
  }
  const initialUpdateValues = {
    title: faqDetail?.title || '',
    description: faqDetail?.description || '',
  }
  const onSubmit = async (values: FaqType) => {
    if (id) {
      setLoading(true)
      const response = await dispatch(updateFaq(id, values))
      if (response) navigation(`/content-management`)
      setLoading(false)
    } else {
      setLoading(true)
      const formValues = {
        title: values.title || undefined,
        description: values.description || undefined,
      }
      const formData = serializeFormData(formValues)
      const response = await dispatch(addFaq(formData))
      if (response) navigation(`/content-management`)
      setLoading(false)
    }
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().trim().required('Title cannot be empty.'),
    description: Yup.string().trim().required('Description cannot be empty.'),
  })

  const onClickListAction = ({command, setValues, submitForm}: FormActionType<FaqType>) => {
    if (command === 'cancel') {
      navigation(`/content-management`)
    } else if (command === 'remove') {
      setValues(initialAddValues)
    } else if (command === 'save' || command === 'update') {
      submitForm()
    }
  }

  return (
    <Formik
      initialValues={faqDetail?.id ? initialUpdateValues : initialAddValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({errors, touched, submitForm, setFieldValue, setValues}) => (
        <Form className="h-100">
          <UpdateContainer
            btn3Title={id ? 'remove' : undefined}
            btn2Title="cancel"
            btn1Title={id ? 'update' : 'save'}
            title={id ? 'editFAQs' : 'addFAQs'}
            loading={loading}
            onClick={(command: string) => onClickListAction({command, setValues, submitForm})}
          >
            <div className="faqs-edit-box">
              <div className="flex f-0-3">
                <div className="mr-3 f-1">
                  <Input
                    name="title"
                    label={t('heading')}
                    onChange={(e: EventType) => {
                      setFieldValue('title', e.target.value)
                    }}
                    placeholder={t('enterHeadingHere')}
                    error={touched.title && errors.title}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                  <Input
                    name="description"
                    label={t('bodyText')}
                    onChange={(e: EventType) => {
                      setFieldValue('description', e.target.value)
                    }}
                    component="textarea"
                    placeholder={t('enterDescriptionHere')}
                    error={touched.description && errors.description}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                </div>
              </div>
            </div>
          </UpdateContainer>
        </Form>
      )}
    </Formik>
  )
}

export default memo(FaqUpdate)
