import Api, {urls} from '../../../services/api'
import {ApiResponseList, matchContentMatchReport} from '../types'
import logException from '../../../services/sentry'
import showNotification from '../../../functions/notification'
import {matchContentMatchReportAction} from '../../slices'
import {AppDispatch} from '../..'
import {apiErrorBlock} from '../../../functions/utils'

export const getMatchContentMatchReport = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<matchContentMatchReport> = await Api.get(
      `${urls.matchReport}?${id ? `match_fixture_id=${id}` : ''}`,
    )
    const {message, status, data} = response.data
    if (status && data) {
      await dispatch(
        matchContentMatchReportAction.updateMatchContentMatchReport({
          matchContentMatchReport: data.data as matchContentMatchReport,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    // eslint-disable-next-line prettier/prettier
  } catch (e: any) {
    apiErrorBlock(e)
  }
}

export const saveMatchReport = (params: FormData) => async () => {
  try {
    const response: ApiResponseList<matchContentMatchReport> = await Api.post(
      `/match-report`,
      params,
    )
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}

export const clearMatchReport = () => async (dispatch: AppDispatch) => {
  try {
    await dispatch(matchContentMatchReportAction.clearMatchReport())
    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}

export const updateMatchReport = (id: number, params: FormData) => async () => {
  try {
    const response: ApiResponseList<matchContentMatchReport> = await Api.post(
      `/match-report/${id}`,
      params,
    )
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
