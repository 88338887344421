import React, {memo} from 'react'
import {AddLinkButton, BlockButton, MarkButton} from './Button'
import images from '../../assets/images'

const Toolbar = memo(() => (
  <div className="toolbar">
    <MarkButton format="bold" Icon={images.editor.bold} />
    <MarkButton format="italic" Icon={images.editor.italic} />
    <MarkButton format="underline" Icon={images.editor.underline} />
    <MarkButton format="strikethrough" Icon={images.editor.strikethrough} />
    <span className="mr-4" />
    <BlockButton format="heading-one" Icon={images.editor.header1} />
    <BlockButton format="heading-two" Icon={images.editor.header2} />
    <span className="mr-4" />
    <BlockButton format="left" Icon={images.editor.left} />
    <BlockButton format="center" Icon={images.editor.center} />
    <BlockButton format="right" Icon={images.editor.right} />
    <BlockButton format="justify" Icon={images.editor.justify} />
    <span className="mr-4" />
    <AddLinkButton format="link-active" Icon={images.editor.link} />
    <span className="mr-4" />
    <BlockButton format="bulleted-list" Icon={images.editor.bulleted} />
    <BlockButton format="numbered-list" Icon={images.editor.numbered} />
  </div>
))

export default Toolbar
