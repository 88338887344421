import Api, {urls} from '../../services/api'
import {ApiResponseList, TopEarningClubType, SortingType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {topEarningClubAction} from '../slices'
import {AppDispatch} from '..'
import {topEarningClubList} from '../../data'
import {apiErrorBlock} from '../../functions/utils'

export const getTopEarningClubList = (params: SortingType) => async (dispatch: AppDispatch) => {
  try {
    const {page} = params
    const response: ApiResponseList<TopEarningClubType[]> = await Api.get(
      `${urls.clubRequest}?page=${page}${params.sort_by ? `&sort_by=${params.sort_by}` : ''}${
        params.sort_order ? `&sort_order=${params.sort_order}` : ''
      }`,
    )
    const {message} = response.data
    // if (status && data?.data) { TODO:update when api done
    await dispatch(
      topEarningClubAction.updateTopEarningClubList({
        topEarningClubList,
        total: 11,
        from: 1,
      }),
    )
    // }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
}
