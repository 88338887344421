import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LeagueType} from '../actions/types'

export interface LeagueState {
  leagueList: LeagueType[]
  total: number
  from: number
  leagueDetail: LeagueType
}

const initialLeagueDetail = {
  id: 0,
  title: '',
}

const initialState = {
  leagueList: [],
  total: 0,
  from: 0,
  leagueDetail: initialLeagueDetail,
} as LeagueState

export const leagueSlice = createSlice({
  name: 'league',
  initialState,
  reducers: {
    updateLeagueList: (state, action: PayloadAction<Partial<LeagueState>>) => {
      const {leagueList, total, from} = action.payload
      state.leagueList = leagueList as LeagueType[]
      state.total = total || initialState.total
      state.from = from || initialState.from
    },
    updateLeagueDetail: (state, action: PayloadAction<Partial<LeagueState>>) => {
      state.leagueDetail = action.payload.leagueDetail || state.leagueDetail
    },
    clearLeagueDetail: (state) => {
      state.leagueDetail = initialState.leagueDetail
    },
  },
})
