import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {SocialConfigExpireType} from '../actions/types'

export interface SocialConfigExpireState {
  socialConfigExpire?: SocialConfigExpireType
}

const initialState = {socialConfigExpire: {}} as SocialConfigExpireState

export const socialConfigExpireSlice = createSlice({
  name: 'socialConfigExpire',
  initialState,
  reducers: {
    updateSocialConfigExpire: (
      state: SocialConfigExpireState,
      action: PayloadAction<SocialConfigExpireType>,
    ) => {
      state.socialConfigExpire = {...state.socialConfigExpire, ...action.payload}
    },
    clearSocialConfigExpire: (
      state: SocialConfigExpireState,
      action: PayloadAction<SocialConfigExpireType>,
    ) => {
      state.socialConfigExpire = {...state.socialConfigExpire, ...action.payload}
    },
  },
})
