/* eslint-disable react/jsx-curly-newline */
import React from 'react'
import {useTranslation} from 'react-i18next'
import {format} from 'date-fns'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {Button, RichTextInput} from '../../component'
import {MatchContentMatchInfoType} from './type'
import {EventType} from '../contentManagement/type'
import Select from '../../component/select'
import {hoursList, minuteList} from '../../data/time'
import MatchInfoView from './matchInfoView'
import {setFieldValueType} from '../../component/type'

const MatchInfo = ({
  item,
  initialValue,
  onSubmit,
  handleEditState,
  isEdit,
  setMatchInfoValue,
}: MatchContentMatchInfoType) => {
  const {t} = useTranslation()
  const matchTypeList = [
    {
      id: 1,
      value: '0',
      label: 'Friendly',
    },
    {
      id: 2,
      value: '1',
      label: 'Orignal',
    },
  ]

  const validationSchema = Yup.object().shape({
    meetTime: Yup.string().trim().required('Meet Time cannot be empty.'),
    kickoffTime: Yup.date().when(
      'meetTime',
      (meetTime, schema) =>
        meetTime && schema.min(meetTime, 'Kickoff Time field must be after Meet Time'),
    ),
    matchType: Yup.string().trim().required('Match Type cannot be empty.'),
    attendTime: Yup.date().when(
      'meetTime',
      (meetTime, schema) =>
        meetTime && schema.min(meetTime, 'Attendance Time field must be after Meet Time'),
    ),
  })

  const handleTime = (
    setFieldValue: setFieldValueType,
    value: string,
    type: string,
    oldValue: string,
    field: string,
    values: any,
  ) => {
    const newDate = new Date(oldValue)
    if (type === 'hour') {
      newDate.setHours(parseInt(value, 10))
      setFieldValue(field, format(newDate, 'yyyy-MM-dd HH:mm:ss'))
      setMatchInfoValue({...values, field: format(newDate, 'yyyy-MM-dd HH:mm:ss')})
    } else if (type === 'minute') {
      newDate.setMinutes(parseInt(value, 10))
      setFieldValue(field, format(newDate, 'yyyy-MM-dd HH:mm:ss'))
      setMatchInfoValue({...values, field: format(newDate, 'yyyy-MM-dd HH:mm:ss')})
    }
  }
  return (
    <div className="table-outer f-1">
      <Formik initialValues={initialValue} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({touched, errors, setFieldValue, submitForm, values}) => (
          <Form>
            <div className="table-header">
              <div className="c-carousal__header no-border-b">
                <div className="c-carousal__header__lpart v-center">
                  <span className="f-Exo-Bold title-color">{t('matchInfo').toUpperCase()}</span>
                </div>
                <div className="c-carousal__header__rpart flex-center">
                  {isEdit ? (
                    <div className="c-carousal__header__rpart flex-center">
                      <Button
                        title={t('save')}
                        type="submit"
                        classAddon=" c-button--icons f-Exo-Bold mr-2"
                        onClick={submitForm}
                      />
                      <div>
                        <Button
                          title={t('cancel')}
                          classAddon="c-button--br c-button--br--gray-ab bg-transparent f-Exo-Bold"
                          onClick={handleEditState}
                        />
                      </div>
                    </div>
                  ) : (
                    <Button
                      title={t('edit')}
                      type="submit"
                      classAddon=" c-button--icons f-Exo-Bold mr-2"
                      onClick={handleEditState}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex-1-center-v flex-d-column bg-gray">
              {isEdit ? (
                <div className="flex mt-4 flex-d-column ml-3 card-match-content">
                  <div className="f-1">
                    <div className="flex f-0-8">
                      <div className="flex-1 flex-d-column">
                        <div className="flex-1 player-name-input-we-l mt-1">
                          <Select
                            name="meetTime"
                            label={t('meetTime')}
                            value={format(new Date(values.meetTime), 'HH')}
                            error={touched.meetTime && errors.meetTime}
                            onChange={(e: EventType) => {
                              handleTime(
                                setFieldValue,
                                e.target.value,
                                'hour',
                                values.meetTime,
                                'meetTime',
                                values,
                              )
                            }}
                            className="form-control left-redius-none"
                            classNameLable="fn-14"
                            optionsList={hoursList}
                            classAddonOuter="f-1"
                          />
                          <Select
                            name="meetTime"
                            value={format(new Date(values.meetTime), 'mm')}
                            onChange={(e: EventType) => {
                              handleTime(
                                setFieldValue,
                                e.target.value,
                                'minute',
                                values.meetTime,
                                'meetTime',
                                values,
                              )
                            }}
                            className="form-control mt-4 right-redius-none"
                            classNameLable="fn-14"
                            optionsList={minuteList}
                            classAddonOuter="f-1"
                          />
                        </div>
                      </div>
                      <div className="flex-1 flex-d-column">
                        <div className="flex-1 player-name-input-we-r mt-1">
                          <Select
                            name="kickoffTime"
                            label={t('kickoffTime')}
                            value={format(new Date(values.kickoffTime), 'HH')}
                            error={touched.kickoffTime && errors.kickoffTime}
                            onChange={(e: EventType) => {
                              handleTime(
                                setFieldValue,
                                e.target.value,
                                'hour',
                                values.kickoffTime,
                                'kickoffTime',
                                values,
                              )
                            }}
                            className="form-control left-redius-none"
                            classNameLable="fn-14"
                            optionsList={hoursList}
                            classAddonOuter="f-1"
                          />
                          <Select
                            name="kickoffTime"
                            value={format(new Date(values.kickoffTime), 'm')}
                            onChange={(e: EventType) => {
                              handleTime(
                                setFieldValue,
                                e.target.value,
                                'minute',
                                values.kickoffTime,
                                'kickoffTime',
                                values,
                              )
                            }}
                            className="form-control mt-4 right-redius-none"
                            classNameLable="fn-14"
                            optionsList={minuteList}
                            classAddonOuter="f-1"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex f-0-8">
                      <Select
                        name="matchType"
                        label={t('type')}
                        error={touched.matchType && errors.matchType}
                        onChange={(e: EventType) => {
                          setFieldValue('matchType', e.target.value)
                          setMatchInfoValue({...values, matchType: parseInt(e.target.value, 10)})
                        }}
                        className="form-control mt-1  player-name-input-we-l"
                        classNameLable="fn-14"
                        optionsList={matchTypeList}
                        classAddonOuter="f-1"
                      />
                      <div className="flex-1 flex-d-column">
                        <div className="flex-1 player-name-input-we-r mt-1">
                          <Select
                            name="attendTime"
                            label={t('attendance')}
                            value={format(new Date(values.attendTime), 'HH')}
                            error={touched.attendTime && errors.attendTime}
                            onChange={(e: EventType) => {
                              handleTime(
                                setFieldValue,
                                e.target.value,
                                'hour',
                                values.attendTime,
                                'attendTime',
                                values,
                              )
                            }}
                            className="form-control left-redius-none"
                            classNameLable="fn-14"
                            optionsList={hoursList}
                            classAddonOuter="f-1"
                          />
                          <Select
                            name="attendTime"
                            value={format(new Date(values.attendTime), 'm')}
                            onChange={(e: EventType) => {
                              handleTime(
                                setFieldValue,
                                e.target.value,
                                'minute',
                                values.attendTime,
                                'attendTime',
                                values,
                              )
                            }}
                            className="form-control mt-4 right-redius-none"
                            classNameLable="fn-14"
                            optionsList={minuteList}
                            classAddonOuter="f-1"
                          />
                        </div>
                      </div>
                    </div>
                    <RichTextInput
                      label={`${t('additionalInfo')}  (${t('optional')})`}
                      classAddon="bg-white"
                      value={values.matchDesc}
                      onChange={(news_description) => {
                        setFieldValue('matchDesc', news_description)
                        setMatchInfoValue({...values, matchDesc: news_description})
                      }}
                      error={touched.matchDesc && (errors.matchDesc as string | boolean)}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <MatchInfoView item={item} />
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
export default MatchInfo
