import Api, {urls} from '../../services/api'
import {ApiResponseList, ApiResponseType, FaqType, SortingType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {faqActions} from '../slices'
import {AppDispatch} from '..'
import {apiErrorBlock} from '../../functions/utils'

export const getFaqList = (params: SortingType) => async (dispatch: AppDispatch) => {
  try {
    const {page} = params
    const response: ApiResponseList<FaqType[]> = await Api.get(
      `${urls.faqs}?page=${page}${
        params.per_page && params.per_page > 0 ? `&per_page=${params.per_page}` : ''
      }`,
    )
    const {status, message, data} = response.data
    if (status && data?.data) {
      dispatch(
        faqActions.updateFaqList({
          faqList: data.data,
          total: data.total || 0,
          from: data.from || 0,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    logException(e)
    console.log(e)
  }
}
export const addFaq = (params: FormData) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<FaqType[]> = await Api.post(`${urls.faqs}`, params)
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getFaqList({page: 1}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const updateFaq =
  (id: number, params: {title: string; description: string}) => async (dispatch: AppDispatch) => {
    try {
      const response: ApiResponseList<FaqType[]> = await Api.patch(`${urls.faqs}/${id}`, params)
      const {status, message} = response.data
      if (status && message) {
        if (message) showNotification({title: 'Success', message, type: 'success'})
        await dispatch(getFaqList({page: 1}))
        return true
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return false
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }
export const getFaqDetail = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseType<FaqType> = await Api.get(`${urls.faqs}/${id}`)
    const {status, message, data} = response.data
    if (status && data) {
      await dispatch(faqActions.updateFaqDetail({faqDetail: data}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const clearFaqDetail = () => async (dispatch: AppDispatch) => {
  try {
    await dispatch(faqActions.clearFaqDetail())
    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const deleteFaq = (id: number, options: SortingType) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<FaqType[]> = await Api.delete(`${urls.faqs}/${id}`)
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getFaqList(options))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
