import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {Button} from '../../component'
import SidebarSubList from './subList'
import {SidebarListType} from './type'

const SidebarList = ({
  item,
  showId,
  handleShow,
  setShowSubId,
  showSubId,
  isActive,
  lastItemClass,
}: SidebarListType) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {icon, title, subList, id, link} = item
  const handleSubShow = (subListShowId: string, urlLink: string) => {
    navigate(urlLink)
    if (subListShowId === showId) {
      setShowSubId('')
    } else {
      setShowSubId(subListShowId)
    }
  }

  return (
    <div className="c-nav-card">
      <Button
        buttonType="submit"
        className={`c-nav-card__button ${showId === id && 'active-drop'} ${
          showId === id && subList.length > 0 && 'active-drop-up'
        } ${isActive === id && 'active'} ${
          isActive === id && subList.length > 0 && 'active-bottom'
        } ${lastItemClass}`}
        buttonMode="button"
        onClick={() => handleShow(id, link)}
      >
        <i className={`c-icon ${icon} sidebar-icon`} />
        <span className="sidebar-text f-Exo-Medium">{t(title)}</span>
      </Button>
      {showId === id && (
        <div id="content" className="c-nav-card__subnav collapse show" data-parent="#leftNavbar">
          <ul className="c-nav-card__subnav__list">
            {Array.isArray(subList) &&
              subList.map((subItem) => (
                <SidebarSubList
                  item={subItem}
                  key={`sidebar-sub-list-${subItem.id}`}
                  handleSubShow={handleSubShow}
                  showSubId={showSubId}
                />
              ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default SidebarList
