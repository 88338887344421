import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {mediasModel} from '../../data'
import {BenefitType} from '../actions/types'

export interface BenefitState {
  benefitList: BenefitType[]
  total: number
  from: number
  benefitDetail: BenefitType
}

const initialBenefitDetail = {
  id: 0,
  title: '',
  description: '',
  created_at: '',
  medias_model: [mediasModel],
}
const initialState = {
  benefitList: [],
  total: 0,
  from: 0,
  benefitDetail: initialBenefitDetail,
} as BenefitState

export const benefitSlice = createSlice({
  name: 'benefit',
  initialState,
  reducers: {
    updateBenefitList: (state, action: PayloadAction<Partial<BenefitState>>) => {
      const {benefitList, total, from} = action.payload
      state.benefitList = benefitList || state.benefitList
      state.total = total || state.total
      state.from = from || state.from
    },
    updateBenefitDetail: (state, action: PayloadAction<Partial<BenefitState>>) => {
      state.benefitDetail = action.payload.benefitDetail || state.benefitDetail
    },
    clearBenefitDetail: (state) => {
      state.benefitDetail = initialState.benefitDetail
    },
  },
})
