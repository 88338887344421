import {AppDispatch} from '..'
import {apiErrorBlock} from '../../functions/utils'
import Api, {urls} from '../../services/api'
import {socialConfigExpireAction} from '../slices'
import {ApiResponseType, SocialConfigExpireType} from './types'

export const getSocialConfigExpire = () => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseType<SocialConfigExpireType> = await Api.get(
      `${urls.SocialConfigExpireGet}`,
    )
    const {status, data} = response.data
    if (status && data) {
      await dispatch(socialConfigExpireAction.updateSocialConfigExpire(data))
    }
    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}

export const clearSocialConfigExpire = (params: any) => async (dispatch: AppDispatch) => {
  try {
    await Api.post(`${urls.SocialConfigSet}`, params)
    await dispatch(socialConfigExpireAction.clearSocialConfigExpire(params))

    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
