import React, {CSSProperties, memo} from 'react'
import {RenderElementProps} from 'slate-react'
import LinkComponent from './LinkComponent'
// eslint-disable-next-line  import/extensions
import {ParagraphElement} from './types'

const Element = memo((props: RenderElementProps) => {
  const {attributes, children, element} = props
  const style = (element as ParagraphElement).align
    ? ({
        textAlign: (element as ParagraphElement).align,
      } as CSSProperties)
    : undefined
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    case 'link':
      return <LinkComponent {...props} />
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
})

export default Element
