import React, {memo} from 'react'
import {useTranslation} from 'react-i18next'
import {Button} from '../../component'
import {UpdateContainerRequestType} from '../../component/type'

const UpdateContainer = ({
  btn3Title,
  btn2Title,
  btn1Title,
  children,
  onClick,
  title,
  description,
}: UpdateContainerRequestType) => {
  const {t} = useTranslation()

  return (
    <div className="c-m-btn-remove">
      <div className="c-card p-3 flex-1 bg-white">
        <div className="c-card__body c-m-card-body">
          {btn3Title && (
            <div className="flex ml-4 v-flex-end f-0-1">
              <Button
                title={t(btn3Title)}
                onClick={() => onClick && onClick(btn3Title)}
                classAddon="c-button--we-80 c-button--small f-Exo-Bold"
              />
            </div>
          )}
          <div className="flex-1-center-h flex-d-column">
            <div className="flex flex-d-column f-0-2 h-flex-end">
              <div className="carousal-edit-title f-Exo-Bold mt-5">{t(title)}</div>
              {description && <div className="carousal-edit-desc mt-2 mb-4">{t(description)}</div>}
            </div>
            <div className="flex-center f-0-5 w-100 h-flex-start">{children}</div>
            <div className="mb-4 flex-center-h f-0-3 v-flex-start">
              {btn1Title && (
                <Button
                  title={t(btn1Title)}
                  onClick={() => onClick && onClick(btn1Title)}
                  classAddon="c-button--small f-Exo-Bold c-button--bg--green mr-2"
                />
              )}
              {btn2Title && (
                <Button
                  title={t(btn2Title)}
                  classAddon="c-button--small f-Exo-Bold"
                  onClick={() => onClick && onClick(btn2Title)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(UpdateContainer)
