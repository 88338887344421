import React, {memo, useState, useEffect} from 'react'
import {Formik, Form} from 'formik'
import {useParams, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import {format} from 'date-fns'
import {DatePicker, Input, Label, RichTextInput, Select, ClubSearch} from '../../component'
import {
  addClubFixture,
  addFixture,
  clearFixtureDetail,
  getClubList,
  updateClubFixture,
  updateFixture,
} from '../../store/actions'
import {RootState} from '../../store/slices'
import {useAppDispatch, useSelector} from '../../store'
import {serializeFormData} from '../../functions/utils'
import UpdateContainer from '../contentManagement/common/updateContainer'
import {EventType, FormActionType} from '../contentManagement/type'
import {FixtureRequestType} from './type'
import {hoursList, minuteList} from '../../data/time'
import {setFieldValueType} from '../../component/type'
import {ClubType} from '../../store/actions/types'
import {defaultPageSize} from '../../configs/paginationSetting'

const FixtureUpdate = ({isMaster}: {isMaster: boolean}) => {
  const {t} = useTranslation()
  const navigation = useNavigate()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const {fixtureDetail} = useSelector(({fixture, matchFixtureRequest}: RootState) => ({
    fixtureDetail: isMaster ? fixture.fixtureDetail : matchFixtureRequest.clubFixtureDetail,
  }))

  useEffect(() => {
    dispatch(
      getClubList({
        page: 1,
        request_status: 3,
        per_page: defaultPageSize,
        sort_by: 'club_name',
        sort_order: 'asc',
      }),
    )
  }, [])

  const params = useParams()
  const id = (params?.id && parseInt(params.id, 10) && fixtureDetail?.id) || undefined
  if (!id) {
    dispatch(clearFixtureDetail())
  }
  const initialAddValues = {
    club_id: '',
    club_name: '',
    opponent_club_id: '',
    opponent_club_name: '',
    match_start_time: '',
    match_vanue: '',
    kickoff_time: '01',
    match_desc: [
      {
        type: 'paragraph',
        children: [{text: ''}],
      },
    ],
  }
  const initialUpdateValues = {
    club_name: fixtureDetail?.club?.club_name || '',
    opponent_club_name: fixtureDetail?.opponent_club?.club_name || '',
    club_id: fixtureDetail?.club?.id || '',
    opponent_club_id: fixtureDetail?.opponent_club?.id || '',
    match_start_time: fixtureDetail?.match_start_time
      ? format(new Date(fixtureDetail.match_start_time), 'yyyy-MM-dd').toString()
      : '',
    match_vanue: fixtureDetail?.match_vanue || '',
    kickoff_time: fixtureDetail?.match_kickoff_time
      ? new Date(fixtureDetail.match_kickoff_time).toString()
      : '01',
    match_desc: fixtureDetail?.match_description
      ? JSON.parse(fixtureDetail?.match_description)
      : [
          {
            type: 'paragraph',
            children: [{text: ''}],
          },
        ],
  }
  const onSubmit = async (values: FixtureRequestType) => {
    setLoading(true)
    const matchStartTime = new Date(values.match_start_time)
    const startTime = new Date(values.kickoff_time)
    matchStartTime.setHours(startTime.getHours())
    matchStartTime.setMinutes(startTime.getMinutes())
    if (id) {
      const requestData = {
        club_name: isMaster ? values.club_name || '' : undefined,
        opponent_club_name: values.opponent_club_name || '',
        club_id: isMaster ? values.club_id || '' : undefined,
        opponent_club_id: values.opponent_club_id || '',
        match_vanue: values.match_vanue || '',
        match_kickoff_time: format(new Date(values.kickoff_time), 'yyyy-MM-dd HH:mm:ss') || '',
        match_start_time: format(matchStartTime, 'yyyy-MM-dd HH:mm:ss') || '',
        match_description: JSON.stringify(values.match_desc) || '',
      }
      const response = isMaster
        ? await dispatch(updateFixture(id, requestData))
        : await dispatch(updateClubFixture(id, requestData))
      if (response) navigation(`/match-fixture`)
      setLoading(false)
    } else {
      const formValues: any = {}
      formValues.club_id = isMaster ? values.club_id : undefined
      formValues.opponent_club_id = values.opponent_club_id || undefined
      formValues.match_vanue = values.match_vanue || undefined
      formValues.match_kickoff_time = values.kickoff_time || undefined
      formValues.match_description = JSON.stringify(values.match_desc) || undefined
      formValues.match_start_time = format(matchStartTime, 'yyyy-MM-dd HH:mm:ss') || undefined
      const formData = serializeFormData(formValues)
      const response = isMaster
        ? await dispatch(addFixture(formData))
        : await dispatch(addClubFixture(formData))
      if (response) navigation(`/match-fixture`)
      setLoading(false)
    }
  }

  const validationSchema = isMaster
    ? Yup.object().shape({
        club_name: Yup.string().trim().required('Club name cannot be empty.'),
        opponent_club_name: Yup.string().trim().required('Opponent name cannot be empty.'),
        match_start_time: Yup.string().trim().required('Match date must be determined.'),
        kickoff_time: Yup.string().trim().required('Meet Time cannot be empty.'),
        match_vanue: Yup.string().trim().required('Match venue cannot be empty.'),
      })
    : Yup.object().shape({
        opponent_club_name: Yup.string().trim().required('Opponent name cannot be empty.'),
        match_start_time: Yup.string().trim().required('Match date must be determined.'),
        kickoff_time: Yup.string().trim().required('Meet Time cannot be empty.'),
        match_vanue: Yup.string().trim().required('Match venue cannot be empty.'),
      })

  const onClickListAction = ({command, submitForm}: FormActionType<FixtureRequestType>) => {
    if (command === 'cancel') {
      navigation(`/match-fixture`)
    } else if (command === 'save' || command === 'update') {
      submitForm()
    }
  }

  const handleTime = (
    setFieldValue: setFieldValueType,
    value: string,
    type: string,
    oldValue: string,
    field: string,
  ) => {
    const newDate = new Date(oldValue)
    newDate.setDate(new Date().getDate())
    newDate.setMonth(new Date().getMonth())
    newDate.setFullYear(new Date().getFullYear())
    if (type === 'hour') {
      newDate.setHours(parseInt(value, 10))
      setFieldValue(field, format(newDate, 'yyyy-MM-dd HH:mm:ss'))
    } else if (type === 'minute') {
      newDate.setMinutes(parseInt(value, 10))
      setFieldValue(field, format(newDate, 'yyyy-MM-dd HH:mm:ss'))
    }
  }

  const handleOpponentClubChange = (selectedClub: ClubType, setFieldValue: any) => {
    setFieldValue('opponent_club_id', selectedClub.id)
    setFieldValue('opponent_club_name', selectedClub.club_name)
  }

  const handleClubChange = (selectedClub: ClubType, setFieldValue: any) => {
    setFieldValue('club_id', selectedClub.id)
    setFieldValue('club_name', selectedClub.club_name)
  }
  return (
    <Formik
      initialValues={
        fixtureDetail?.id
          ? (initialUpdateValues as FixtureRequestType)
          : (initialAddValues as FixtureRequestType)
      }
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({errors, touched, submitForm, setFieldValue, setValues, values}) => (
        <Form className="h-100">
          <UpdateContainer
            btn2Title="cancel"
            btn1Title={id ? 'update' : 'save'}
            title={
              !isMaster && !id
                ? 'requestFixtureDetails'
                : !isMaster && id
                ? 'editRequestFixtureDetails'
                : id
                ? 'editFixtureDetails'
                : 'addFixtureDetails'
            }
            loading={loading}
            onClick={(command: string) => onClickListAction({command, setValues, submitForm})}
          >
            <div className="faqs-edit-box">
              <div className="f-0-3">
                <div className="f-1">
                  {isMaster ? (
                    <div className="f-1 row">
                      <div className="f-1 mr-1">
                        <Label label={t('opponent1')} className="mb-1" />
                        <ClubSearch
                          handleChange={handleClubChange}
                          setFieldValue={setFieldValue}
                          defaultValue={fixtureDetail?.club?.club_name}
                          error={touched.club_name && errors.club_name}
                        />
                      </div>
                      <div className="f-1 ml-1">
                        <Label label={t('opponent2')} className="mb-1" />
                        <ClubSearch
                          handleChange={handleOpponentClubChange}
                          setFieldValue={setFieldValue}
                          defaultValue={fixtureDetail?.opponent_club?.club_name}
                          error={touched.opponent_club_name && errors.opponent_club_name}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="f-1 row mb-1">
                      <div className="f-1 ml-1">
                        <Label label={t('opponent')} />
                        <ClubSearch
                          handleChange={handleOpponentClubChange}
                          setFieldValue={setFieldValue}
                          defaultValue={fixtureDetail?.opponent_club?.club_name}
                          classNameOuter="mt-1"
                          error={touched.opponent_club_name && errors.opponent_club_name}
                        />
                      </div>
                    </div>
                  )}
                  <div className="f-1 row mt-1">
                    <div className="f-1 mr-1">
                      <Input
                        name="match_start_time"
                        label={t('date')}
                        onChange={(e: EventType) => {
                          setFieldValue('match_start_time', e.target.value)
                        }}
                        onClick={() => setShowDatePicker(true)}
                        error={touched.match_start_time && errors.match_start_time}
                        className="form-control mt-1 pointer"
                        classNameLable="fs-15"
                      />
                      <DatePicker
                        setIsOpen={setShowDatePicker}
                        isOpen={showDatePicker}
                        label="Select Start Date"
                        minDate={new Date()}
                        onChange={(date) => {
                          setFieldValue('match_start_time', format(date, 'yyyy-MM-dd'))
                          setShowDatePicker(false)
                        }}
                      />
                    </div>
                  </div>
                  <Label label={t('kickoffTime')} className="row-default-m" />
                  <div className="f-1 row">
                    <div className="f-1 mr-1">
                      <Select
                        name="kickoff_time"
                        value={format(new Date(values.kickoff_time), 'HH')}
                        error={touched.kickoff_time && errors.kickoff_time}
                        onChange={(e: EventType) => {
                          handleTime(
                            setFieldValue,
                            e.target.value,
                            'hour',
                            values.kickoff_time,
                            'kickoff_time',
                          )
                        }}
                        className="form-control mt-1"
                        classNameLable="fs-15"
                        optionsList={hoursList}
                        classAddonOuter="f-1"
                      />
                    </div>
                    <div className="f-1 mr-1">
                      <Select
                        name="kickoff_time"
                        value={format(new Date(values.kickoff_time), 'mm')}
                        onChange={(e: EventType) => {
                          handleTime(
                            setFieldValue,
                            e.target.value,
                            'minute',
                            values.kickoff_time,
                            'kickoff_time',
                          )
                        }}
                        className="form-control mt-1"
                        classNameLable="fs-15"
                        optionsList={minuteList}
                        classAddonOuter="f-1"
                      />
                    </div>
                  </div>
                  <div className="f-1 row">
                    <div className="f-1 mr-1">
                      <Input
                        name="match_vanue"
                        label={t('matchVenue')}
                        error={touched.match_vanue && errors.match_vanue}
                        onChange={(e: EventType) => setFieldValue('match_vanue', e.target.value)}
                        className="form-control mt-1"
                        classNameLable="fs-15"
                      />
                    </div>
                  </div>
                  <div className="f-1 row">
                    <div className="f-1 mr-1">
                      <RichTextInput
                        label={t('additionalInfo')}
                        classAddon="bg-white"
                        value={values.match_desc}
                        onChange={(match_description) => {
                          setFieldValue('match_desc', match_description)
                        }}
                        error={touched.match_desc && (errors.match_desc as string | boolean)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </UpdateContainer>
        </Form>
      )}
    </Formik>
  )
}

export default memo(FixtureUpdate)
