import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {UserStateType} from '../actions/types'

const initialState = {user: {}} as UserState

export interface UserState {
  user: UserStateType
}
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<UserState>) => {
      state.user = action.payload.user
    },
  },
})
