import React, {memo, useState, useEffect} from 'react'
import {Formik, Form} from 'formik'
import {useParams, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import {Input} from '../../component'
import {
  addUserManagement,
  clearUserManagementDetail,
  getCountryList,
  updateUserManagement,
} from '../../store/actions'
import {RootState} from '../../store/slices'
import {useAppDispatch, useSelector} from '../../store'
import {serializeFormData} from '../../functions/utils'
import UpdateContainer from '../contentManagement/common/updateContainer'
import {EventType, FormActionType} from '../contentManagement/type'
import {UserManagementRequestType} from './type'
import CountryList from '../../component/countryList'

const UserUpdate = () => {
  const {t} = useTranslation()
  const navigation = useNavigate()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const {userManagementDetail, countryList} = useSelector(
    ({userManagement, country}: RootState) => ({
      userManagementDetail: userManagement.userManagementDetail,
      countryList: country.countryList,
    }),
  )

  useEffect(() => {
    dispatch(getCountryList())
  }, [])
  const params = useParams()
  const id = (params?.id && parseInt(params.id, 10) && userManagementDetail?.id) || undefined
  if (!id) {
    dispatch(clearUserManagementDetail())
  }
  const initialAddValues = {
    email: '',
    name: '',
    mobile_number: '',
    password: '',
    password_confirmation: '',
    country_id: '233',
  }
  const initialUpdateValues = {
    email: '',
    country_id: '',
    mobile_number: '',
    name: userManagementDetail?.name || '',
    password: '',
    password_confirmation: '',
  }
  const onSubmit = async (values: UserManagementRequestType) => {
    setLoading(true)
    if (id) {
      const formValues = {
        name: values.name || undefined,
        password: values.password || undefined,
        password_confirmation: values.password_confirmation || undefined,
        role_id: 1,
      }
      const response = await dispatch(updateUserManagement(id, formValues))
      if (response) navigation(`/user-management`)
      setLoading(false)
    } else {
      const formValues = {
        name: values.name || undefined,
        email: values.email || undefined,
        country_id: values.country_id || undefined,
        mobile_number: values.mobile_number || undefined,
        password: values.password || undefined,
        password_confirmation: values.password_confirmation || undefined,
        role_id: 1,
      }
      const formData = serializeFormData(formValues)
      const response = await dispatch(addUserManagement(formData))
      if (response) navigation(`/user-management`)
      setLoading(false)
    }
  }

  const validationSchemaAdd = Yup.object().shape({
    name: Yup.string().trim().required('Name cannot be empty.'),
    email: Yup.string()
      .email('Please enter valid email address.')
      .required('Email cannot be empty.'),
    // country_id: Yup.string().trim().required('Country cannot be empty.'),
    // mobile_number: Yup.string().trim().required('Phone cannot be empty.'),
    password: Yup.string().trim().required('Password cannot be empty.'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords should match.')
      .trim()
      .required('Confirm Password cannot be empty.'),
  })
  const validationSchemaUpdate = Yup.object().shape({
    name: Yup.string().trim().required('Name cannot be empty.'),
    password: Yup.string().trim().required('Password cannot be empty.'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords should match.')
      .trim()
      .required('Confirm Password cannot be empty.'),
  })

  const onClickListAction = ({command, submitForm}: FormActionType<UserManagementRequestType>) => {
    if (command === 'cancel') {
      navigation(`/user-management`)
    } else if (command === 'save' || command === 'update') {
      submitForm()
    }
  }

  return (
    <Formik
      initialValues={userManagementDetail?.id ? initialUpdateValues : initialAddValues}
      validationSchema={userManagementDetail?.id ? validationSchemaUpdate : validationSchemaAdd}
      onSubmit={onSubmit}
    >
      {({errors, touched, submitForm, setFieldValue, setValues}) => (
        <Form className="h-100">
          <UpdateContainer
            btn2Title="cancel"
            btn1Title={id ? 'update' : 'save'}
            title={id ? 'editUserDetails' : 'addUserDetails'}
            loading={loading}
            onClick={(command: string) => onClickListAction({command, setValues, submitForm})}
          >
            <div className="faqs-edit-box">
              <div className="flex f-0-3">
                <div className="mr-3 f-1">
                  <Input
                    name="name"
                    label={t('fullName')}
                    onChange={(e: EventType) => {
                      setFieldValue('name', e.target.value)
                    }}
                    error={touched.name && errors.name}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                  {!id && (
                    <Input
                      name="email"
                      label={t('email')}
                      onChange={(e: EventType) => {
                        setFieldValue('email', e.target.value)
                      }}
                      error={touched.email && errors.email}
                      className="form-control mt-1"
                      classNameLable="fs-15"
                    />
                  )}
                  {!id && Array.isArray(countryList) && countryList.length > 0 && (
                    <CountryList
                      name="mobile_number"
                      error={touched.mobile_number && errors.mobile_number}
                      countryError={touched.country_id && errors.country_id}
                      label="Phone"
                      onChange={(e: EventType) => {
                        setFieldValue('mobile_number', e.target.value)
                      }}
                      onSelect={(country) => {
                        setFieldValue('country_id', country.id)
                      }}
                    />
                  )}
                  <Input
                    name="password"
                    label={t('password')}
                    onChange={(e: EventType) => {
                      setFieldValue('password', e.target.value)
                    }}
                    error={touched.password && errors.password}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                  <Input
                    name="password_confirmation"
                    label={t('confirmPassword')}
                    onChange={(e: EventType) => {
                      setFieldValue('password_confirmation', e.target.value)
                    }}
                    error={touched.password_confirmation && errors.password_confirmation}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                </div>
              </div>
            </div>
          </UpdateContainer>
        </Form>
      )}
    </Formik>
  )
}

export default memo(UserUpdate)
