import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {matchContentFinalScore} from '../../actions/types'

export interface MatchContentFinalScoreState {
  matchContentFinalScore: matchContentFinalScore
}

const initialState = {matchContentFinalScore: {}} as MatchContentFinalScoreState

export const matchContentFinalScoreSlice = createSlice({
  name: 'matchContentFinalScoreData',
  initialState,
  reducers: {
    updateMatchContentFinalScore: (state, action: PayloadAction<MatchContentFinalScoreState>) => {
      state.matchContentFinalScore = action.payload.matchContentFinalScore
    },
    clearMatchContentFinalScore: (state) => {
      state.matchContentFinalScore = initialState.matchContentFinalScore
    },
  },
})
