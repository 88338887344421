import React, {memo, useCallback, useMemo, useState, useEffect} from 'react'
import {createTable} from '@tanstack/react-table'
import {useTranslation} from 'react-i18next'
import {UpdateListType} from '../../component/type'
import {List as ListView, Popup, Sort} from '../../component'
import ListRight from '../../component/listRight'
import {useSelector, useAppDispatch} from '../../store'
import {ModearationType} from '../../store/actions/types'
import {UndoDenyButtonList, DenyButtonList} from '../../component/List/Commands'
import {addSettingList, inModeration, userOptionList} from '../../data'
import {
  clearModerationDetail,
  getAppSettingList,
  getModerationList,
  updateModerationStatus,
} from '../../store/actions'
import {getRole} from '../../store/selectors/user'
import {moderationActions} from '../../store/slices'
import {serializeFormData} from '../../functions/utils'
import {defaultPageSize} from '../../configs/paginationSetting'

const table = createTable().setRowType<ModearationType>()

const Moderation = memo(() => {
  const {t} = useTranslation()
  const role = useSelector(getRole)
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [commandValue, setCommandValue] = useState('')
  const isMaster = role === 'master_user'
  const {list, total, from, moderationReportList, moderationDetails} = useSelector(
    ({moderation, appSetting}) => ({
      list: moderation.modearationList,
      total: moderation.total,
      from: moderation.from,
      moderationReportList: appSetting.moderationReportList,
      moderationDetails: moderation.modearationDetail,
    }),
  )
  const defaultOptions = useMemo(
    () => ({
      sort_by: 'created_at',
      sort_order: 'desc',
      page: 1,
      per_page: defaultPageSize,
    }),
    [],
  )
  const [options, setOptions] = useState(defaultOptions)
  const dispatch = useAppDispatch()

  const handleModerationReportType = async () => {
    await dispatch(getAppSettingList(isMaster, addSettingList.moderationReportType))
    await dispatch(getModerationList(options))
  }
  useEffect(() => {
    handleModerationReportType()
  }, [options])

  const columns = useMemo(
    () => [
      table.createDataColumn('content_data', {
        header: 'CONTENT',
        size: 400,
        cell: ({row}) => <span>{row.original?.content_data}</span>,
      }),
      table.createDataColumn('moderation_report_type', {
        header: 'ISSUE',
        size: 600,
        cell: ({row}) => (
          <div style={{fontWeight: 'bold'}}>
            {row.original?.original_url && (
              <img src={row.original?.original_url} alt="" className="img-testimonials-list mr-2" />
            )}
            <span>{moderationReportList[`${row.original?.moderation_report_type || '0'}`]}</span>
          </div>
        ),
      }),
      table.createDataColumn('posted_by', {
        header: 'POSTED BY',
        size: 300,
        cell: ({row}) => <span>{row.original?.posted_by}</span>,
      }),
      table.createDataColumn('id', {
        header: '',
        cell: ({row}) =>
          row.original && (
            <ListRight
              row={row.original}
              onClick={onUpdatePress}
              buttonList={[row.original?.in_moderation ? DenyButtonList : UndoDenyButtonList]}
            />
          ),
      }),
    ],
    [from],
  )

  const onSortChange = useCallback(
    (option: string) => {
      if (option === 'created_at-desc') {
        setOptions({...options, sort_by: 'created_at', sort_order: 'desc', page: 1})
      } else if (option === 'created_at-asc') {
        setOptions({...options, sort_by: 'created_at', sort_order: 'asc', page: 1})
      }
    },
    [options],
  )

  const onUpdatePress = async ({id, command}: UpdateListType) => {
    if (command === 'deny' || command === 'undo-deny') {
      setCommandValue(command)
      const data = list.filter((item) => item.id === id)
      await dispatch(moderationActions.updateModearationDetail({modearationDetail: data[0]}))
      setIsOpenPopup(true)
    } else if (command === 'undoDenyConfirmed') {
      setIsLoading(true)
      const dataTosend = {
        content_moderation_id: (moderationDetails && moderationDetails.id) || null,
        in_moderation: inModeration.issue,
        medias_model_id: (moderationDetails && moderationDetails.medias_model_id) || null,
      }
      const formData = serializeFormData(dataTosend)
      await dispatch(updateModerationStatus(formData))
      setIsLoading(false)
      setIsOpenPopup(false)
      dispatch(clearModerationDetail())
    } else if (command === 'denyConfirmed') {
      setIsLoading(true)
      const dataTosend = {
        content_moderation_id: (moderationDetails && moderationDetails.id) || null,
        in_moderation: inModeration.no_issue,
        medias_model_id: (moderationDetails && moderationDetails.medias_model_id) || null,
      }
      const formData = serializeFormData(dataTosend)
      await dispatch(updateModerationStatus(formData))
      setIsLoading(false)
      setIsOpenPopup(false)
      dispatch(clearModerationDetail())
    }
  }

  const onPaginate = useCallback(
    (page: number) => {
      setOptions({...options, page})
    },
    [options],
  )

  const onLimit = useCallback(
    (per_page: number) => {
      setOptions({...options, per_page, page: 1})
    },
    [options],
  )

  return (
    <div className="table-outer">
      <div className="table-header">
        <div className="c-carousal__header">
          <div className="c-carousal__header__lpart v-center">
            <span className="f-Exo-Bold title-color">{t('awaitingApproval')}</span>
          </div>
          <div className="c-carousal__header__rpart flex-center">
            <Sort optionList={userOptionList} onSortChange={onSortChange} classAddon="p-2" />
          </div>
        </div>
        <Popup
          isOpen={isOpenPopup}
          setIsOpen={setIsOpenPopup}
          onPressDelete={
            () =>
              onUpdatePress({
                id: (moderationDetails && moderationDetails.id) || 0,
                command: `${commandValue === 'deny' ? 'denyConfirmed' : 'undoDenyConfirmed'}`,
              })
            // eslint-disable-next-line react/jsx-curly-newline
          }
          loading={isLoading}
          btn2={commandValue === 'deny' ? 'deny' : 'undo-deny'}
          title={commandValue === 'deny' ? t('deny') : t('undoDeny')}
          description={commandValue === 'deny' ? t('denyText') : t('undoDenyText')}
        />
      </div>
      <ListView
        columns={columns}
        data={list}
        total={total}
        onPaginate={onPaginate}
        forcePageIndex={options.page - 1}
        options={options}
        isPageSize
        onLimit={onLimit}
        pageSize={options?.per_page}
      />
    </div>
  )
})

export default Moderation
