import {AppDispatch} from '..'
import {apiErrorBlock} from '../../functions/utils'
import Api, {urls} from '../../services/api'
import {socialConfigAction} from '../slices'
import {ApiResponseType, SocialConfigType} from './types'

export const getSocialConfig = () => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseType<SocialConfigType> = await Api.get(`${urls.SocialConfigGet}`)
    const {status, data} = response.data
    if (status && data) {
      await dispatch(socialConfigAction.updateSocialConfig(data))
    }
    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}

export const addSocialConfig = (params: any) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseType<SocialConfigType> = await Api.post(
      `${urls.SocialConfigSet}`,
      params,
    )
    const {status, message} = response.data
    if (status && message) {
      await dispatch(getSocialConfig())
      await dispatch(socialConfigAction.updateSocialConfig(params))
    }
    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}

export const clearSocialConfig = (params: any) => async (dispatch: AppDispatch) => {
  try {
    await Api.post(`${urls.SocialConfigSet}`, params)
    await dispatch(socialConfigAction.clearSocialConfig(params))

    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
