import {Descendant} from 'slate'
import {MediaLibraryItem} from '../../store/actions/types'

interface FormItemType {
  title: string
  accept: any
  maxFiles: number
  label: string
  placeholder: string
  btn1Title: string
  btn2Title: string
  inputLabel: string
  editorLabel: string
  featuredTitle: string
}
export interface FormviewType {
  tabItem: FormItemType
  news_type: NewsType
  tabData: PreviewDataType
  files: never[]
  setTabData: React.Dispatch<React.SetStateAction<PreviewDataType>>
  setFiles: React.Dispatch<React.SetStateAction<never[]>>
  setIsOpen: (isOpen: boolean) => void
  attachMedia: MediaLibraryItem[]
  setAttachMedia: React.Dispatch<React.SetStateAction<MediaLibraryItem[]>>
  setSingleTab: React.Dispatch<any>
  handleResetSort?: () => void
}
export interface socialMediaType {
  icon: string
  name: string
}
export interface MediaType {
  medias_id: number
  original_url: string
  file_name: string
  file?: Blob
}
export interface PreviewDataType {
  id?: number
  news_slug: string
  artical?: MediaType
  video?: MediaType
  gallery?: MediaType[]
  medias_model?: MediaType[]
  news_type: NewsType
  created_at: string
  news_title: string
  news_description: Descendant[]
  attach_media_id?: number[]
  attach_media?: MediaLibraryItem[]
}

export enum NewsType {
  'article' = 0,
  'gallery' = 1,
  'video' = 2,
}

export interface AddNewsStoryType {
  setIsOpen: (isOpen: boolean) => void
  newsType?: number
  singleTab?: number
  setSingleTab: React.Dispatch<any>
  handleResetSort?: () => void
  setNewType: React.Dispatch<any>
}
