import React, {useEffect, useState} from 'react'
import {ReactNotifications} from 'react-notifications-component'
import Cookies from 'universal-cookie'
import Header from './header'
import Sidebar from './sidebar'
import Breadcrumb from './breadcrumb'
import Router from '../router'
import {onUserLogin} from '../store/actions'
import {useSelector, useAppDispatch} from '../store'
import {getRole} from '../store/selectors/user'
import '../assets/styles/main.css'
import '../assets/styles/style.css'
import 'react-notifications-component/dist/theme.css'
import '../assets/styles/table.css'

const Main = () => {
  const dispatch = useAppDispatch()
  const role = useSelector(getRole)
  const cookies = new Cookies()
  const [loading, setLoading] = useState(true)
  const isMaster = role === 'master_user'
  useEffect(() => {
    const getUser = async () => {
      const user = cookies.get('user')
      if (user?.token) {
        await dispatch(onUserLogin(user))
      } else window.open('https://devdemo.gdxsports.com/login', '_self')
      setLoading(false)
    }
    getUser()
  }, [])

  return (
    <>
      <Header isMaster={isMaster} />
      <ReactNotifications />
      <section className={`c-wrapper c-bg-gray-ee ${isMaster && 'pt-0'}`}>
        <div className="container-fluid">
          <div className="row">
            <Sidebar isMaster={isMaster} />
            <div className={`flex-1 flex-d-column ${isMaster && 'py-3'} ml-3`}>
              {!isMaster && <Breadcrumb />}
              {loading ? <div>loading...</div> : <Router isMaster={isMaster} />}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Main
