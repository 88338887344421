import Api, {urls} from '../../services/api'
import {ApiResponseList, AddEventCommentaryType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {AppDispatch} from '..'
import {eventAction} from '../slices'
import {apiErrorBlock} from '../../functions/utils'

export const getCommentaryEventList = () => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<AddEventCommentaryType[]> = await Api.get(
      `${urls.matchCommentaryEvent}`,
    )
    const {status, message, data} = response.data
    if (status && data?.data) {
      await dispatch(
        eventAction.updateEventList({
          eventList: data.data,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return []
  } catch (e: any) {
    apiErrorBlock(e)
    return []
  }
}
