import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import SidebarHeader from './header'
import SidebarList from './list'
import {getSidebar} from '../../data'
import {useSelector} from '../../store'
import {getRole} from '../../store/selectors/user'

const Sidebar = (props: {isMaster: boolean}) => {
  const [showSubId, setShowSubId] = useState('')
  const [isActive, setIsActive] = useState('')
  const navigation = useNavigate()
  const {isMaster} = props
  const role = useSelector(getRole)
  const sidebarList = getSidebar(role)
  let defaultId = sidebarList.filter(
    (data) => data.link === `/${window.location.href.split('/')[3]}`,
  )
  let defaultIdSub = defaultId[0]?.subList.filter(
    (data) =>
      data.link === `/${window.location.href.split('/')[3]}/${window.location.href.split('/')[4]}`,
  )
  const [showId, setShowId] = useState(defaultId[0]?.id || '')
  const handleShow = (listShowId: string, urlLink: string) => {
    const currentId = showId || isActive
    if (listShowId !== currentId) {
      navigation(urlLink)
      const sublist = sidebarList.filter((data) => data.id === listShowId)
      setShowSubId(sublist[0].subList[0]?.id)
    }
    if (listShowId === showId) {
      setShowId('')
      setIsActive(listShowId)
    } else {
      setShowId(listShowId)
      setIsActive('')
    }
  }

  const handlePathChange = async () => {
    defaultId = await sidebarList.filter(
      (data) => data.link === `/${window.location.href.split('/')[3]}`,
    )
    defaultIdSub = await defaultId[0]?.subList.filter(
      (data) =>
        data.link ===
        `/${window.location.href.split('/')[3]}/${window.location.href.split('/')[4]}`,
    )
    setShowId(defaultId[0]?.id || '')
    setShowSubId(defaultIdSub[0]?.id || '')
    setIsActive('')
  }

  useEffect(() => {
    handlePathChange()
  }, [window.location.pathname])

  const handleReset = async () => {
    const currentUrl = await window.location.pathname
    if (currentUrl === '/404') {
      setShowId('')
      setIsActive('')
    } else {
      // handlePathChange()
    }
  }

  useEffect(() => {
    handleReset()
  })

  useEffect(() => {
    setShowId(defaultId[0]?.id)
    if (Array.isArray(defaultId) && defaultId[0]) {
      setShowSubId(defaultIdSub[0]?.id || defaultId[0]?.subList[0]?.id)
    }
  }, [window.location])

  return (
    <div className={` ${isMaster ? 'p-0' : 'w-240px'}`}>
      <div className={`c-first-column c-main-nav ${isMaster ? 'p-0' : 'possition-fix'}`}>
        <div className="c-left-nav">
          {!isMaster && <SidebarHeader />}
          <div className="c-accordion" id="leftNavbar">
            {Array.isArray(sidebarList) &&
              sidebarList.map((item, index) => {
                const isShow =
                  (isMaster && Array.isArray(item?.roles) && item.roles.includes('master_user')) ||
                  (!isMaster && Array.isArray(item?.roles) && item.roles.includes('club_user'))
                return isShow ? (
                  <SidebarList
                    item={item}
                    key={`sidebar-list-${item.id}`}
                    handleShow={handleShow}
                    showId={showId}
                    showSubId={showSubId}
                    setShowSubId={setShowSubId}
                    isActive={isActive}
                    lastItemClass={index === sidebarList.length - 1 ? 'sidebar-last-item' : ''}
                  />
                ) : (
                  <div />
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
