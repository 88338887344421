import React, {memo, useCallback, useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {Button, Input} from '../../component'
import ListItemView from './listItemView'
import {updateType} from './type'
import {EventType} from '../contentManagement/type'
import CountryList from '../../component/countryList'
import {getCountryList, getAccountSettingInfo, updateAccountSettingInfo} from '../../store/actions'
import {useAppDispatch, useSelector} from '../../store'
import {RootState} from '../../store/slices'
import {getRole} from '../../store/selectors/user'

const AccountManagement = memo(() => {
  const [isEditView, setIsEditView] = useState({
    name: false,
    // address: false,
    password: false,
    email: false,
    phone: false,
  })
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const {countryList} = useSelector(({country}: RootState) => ({
    countryList: country.countryList,
  }))
  const role = useSelector(getRole)
  const isMaster = role === 'master_user'
  useEffect(() => {
    dispatch(getCountryList())
    dispatch(getAccountSettingInfo(isMaster))
  }, [])
  const {myDetails} = useSelector(({accountSetting}) => ({
    myDetails: accountSetting.accountSetting,
  }))
  const onUpdatePress = useCallback(async ({id, command}: updateType) => {
    if (command === 'edit') {
      setIsEditView({...isEditView, [id]: true})
    } else if (command === 'cancel') {
      setIsEditView({...isEditView, [id]: false})
    }
  }, [])
  const initialFullName = {
    firstName: myDetails.user_data?.first_name || '',
    lastName: myDetails.user_data?.last_name || '',
  }
  // const initialAddress = {
  //   addressLine1: myDetails.addressLine1 || '',
  //   addressLine2: myDetails.addressLine2 || '',
  // }
  const initialPassword = {
    password: undefined,
    confirmPassword: undefined,
    oldPassword: undefined,
  }
  const initialemail = {
    email: myDetails.email || '',
  }
  const initialMobileNumber = {
    country_id: myDetails.user_data?.country_id || undefined,
    mobile_number: myDetails.user_data?.mobile_number || undefined,
  }
  const validationFullNameSchema = Yup.object().shape({
    firstName: Yup.string().trim().required('First Name cannot be empty.'),
    lastName: Yup.string().trim().required('Last Name cannot be empty.'),
  })
  // const validationAddressSchema = Yup.object().shape({
  //   addressLine1: Yup.string().trim().required('Address Line 1 cannot be empty.'),
  // })
  const validationPasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().trim().required('Old password cannot be empty.'),
    password: Yup.string().trim().required('Password cannot be empty.'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .trim()
      .required('Confirm Password cannot be empty.'),
  })
  const validationEmailSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter valid email address.')
      .required('Email cannot be empty.'),
  })
  const validationMobileNumberSchema = Yup.object().shape({
    country_id: Yup.string().trim().required('Country cannot be empty.'),
    mobile_number: Yup.string().trim().required('Phone cannot be empty.'),
  })
  const onSubmit = async (values: any) => {
    // TODO: api call
    const updatedData = {
      first_name: values.firstName || myDetails.user_data.first_name,
      last_name: values.lastName || myDetails.user_data.last_name,
      email: values.email || myDetails.email,
      mobile_number: values.mobile_number || myDetails.user_data.mobile_number,
      country_id: values.country_id || myDetails.user_data.country_id,
      current_password: values.oldPassword || undefined,
      password: values.password || undefined,
      password_confirmation: values.confirmPassword || undefined,
    }
    dispatch(updateAccountSettingInfo(isMaster, updatedData))
    setIsEditView({
      name: false,
      password: false,
      email: false,
      phone: false,
    })
    console.log(updatedData)
  }

  return (
    <div className="table-outer">
      <div className="table-header">
        <div className="c-carousal__header border-light-gray">
          <div className="c-carousal__header__lpart v-center mt-2 mb-1">
            <span className="font-b fn-style-i f-Exo-Bold fn-18">
              {t('myDetails').toUpperCase()}
            </span>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <ListItemView
          isEdit
          title="name"
          value={`${myDetails.user_data?.first_name} ${myDetails.user_data?.last_name}`}
          onUpdatePress={onUpdatePress}
          isEditView={isEditView.name}
        >
          <div className="f-0-3">
            <Formik
              initialValues={initialFullName}
              validationSchema={validationFullNameSchema}
              onSubmit={onSubmit}
            >
              {({touched, errors, setFieldValue, submitForm}) => (
                <Form>
                  <Input
                    name="firstName"
                    label={t('firstName')}
                    error={touched.firstName && errors.firstName}
                    onChange={(e: EventType) => setFieldValue('firstName', e.target.value)}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                  <Input
                    name="lastName"
                    label={t('lastName')}
                    error={touched.lastName && errors.lastName}
                    onChange={(e: EventType) => setFieldValue('lastName', e.target.value)}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                  <Button
                    classAddon="c-button--small f-Exo-Bold mr-2"
                    title={t('save')}
                    type="submit"
                    onClick={submitForm}
                  />
                  <Button
                    classAddon="c-button--small c-button--br c-button--br--gray-ab bg-transparent mr-2 f-Exo-Bold"
                    title={t('cancel')}
                    onClick={() => onUpdatePress({id: 'name', command: 'cancel'})}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </ListItemView>
        <ListItemView
          isEdit
          title="email"
          value={myDetails.email}
          onUpdatePress={onUpdatePress}
          isEditView={isEditView.email}
        >
          <div className="f-0-3">
            <Formik
              initialValues={initialemail}
              validationSchema={validationEmailSchema}
              onSubmit={onSubmit}
            >
              {({touched, errors, setFieldValue, submitForm}) => (
                <Form>
                  <Input
                    name="email"
                    label={t('email')}
                    error={touched.email && errors.email}
                    onChange={(e: EventType) => setFieldValue('email', e.target.value)}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                  <Button
                    classAddon="c-button--small f-Exo-Bold mr-2"
                    title={t('save')}
                    type="submit"
                    onClick={submitForm}
                  />
                  <Button
                    classAddon="c-button--small c-button--br c-button--br--gray-ab bg-transparent mr-2 f-Exo-Bold"
                    title={t('cancel')}
                    onClick={() => onUpdatePress({id: 'email', command: 'cancel'})}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </ListItemView>
        <ListItemView
          isEdit
          title="phone"
          value={`+${
            countryList.filter((data) => data.id === myDetails.user_data?.country_id)[0]
              ?.dialling_code
          } ${myDetails.user_data?.mobile_number}`}
          onUpdatePress={onUpdatePress}
          isEditView={isEditView.phone}
        >
          <div className="f-0-3">
            <Formik
              initialValues={initialMobileNumber}
              validationSchema={validationMobileNumberSchema}
              onSubmit={onSubmit}
            >
              {({touched, errors, setFieldValue, submitForm}) => (
                <Form>
                  {Array.isArray(countryList) && countryList.length > 0 && (
                    <CountryList
                      name="mobile_number"
                      error={touched.mobile_number && errors.mobile_number}
                      countryError={touched.country_id && errors.country_id}
                      defaultCountryId={myDetails.user_data.country_id}
                      label="Phone"
                      onChange={(e: EventType) => {
                        setFieldValue('mobile_number', e.target.value)
                      }}
                      onSelect={(country) => {
                        setFieldValue('country_id', country.id)
                      }}
                    />
                  )}
                  <Button
                    classAddon="c-button--small f-Exo-Bold mr-2"
                    title={t('save')}
                    type="submit"
                    onClick={submitForm}
                  />
                  <Button
                    classAddon="c-button--small c-button--br c-button--br--gray-ab bg-transparent mr-2 f-Exo-Bold"
                    title={t('cancel')}
                    onClick={() => onUpdatePress({id: 'phone', command: 'cancel'})}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </ListItemView>
        {/* <ListItemView
          isEdit
          title="address"
          value={`${myDetails.addressLine1}, ${myDetails.addressLine2}`}
          onUpdatePress={onUpdatePress}
          isEditView={isEditView.address}
        >
          <div className="f-0-3">
            <Formik
              initialValues={initialAddress}
              validationSchema={validationAddressSchema}
              onSubmit={onSubmit}
            >
              {({touched, errors, setFieldValue, submitForm}) => (
                <Form>
                  <Input
                    name="addressLine1"
                    label={t('addressLine1')}
                    error={touched.addressLine1 && errors.addressLine1}
                    onChange={(e: EventType) => setFieldValue('addressLine1', e.target.value)}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                  <Input
                    name="addressLine2"
                    label={t('addressLine2')}
                    error={touched.addressLine2 && errors.addressLine2}
                    onChange={(e: EventType) => setFieldValue('addressLine2', e.target.value)}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                  <Button
                    classAddon="c-button--small f-Exo-Bold mr-2"
                    title={t('save')}
                    type="submit"
                    onClick={submitForm}
                  />
                  <Button
                    classAddon="c-button--small c-button--br c-button--br--gray-ab bg-transparent mr-2 f-Exo-Bold"
                    title={t('cancel')}
                    onClick={() => onUpdatePress({id: 'address', command: 'cancel'})}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </ListItemView> */}
        <ListItemView
          isEdit
          title="password"
          value="●●●●●●●"
          onUpdatePress={onUpdatePress}
          isEditView={isEditView.password}
        >
          <div className="f-0-3">
            <Formik
              initialValues={initialPassword}
              validationSchema={validationPasswordSchema}
              onSubmit={onSubmit}
            >
              {({touched, errors, setFieldValue, submitForm}) => (
                <Form>
                  <Input
                    type="password"
                    name="oldPassword"
                    label={t('oldPassword')}
                    error={touched.oldPassword && errors.oldPassword}
                    onChange={(e: EventType) => setFieldValue('oldPassword', e.target.value)}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                  <Input
                    type="password"
                    name="password"
                    label={t('newPassword')}
                    error={touched.password && errors.password}
                    onChange={(e: EventType) => setFieldValue('password', e.target.value)}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                  <Input
                    type="password"
                    name="confirmPassword"
                    label={t('confirmPassword')}
                    error={touched.confirmPassword && errors.confirmPassword}
                    onChange={(e: EventType) => setFieldValue('confirmPassword', e.target.value)}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                  <Button
                    classAddon="c-button--small f-Exo-Bold mr-2"
                    title={t('save')}
                    type="submit"
                    onClick={submitForm}
                  />
                  <Button
                    classAddon="c-button--small c-button--br c-button--br--gray-ab bg-transparent mr-2 f-Exo-Bold"
                    title={t('cancel')}
                    onClick={() => onUpdatePress({id: 'address', command: 'cancel'})}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </ListItemView>
      </div>
    </div>
  )
})

export default AccountManagement
