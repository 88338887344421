import React, {memo, useState, useCallback, useMemo} from 'react'
import Modal from 'react-modal'
import {useTranslation} from 'react-i18next'
import {Formik, Form} from 'formik'
import {Button, Input} from '.'
import {PromptType} from './type'
import {customStyles} from '../data'

const Propmt = ({isOpen, setIsOpen, title, description, btnTitle, onBtnClick}: PromptType) => {
  const [promptValue, setPromptValue] = useState('')
  const {t} = useTranslation()

  const onClickPressed = useCallback(() => {
    onBtnClick(promptValue)
    setIsOpen(false)
  }, [promptValue])

  return (
    <Modal
      isOpen={isOpen}
      style={useMemo(() => customStyles, [customStyles])}
      onRequestClose={() => setIsOpen(false)}
    >
      {title && (
        <div className="c-card__title pt-3">
          <h4>{title}</h4>
        </div>
      )}
      {description && (
        <div className="c-card__title pt-3 pb-1">
          <h6>{description}</h6>
        </div>
      )}
      <Formik initialValues={{promptValue: ''}} onSubmit={() => undefined}>
        <Form>
          <Input
            name="promptValue"
            value={promptValue}
            onChange={(e: {target: {value: string}}) => setPromptValue(e.target.value)}
          />
        </Form>
      </Formik>
      <div className="c-card__footer text-right">
        <Button
          title={t('cancel')}
          classAddon="c-button--bg--black-33 mr-3"
          onClick={() => setIsOpen(false)}
        />
        <Button title={btnTitle || t('add')} onClick={onClickPressed} />
      </div>
    </Modal>
  )
}

export default memo(Propmt)
