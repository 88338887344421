import React from 'react'
import {Button} from '../../../component'
import {matchLiveCommentary} from '../../../store/actions/types'

const NormalCommentary = ({
  item,
  isBottomLine,
  handleDeleteCommentary,
}: {
  item: matchLiveCommentary
  isBottomLine: boolean
  handleDeleteCommentary: (id: number, match_fixtures_id: number) => void
}) => {
  const {commentary_time, commentary_text, commentary_type, id, match_fixtures_id} = item
  return (
    <>
      <div className="flex-1 flex-d-row p-4">
        <div className="flex-center f-0-1 flex-d-row">
          <div className="flex-1-center-v">
            <img
              src={commentary_type.medias_model[0].original_url}
              className="img_preview_commentary"
              alt="loader"
            />
          </div>
          <div className="flex-1-center-v f-Exo-Bold">{`${commentary_time}'`}</div>
        </div>
        <div className="flex f-0-85">{commentary_text}</div>
        <div className="flex f-0-05 h-flex-end">
          <Button
            icon="c-icon--close"
            className=""
            onClick={() => handleDeleteCommentary(id, match_fixtures_id)}
          />
        </div>
      </div>
      {isBottomLine && <div style={{border: '1px solid #CFD3D6'}} />}
    </>
  )
}

export default NormalCommentary
