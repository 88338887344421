import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RoleItem, RolePermissionItem} from '../actions/types'

export interface RolesState {
  list: RoleItem[]
  total: number
  from: number
  permissionsList: RolePermissionItem[]
  permissionsTotal: number
  permissionsFrom: number
}

const initialState = {
  list: [],
  total: 0,
  from: 0,
  permissionsList: [],
  permissionsTotal: 0,
  permissionsFrom: 0,
} as RolesState

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    updateList: (state, action: PayloadAction<Partial<RolesState>>) => {
      if (action.payload.list && action.payload.total && action.payload.from) {
        state.list = action.payload.list
        state.total = action.payload.total
        state.from = action.payload.from
      }
    },
    updateRolePermissions: (state, action: PayloadAction<Partial<RolesState>>) => {
      if (
        action.payload.permissionsList &&
        action.payload.permissionsTotal &&
        action.payload.permissionsFrom
      ) {
        state.permissionsList = action.payload.permissionsList
        state.permissionsTotal = action.payload.permissionsTotal
        state.permissionsFrom = action.payload.permissionsFrom
      }
    },
    resetRolePermissions: (state) => {
      state.permissionsList = []
      state.permissionsTotal = 0
      state.permissionsFrom = 0
    },
  },
})
