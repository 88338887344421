import React from 'react'
import {useTranslation} from 'react-i18next'
import {Button} from '../../component'
import {SidebarSubType} from './type'

const SidebarSubList = ({item, showSubId, handleSubShow}: SidebarSubType) => {
  const {t} = useTranslation()
  const {icon, title, id, link} = item
  return (
    <li className={`${showSubId === id && 'active'}`}>
      <Button onClick={() => handleSubShow(id, link)} className="" icon={icon} title={t(title)} />
    </li>
  )
}

export default SidebarSubList
