import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {matchContentMatchInfo} from '../../actions/types'

export interface MatchContentMatchInfoState {
  matchContentMatchInfo: matchContentMatchInfo
}

const initialState = {matchContentMatchInfo: {}} as MatchContentMatchInfoState

export const matchContentMatchInfoSlice = createSlice({
  name: 'matchContentMatchInfoData',
  initialState,
  reducers: {
    updateMatchContentMatchInfo: (state, action: PayloadAction<MatchContentMatchInfoState>) => {
      state.matchContentMatchInfo = action.payload.matchContentMatchInfo
    },
  },
})
