/* eslint-disable react/jsx-curly-newline */
import React, {memo} from 'react'
import {SortPageType} from './type'

const PageSizeSelect = ({
  optionList,
  onLimitValueChange,
  classAddon,
  classAddonInner,
  defaultValue,
}: SortPageType) => (
  <div className={`${classAddon}`}>
    <div className="c-card__header__right">
      <div className="c-form-group m-0">
        <select
          className={`form-control ${classAddonInner}`}
          defaultValue={defaultValue || optionList[0].value}
          onChange={(e: {target: {value: string}}) =>
            onLimitValueChange(parseInt(e.target.value, 10))
          }
        >
          {Array.isArray(optionList) &&
            optionList.map((option) => (
              <option key={`option-list-${option.label}`} value={option.value}>
                {option.label}
              </option>
            ))}
        </select>
      </div>
    </div>
  </div>
)
export default memo(PageSizeSelect)
