import React, {memo} from 'react'
import {ClubInfoType} from './type'

const ClubInfo = memo(({clubName, url}: ClubInfoType) => {
  return (
    <>
      <div className="flex-center-h">
        <img src={url} alt="" className="img_preview-match-content-detail" />
      </div>
      <div className="flex-center-h pt-3">
        <span className="f-Exo-Bold">{clubName}</span>
      </div>
    </>
  )
})

export default ClubInfo
