import React from 'react'
import {daysOptionList} from '../../../data'
import ClubRevenueBody from './clubRevenueBody'
import ClubRevenueFooter from './clubRevenueFooter'
import ClubRevenueHeader from './clubRevenueHeader'
import {ClubRevenueType} from '../type'

const ClubRevenue = ({item}: ClubRevenueType) => {
  return (
    <div className="col-lg-auto">
      <div className="c-last-column pt-0">
        <div className="c-card">
          <ClubRevenueHeader item={item.clubRevenueHeader} filterList={daysOptionList} />
          <ClubRevenueBody item={item.clubRevenueBody} />
          <ClubRevenueFooter item={item.clubRevenueFooter} />
        </div>
      </div>
    </div>
  )
}
export default ClubRevenue
