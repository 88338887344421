import React, {memo, useCallback, useMemo, useState, useEffect} from 'react'
import {createTable} from '@tanstack/react-table'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {format} from 'date-fns'
import {UpdateListType} from '../../component/type'
import {Button, List as ListView, Sort} from '../../component'
import ListRight from '../../component/listRight'
import {useSelector, useAppDispatch} from '../../store'
import {ClubRequestType} from '../../store/actions/types'
import {ApproveButtonList, DenyButtonList} from '../../component/List/Commands'
import {userOptionList} from '../../data'
import {clearClubRequestDetail, getClubRequestList} from '../../store/actions'
import {defaultPageSize} from '../../configs/paginationSetting'

const table = createTable().setRowType<ClubRequestType>()

const ClubAdminRequest = memo(() => {
  const {t} = useTranslation()
  const navigation = useNavigate()
  const {list, total, from} = useSelector(({clubRequest}) => ({
    list: clubRequest.clubRequestList,
    total: clubRequest.total,
    from: clubRequest.from,
  }))
  const defaultOptions = useMemo(
    () => ({
      sort_by: 'created_at',
      sort_order: 'desc',
      page: 1,
      per_page: defaultPageSize,
    }),
    [],
  )
  const [options, setOptions] = useState(defaultOptions)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(clearClubRequestDetail())
    dispatch(getClubRequestList(options))
  }, [options])

  const columns = useMemo(
    () => [
      table.createDataColumn('id', {
        header: 'NUMBER',
        size: 200,
        cell: ({row}) => <span>{String(row.index + from).padStart(2, '0')}</span>,
      }),
      table.createDataColumn('journey_club', {
        header: 'NAME',
        size: 400,
        cell: ({row}) => (
          <span>
            {row.original?.first_name} {row.original?.last_name}
          </span>
        ),
      }),
      table.createDataColumn('journey_club', {
        header: 'CLUB NAME',
        size: 400,
        cell: ({row}) => <span>{row.original?.journey_club?.club_name}</span>,
      }),
      table.createDataColumn('email', {
        header: 'EMAIL',
        size: 600,
        cell: ({row}) => <span>{row.original?.email}</span>,
      }),
      table.createDataColumn('created_at', {
        header: 'DATE & TIME',
        size: 300,
        cell: ({row}) => (
          <span>
            {(row.original?.created_at &&
              format(new Date(`${row.original?.created_at}`), 'dd MMM yyyy, hh:mm a')) ||
              format(new Date(), 'dd MMM yyyy, hh:mm a')}
          </span>
        ),
      }),
      table.createDataColumn('id', {
        header: '',
        cell: ({row}) =>
          row.original && (
            <ListRight
              row={row.original}
              onClick={onUpdatePress}
              buttonList={[ApproveButtonList, DenyButtonList]}
            />
          ),
      }),
    ],
    [from],
  )

  const onSortChange = useCallback(
    (option: string) => {
      if (option === 'created_at-desc') {
        setOptions({...options, sort_by: 'created_at', sort_order: 'desc', page: 1})
      } else if (option === 'created_at-asc') {
        setOptions({...options, sort_by: 'created_at', sort_order: 'asc', page: 1})
      }
    },
    [options],
  )

  const onUpdatePress = async ({id, command}: UpdateListType) => {
    if (command === 'deny' || command === 'approve') {
      navigation(`/club-admin-request/${id}`)
    }
  }

  const onPaginate = useCallback(
    (page: number) => {
      setOptions({...options, page})
    },
    [options],
  )

  const onLimit = useCallback(
    (per_page: number) => {
      setOptions({...options, per_page, page: 1})
    },
    [options],
  )

  return (
    <div className="table-outer">
      <div className="table-header">
        <div className="c-carousal__header">
          <div className="c-carousal__header__lpart v-center ">
            <span className="f-Exo-Bold title-color">{t('clubAdminRequestList')}</span>
          </div>
          <div className="c-carousal__header__rpart flex-center">
            <Button
              icon="c-icon--plus"
              title={t('addNewClub')}
              classAddon="c-button--we-160 c-button--icons f-Exo-Medium"
              onClick={() => navigation(`/club-management/add`)}
            />
            <div>
              <Sort optionList={userOptionList} onSortChange={onSortChange} classAddon="p-2" />
            </div>
          </div>
        </div>
      </div>
      <ListView
        columns={columns}
        data={list}
        total={total}
        onPaginate={onPaginate}
        forcePageIndex={options.page - 1}
        options={options}
        isPageSize
        onLimit={onLimit}
        pageSize={options?.per_page}
      />
    </div>
  )
})

export default ClubAdminRequest
