import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {UserManagementType} from '../actions/types'

export interface UserManagementState {
  userManagementList: UserManagementType[]
  total: number
  from: number
  userManagementDetail: UserManagementType
}

const initialUserManagementDetail = {
  id: 0,
  name: '',
  created_at: '',
  email: '',
  master_user_detail: {
    mobile_number: '',
    user_country: {
      dialling_code: '',
    },
  },
}
const initialState = {
  userManagementList: [],
  total: 0,
  from: 0,
  userManagementDetail: initialUserManagementDetail,
} as UserManagementState

export const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    updateUserManagementList: (state, action: PayloadAction<Partial<UserManagementState>>) => {
      const {userManagementList, total, from} = action.payload
      state.userManagementList = userManagementList || initialState.userManagementList
      state.total = total || initialState.total
      state.from = from || initialState.from
    },
    updateUserManagementDetail: (state, action: PayloadAction<Partial<UserManagementState>>) => {
      state.userManagementDetail = action.payload.userManagementDetail || state.userManagementDetail
    },
    clearUserManagementDetail: (state) => {
      state.userManagementDetail = initialState.userManagementDetail
    },
  },
})
