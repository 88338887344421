/* eslint-disable react/no-children-prop */
import React, {memo, useEffect, useState} from 'react'
import REAutocomplete from 'react-autocomplete'
import {LeagueType} from '../store/actions/types'
import {EventType} from '../container/contentManagement/type'
import {useAppDispatch} from '../store'
import {getLeagueListWithoutRedux} from '../store/actions'
import {AutoLeagueSelect} from './type'
import {defaultPageSize} from '../configs/paginationSetting'

const LeagueSearch = memo(
  ({handleChange, setFieldValue, classNameOuter, defaultValue, error}: AutoLeagueSelect) => {
    const dispatch = useAppDispatch()
    const [leagueList, setLeagueList] = useState<LeagueType[]>()
    const [searchTerms, setSearchTerms] = useState<string>(defaultValue || '')

    const handleSelect = (value: string) => {
      const selectedLeague =
        leagueList && leagueList.filter((league) => league.title.toString() === value)[0]
      if (selectedLeague) {
        handleChange(selectedLeague, setFieldValue)
      } else {
        handleChange({title: value, id: 0}, setFieldValue)
      }
      setSearchTerms(value)
    }
    const handleFetClub = async () => {
      const totalLeagueList = await dispatch(
        getLeagueListWithoutRedux({
          page: 1,
          per_page: defaultPageSize,
          sort_by: 'title',
          sort_order: 'asc',
          title: searchTerms || '',
        }),
      )
      setLeagueList(totalLeagueList)
    }

    useEffect(() => {
      handleFetClub()
    }, [searchTerms])

    useEffect(() => {
      setSearchTerms(defaultValue || '')
    }, [defaultValue])

    return (
      <div className={`c-form-group mb-0 ${classNameOuter || ''}`}>
        {Array.isArray(leagueList) && (
          <REAutocomplete
            getItemValue={(item: LeagueType) => item.title}
            items={leagueList}
            renderMenu={(items, value, styles) => (
              <div style={{...styles}} className="dropdown-style" children={items} />
            )}
            renderItem={(item, isHighlighted) => (
              <div
                className="py-1 px-3 dropdown-box"
                style={{
                  background: isHighlighted ? '#CF0B48' : 'white',
                  color: isHighlighted ? '#ffffff' : '#000000',
                }}
              >
                {item.title}
              </div>
            )}
            renderInput={(props) => (
              <input placeholder="Search League" {...props} className="form-control h-100" />
            )}
            wrapperStyle={{height: '100%'}}
            value={searchTerms}
            onChange={(e: EventType) => {
              handleSelect('')
              setSearchTerms(e.target.value)
            }}
            onSelect={(value: string) => {
              handleSelect(value)
            }}
          />
        )}
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    )
  },
)

export default LeagueSearch
