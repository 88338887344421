import Api, {urls} from '../../../services/api'
import {ApiResponseList, matchLiveCommentary} from '../types'
import logException from '../../../services/sentry'
import showNotification from '../../../functions/notification'
import {matchLiveCommentaryAction} from '../../slices'
import {AppDispatch} from '../..'
import {apiErrorBlock} from '../../../functions/utils'

export const getMatchLiveCommentary =
  (id: number, {page}: {page?: number}) =>
  async (dispatch: AppDispatch) => {
    try {
      const response: ApiResponseList<matchLiveCommentary[]> = await Api.get(
        `${urls.matchLiveCommentary}?${id ? `match_fixture_id=${id}` : ''}`,
        {
          params: {
            page,
          },
        },
      )
      const {message, data, status} = response.data
      if (status && data) {
        await dispatch(
          matchLiveCommentaryAction.updateMatchLiveCommentary({
            matchLiveCommentary: data.data as matchLiveCommentary[],
            total: data.total || 0,
            from: data.from || 0,
            loading: false,
          }),
        )
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      // eslint-disable-next-line prettier/prettier
    } catch (e: any) {
      apiErrorBlock(e)
    }
  }

export const saveLiveCommentary =
  (id: number, params: FormData) => async (dispatch: AppDispatch) => {
    try {
      const response: ApiResponseList<matchLiveCommentary> = await Api.post(
        `${urls.matchLiveCommentary}`,
        params,
      )
      const {status, message} = response.data
      if (status && message) {
        if (message) showNotification({title: 'Success', message, type: 'success'})
        await dispatch(getMatchLiveCommentary(id, {page: 1}))
        return true
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return false
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }

export const deleteLiveCommentary =
  (id: number, matchFixtuerID: number) => async (dispatch: AppDispatch) => {
    try {
      const response: ApiResponseList<matchLiveCommentary> = await Api.delete(
        `${urls.matchLiveCommentary}/${id}`,
      )
      const {status, message} = response.data
      if (status && message) {
        if (message) showNotification({title: 'Success', message, type: 'success'})
        await dispatch(getMatchLiveCommentary(matchFixtuerID, {page: 1}))
        return true
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return false
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }

export const setLiveCommentary =
  (id: number, match_Fixture_id: number) => async (dispatch: AppDispatch) => {
    try {
      await dispatch(
        matchLiveCommentaryAction.updateLiveCommentaryDetalis({
          matchLiveDetail: {id, match_Fixture_id},
        }),
      )
      return true
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }

export const clearLiveCommentary = () => async (dispatch: AppDispatch) => {
  try {
    await dispatch(matchLiveCommentaryAction.clearLiveCommentary())
    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
