import {OptionListType} from '../component/type'

export const defaultPageSize = 10
export const PageSizeList: OptionListType[] = [
  {
    id: 0,
    label: '5',
    value: 5,
  },
  {
    id: 1,
    label: '10',
    value: 10,
  },
  {
    id: 2,
    label: '15',
    value: 15,
  },
  {
    id: 3,
    label: '20',
    value: 20,
  },
  {
    id: 4,
    label: '50',
    value: 50,
  },
  {
    id: 5,
    label: '100',
    value: 100,
  },
]
