export enum ClubRequestStatus {
  'pending' = 0,
  'requested' = 1,
  'approved' = 2,
  'denied' = 3,
}

export enum RegistrationStepsStatus {
  'denied' = 0,
  'mobile_verified' = 1,
  'club_detail' = 2,
  'pending' = 3,
  'approved' = 4,
  'email_verified' = 5,
}
