import React, {memo, useState, useEffect} from 'react'
import {Tabs} from '../../component'
import {useSelector} from '../../store'
import {matchFixtureTabList} from '../../data'
import MatchFixtureList from './matchFixture'
import MatchResultList from './matchResult'
import ClubFixtureRequest from './matchRequest'

const MatchFixture = memo(({isMaster}: {isMaster: boolean}) => {
  const {activeTab} = useSelector(({tab}) => ({activeTab: tab.activeTab}))
  const [currentActiveTab, setCurrentActiveTab] = useState<number>(0)
  useEffect(() => {
    setCurrentActiveTab(activeTab && activeTab > 2 ? 0 : activeTab)
  }, [activeTab])
  return (
    <>
      {isMaster && (
        <div className="mb-4">
          <Tabs
            title=""
            tabList={matchFixtureTabList}
            news_type={currentActiveTab}
            setNews_type={setCurrentActiveTab}
            activeClass="active-gray"
            isManagement
          />
        </div>
      )}
      {(currentActiveTab === 0 || !isMaster) && <MatchFixtureList isMaster={isMaster} />}
      {currentActiveTab === 1 && isMaster && <MatchResultList />}
      {currentActiveTab === 2 && isMaster && <ClubFixtureRequest />}
    </>
  )
})

export default MatchFixture
