import React from 'react'
import {useTranslation} from 'react-i18next'
import {Button} from '../../component'
import listItemViewType from './type'

const ListItemView = ({
  title,
  value,
  isEdit,
  children,
  onUpdatePress,
  isEditView,
}: listItemViewType) => {
  const {t} = useTranslation()

  return (
    <div
      className="flex-1-center-v  my-detail-outer"
      style={{
        backgroundColor: `${isEditView ? '#F6F6F6' : ''}`,
      }}
    >
      <div className="f-Exo-Bold f-0-1">{t(title)}</div>
      <>
        {isEditView ? (
          <> {children}</>
        ) : (
          <>
            <div className={`f-0-8 ${!isEdit && 'disable-div'}`}>{value}</div>
            <div className="flex f-0-1 h-flex-end">
              {isEdit && (
                <div>
                  <Button
                    title={t('edit')}
                    classAddon="bg-null p-0"
                    style={{color: '#CF0B48'}}
                    onClick={() => onUpdatePress && onUpdatePress({id: title, command: 'edit'})}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </>
    </div>
  )
}

export default ListItemView
