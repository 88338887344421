import React from 'react'
import HeaderLeft from './headerLeft'
import HeaderRight from './headerRight'
import {headerData} from '../../data'

const Header = ({isMaster}: {isMaster: boolean}) => (
  <header className={`${!isMaster && 'h-60px'}`}>
    <div className={`c-header ${isMaster ? 'c-header-master' : 'possition-fix h-60px w-100'}`}>
      <div className={`container-fluid p-0 ${!isMaster && ''}`}>
        <div className="row">
          <HeaderLeft gdxLogo={isMaster ? headerData.gdxLogoMaster : headerData.gdxLogo} />
          <HeaderRight />
        </div>
      </div>
    </div>
  </header>
)

export default Header
