import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ModearationType} from '../actions/types'

export interface ModearationState {
  modearationList: ModearationType[]
  total: number
  from: number
  modearationDetail?: ModearationType
}

const initialModearationDetail = {
  id: 0,
  in_moderation: 0,
  module_field_type: '',
  original_url: '',
  module_type: 0,
  module_id: 0,
  content_data: '',
  posted_by: '',
  moderation_report_type: 0,
  medias_model_id: 0,
}

const initialState = {
  modearationList: [],
  total: 0,
  from: 0,
  modearationDetail: initialModearationDetail,
} as ModearationState

export const modearationSlice = createSlice({
  name: 'modearation',
  initialState,
  reducers: {
    updateModearationList: (state, action: PayloadAction<ModearationState>) => {
      state.modearationList = action.payload.modearationList
      state.total = action.payload.total
      state.from = action.payload.from
    },
    updateModearationDetail: (state, action: PayloadAction<Partial<ModearationState>>) => {
      state.modearationDetail = action.payload.modearationDetail || state.modearationDetail
    },
    clearModearationDetail: (state) => {
      state.modearationDetail = initialState.modearationDetail
    },
  },
})
