import React, {memo, useMemo} from 'react'
import Modal from 'react-modal'
import {useTranslation} from 'react-i18next'
import {Button} from '.'
import {PopupType} from './type'
import {customStyles} from '../data'

const Popup = ({
  isOpen,
  setIsOpen,
  title,
  description,
  onPressDelete,
  loading,
  btn1,
  btn2,
}: PopupType) => {
  const {t} = useTranslation()
  return (
    <Modal
      isOpen={isOpen}
      style={useMemo(() => customStyles, [customStyles])}
      onRequestClose={() => setIsOpen(false)}
    >
      <div className="c-card__title">
        <h4>{title}</h4>
      </div>
      <div className="c-card__title pt-3 pb-3">
        <h6 dangerouslySetInnerHTML={{__html: description}} />
      </div>
      <div className="c-card__footer text-right">
        <Button
          title={(btn1 && t(btn1)) || t('cancel')}
          classAddon="c-button--bg--black-33 mr-3"
          onClick={() => setIsOpen(false)}
        />
        <Button
          icon={loading ? 'c-icon--loader mr-2' : ''}
          title={(btn2 && t(btn2)) || t('delete')}
          onClick={onPressDelete}
        />
      </div>
    </Modal>
  )
}

export default memo(Popup)
