import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {PlayerManagementType} from '../actions/types'

export interface PlayerState {
  playerList: PlayerManagementType[]
  total: number
  from: number
  playerDetail?: PlayerManagementType
}

const initialPlayerDetail = {
  id: 0,
  club_id: 0,
  first_name: '',
  last_name: '',
  position: 0,
  shirt_number: 0,
  created_at: '',
  medias_model: [
    {
      medias_id: 0,
      file_name: '',
      original_url: '',
    },
  ],
}

const initialState = {
  playerList: [],
  total: 0,
  from: 0,
  playerDetail: initialPlayerDetail,
} as PlayerState

export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    updatePlayerList: (state, action: PayloadAction<PlayerState>) => {
      const {playerList, total, from} = action.payload
      state.playerList = !from || from === 1 ? playerList : [...state.playerList, ...playerList]
      state.total = total || state.total
      state.from = from || state.from
    },
    updatePlayerDetail: (state, action: PayloadAction<Partial<PlayerState>>) => {
      state.playerDetail = action.payload.playerDetail || state.playerDetail
    },
    clearPlayerDetail: (state) => {
      state.playerDetail = initialState.playerDetail
    },
  },
})
