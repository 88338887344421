import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AddEventCommentaryType} from '../actions/types'

export interface EventState {
  eventList: AddEventCommentaryType[]
}

const initialState = {
  eventList: [],
} as EventState

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    updateEventList: (state, action: PayloadAction<EventState>) => {
      state.eventList = action.payload.eventList || state.eventList
    },
  },
})
