import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {WidgetClubType} from '../actions/types'

const initialState = {widgetClub: {}} as WidgetClubState

export interface WidgetClubState {
  widgetClub: WidgetClubType
}
export const widgetClubSlice = createSlice({
  name: 'widgetClub',
  initialState,
  reducers: {
    updateWidgetClub: (state, action: PayloadAction<WidgetClubState>) => {
      state.widgetClub = action.payload.widgetClub
    },
  },
})
