import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {MatchContentType} from '../actions/types'

export interface MatchContentState {
  matchContentList: MatchContentType[]
  total: number
  from: number
}

const initialState = {matchContentList: [], total: 0, from: 0} as MatchContentState

export const matchContentSlice = createSlice({
  name: 'matchContent',
  initialState,
  reducers: {
    updateMatchContentList: (state, action: PayloadAction<MatchContentState>) => {
      state.matchContentList = action.payload.matchContentList
      state.total = action.payload.total
      state.from = action.payload.from
    },
  },
})
