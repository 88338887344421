import Api, {urls} from '../../services/api'
import {ApiResponseList, SingleSettingType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {apiErrorBlock} from '../../functions/utils'

export const getSingleSetting = async (params: {key: string}): Promise<SingleSettingType[]> => {
  try {
    const {key} = params
    const response: ApiResponseList<SingleSettingType[]> = await Api.get(
      `${urls.appSettings}?meta_key=${key}`,
    )
    const {status, message, data} = response.data
    if (status && data?.data && data?.data.length > 0 && data?.data[0]) {
      return data?.data
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return []
  } catch (e: any) {
    apiErrorBlock(e)
    return []
  }
}
export const addSingleSetting = async (params: SingleSettingType) => {
  try {
    const response: ApiResponseList<SingleSettingType[]> = await Api.post(
      `${urls.appSettings}`,
      params,
    )
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const updateSingleSetting = async (params: SingleSettingType) => {
  try {
    const response: ApiResponseList<SingleSettingType[]> = await Api.patch(
      `${urls.appSettings}/${params.id}`,
      params,
    )
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
