import React, {lazy} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import ClubAdminRequest from './container/clubAdminRequest'
import UpdateRequest from './container/clubAdminRequest/updateClubRequest'
import AccountManagement from './container/accountManagement'
import ClubManagement from './container/clubManagement'
import ClubUpdate from './container/clubManagement/clubUpdate'
import ContentManagement from './container/contentManagement'
import BenefitsUpdate from './container/contentManagement/benefit/benefitsUpdate'
import CarousalUpdate from './container/contentManagement/carousal/carousalUpdate'
import FaqUpdate from './container/contentManagement/faq/faqUpdate'
import TestimonialsUpdate from './container/contentManagement/testimonial/testimonialsUpdate'
import MatchContent from './container/matchContent'
import News from './container/news'
import PermissionsList from './container/security/permissions'
import PermissionsForm from './container/security/permissions/permissionform'
import Social from './container/social'
import MatchFixture from './container/matchFixture'
import FixtureUpdate from './container/matchFixture/fixtureUpdate'
import UserManagement from './container/userManagement'
import UserUpdate from './container/userManagement/userUpdate'
import RolesList from './container/security/roles'
import RolesForm from './container/security/roles/roleform'
import RolePermissions from './container/security/roles/rolepermissions'
import MediaLibrary from './container/medialibrary'
import PlayerManagement from './container/playerManagement'
import MatchDetail from './container/matchContent/matchDetail'
import Home from './container/home'
import MasterDashboard from './container/masterDashboard'
import LeagueManagement from './container/leagueManagement'
import LeagueUpdate from './container/leagueManagement/leagueUpdate'
import Moderation from './container/moderation'
import AdsManagement from './container/adsManagement'
import AdsUpdate from './container/adsManagement/adsUpdate'

const PageNotFound = lazy(() => import('./container/pageNotFound'))
function Router({isMaster}: {isMaster: boolean}) {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/404" />} />
      <Route path="/404" element={<PageNotFound />} />
      {isMaster ? (
        <Route path="/" element={<MasterDashboard />} />
      ) : (
        <Route path="/" element={<Home />} />
      )}
      <Route path="/match-fixture" element={<MatchFixture isMaster={isMaster} />} />
      <Route path="/match-fixture/:id" element={<FixtureUpdate isMaster={isMaster} />} />
      {isMaster && <Route path="/user-management" element={<UserManagement />} />}
      {isMaster && <Route path="/user-management/:id" element={<UserUpdate />} />}
      {!isMaster && (
        <Route path="/club-settings" element={<Navigate to="/club-settings/social-account" />} />
      )}
      {!isMaster && <Route path="/club-settings/social-account" element={<Social />} />}

      {!isMaster && <Route path="/content" element={<Navigate to="/content/news-stories" />} />}
      {!isMaster && <Route path="/content/news-stories" element={<News />} />}
      {!isMaster && <Route path="/content/match-content" element={<MatchContent />} />}
      {!isMaster && <Route path="/content/match-content/:id" element={<MatchDetail />} />}
      {!isMaster && <Route path="/content/media-library" element={<MediaLibrary />} />}

      {!isMaster && <Route path="/player-management" element={<PlayerManagement />} />}

      {isMaster && <Route path="/content-management" element={<ContentManagement />} />}
      {isMaster && <Route path="/content-management/carousal/:id" element={<CarousalUpdate />} />}
      {isMaster && <Route path="/content-management/faq/:id" element={<FaqUpdate />} />}
      {isMaster && (
        <Route path="/content-management/testimonial/:id" element={<TestimonialsUpdate />} />
      )}
      {isMaster && <Route path="/content-management/benefit/:id" element={<BenefitsUpdate />} />}
      {isMaster && <Route path="/club-admin-request" element={<ClubAdminRequest />} />}
      {isMaster && <Route path="/club-admin-request/:id" element={<UpdateRequest />} />}
      {isMaster && <Route path="/league-management" element={<LeagueManagement />} />}
      {isMaster && <Route path="/league-management/:id" element={<LeagueUpdate />} />}
      {isMaster && <Route path="/club-management" element={<ClubManagement />} />}
      {isMaster && <Route path="/club-management/:id" element={<ClubUpdate />} />}
      {isMaster && <Route path="/moderation" element={<Moderation />} />}
      {isMaster && <Route path="/security" element={<Navigate to="/security/permissions" />} />}
      {isMaster && <Route path="/security/permissions" element={<PermissionsList />} />}
      {isMaster && <Route path="/security/permissions/save/:id" element={<PermissionsForm />} />}
      {isMaster && <Route path="/security/permissions/save" element={<PermissionsForm />} />}
      {isMaster && <Route path="/security/roles" element={<RolesList />} />}
      {isMaster && <Route path="/security/roles/save/:id" element={<RolesForm />} />}
      {isMaster && <Route path="/security/roles/save" element={<RolesForm />} />}
      {isMaster && (
        <Route path="/security/roles/permissions/:roleId" element={<RolePermissions />} />
      )}
      <Route path="/account-settings" element={<AccountManagement />} />
      {isMaster && <Route path="/ads-management" element={<AdsManagement />} />}
      {isMaster && <Route path="/ads-management/:id" element={<AdsUpdate />} />}
    </Routes>
  )
}
export default Router
