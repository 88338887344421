const spanish = {
  translation: {
    counter: 'mostrador',
    increment: 'INCREMENTO',
    decrement: 'DECREMENTO',
    analytics: 'Analítica',
    getcookie: 'Obtener galleta',
    setcookie: 'Establecer galleta',
    checkSentry: 'Comprobar centinela (Solo producción)',
  },
}
export default spanish
