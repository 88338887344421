import React, {Suspense} from 'react'
import {Provider as ReduxProvider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {BrowserRouter} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import {BrowserTracing} from '@sentry/tracing'
import configs from './configs'
import store, {persistor} from './store'
import {Loader} from './component'
import './languages/i18n'
import Main from './container'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: configs.sentryDSN,
    integrations: [new BrowserTracing()],
    environment: 'PRODUCTION',
    tracesSampleRate: 1.0,
  })
}
function App() {
  return (
    <div className="data-theme">
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<Loader />}>
            <BrowserRouter>
              <Main />
            </BrowserRouter>
          </Suspense>
        </PersistGate>
      </ReduxProvider>
    </div>
  )
}
export default App
