/* eslint-disable react/jsx-curly-newline */
import React, {useCallback, useState} from 'react'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import {Button, Input, RichTextInput, Switch, Thumbnail, Popup} from '../../component'
import DragFile from '../../component/dragFile'
import ModelMediaList from '../medialibrary/modelMediaList'
import {FormviewType, MediaType, NewsType, PreviewDataType} from './type'
import {addNewsStory, updateNewsStory} from '../../store/actions'
import {useAppDispatch, useSelector} from '../../store'
import {checkRichTextEmpty, mapFilesToObject, serializeFormData} from '../../functions/utils'
import {MediaLibraryItem} from '../../store/actions/types'

const Formview = ({
  tabItem,
  news_type,
  tabData,
  setTabData,
  files,
  setFiles,
  setIsOpen,
  attachMedia,
  setAttachMedia,
  setSingleTab,
  handleResetSort,
}: FormviewType) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const {newsDetail, socialExpireData} = useSelector(({news, socialConfigExpire}) => ({
    newsDetail: news.newsDetail,
    socialExpireData: socialConfigExpire.socialConfigExpire,
  }))
  const [isFeatured, setIsFeatured] = useState(newsDetail.is_featured_story || false)
  const [isPublished, setIsPublished] = useState(newsDetail.is_published || false)
  const [showMediaLibrary, setShowMediaLibrary] = useState(false)
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [currentFormValue, setcurrentFormValue] = useState<PreviewDataType>()
  const dispatch = useAppDispatch()

  const addValidationSchema = Yup.object().shape({
    news_title: Yup.string()
      .trim()
      .required(`${t('headlineCannotBeEmpty')}.`),
    news_description: Yup.mixed().test(
      t('descriptionRequired'),
      t('descriptionCannotBeEmpty'),
      (value) => {
        const error = checkRichTextEmpty(value)
        return error
      },
    ),
  })
  const editValidationSchema = Yup.object().shape({
    news_title: Yup.string()
      .trim()
      .required(`${t('headlineCannotBeEmpty')}.`),
    news_description: Yup.mixed().test(
      t('descriptionRequired'),
      t('descriptionCannotBeEmpty'),
      (value) => {
        const error = checkRichTextEmpty(value)
        return error
      },
    ),
  })
  const onAddNewsStory = async (values: PreviewDataType) => {
    setLoading(true)
    const formValues: any = {}
    formValues.news_story_id = (values.id && values.id.toString()) || undefined
    formValues.news_type =
      Array.isArray(values.medias_model) && values.medias_model[0]
        ? values.news_type.toString()
        : Array.isArray(values.attach_media_id) && values.attach_media_id.length > 0
        ? values.news_type.toString()
        : '0'
    formValues.news_title = values.news_title || undefined
    if (Array.isArray(values.medias_model)) {
      if (news_type === 0 && values.medias_model[0] && values.medias_model[0].file) {
        formValues.artical = values.medias_model[0].file || undefined
      } else if (news_type === 2 && values.medias_model[0] && values.medias_model[0].file) {
        formValues.video = values.medias_model[0].file || undefined
      }
    }
    if (Array.isArray(values.attach_media_id) && values.attach_media_id.length > 0) {
      formValues.attach_media_id = values.attach_media_id
    }
    formValues.news_description = JSON.stringify(values.news_description) || undefined
    formValues.is_featured_story = isFeatured ? '1' : '0'
    formValues.is_published = isPublished ? '1' : '0'
    formValues.feed_type = '0'
    const formData = serializeFormData(formValues)
    if (Array.isArray(values.medias_model) && news_type === 1) {
      values.medias_model.forEach((media, index) => {
        if (media && media.file) formData.append(`gallery[${index}]`, media.file)
      })
    }
    if (values.id) {
      // START - deleted gallery object
      if (news_type === 1 && newsDetail?.medias_model && newsDetail.medias_model?.length > 0) {
        const deleted_media_id: number[] = []
        newsDetail.medias_model.forEach((media) => {
          let is_deleted = true
          files.forEach((file: MediaType) => {
            if (media.medias_id === file.medias_id) {
              is_deleted = false
            }
          })
          if (is_deleted) {
            deleted_media_id.push(media.medias_id)
          }
        })
        deleted_media_id.forEach((media_id, index) => {
          formData.append(`deleted_media_id[${index}]`, media_id.toString())
        })
      }
      // END - deleted gallery object
      const response = await dispatch(updateNewsStory(values.news_slug, formData))
      if (response) {
        if (handleResetSort) handleResetSort()
        setIsOpen(false)
        setSingleTab(undefined)
      }
    } else {
      const response = await dispatch(addNewsStory(formData))
      if (response) {
        if (handleResetSort) handleResetSort()
        setIsOpen(false)
        setSingleTab(undefined)
      }
    }
    setLoading(false)
  }

  const onFilesSelection = useCallback(
    (acceptedFiles: any) => {
      const newFiles = mapFilesToObject(acceptedFiles)
      if (news_type === 1) files.forEach((file: any) => newFiles.push(file))
      if (news_type === NewsType.article || news_type === NewsType.video) {
        setAttachMedia([])
      }
      setFiles(newFiles)
    },
    [tabItem, files],
  )

  const onFileRemove = useCallback(
    (medias_id: number) => {
      setFiles(files.filter((file: MediaType) => file.medias_id !== medias_id))
    },
    [files],
  )
  const onAttachFileRemove = useCallback(
    (medias_id: number) => {
      setAttachMedia(attachMedia.filter((file: MediaLibraryItem) => file.medias_id !== medias_id))
    },
    [attachMedia],
  )

  const onUpdatePress = async ({value, command}: {value: PreviewDataType; command: string}) => {
    if (command === 'submit') {
      setcurrentFormValue(value)
      setIsOpenPopup(true)
    } else if (command === 'submitConfirmed') {
      setLoading(true)
      await onAddNewsStory(value)
      setIsOpenPopup(false)
      setLoading(false)
    }
  }

  const popupDescription = `${
    socialExpireData?.is_fb_token_exist
      ? socialExpireData?.is_fb_token_expired
        ? 'Facebook connection expired please re-connect.'
        : `Facebook is connected. ${
            socialExpireData?.is_facebook_post_active && 'Enable for posting.'
          }`
      : 'Facebook is not Connected.'
  }<br/> <br/>${
    socialExpireData?.is_in_token_exist
      ? socialExpireData?.is_in_token_expired
        ? 'Instragram connection expired please re-connect.'
        : `Instragram is connected. ${
            socialExpireData?.is_instagram_post_active && 'Enable for posting.'
          }`
      : 'Instragram is not Connected.'
  }<br/> <br/>${
    socialExpireData?.is_tw_token_exist
      ? socialExpireData?.is_tw_token_expired
        ? 'Twitter connection expired please re-connect.'
        : `Twitter is connected. ${
            socialExpireData?.is_twiter_post_active && 'Enable for posting.'
          }`
      : 'Twitter is not Connected.'
  }`

  return (
    <div className="col-md-8">
      <Popup
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        onPressDelete={() =>
          currentFormValue && onUpdatePress({value: currentFormValue, command: 'submitConfirmed'})
        }
        title="Social media Configuration info"
        description={popupDescription}
        loading={loading}
        btn2={t('publish')}
      />
      <Formik
        onSubmit={(values) => onUpdatePress({value: values, command: 'submit'})}
        validationSchema={tabData.news_slug ? editValidationSchema : addValidationSchema}
        initialValues={tabData}
        enableReinitialize
      >
        {({errors, touched, submitForm}) => (
          <Form>
            <DragFile
              accept={tabItem.accept}
              maxFiles={
                news_type === 1
                  ? tabItem.maxFiles - files.length - attachMedia.length
                  : tabItem.maxFiles
              }
              label={t(tabItem.label)}
              placeholder={t(tabItem.placeholder)}
              btn1Title={t(tabItem.btn1Title)}
              btn2Title={t(tabItem.btn2Title)}
              onAddFromMediaLibrary={() => setShowMediaLibrary(true)}
              files={files}
              error={touched.medias_model && (errors.medias_model as string | boolean)}
              isThumbnail
              onChange={onFilesSelection}
              onRemove={onFileRemove}
              thumbType={news_type !== NewsType.video ? 'image' : 'video'}
              classAddonInner={news_type !== NewsType.article ? 'min-h-300' : ''}
            />
            <ModelMediaList
              isOpen={showMediaLibrary}
              options={{
                file_type: news_type === NewsType.article || news_type === NewsType.gallery ? 0 : 1,
              }}
              setIsOpen={setShowMediaLibrary}
              setAttachMedia={(attachMediaItems: MediaLibraryItem[]) => {
                if (news_type === NewsType.article || news_type === NewsType.video) {
                  setFiles([])
                }
                const attachMediaId: number[] = []
                const selectedItems: MediaLibraryItem[] = []
                attachMediaItems.forEach((attachMediaItem) => {
                  selectedItems.push({
                    ...attachMediaItem,
                    medias_id: attachMediaItem.id,
                    original_url: attachMediaItem.original_url,
                    file_name: attachMediaItem.file_name,
                  })
                  attachMediaId.push(attachMediaItem.id)
                })
                setAttachMedia(selectedItems)
              }}
              attachMedia={attachMedia}
              maxLimit={
                news_type === NewsType.article || news_type === NewsType.video
                  ? tabItem.maxFiles
                  : tabItem.maxFiles - files.length - attachMedia.length
              }
              isMulti={news_type === NewsType.gallery}
              outerClassName="scroll-y"
            />
            {Array.isArray(attachMedia) && attachMedia.length > 0 && (
              <Thumbnail
                files={attachMedia as MediaType[]}
                type={news_type === NewsType.video ? 'video' : undefined}
                handleRemove={onAttachFileRemove}
              />
            )}
            <Input
              name="news_title"
              value={tabData.news_title}
              label={t(tabItem.inputLabel)}
              onChange={(e: {target: {value: string}}) => {
                setTabData({...tabData, news_title: e.target.value})
              }}
              error={touched.news_title && errors.news_title}
            />
            <RichTextInput
              classAddon={news_type === NewsType.article ? 'min-h-300' : 'min-h-150'}
              label={t(tabItem.editorLabel)}
              value={tabData.news_description}
              onChange={(news_description) => {
                setTabData({...tabData, news_description})
              }}
              error={touched.news_description && (errors.news_description as string | boolean)}
            />
            <Switch
              label={t(tabItem.featuredTitle)}
              checked={isFeatured}
              onClick={() => setIsFeatured(!isFeatured)}
              isHorizontal
            />
            <div className="c-button-group">
              <Button
                title={t('save')}
                className="c-button c-button--bold c-button--small c-button--br c-button--br--gray-ab"
                onClick={() => {
                  setIsPublished(false)
                  submitForm()
                }}
              />
              <Button
                title={t('publish')}
                classAddon={`c-button--small ${loading ? 'disabled-link' : ''}`}
                icon={loading ? 'c-icon--loader mr-2' : ''}
                onClick={() => {
                  setIsPublished(true)
                  submitForm()
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Formview
