import React, {memo, useCallback, useMemo, useState, useEffect} from 'react'
import {createTable} from '@tanstack/react-table'
import {useTranslation} from 'react-i18next'
import {List as ListView, Sort} from '../../component'
import {useSelector, useAppDispatch} from '../../store'
import {TopEarningClubType} from '../../store/actions/types'
import {valueOptionList} from '../../data'
import {getTopEarningClubList} from '../../store/actions'

const table = createTable().setRowType<TopEarningClubType>()

const TopEarningClub = memo(() => {
  const {t} = useTranslation()
  const {list, total, from} = useSelector(({topEarningClub}) => ({
    list: topEarningClub.topEarningClubList,
    total: topEarningClub.total,
    from: topEarningClub.from,
  }))
  const defaultOptions = useMemo(
    () => ({
      sort_by: 'created_at',
      sort_order: 'desc',
      page: 1,
    }),
    [],
  )
  const [options, setOptions] = useState(defaultOptions)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getTopEarningClubList(options))
  }, [options])

  const columns = useMemo(
    () => [
      table.createDataColumn('id', {
        header: 'CLUB',
        size: 50,
        cell: ({row}) => <span className="f-Exo-Bold">{row.index + from}</span>,
      }),
      table.createDataColumn('club_name', {
        header: ' ',
        size: 600,
        cell: ({row}) => (
          <div>
            {row.original?.club_logo && row.original.club_logo.original_url && (
              <img
                src={row.original.club_logo.original_url}
                alt=""
                className="img-testimonials-list mr-2"
              />
            )}
            <span>{row.original?.club_name}</span>
          </div>
        ),
      }),
      table.createDataColumn('sport', {
        header: 'SPORT',
        size: 100,
        cell: ({row}) => <span>{row.original?.sport}</span>,
      }),
      table.createDataColumn('members', {
        header: 'MEMBERS',
        size: 100,
        cell: ({row}) => <span>{row.original?.members}</span>,
      }),
      table.createDataColumn('revenue', {
        header: 'REVENUE',
        size: 100,
        cell: ({row}) => <span className="f-Exo-Bold">{row.original?.revenue}</span>,
      }),
    ],
    [from],
  )

  const onSortChange = useCallback(
    (option: string) => {
      if (option === 'created_at-desc') {
        setOptions({...options, sort_by: 'created_at', sort_order: 'desc', page: 1})
      } else if (option === 'created_at-asc') {
        setOptions({...options, sort_by: 'created_at', sort_order: 'asc', page: 1})
      }
    },
    [options],
  )

  const onPaginate = useCallback(
    (page: number) => {
      setOptions({...options, page})
    },
    [options],
  )

  return (
    <div className="c-card">
      <div className="table-outer">
        <div className="table-header">
          <div className="c-carousal__header">
            <div className="c-carousal__header__lpart v-center">
              <span className="f-Exo-Bold title-color fn-18">{t('top100EarningClubs')}</span>
            </div>
            <div className="c-carousal__header__rpart flex-center">
              <Sort optionList={valueOptionList} onSortChange={onSortChange} classAddon="p-1" />
            </div>
          </div>
        </div>
        <ListView
          columns={columns}
          data={list}
          total={total}
          pageSize={5}
          onPaginate={onPaginate}
          forcePageIndex={options.page - 1}
        />
      </div>
    </div>
  )
})

export default TopEarningClub
