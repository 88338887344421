import Api, {urls} from '../../services/api'
import {ApiResponseType, CountryListType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {AppDispatch} from '..'
import {countryActions} from '../slices'
import {apiErrorBlock} from '../../functions/utils'

export const getCountryList = () => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseType<CountryListType[]> = await Api.get(`${urls.countryList}`)
    const {status, message, data} = response.data
    if (status && data && data.length > 0) {
      await dispatch(
        countryActions.updateCountryList({
          countryList: data,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return []
  } catch (e: any) {
    apiErrorBlock(e)
    return []
  }
}
