import React, {memo} from 'react'
import UpdateContainer from './common/updateContainer'
import LinkUpdate from './common/linkUpdate'
import {storeLinks} from '../../data'

const StoreLink = () => (
  <UpdateContainer
    btn3Title={undefined}
    btn2Title={undefined}
    btn1Title={undefined}
    title="appStoreLinks"
  >
    <div className="mt-40">
      {Array.isArray(storeLinks) &&
        storeLinks.map((item) => (
          <LinkUpdate
            metaKey={item.meta_key}
            key={`link-update-${item.meta_key}`}
            lable={item.lable}
            placeholder="enterLinkHere"
          />
        ))}
    </div>
  </UpdateContainer>
)

export default memo(StoreLink)
