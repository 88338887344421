import React from 'react'
import images from '../../assets/images'
import {useSelector} from '../../store'
import {getClubInfo} from '../../store/selectors/user'

const SidebarHeader = () => {
  const clubInfo = useSelector(getClubInfo)
  const {club_name: clubName, medias_model} = clubInfo || {}
  const imageUrl =
    Array.isArray(medias_model) && medias_model.length > 0
      ? medias_model[0].original_url
      : images.dummyUser
  return (
    <div className="c-nav-logo">
      <div className="c-nav-logo__image">
        <img src={imageUrl} alt={clubName} className="club-home-logo" />
      </div>
      <div className="c-nav-logo__title f-Exo-Bold">{clubName}</div>
    </div>
  )
}

export default SidebarHeader
