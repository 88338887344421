import React, {memo, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Button} from '.'
import {TabItemType, TabType} from './type'
import {tabActions} from '../store/slices'
import {useSelector, useAppDispatch} from '../store'

const Tabs = ({
  title,
  tabList,
  news_type,
  setNews_type,
  classAddOnUl,
  classAddOnAl,
  activeClass,
  isManagement,
}: TabType) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const {activeTab} = useSelector(({tab}) => ({activeTab: tab.activeTab}))
  useEffect(() => {
    if (isManagement) {
      setNews_type(activeTab)
    } else if (
      !isManagement &&
      Array.isArray(tabList) &&
      tabList.length > 0 &&
      tabList[0].news_type
    ) {
      setNews_type(tabList[0].news_type)
    }
  }, [])

  const handleTabChange = async (currentTab: number) => {
    if (isManagement) {
      await dispatch(tabActions.updateActiveTabList({activeTab: currentTab}))
    }
    setNews_type(currentTab)
  }
  return (
    <div className="c-media-library__filters__lpart">
      {title && <div className="c-media-library__filters__font mr-2">{title}</div>}
      <ul className={`nav nav-tabs c-tabs-button c-tabs-button--add-news ${classAddOnUl}`}>
        {Array.isArray(tabList) &&
          tabList.map((tabItem: TabItemType) => (
            <li
              className={`nav-item ${tabItem.news_type === news_type && `${activeClass}`}`}
              key={`key-item-${tabItem.news_type}`}
            >
              <Button
                className={`nav-link wd-120 ${
                  tabItem.news_type === news_type && `${activeClass}`
                } ${classAddOnAl}`}
                data-toggle="tab"
                onClick={() => handleTabChange(tabItem.news_type)}
                title={t(tabItem.title)}
              />
            </li>
          ))}
      </ul>
    </div>
  )
}
export default memo(Tabs)
