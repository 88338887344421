import {buttonListType, moreOptionType, switchListType} from '../type'

export const SwitchChangeLive: switchListType = {
  title: 'Live',
  command: 'changeLive',
  status: true,
}

export const SwitchChangeFeatured: switchListType = {
  title: 'Featured',
  command: 'changeFeatured',
  status: true,
}

export const SwitchChangeAssigned: switchListType = {
  title: 'assigned',
  command: 'changeassigned',
  status: true,
}

export const MoreOptionEdit: moreOptionType = {
  icon: 'c-icon--edit-gray',
  className: 'dropdown-item',
  title: 'Edit',
  command: 'edit',
}

export const MoreOptionDelete: moreOptionType = {
  icon: 'c-icon--delete',
  className: 'dropdown-item',
  title: 'Delete',
  command: 'delete',
}

export const UpdateButtonList: buttonListType = {
  title: 'Update',
  command: 'update',
  classAddon: 'c-button--small c-button--br c-button--br--gray-ab bg-transparent mr-2 f-Exo-Bold',
}

export const EditeButtonList: buttonListType = {
  title: 'Edit',
  command: 'edit',
  classAddon: 'c-button--small c-button--br c-button--br--gray-ab bg-transparent mr-2 f-Exo-Bold',
}

export const RemoveButtonList: buttonListType = {
  title: 'Remove',
  command: 'remove',
  classAddon: 'c-button--small f-Exo-Bold ',
}

export const DenyButtonList: buttonListType = {
  title: 'Deny',
  command: 'deny',
  classAddon: 'c-button--small f-Exo-Bold',
}

export const MatchDetailsButton: buttonListType = {
  title: 'Match Details',
  command: 'matchDetails',
  classAddon: 'c-button--we-160 c-button--icons f-Exo-Bold',
}

export const ApproveButtonList: buttonListType = {
  title: 'Approve',
  command: 'approve',
  classAddon: 'c-button--small f-Exo-Bold c-button--bg--green mr-2',
}

export const PermissionsButtonList: buttonListType = {
  title: 'permissions',
  command: 'permissions',
  classAddon: 'ml-2 c-button--small f-Exo-Bold',
}

export const ManageButtonList: buttonListType = {
  title: 'Manage',
  command: 'manage',
  classAddon: 'c-button--small f-Exo-Bold ',
}

export const UndoDenyButtonList: buttonListType = {
  title: 'Undo Deny',
  command: 'undo-deny',
  classAddon: 'c-button--small f-Exo-Bold',
}
