import React, {memo, useCallback, useState} from 'react'
import {Formik, Form} from 'formik'
import {useParams, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import UpdateContainer from '../common/updateContainer'
import DragFile from '../../../component/dragFile'
import {addCarousal, clearCarousalDetail, updateCarousal} from '../../../store/actions'
import {RootState} from '../../../store/slices'
import {useAppDispatch, useSelector} from '../../../store'
import {CarousalType, EventType, FormActionType} from '../type'
import {MediaType} from '../../news/type'
import {Input} from '../../../component'
import {mapFilesToObject, serializeFormData} from '../../../functions/utils'
import {mediasModel} from '../../../data'
import {setFieldValueType} from '../../../component/type'

const CarousalUpdate = () => {
  const {t} = useTranslation()
  const navigation = useNavigate()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const {carousalDetail} = useSelector(({carousal}: RootState) => ({
    carousalDetail: carousal.carousalDetail,
  }))
  const params = useParams()
  const id = (params?.id && parseInt(params.id, 10) && carousalDetail?.id) || undefined
  if (!id) {
    dispatch(clearCarousalDetail())
  }
  const initialUpdateValues = {
    banner_title: carousalDetail?.banner_title || '',
    banner_description: carousalDetail?.banner_description || '',
    button_text: carousalDetail?.button_text || '',
    button_url: carousalDetail?.button_url || '',
    medias_model:
      (carousalDetail?.medias_model &&
        carousalDetail.medias_model?.length > 0 &&
        carousalDetail.medias_model[0]) ||
      mediasModel,
    is_active: carousalDetail?.is_active || true,
  }
  const initialAddValues = {
    banner_title: '',
    banner_description: '',
    button_text: '',
    button_url: '',
    medias_model: mediasModel,
    is_active: true,
  }
  const onSubmit = async (values: CarousalType) => {
    setLoading(true)
    const formValues = {
      banner_title: values.banner_title || undefined,
      banner_description: values.banner_description || undefined,
      button_text: values.button_text || undefined,
      button_url: values.button_url || undefined,
      image: values.medias_model.file ? values.medias_model.file : undefined,
      is_active: values.is_active ? '1' : '0',
    }
    const formData = serializeFormData(formValues)
    if (id) {
      const response = await dispatch(updateCarousal(id, formData))
      if (response) navigation(`/content-management`)
      setLoading(false)
    } else {
      const response = await dispatch(addCarousal(formData))
      if (response) navigation(`/content-management`)
      setLoading(false)
    }
  }
  const validationSchema = Yup.object().shape({
    banner_title: Yup.string().trim().required('Banner title cannot be empty.'),
    button_url: Yup.string().nullable().trim().url('Button url should be in valid url format.'),
    medias_model: Yup.mixed().test(t('fileRequired'), t('mediaFilesCannotBeEmpty'), (value) => {
      if (!value || !value.original_url) return false
      return true
    }),
  })

  const onClickListAction = ({command, setValues, submitForm}: FormActionType<CarousalType>) => {
    if (command === 'cancel') {
      navigation(`/content-management`)
    } else if (command === 'remove') {
      setValues(initialAddValues)
    } else if (command === 'save' || command === 'update') {
      submitForm()
    }
  }
  const onFilesSelection = useCallback(
    (acceptedFiles: MediaType[], setFieldValue: setFieldValueType) => {
      const newFiles = mapFilesToObject(acceptedFiles)
      if (newFiles && newFiles.length > 0) {
        setFieldValue('medias_model', newFiles[0])
      }
    },
    [],
  )
  return (
    <Formik
      initialValues={carousalDetail?.id ? initialUpdateValues : initialAddValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({errors, touched, submitForm, setFieldValue, values, setValues}) => (
        <Form className="h-100">
          <UpdateContainer
            btn3Title={id ? 'remove' : undefined}
            btn2Title="cancel"
            btn1Title={id ? 'update' : 'save'}
            title={id ? 'editCarousal' : 'addCarousal'}
            loading={loading}
            onClick={(command: string) => onClickListAction({command, setValues, submitForm})}
          >
            <div className="carousal-edit-drop-box mt-0">
              <div className="flex f-0-5">
                <div className="mr-3 f-1">
                  <Input
                    name="banner_title"
                    label={t('title')}
                    onChange={(e: EventType) => {
                      setFieldValue('banner_title', e.target.value)
                    }}
                    placeholder={t('enterTitleHere')}
                    error={touched.banner_title && errors.banner_title}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                  <DragFile
                    accept={{'image/*': []}}
                    maxFiles={1}
                    label={t('carousalImage')}
                    placeholder={t('dragAnImageFileHere')}
                    btn1Title={t('chooseAFileToUpload')}
                    files={[values.medias_model]}
                    onChange={(acceptedFiles: MediaType[]) => {
                      onFilesSelection(acceptedFiles, setFieldValue)
                    }}
                    isThumbnail={false}
                    error={touched.medias_model && (errors.medias_model as string | boolean)}
                  />
                  <Input
                    name="banner_description"
                    label={t('description')}
                    onChange={(e: EventType) => {
                      setFieldValue('banner_description', e.target.value)
                    }}
                    component="textarea"
                    placeholder={t('enterDescriptionHere')}
                    error={touched.banner_description && errors.banner_description}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                  <Input
                    name="button_text"
                    label={t('buttonText')}
                    onChange={(e: EventType) => {
                      setFieldValue('button_text', e.target.value)
                    }}
                    placeholder={t('enterButtonTextHere')}
                    error={touched.button_text && errors.button_text}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                  <Input
                    name="button_url"
                    label={t('buttonUrl')}
                    onChange={(e: EventType) => {
                      setFieldValue('button_url', e.target.value)
                    }}
                    placeholder={t('enterButtonUrlHere')}
                    error={touched.button_url && errors.button_url}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                </div>
              </div>
            </div>
          </UpdateContainer>
        </Form>
      )}
    </Formik>
  )
}

export default memo(CarousalUpdate)
