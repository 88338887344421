import React, {memo} from 'react'
import ReactPlayer from 'react-player'
import images from '../assets/images'

interface MediaItemProps {
  url: string
  alt?: string
  file_type: number
  type?: 'listitem' | 'detailitem'
}

export enum MediaType {
  'image' = 0,
  'video' = 1,
}

const MediaItem: React.FC<MediaItemProps> = memo((props) => {
  const {url, alt, file_type, type = 'listitem'} = props
  return file_type === MediaType.video ? (
    <div className={type === 'listitem' ? 'cover-image-container-ml' : 'media-item-video-cover'}>
      <ReactPlayer playing={false} url={url} className="cover-image" height="100%" width="100%" />
      <div className="cover-image-top-layer postion-bottom-right">
        <img src={images.video} alt="play-button" className="cover-image-top-layer-img-ml" />
      </div>
    </div>
  ) : file_type === MediaType.image ? (
    <img src={url} className="img-fluid" alt={alt} />
  ) : null
})

export default MediaItem
