import logo from './images/gdx_logo.svg'
import loader from './images/loader.svg'
import headerLogo from './images/gdx-logo.svg'
import headerLogoMaster from './images/gdx_logo_master.svg'
import wa from './images/wa.png'
import players from './images/players.png'
import playButton from './images/play-button.svg'
import blankGallery from './images/default_gallery_view.svg'
import video from './images/icons/video.svg'
import notificationBell from './images/icons/notification_bell.svg'
import notificationBellBlack from './images/icons/notification_bell_black.svg'
import msgBox from './images/icons/msgbox.svg'
import profile from './images/icons/profile.png'
import {ReactComponent as bold} from './images/icons/editor/bold_icon.svg'
import {ReactComponent as italic} from './images/icons/editor/italic_icon.svg'
import {ReactComponent as underline} from './images/icons/editor/underline_icon.svg'
import {ReactComponent as strikethrough} from './images/icons/editor/strikethrough_icon.svg'
import {ReactComponent as bulleted} from './images/icons/editor/bulleted_list.svg'
import {ReactComponent as numbered} from './images/icons/editor/numbered_list.svg'
import {ReactComponent as left} from './images/icons/editor/left_icon.svg'
import {ReactComponent as right} from './images/icons/editor/right_icon.svg'
import {ReactComponent as center} from './images/icons/editor/center_icon.svg'
import {ReactComponent as justify} from './images/icons/editor/justify_icon.svg'
import {ReactComponent as header1} from './images/icons/editor/h1_icon.svg'
import {ReactComponent as header2} from './images/icons/editor/h2_icon.svg'
import {ReactComponent as link} from './images/icons/editor/link_icon.svg'
import info from './images/icons/info.svg'
import play from './images/icons/play.svg'
import thumb from './images/thumb.png'
import dummyUser from './images/dummyUser.jpg'

const images = {
  logo,
  loader,
  headerLogo,
  headerLogoMaster,
  wa,
  players,
  playButton,
  blankGallery,
  video,
  msgBox,
  profile,
  notificationBell,
  notificationBellBlack,
  editor: {
    // Editor icons downloaded form
    // https://www.iconfinder.com/iconsets/google-material-design-icons
    bold,
    italic,
    underline,
    strikethrough,
    bulleted,
    numbered,
    left,
    right,
    center,
    justify,
    header1,
    header2,
    link,
  },
  info,
  play,
  thumb,
  dummyUser,
}

export default images
