import React, {memo, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {EventType} from '../type'
import {Input} from '../../../component'
import {LinkFormType} from '../../../component/type'
import {getSingleSetting} from '../../../store/actions/setting'

const LinkForm = ({
  lable,
  placeholder,
  touched,
  errors,
  values,
  setFieldValue,
  setLoading,
}: LinkFormType) => {
  const {t} = useTranslation()
  useEffect(() => {
    const getKeyValue = async () => {
      setLoading(true)
      const response = await getSingleSetting({key: values.meta_key})
      if (response && response.length > 0) {
        setFieldValue('meta_key', response[0].meta_key)
        setFieldValue('id', response[0].id)
        setFieldValue('meta_value', response[0].meta_value)
      }
      setLoading(false)
    }
    getKeyValue()
  }, [])
  return (
    <div className="mr-3 f-1">
      <Input
        name="meta_value"
        label={t(lable)}
        placeholder={t(placeholder)}
        error={touched.meta_value && errors.meta_value}
        className="form-control mt-1"
        classNameLable="fs-15"
        onChange={(e: EventType) => setFieldValue('meta_value', e.target.value)}
      />
    </div>
  )
}

export default memo(LinkForm)
