import React, {memo, useState, useCallback, useMemo} from 'react'
import {useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {Button, Modal} from '../../component'
import {getMediaItemDetails} from '../../store/actions'
import {MediaLibraryFilters, MediaLibraryItem} from '../../store/actions/types'
import MediaForm from '../medialibrary/mediaform'
import MediaList from '../medialibrary/medialist'

const MatchMedia = memo(() => {
  const {t} = useTranslation()
  const params = useParams()
  const defaultOptions: MediaLibraryFilters = useMemo(
    () => ({
      search: '',
      file_type: undefined,
      from_date: undefined,
      to_date: undefined,
      tag_slug: params.id,
    }),
    [],
  )
  const [options] = useState(defaultOptions)
  const onEditPress = useCallback(async (mediaItem: MediaLibraryItem) => {
    const item = await getMediaItemDetails(mediaItem.id)
    if (item) {
      setEditItem(item)
      setIsFormOpen(true)
    }
  }, [])

  const [editItem, setEditItem] = useState<MediaLibraryItem>()
  const [isFormOpen, setIsFormOpen] = useState(false)
  return (
    <div className="flex f-0-3">
      <div className="table-outer f-1 max-h-600" style={{overflow: 'auto'}}>
        <div className="table-header">
          <div className="c-carousal__header">
            <div className="c-carousal__header__lpart v-center">
              <span className="f-Exo-Bold title-color">{t('madia').toUpperCase()}</span>
            </div>
            <div className="c-carousal__header__rpart">
              <Button
                title={t('addImage')}
                classAddon="c-button--icons f-Exo-Medium"
                onClick={() => {
                  setEditItem(undefined)
                  setIsFormOpen(true)
                }}
              />
            </div>
          </div>
        </div>
        <Modal isOpen={isFormOpen} setIsOpen={setIsFormOpen}>
          <MediaForm
            onClose={() => {
              setIsFormOpen(false)
            }}
            editItem={editItem}
            defaultSlug={`${params.id}`}
            accept={{'image/*': []}}
            formTitle="Image"
            placeHolder="Drag an image here"
          />
        </Modal>
        <div className="c-media-library__body c-media-library__body--p-14">
          <div id="allMedia">
            <MediaList options={options} onEdit={onEditPress} isMediaLibraty={false} />
          </div>
        </div>
      </div>
    </div>
  )
})

export default MatchMedia
