import React, {useMemo, useState, useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Sort} from '../../../component'
import {ClubRevenueHeaderType} from '../type'

const ClubRevenueHeader = ({item, filterList}: ClubRevenueHeaderType) => {
  const {t} = useTranslation()
  const defaultOptions = useMemo(
    () => ({
      sort_by: 'last_7_days',
      sort_order: 'desc',
      page: 1,
    }),
    [],
  )
  const [options, setOptions] = useState(defaultOptions)
  const onSortChange = useCallback(
    (option: string) => {
      if (option === 'last_3_days') {
        setOptions({...options, sort_by: 'last_3_days', sort_order: 'desc', page: 1})
      } else if (option === 'last_5_days') {
        setOptions({...options, sort_by: 'last_5_days', sort_order: 'desc', page: 1})
      } else if (option === 'last_7_days') {
        setOptions({...options, sort_by: 'last_7_days', sort_order: 'desc', page: 1})
      }
    },
    [options],
  )
  return (
    <div className="c-card__header flex-row --ptb-10 pr-3">
      <div className="c-card__header__left p-0">
        <div className="c-card__header__title-1 f-Exo-Bold">{t(item.title).toUpperCase()}</div>
      </div>
      {Array.isArray(filterList) && filterList.length > 0 && (
        <Sort
          optionList={filterList}
          onSortChange={onSortChange}
          classAddon="p-0"
          classAddonInner="max-w-130"
        />
      )}
    </div>
  )
}
export default ClubRevenueHeader
