import React from 'react'
import {Button} from '../../../component'
import {matchLiveCommentary} from '../../../store/actions/types'

const HighlightCommentary = ({
  item,
  handleDeleteCommentary,
}: {
  item: matchLiveCommentary
  handleDeleteCommentary: (id: number, match_fixtures_id: number) => void
}) => {
  const {commentary_time, commentary_type, commentary_text, id, match_fixtures_id} = item
  return (
    <div className="flex-1 flex-d-row pl-4 pr-4 pb-3 pt-3 mb-2 mt-2 br-10 bg-light-green f-color-white">
      <div className="flex f-0-1 flex-d-row">
        <div className="flex-1">
          <img
            src={commentary_type.medias_model[0].original_url}
            className="img_preview_commentary"
            alt="loader"
          />
        </div>
        <div className="flex-1 f-Exo-Bold">{`${commentary_time}'`}</div>
      </div>
      <div className="flex f-0-85 flex-d-column">
        <div className="flex f-0-85 f-Exo-Bold mb-4">GOOOOOOOAAAAALLLL!!!</div>
        <div className="flex f-0-85 ">{commentary_text}</div>
      </div>
      <div className="flex f-0-05 h-flex-end">
        <Button
          icon="c-icon--close"
          className=""
          type="submit"
          onClick={() => handleDeleteCommentary(id, match_fixtures_id)}
        />
      </div>
    </div>
  )
}

export default HighlightCommentary
