import React from 'react'
import {LineChart, Line, ResponsiveContainer} from 'recharts'
import ClubRevenueHeader from '../home/clubRevenue/clubRevenueHeader'
import {TotalCLubType} from './type'

const TotalClub = ({item}: TotalCLubType) => {
  return (
    <div className="flex-1 h-flex-end">
      <div className="f-1 pl-5">
        <div className="c-card pb-3 bg-white">
          <ClubRevenueHeader item={{title: 'totalClubs'}} />
          <div className="c-card__body flex-1-center">
            <div className="flex-1-center c-total-club-body">
              <div className="c-club-revenue-card flex-center-v f-0-5">
                <div className="c-club-revenue-card__icons mr-2 mb-1">
                  <i className="c-icon c-icon--triangle c-icon--triangle--up" />
                </div>
                <div className="c-club-revenue-card__price f-Exo-Bold">{item.totalClubCount}</div>
              </div>
              <div className="flex-1 h-flex-end">
                <div className="flex-1-center-v h-flex-end">
                  <ResponsiveContainer width="90%" aspect={2.0 / 1.0}>
                    <LineChart data={item.chartData}>
                      <Line dataKey="pv" stroke="#0BCF65" strokeWidth={2} dot={false} />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TotalClub
