import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface CounterState {
  counter: number
}

const initialState = {counter: 0} as CounterState

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state, action: PayloadAction<CounterState>) => {
      state.counter += action.payload.counter
    },
    decrement: (state, action: PayloadAction<CounterState>) => {
      state.counter -= state.counter > 0 ? action.payload.counter : 0
    },
  },
})
