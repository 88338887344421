/* eslint-disable react/jsx-curly-newline */
import React from 'react'
import {RevenueItemType} from '../type'

const RevenueItem = ({item, currency}: RevenueItemType) => {
  return (
    <div className="c-revenue-item">
      <div className="c-revenue-item__text">{item.revunueTitle}</div>
      <span className="c-revenue-item__price">
        {currency}
        {item.revunuePrice}
      </span>
    </div>
  )
}
export default RevenueItem
