import React, {memo, useCallback, useEffect, useState} from 'react'
import {createEditor, Descendant} from 'slate'
import {Slate, Editable, withReact, RenderElementProps, RenderLeafProps} from 'slate-react'
import isHotkey from 'is-hotkey'
import {withHistory} from 'slate-history'
import Element from './Element'
import Leaf from './Leaf'
import './styles.css'
import Toolbar from './Toolbar'
import {toggleMark} from './utils'
import {useDebounce} from '../../functions/hooks/useDebounce'

const HOTKEYS: Record<string, string> = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+s': 'strikethroguh',
}

const initialValue: Descendant[] = [{type: 'paragraph', children: [{text: ''}]}]

interface RichTextEditorProps {
  onChange: (value: Descendant[]) => void
  value?: Descendant[]
  isDisabled?: boolean
  isToolBarHidden?: boolean
  error: boolean | string
  classAddon?: string
}

const RichTextEditor = (props: RichTextEditorProps) => {
  const {onChange, value = initialValue, isToolBarHidden, isDisabled, classAddon} = props
  const renderElement = useCallback(
    (elementProps: RenderElementProps) => <Element {...elementProps} />,
    [],
  )
  const renderLeaf = useCallback((leafProps: RenderLeafProps) => <Leaf {...leafProps} />, [])
  const [editor] = useState(() => withReact(withHistory(createEditor())))
  const debouncedValue = useDebounce<Descendant[]>(value, 500)

  useEffect(() => {
    editor.children = value
    editor.onChange()
  }, [debouncedValue])

  return (
    <div
      className={`container ml-0 ${isDisabled ? 'container_disable pl-0 pr-0' : ''} ${
        props.error ? 'invalid-control' : ''
      } ${classAddon}`}
    >
      <Slate editor={editor} value={value} onChange={onChange}>
        {!isToolBarHidden && !isDisabled && <Toolbar />}
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          autoFocus
          className={!isDisabled ? 'editor_scrolling_height' : ''}
          onKeyDown={(event) => {
            Object.keys(HOTKEYS).forEach((hotkey) => {
              if (isHotkey(hotkey, event as any)) {
                event.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
              }
            })
          }}
          readOnly={isDisabled}
          placeholder=""
        />
      </Slate>
    </div>
  )
}

export default memo(RichTextEditor)
