import React, {memo} from 'react'
import ReactPlayer from 'react-player'
import images from '../../assets/images'
import {NewsStoryType} from '../../store/actions/types'
import {NewsType} from './type'

interface NewsMediaProps {
  news: NewsStoryType
}

const NewsMedia: React.FC<NewsMediaProps> = memo(({news}) => {
  if (!(news?.medias_model && news?.medias_model?.length > 0)) {
    return null
  }
  if (news.news_type === NewsType.article)
    return (
      <div
        style={{backgroundImage: `url(${news.medias_model[0]?.original_url || images.dummyUser})`}}
        className="cover-image"
      />
    )
  if (news.news_type === NewsType.gallery)
    return (
      <div className="cover-image-container">
        <div
          style={{
            backgroundImage: `url(${news.medias_model[0]?.original_url || images.dummyUser})`,
          }}
          className="cover-image"
        />
        <div className="cover-image-top-layer">
          <div className="cover-image-top-layer-text">+ {news.medias_model?.length - 1}</div>
        </div>
      </div>
    )
  if (news.news_type === NewsType.video)
    return (
      <div className="cover-image-container">
        <ReactPlayer
          playing={false}
          url={news?.medias_model[0]?.original_url || images.dummyUser}
          className="cover-image"
          height="100%"
          width="100%"
        />
        <div className="cover-image-top-layer">
          <img src={images.playButton} alt="play-button" className="cover-image-top-layer-img" />
        </div>
      </div>
    )
  return null
})

export default NewsMedia
