import Api, {urls} from '../../services/api'
import {ApiResponseList, ApiResponseType, SortingType, LeagueType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {leagueAction} from '../slices'
import {AppDispatch} from '..'
import {apiErrorBlock} from '../../functions/utils'

export const getLeagueList = (params: SortingType) => async (dispatch: AppDispatch) => {
  try {
    const {page} = params
    const response: ApiResponseList<LeagueType[]> = await Api.get(
      `${urls.leagues}?page=${page}${params.sort_by ? `&sort_by=${params.sort_by}` : ''}${
        params.sort_order ? `&sort_order=${params.sort_order}` : ''
      }${params.per_page && params.per_page > 0 ? `&per_page=${params.per_page}` : ''}`,
    )
    const {status, message, data} = response.data
    if (status && data?.data) {
      await dispatch(
        leagueAction.updateLeagueList({
          leagueList: data.data,
          total: data.total || 0,
          from: data.from || 0,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
}

export const addLeague = (params: FormData) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<LeagueType[]> = await Api.post(`${urls.leagues}`, params)
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getLeagueList({page: 1}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const updateLeague =
  (id: number, params: {title: string}) => async (dispatch: AppDispatch) => {
    try {
      const response: ApiResponseList<LeagueType[]> = await Api.patch(
        `${urls.leagues}/${id}`,
        params,
      )
      const {status, message} = response.data
      if (status && message) {
        if (message) showNotification({title: 'Success', message, type: 'success'})
        await dispatch(getLeagueList({page: 1}))
        return true
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return false
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }

export const getLeagueDetail = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseType<LeagueType> = await Api.get(`${urls.leagues}/${id}`)
    const {status, message, data} = response.data
    if (status && data) {
      await dispatch(leagueAction.updateLeagueDetail({leagueDetail: data}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const clearLeagueDetail = () => async (dispatch: AppDispatch) => {
  try {
    await dispatch(leagueAction.clearLeagueDetail())
    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const deleteLeague = (id: number, options: SortingType) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<LeagueType[]> = await Api.delete(`${urls.leagues}/${id}`)
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getLeagueList(options))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}

export const getLeagueListWithoutRedux = (params: SortingType) => async () => {
  try {
    const {page} = params
    const response: ApiResponseList<LeagueType[]> = await Api.get(
      `${urls.leagues}?page=${page}${params.sort_by ? `&sort_by=${params.sort_by}` : ''}${
        params.sort_order ? `&sort_order=${params.sort_order}` : ''
      }${params.per_page && params.per_page > 0 ? `&per_page=${params.per_page}` : ''}${
        params.title ? `&title=${params.title}` : ''
      }`,
    )
    const {status, message, data} = response.data
    if (status && data?.data) {
      return data.data
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
  return []
}
