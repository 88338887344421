/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {memo, useState, useCallback, useMemo} from 'react'
import Modal from 'react-modal'
import {useTranslation} from 'react-i18next'
import {Button} from '.'
import {PageListType} from './type'
import {customStyles} from '../data'

const PageList = ({isOpen, setIsOpen, title, pageList, btnTitle, onBtnClick}: PageListType) => {
  const [pageValue, setPageValue] = useState<any>()
  const {t} = useTranslation()

  const onClickPressed = useCallback(() => {
    onBtnClick(pageValue)
    setIsOpen(false)
  }, [pageValue])

  const handleSelect = (data: any) => {
    setPageValue(data)
  }
  return (
    <Modal
      isOpen={isOpen}
      style={useMemo(() => customStyles, [customStyles])}
      onRequestClose={() => setIsOpen(false)}
    >
      {title && (
        <div className="c-card__title pt-2">
          <h4>{title}</h4>
        </div>
      )}
      <div className="mb-3 mt-3">
        {Array.isArray(pageList) && pageList.length > 0 ? (
          pageList.map((item) => {
            return (
              <Button
                title={item.name}
                className={`c-button c-button--br f-Exo-Medium mr-2 mb-2 ${
                  item.name === pageValue?.name ? 'c-button--bg--pink' : 'c-button--br--gray-ab'
                }`}
                onClick={() => handleSelect(item)}
              />
            )
          })
        ) : (
          <div className="c-card__title pt-3 pb-1">
            <h6>No Page Found</h6>
          </div>
        )}
      </div>
      <div className="c-card__footer text-right">
        <Button
          title={t('cancel')}
          classAddon="c-button--bg--black-33 mr-3"
          onClick={() => setIsOpen(false)}
        />
        <Button title={btnTitle || t('add')} onClick={onClickPressed} />
      </div>
    </Modal>
  )
}

export default memo(PageList)
