import Api, {urls} from '../../services/api'
import {ApiResponseList, ApiResponseType, SortingType, ClubSortingType, ClubType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {clubActions} from '../slices'
import {AppDispatch} from '..'
import {apiErrorBlock} from '../../functions/utils'
import {clearLeagueDetail} from './league'

export const getClubList = (params: ClubSortingType) => async (dispatch: AppDispatch) => {
  try {
    const {page} = params
    const response: ApiResponseList<ClubType[]> = await Api.get(
      `${urls.club}?page=${page}${
        params.request_status && params.request_status > 0
          ? `&request_status=${params.request_status - 1}`
          : ''
      }${params.per_page && params.per_page > 0 ? `&per_page=${params.per_page}` : ''}${
        params.sort_by ? `&sort_by=${params.sort_by}` : ''
      }${params.sort_order ? `&sort_order	=${params.sort_order}` : ''}${
        params.club_name ? `&club_name	=${params.club_name}` : ''
      }`,
    )
    const {status, message, data} = response.data
    if (status && data?.data) {
      await dispatch(
        clubActions.updateClubList({
          clubList: data.data,
          total: data.total || 0,
          from: data.from || 0,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
}

export const addClub = (params: FormData) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<ClubType[]> = await Api.post(`${urls.club}`, params)
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getClubList({page: 1}))
      await dispatch(clearClubDetail())
      await dispatch(clearLeagueDetail())
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const updateClub = (id: number, params: FormData) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<ClubType[]> = await Api.post(`${urls.club}/${id}`, params)
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getClubList({page: 1}))
      await dispatch(clearClubDetail())
      await dispatch(clearLeagueDetail())
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}

export const getClubDetail = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseType<ClubType> = await Api.get(`${urls.club}/${id}`)
    const {status, message, data} = response.data
    if (status && data) {
      await dispatch(clubActions.updateClubDetail({clubDetail: data}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const clearClubDetail = () => async (dispatch: AppDispatch) => {
  try {
    await dispatch(clubActions.clearClubDetail())
    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const deleteClub = (id: number, options: SortingType) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<ClubType[]> = await Api.delete(`${urls.club}/${id}`)
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getClubList(options))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}

export const getClubListWithoutRedux = (params: ClubSortingType) => async () => {
  try {
    const {page} = params
    const response: ApiResponseList<ClubType[]> = await Api.get(
      `${urls.club}?page=${page}${
        params.request_status && params.request_status > 0
          ? `&request_status=${params.request_status - 1}`
          : ''
      }${params.per_page && params.per_page > 0 ? `&per_page=${params.per_page}` : ''}${
        params.sort_by ? `&sort_by=${params.sort_by}` : ''
      }${params.sort_order ? `&sort_order	=${params.sort_order}` : ''}${
        params.club_name ? `&club_name	=${params.club_name}` : ''
      }`,
    )
    const {status, message, data} = response.data
    if (status && data?.data) {
      return data.data
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
  return []
}
