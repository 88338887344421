import Api, {urls} from '../../services/api'
import {ApiResponseList, ApiResponseType, SortingType, AdsSortingType, AdsType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {adsActions} from '../slices'
import {AppDispatch} from '..'
import {apiErrorBlock} from '../../functions/utils'
import {AdsUpdateRequestType} from '../../container/adsManagement/type'

export const getAdsList = (params: AdsSortingType) => async (dispatch: AppDispatch) => {
  try {
    const {page} = params
    const response: ApiResponseList<AdsType[]> = await Api.get(
      `${urls.ads}?page=${page}${
        params.is_active && params.is_active > 0 ? `&is_active =${params.is_active}` : ''
      }${params.per_page && params.per_page > 0 ? `&per_page=${params.per_page}` : ''}`,
    )
    const {status, message, data} = response.data
    if (status && data?.data) {
      await dispatch(
        adsActions.updateAdsList({
          adsList: data.data,
          total: data.total || 0,
          from: data.from || 0,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
}

export const addAds = (params: FormData) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<AdsType[]> = await Api.post(`${urls.ads}`, params)
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getAdsList({page: 1}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const updateAds =
  (id: number, params: AdsUpdateRequestType) => async (dispatch: AppDispatch) => {
    try {
      const response: ApiResponseList<AdsType[]> = await Api.patch(`${urls.ads}/${id}`, params)
      const {status, message} = response.data
      if (status && message) {
        if (message) showNotification({title: 'Success', message, type: 'success'})
        await dispatch(getAdsList({page: 1}))
        return true
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return false
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }

export const getAdsDetail = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseType<AdsType> = await Api.get(`${urls.ads}/${id}`)
    const {status, message, data} = response.data
    if (status && data) {
      await dispatch(adsActions.updateAdsDetail({adsDetail: data}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const clearAdsDetail = () => async (dispatch: AppDispatch) => {
  try {
    await dispatch(adsActions.clearAdsDetail())
    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const deleteAds = (id: number, options: SortingType) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<AdsType[]> = await Api.delete(`${urls.ads}/${id}`)
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getAdsList(options))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
