import React, {memo, useState} from 'react'
import {Formik, Form} from 'formik'
import {useParams, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {v4 as uuidv4} from 'uuid'
import UpdateContainer from '../common/updateContainer'
import {Input, Label, Select, ClubSearch} from '../../../component'
import {addTestimonial, clearTestimonialDetail, updateTestimonial} from '../../../store/actions'
import {RootState} from '../../../store/slices'
import {useAppDispatch, useSelector} from '../../../store'
import {EventType, FormActionType, TestimonialType} from '../type'
import {convertAppSettingResToSelectList, serializeFormData} from '../../../functions/utils'
import {mediasModel} from '../../../data'
import {ClubType} from '../../../store/actions/types'

const TestimonialsUpdate = () => {
  const {t} = useTranslation()
  const navigation = useNavigate()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const {testimonialDetail, playerPositionList} = useSelector(
    ({testimonial, appSetting}: RootState) => ({
      testimonialDetail: testimonial.testimonialDetail,
      playerPositionList: appSetting.playerPositionList,
    }),
  )
  const params = useParams()
  const id = (params?.id && parseInt(params.id, 10) && testimonialDetail?.id) || undefined
  if (!id) {
    dispatch(clearTestimonialDetail())
  }
  const positionList = convertAppSettingResToSelectList(playerPositionList)

  const [clubTerms, setSearchTerms] = useState(testimonialDetail?.club_name)
  const initialAddValues = {
    name: '',
    medias_model: mediasModel,
    position: '',
    description: '',
    club_id: '',
  }
  const initialUpdateValues = {
    name: testimonialDetail?.name || '',
    medias_model:
      (testimonialDetail?.medias_model &&
        testimonialDetail.medias_model?.length > 0 &&
        testimonialDetail.medias_model[0]) ||
      mediasModel,
    position: testimonialDetail?.position || '',
    description: testimonialDetail?.description || '',
    club_id: testimonialDetail?.club_id || '',
  }
  const onSubmit = async (values: TestimonialType) => {
    setLoading(true)
    const formValues = {
      name: values.name || undefined,
      position: values.position || undefined,
      description: values.description || undefined,
      image: values.medias_model.file ? values.medias_model.file : undefined,
      club_id: values.club_id || undefined,
    }
    const formData = serializeFormData(formValues)
    if (id) {
      const response = await dispatch(updateTestimonial(id, formData))
      if (response) navigation(`/content-management`)
      setLoading(false)
    } else {
      const response = await dispatch(addTestimonial(formData))
      if (response) navigation(`/content-management`)
      setLoading(false)
    }
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required('Name cannot be empty.'),
    medias_model: Yup.mixed().test(t('fileRequired'), t('mediaFilesCannotBeEmpty'), (value) => {
      if (!value || !value.original_url) return false
      return true
    }),
    description: Yup.string().trim().required('Description cannot be empty.'),
    club_id: Yup.string().trim().required('Club cannot be empty.'),
  })

  const onClickListAction = ({command, setValues, submitForm}: FormActionType<TestimonialType>) => {
    if (command === 'cancel') {
      navigation(`/content-management`)
    } else if (command === 'remove') {
      setValues(initialAddValues)
      setSearchTerms('')
    } else if (command === 'save' || command === 'update') {
      submitForm()
    }
  }

  const handleClubChange = (selectedClub: ClubType, setFieldValue: any) => {
    setFieldValue('club_id', selectedClub.id || '')
    setSearchTerms(selectedClub.club_name)
  }

  return (
    <Formik
      initialValues={testimonialDetail?.id ? initialUpdateValues : initialAddValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({errors, touched, submitForm, setFieldValue, setValues, values}) => (
        <Form className="h-100">
          <UpdateContainer
            btn3Title={id ? 'remove' : undefined}
            btn2Title="cancel"
            btn1Title={id ? 'update' : 'save'}
            title={id ? 'editTestimonials' : 'addTestimonials'}
            loading={loading}
            onClick={(command: string) => onClickListAction({command, setValues, submitForm})}
          >
            <div className="faqs-edit-box">
              <div className="flex f-0-3">
                <div className="mr-3 f-1">
                  <div className="c-form-group">
                    <Label label={t('image')} className="fs-15" />
                    <div className="flex-center-v flex-d-row">
                      <div className="mr-3">
                        <div className="img_preview-testimonials-inner mt-1 border-light-gray">
                          {values?.medias_model?.original_url && (
                            <img
                              src={values.medias_model.original_url}
                              alt=""
                              className="img_preview-testimonials-inner"
                            />
                          )}
                        </div>
                      </div>
                      <label
                        htmlFor="formId"
                        className="mb-0"
                        onChange={(e: any) => {
                          setFieldValue('medias_model', {
                            id: uuidv4(),
                            file_name: e.target.files[0].name,
                            file: e.target.files[0],
                            original_url: URL.createObjectURL(e.target.files[0]),
                          })
                        }}
                      >
                        <input
                          name=""
                          type="file"
                          id="formId"
                          hidden
                          accept="image/png, image/jpeg"
                        />
                        <span className="c-pointer">
                          <span className="icon-edit-text">
                            {values.medias_model && values.medias_model.file_name
                              ? values.medias_model.file_name
                              : t('chooseFileToUpload')}
                          </span>{' '}
                          <i className="c-icon c-icon--edit-red" />
                        </span>
                      </label>
                    </div>
                    {touched.medias_model && errors.medias_model && (
                      <div className="invalid-feedback">{errors.medias_model as string}</div>
                    )}
                  </div>
                  <Input
                    name="name"
                    label={t('name')}
                    onChange={(e: EventType) => {
                      setFieldValue('name', e.target.value)
                    }}
                    placeholder={t('enterNameHere')}
                    error={touched.name && errors.name}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                  <Label label={t('clubName')} className="mb-1" />
                  <ClubSearch
                    handleChange={handleClubChange}
                    setFieldValue={setFieldValue}
                    classNameOuter="mt-1 mb-4"
                    defaultValue={clubTerms}
                    error={touched.club_id && errors.club_id}
                  />
                  <Select
                    name="position"
                    label={t('position')}
                    error={touched.position && errors.position}
                    onChange={(e: EventType) => setFieldValue('position', e.target.value)}
                    className="form-control mt-1"
                    classNameLable="fn-15"
                    optionsList={positionList}
                    emptyText={t('selectPosition')}
                  />
                  <Input
                    name="description"
                    label={t('bodyText')}
                    onChange={(e: EventType) => {
                      setFieldValue('description', e.target.value)
                    }}
                    component="textarea"
                    placeholder={t('enterDescriptionHere')}
                    error={touched.description && errors.description}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                </div>
              </div>
            </div>
          </UpdateContainer>
        </Form>
      )}
    </Formik>
  )
}

export default memo(TestimonialsUpdate)
