import React, {useState, useEffect} from 'react'
import {useDropzone} from 'react-dropzone'
import ReactPlayer from 'react-player'
import {Label, Thumbnail} from '.'
import Button from './button'
import {DragFilesType} from './type'

const DragFile = ({
  accept,
  maxFiles,
  label,
  placeholder,
  btn1Title,
  btn2Title,
  files,
  error: validationError,
  isThumbnail,
  classAddonInner,
  classAddonOuter,
  onChange,
  onRemove,
  thumbType,
  isVideo,
  onAddFromMediaLibrary,
  isOrButtonLine,
}: DragFilesType) => {
  const [error, setError] = useState('')
  const [isFile, setIsFile] = useState(false)
  const onDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length <= 0) return
    setError('')
    if (acceptedFiles.length > maxFiles) {
      setError('Too many files.')
      return
    }
    onChange(acceptedFiles)
  }

  const handleRemove = (medias_id: number) => {
    setError('')
    if (isThumbnail && onRemove) onRemove(medias_id)
  }

  const {getRootProps, getInputProps, fileRejections} = useDropzone({
    onDrop,
    accept,
    maxFiles,
  })
  useEffect(() => {
    setError('')
    if (
      fileRejections &&
      fileRejections.length > 0 &&
      fileRejections[0].errors &&
      fileRejections[0].errors.length > 0 &&
      fileRejections[0].errors[0].message
    ) {
      fileRejections.forEach((fileRejection) => {
        if (fileRejection.errors && fileRejection.errors.length > 0) {
          fileRejection.errors.forEach((fileError) => {
            if (fileError.message.trim() !== error.trim()) {
              setError(`${error} ${fileError.message}`)
            }
          })
        }
      })
    }
  }, [fileRejections])

  useEffect(() => {
    if (Array.isArray(files) && files.length > 0 && files[0].original_url) {
      setIsFile(true)
    } else {
      setIsFile(false)
    }
  }, [files])

  return (
    <div className={`c-form-group ${classAddonOuter}`}>
      {label && <Label label={label} />}
      <div
        className={`c-upload-media c-upload-media--hi-auto ${
          !isThumbnail && isFile ? '' : 'c-upload-media--ptb-22'
        } ${validationError || error ? 'invalid-control' : ''}  ${classAddonInner}`}
        style={{
          backgroundImage: `${!isThumbnail && isFile ? `url(${files[0]?.original_url})` : ''}`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        {...getRootProps()}
      >
        {!isThumbnail && isFile && isVideo && (
          <ReactPlayer
            playing={false}
            url={files[0]?.original_url}
            height="100%"
            width="100%"
            style={{position: 'absolute', zIndex: 1}}
          />
        )}
        <div
          style={{
            opacity: ` ${!isThumbnail && (isFile ? 0 : 1)}`,
            zIndex: 3,
          }}
          className={`${
            !isThumbnail && isFile && 'drop-display drop-item-img-bg c-upload-media--ptb-22'
          }`}
        >
          <input {...getInputProps()} />
          <div
            className="c-upload-media__drag-image c-upload-media__drag-image--mb-18"
            style={{color: `${!isThumbnail && isFile ? `#efefef` : '#22222280'}`}}
          >
            {placeholder}
          </div>
          <div className="c-upload-media__body">
            {!isOrButtonLine && (
              <div
                className="c-upload-media__text"
                style={{color: `${!isThumbnail && isFile ? `#efefef` : '#22222280'}`}}
              >
                or...
              </div>
            )}
            <div className="c-upload-media__button">
              {isOrButtonLine && (
                <span style={{color: `${!isThumbnail && isFile ? `#efefef` : '#22222280'}`}}>
                  {' '}
                  or...{' '}
                </span>
              )}
              {btn1Title && <Button classAddon="mr-2" title={btn1Title} />}
              {btn2Title && onAddFromMediaLibrary && (
                <Button
                  classAddon="c-button--bg--black-33 z-10"
                  title={btn2Title}
                  onClick={(e) => {
                    e.stopPropagation()
                    onAddFromMediaLibrary()
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {isThumbnail && Array.isArray(files) && files.length > 0 && (
        <Thumbnail files={files} type={thumbType} handleRemove={handleRemove} />
      )}
      <div className="invalid-feedback">{error}</div>
      {validationError && <div className="invalid-feedback">{validationError}</div>}
    </div>
  )
}
export default DragFile
