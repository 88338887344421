/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {memo} from 'react'
import {useTranslation} from 'react-i18next'
import {Button} from '../../../component'
import {UpdateContainerType} from '../../../component/type'

const UpdateContainer = ({
  btn3Title,
  btn2Title,
  btn1Title,
  children,
  onClick,
  title,
  loading,
  btn4Title,
}: UpdateContainerType) => {
  const {t} = useTranslation()

  return (
    <div className="c-m-btn-remove">
      {btn3Title && (
        <div className="mb-3">
          <Button
            title={t(btn3Title)}
            onClick={() => onClick && onClick(btn3Title)}
            classAddon="f-Exo-Bold c-button--we-120"
          />
        </div>
      )}
      <div className="c-card flex-1 flex-d-column mb-5 bg-white">
        {btn4Title && (
          <div className="mt-4">
            <div
              className="c-pointer flex h-flex-end v-center mr-5"
              onClick={() => onClick && onClick('remove')}
            >
              <i className="c-icon c-icon--delete" />
              <span className="icon-edit-text ml-2">{t(btn4Title)}</span>
            </div>
          </div>
        )}
        <div className="c-card__body c-m-card-body mb-3">
          <div className="f-1">
            <div className="carousal-edit-title f-Exo-Bold mt-70">{t(title)}</div>
            {children}
          </div>
          <div className="mb-4 flex-center-h">
            {btn1Title && (
              <Button
                title={t(btn1Title)}
                icon={loading ? 'c-icon--loader mr-2' : ''}
                onClick={() => onClick && onClick(btn1Title)}
                classAddon="f-Exo-Bold c-button--we-120"
              />
            )}
            {btn2Title && (
              <Button
                title={t(btn2Title)}
                classAddon=" c-button--br c-button--br--gray-ab bg-transparent ml-3 f-Exo-Bold c-button--we-120"
                onClick={() => onClick && onClick(btn2Title)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(UpdateContainer)
