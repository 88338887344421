import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {mediasModel} from '../../data'
import {CarousalType} from '../actions/types'

export interface CarousalState {
  carousalList: CarousalType[]
  total: number
  from: number
  carousalDetail: CarousalType
}

const initialCarousalDetail = {
  id: 0,
  banner_title: '',
  banner_description: '',
  button_text: '',
  created_at: '',
  button_url: '',
  is_active: false,
  medias_model: [mediasModel],
}

const initialState = {
  carousalList: [],
  total: 0,
  from: 0,
  carousalDetail: initialCarousalDetail,
} as CarousalState

export const carousalSlice = createSlice({
  name: 'carousal',
  initialState,
  reducers: {
    updateCarousalList: (state, action: PayloadAction<Partial<CarousalState>>) => {
      const {carousalList, total, from} = action.payload
      state.carousalList = carousalList || state.carousalList
      state.total = total || state.total
      state.from = from || state.from
    },
    updateCarousalDetail: (state, action: PayloadAction<Partial<CarousalState>>) => {
      state.carousalDetail = action.payload.carousalDetail || state.carousalDetail
    },
    clearCarousalDetail: (state) => {
      state.carousalDetail = initialState.carousalDetail
    },
  },
})
