import images from '../assets/images'
import {OptionListType} from '../component/type'

export const optionList: OptionListType[] = [
  {
    id: 0,
    label: 'Newest First',
    value: 'created_at-desc',
  },
  {
    id: 1,
    label: 'Oldest First',
    value: 'created_at-asc',
  },
  {
    id: 2,
    label: 'Show Featured',
    value: 'is_featured_story',
  },
  {
    id: 2,
    label: 'Show Published',
    value: 'is_published',
  },
]

export const newsList = [
  {
    id: 0,
    newsImg: images.players,
    newsTitle: '10 Man Consett hold West',
    newsDescription: `West Auckland Town 1-1 Consett AFC. West Auckland Town missed a second-half penalty as they had to settle for a point in a`,
    newsDate: '2 Apr 2019, 2:14pm',
    switchList: [
      {
        title: 'live',
        command: 'changeLive',
        status: true,
      },
      {
        title: 'featured',
        command: 'changeFeatured',
        status: true,
      },
    ],
    moreOption: [
      {
        icon: 'c-icon--edit-gray',
        className: 'dropdown-item',
        title: 'edit',
        command: 'edit',
      },
      {
        icon: 'c-icon--delete',
        className: 'dropdown-item',
        title: 'delete',
        command: 'delete',
      },
    ],
  },
  {
    id: 1,
    newsImg: images.players,
    newsTitle: '10 Man Consett hold West',
    newsDescription: `West Auckland Town 1-1 Consett AFC. West Auckland Town missed a second-half penalty as they had to settle for a point in a`,
    newsDate: '2 Apr 2019, 2:14pm',
    switchList: [
      {
        title: 'live',
        command: 'changeLive',
        status: true,
      },
      {
        title: 'featured',
        command: 'changeFeatured',
        status: true,
      },
    ],
    moreOption: [
      {
        icon: 'c-icon--edit-gray',
        className: 'dropdown-item',
        title: 'edit',
        command: 'edit',
      },
      {
        icon: 'c-icon--delete',
        className: 'dropdown-item',
        title: 'delete',
        command: 'delete',
      },
    ],
  },
  {
    id: 2,
    newsImg: images.players,
    newsTitle: '10 Man Consett hold West',
    newsDescription: `West Auckland Town 1-1 Consett AFC. West Auckland Town missed a second-half penalty as they had to settle for a point in a`,
    newsDate: '2 Apr 2019, 2:14pm',
    switchList: [
      {
        title: 'live',
        command: 'changeLive',
        status: true,
      },
      {
        title: 'featured',
        command: 'changeFeatured',
        status: true,
      },
    ],
    moreOption: [
      {
        icon: 'c-icon--edit-gray',
        className: 'dropdown-item',
        title: 'edit',
        command: 'edit',
      },
      {
        icon: 'c-icon--delete',
        className: 'dropdown-item',
        title: 'delete',
        command: 'delete',
      },
    ],
  },
]
