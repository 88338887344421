import Api, {urls} from '../../services/api'
import {ApiResponseList, TagType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {tagActions} from '../slices'
import {AppDispatch} from '..'
import {apiErrorBlock} from '../../functions/utils'

export const getTagList = (params: {search: string}) => async (dispatch: AppDispatch) => {
  try {
    const {search} = params
    const response: ApiResponseList<TagType[]> = await Api.get(
      `${urls.clubTags}?${search ? `tag_title=${search}` : ''}`,
    )
    const {status, message, data} = response.data
    if (status && data?.data) {
      await dispatch(
        tagActions.updateTagList({
          tagList: data.data,
          total: data.total || 0,
          from: data.from || 0,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
}
