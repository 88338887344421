import React from 'react'
import {useTranslation} from 'react-i18next'
import {format} from 'date-fns'
import {RichTextInput} from '../../component'
import {MatchContentMatchInfoPropsType} from './type'
import {MatchType} from '../../data'

const MatchInfoView = ({item}: MatchContentMatchInfoPropsType) => {
  const {t} = useTranslation()

  const match_description = JSON.parse(item.match_description)

  return (
    <>
      <div className="flex-1-center-v card-match-content">
        <div className="flex-1 flex-d-column">
          <span className="f-14 flex-1">{t('meetTime')}</span>
          <span className="f-Exo-Bold flex-1 f-18">
            {item?.match_meet_time ? format(new Date(item.match_meet_time), 'kk:mm') : 'N/A'}
          </span>
        </div>
        <div className="flex-1 flex-d-column">
          <span className="f-14 flex-1">{t('kickoffTime')}</span>
          <span className="f-Exo-Bold f-18 flex-1">
            {format(new Date(item.match_kickoff_time), 'kk:mm')}
          </span>
        </div>
        <div className="flex-1 flex-d-column">
          <span className="f-14 flex-1">{t('type')}</span>
          <span className="f-Exo-Bold f-18 flex-1">{MatchType[item.match_type]}</span>
        </div>
        <div className="flex-1 flex-d-column">
          <span className="f-14 flex-1">{t('attendance')}</span>
          <span className="f-Exo-Bold f-18 flex-1">
            {item?.match_attend_time ? format(new Date(item.match_attend_time), 'kk:mm') : 'N/A'}
          </span>
        </div>
      </div>
      <div className='className="flex-1-center-v flex-d-column card-match-content'>
        <div className="f-Exo-Bold f-14 f-1">{t('additionalInfo')}:</div>
        <div className=" f-14 f-1">
          <RichTextInput
            value={
              match_description || [
                {
                  type: 'paragraph',
                  children: [{text: 'No information available'}],
                },
              ]
            }
            isDisabled
            onChange={() => undefined}
          />
        </div>
      </div>
    </>
  )
}
export default MatchInfoView
