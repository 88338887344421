import React, {memo, useCallback, useEffect, useMemo, useState} from 'react'
import {createTable} from '@tanstack/react-table'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {UpdateListType} from '../../component/type'
import {Button, List as ListView, Popup, Sort} from '../../component'
import ListRight from '../../component/listRight'
import {
  getUserManagementList,
  getUserManagementDetail,
  clearUserManagementDetail,
  deleteUserManagement,
} from '../../store/actions'
import {useSelector, useAppDispatch} from '../../store'
import {UserManagementType} from '../../store/actions/types'
import {EditeButtonList} from '../../component/List/Commands'
import {userOptionList} from '../../data'
import {defaultPageSize} from '../../configs/paginationSetting'

const table = createTable().setRowType<UserManagementType>()

const UserManagement = memo(() => {
  const {t} = useTranslation()
  const navigation = useNavigate()
  const {list, total, from} = useSelector(({userManagement}) => ({
    list: userManagement.userManagementList,
    total: userManagement.total,
    from: userManagement.from,
  }))
  const [selectedRow, setSelectedRow] = useState<UserManagementType>()
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [currentItemId, setCurrentItemId] = useState(0)
  const [loading, setLoading] = useState(false)
  const defaultOptions = useMemo(
    () => ({
      sort_by: 'created_at',
      sort_order: 'desc',
      page: 1,
      per_page: defaultPageSize,
    }),
    [],
  )
  const [options, setOptions] = useState(defaultOptions)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getUserManagementList(options))
  }, [options])

  const columns = useMemo(
    () => [
      table.createDataColumn('id', {
        header: 'NUMBER',
        cell: ({row}) => <span>{String(row.index + from).padStart(2, '0')}</span>,
      }),
      table.createDataColumn('name', {
        size: 300,
        header: 'USER NAME',
        cell: ({row}) => <span>{row.original?.name}</span>,
      }),
      table.createDataColumn('email', {
        header: 'EMAIL',
        size: 500,
        cell: ({row}) => <span>{row.original?.email}</span>,
      }),
      table.createDataColumn('master_user_detail', {
        header: 'CONTACT INFO',
        size: 300,
        cell: ({row}) => (
          <span style={{fontWeight: 'bold'}}>
            {row.original?.master_user_detail?.user_country?.dialling_code ? '+' : ''}
            {row.original?.master_user_detail?.user_country?.dialling_code}{' '}
            {row.original?.master_user_detail?.mobile_number}
          </span>
        ),
      }),
      table.createDataColumn('id', {
        header: '',
        cell: ({row}) =>
          row.original && (
            <ListRight row={row.original} onClick={onUpdatePress} buttonList={[EditeButtonList]} />
          ),
      }),
    ],
    [from],
  )

  const onSortChange = useCallback(
    (option: string) => {
      if (option === 'created_at-desc') {
        setOptions({...options, sort_by: 'created_at', sort_order: 'desc', page: 1})
      } else if (option === 'created_at-asc') {
        setOptions({...options, sort_by: 'created_at', sort_order: 'asc', page: 1})
      }
    },
    [options],
  )

  const onLimit = useCallback(
    (per_page: number) => {
      setOptions({...options, per_page, page: 1})
    },
    [options],
  )

  const onUpdatePress = useCallback(
    async ({id, command}: UpdateListType) => {
      if (command === 'edit') {
        const response = await dispatch(getUserManagementDetail(id))
        if (response) navigation(`/user-management/${id}`)
      } else if (command === 'remove') {
        setCurrentItemId(id)
        setIsOpenPopup(true)
      } else if (command === 'deleteConfirmed') {
        setLoading(true)
        await dispatch(deleteUserManagement(id, options))
        setIsOpenPopup(false)
        setLoading(false)
      }
      setSelectedRow(undefined)
    },
    [selectedRow, list],
  )

  const onPaginate = useCallback(
    (page: number) => {
      setOptions({...options, page})
    },
    [options],
  )

  const onAddNewPress = useCallback(() => {
    dispatch(clearUserManagementDetail())
    navigation(`/user-management/add`)
  }, [])

  return (
    <div className="table-outer">
      <div className="table-header">
        <div className="c-carousal__header">
          <div className="c-carousal__header__lpart v-center">
            <span className="f-Exo-Bold fn-18">
              {t('results')}
              <span style={{color: '#CF0B48'}}>{` (${total})`}</span>
            </span>
          </div>
          <div className="c-carousal__header__rpart flex-center">
            <Button
              icon="c-icon--plus"
              title={t('addNewUser')}
              classAddon="c-button--we-160 c-button--icons f-Exo-Medium"
              onClick={onAddNewPress}
            />
            <Popup
              isOpen={isOpenPopup}
              setIsOpen={setIsOpenPopup}
              onPressDelete={() => onUpdatePress({id: currentItemId, command: 'deleteConfirmed'})}
              title={t('delete')}
              description={t('deleteText')}
              loading={loading}
            />
            <div>
              <Sort optionList={userOptionList} onSortChange={onSortChange} classAddon="p-2" />
            </div>
          </div>
        </div>
      </div>
      <ListView
        columns={columns}
        data={list}
        total={total}
        onPaginate={onPaginate}
        forcePageIndex={options.page - 1}
        options={options}
        isPageSize
        onLimit={onLimit}
        pageSize={options?.per_page}
      />
    </div>
  )
})

export default UserManagement
