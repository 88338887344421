import {AxiosResponse} from 'axios'
import {format} from 'date-fns'
import {AppDispatch} from '../index'
import {ApiResponse, ApiResponseList, MediaLibraryItem, MediaLibraryFilters} from './types'
import Api, {urls} from '../../services/api'
import showNotification from '../../functions/notification'
import logException from '../../services/sentry'
import {mediaLibraryActions} from '../slices'
import {apiErrorBlock} from '../../functions/utils'

export const saveMediaItem =
  (params: FormData, defaultSlug?: string) =>
  async (dispatch: AppDispatch): Promise<boolean> => {
    try {
      const id = params.get('media_library_id')
      const response: AxiosResponse<ApiResponse<string>> = await Api.post(
        `${urls.mediaLibrary}${id ? `/${id}` : ''}`,
        params,
      )
      const {status, message} = response.data
      if (status && message) {
        if (message) showNotification({title: 'Success', message, type: 'success'})
        await dispatch(getMedialLibraryList({page: 1, tag_slug: defaultSlug || undefined}))
        return true
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return false
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }

export const getMedialLibraryList =
  (params: MediaLibraryFilters) => async (dispatch: AppDispatch) => {
    try {
      const {page} = params
      dispatch(mediaLibraryActions.updateLoading(true))
      const response: ApiResponseList<MediaLibraryItem[]> = await Api.get(urls.mediaLibrary, {
        params: {
          page,
          search_text: params.search?.trim() || undefined,
          from_date: params.from_date ? format(params.from_date, 'yyyy-MM-dd') : undefined,
          to_date: params.to_date ? format(params.to_date, 'yyyy-MM-dd') : undefined,
          file_type: typeof params.file_type === 'number' ? params.file_type : undefined,
          tag_slug: params.tag_slug || undefined,
        },
      })
      const {status, message, data} = response.data
      if (status && data?.data) {
        await dispatch(
          mediaLibraryActions.updateList({
            mediaList: data.data,
            total: data.total || 0,
            from: data.from || 0,
            loading: false,
          }),
        )
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
    } catch (e: any) {
      dispatch(mediaLibraryActions.updateLoading(true))
      apiErrorBlock(e)
    }
  }

export const getMediaItemDetails = async (id: number) => {
  try {
    const response: AxiosResponse<ApiResponse<MediaLibraryItem>> = await Api.get(
      `${urls.mediaLibrary}/${id}`,
    )
    const {status, message, data} = response.data
    if (status && data) {
      return data
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return null
  } catch (e: any) {
    apiErrorBlock(e)
    return null
  }
}

export const deleteMediaItem =
  (id: number, defaultSlug?: string) => async (dispatch: AppDispatch) => {
    try {
      const response: ApiResponseList<MediaLibraryItem[]> = await Api.delete(
        `${urls.mediaLibrary}/${id}`,
      )
      const {status, message} = response.data
      if (status && message) {
        if (message) showNotification({title: 'Success', message, type: 'success'})
        await dispatch(getMedialLibraryList({page: 1, tag_slug: defaultSlug || undefined}))
        return true
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return false
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }

export const getMedialLibraryListByFixtureId =
  (params: MediaLibraryFilters) => async (dispatch: AppDispatch) => {
    try {
      const {page, tag_slug} = params
      dispatch(mediaLibraryActions.updateLoading(true))
      const response: ApiResponseList<MediaLibraryItem[]> = await Api.get(
        `${urls.matchMedia}?page=${page}${tag_slug ? `&match_fixture_id=${tag_slug}` : ''}`,
      )
      const {status, message, data} = response.data
      if (status && data?.data) {
        await dispatch(
          mediaLibraryActions.updateList({
            mediaList: data.data,
            total: data.total || 0,
            from: data.from || 0,
            loading: false,
          }),
        )
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
    } catch (e: any) {
      dispatch(mediaLibraryActions.updateLoading(true))
      apiErrorBlock(e)
    }
  }

export const MatchMediaItem =
  (params: FormData, defaultSlug: string) =>
  async (dispatch: AppDispatch): Promise<boolean> => {
    try {
      const response: AxiosResponse<ApiResponse<string>> = await Api.post(
        `${urls.matchMedia}`,
        params,
      )
      const {status, message} = response.data
      if (status && message) {
        if (message) showNotification({title: 'Success', message, type: 'success'})
        await dispatch(
          getMedialLibraryListByFixtureId({page: 1, tag_slug: defaultSlug || undefined}),
        )
        return true
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return false
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }
