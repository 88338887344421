import React, {useState, useEffect} from 'react'
import {Tabs} from '../../component'
import {addSettingList, contentTabList} from '../../data'
import Carousal from './carousal'
import TestimonialsList from './testimonial'
import FaqsList from './faq'
import BenefitsList from './benefit'
import {useSelector, useAppDispatch} from '../../store'
import StoreLink from './storeLink'
import SocialMediaLink from './socialMediaLink'
import MarketingVideo from './marketingVideo'
import {getAppSettingList} from '../../store/actions'
import {getRole} from '../../store/selectors/user'

const ContentManagement = () => {
  const {activeTab} = useSelector(({tab}) => ({activeTab: tab.activeTab}))
  const [currentActiveTab, setCurrentActiveTab] = useState<number>(activeTab)
  const dispatch = useAppDispatch()
  const role = useSelector(getRole)
  const isMaster = role === 'master_user'
  useEffect(() => {
    dispatch(getAppSettingList(isMaster, addSettingList.playerPositionList))
  }, [isMaster])
  return (
    <div className="flex-1 flex-d-column h-100">
      <div className="content-tabs mb-4 f-0-02">
        <Tabs
          title=""
          tabList={contentTabList}
          news_type={currentActiveTab}
          setNews_type={setCurrentActiveTab}
          classAddOnUl="content-tabs-w"
          classAddOnAl="content-tabs-active"
          activeClass="active-red"
          isManagement
        />
      </div>
      {activeTab === 0 && <Carousal />}
      {activeTab === 1 && <BenefitsList />}
      {activeTab === 2 && <StoreLink />}
      {activeTab === 3 && <SocialMediaLink />}
      {activeTab === 4 && <TestimonialsList />}
      {activeTab === 5 && <MarketingVideo />}
      {activeTab === 6 && <FaqsList />}
    </div>
  )
}

export default ContentManagement
