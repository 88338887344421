import Api, {urls} from '../../services/api'
import {ApiResponseType, appSettingType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {AppDispatch} from '..'
import {appSettingAction} from '../slices'
import {apiErrorBlock} from '../../functions/utils'

export const getAppSettingList =
  (isMaster: boolean, metaKey: string) => async (dispatch: AppDispatch) => {
    try {
      const response: ApiResponseType<appSettingType> = await Api.get(
        `${isMaster ? urls.masterSetting : urls.clubSetting}?meta_key=${metaKey}`,
      )
      const {status, message, data} = response.data
      if (status && data?.player_position) {
        await dispatch(
          appSettingAction.updateAppSettingData({
            playerPositionList: data.player_position,
          }),
        )
      }
      if (status && data?.commentary_period) {
        await dispatch(
          appSettingAction.updateAppSettingData({
            commentaryPeriodList: data.commentary_period,
          }),
        )
      }
      if (status && data?.moderation_report_type) {
        await dispatch(
          appSettingAction.updateAppSettingData({
            moderationReportList: data.moderation_report_type,
          }),
        )
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return []
    } catch (e: any) {
      apiErrorBlock(e)
      return []
    }
  }
