/* eslint-disable react/no-children-prop */
import React, {memo, useEffect, useState} from 'react'
import {Button, RangePicker} from '../../component'
import {RangeSelectionType} from '../../component/type'
import {EventType} from '../contentManagement/type'
import {useSelector, useAppDispatch} from '../../store'
import {getTagList} from '../../store/actions'
import Autocomplete from './autocomplete'
import {mediaLibraryTabList as tabList} from '../../data'
import {useDebounce} from '../../functions/hooks/useDebounce'

interface FilterBarProps {
  onFilterChange: (command: string, value?: string) => void
  onDateChange: (selectionRange: {endDate: Date | undefined; startDate: Date | undefined}) => void
  selectedTab: string
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>
}

const FilterBar = memo(
  ({onFilterChange, onDateChange, setSelectedTab, selectedTab}: FilterBarProps) => {
    const initialDateRange = {
      startDate: undefined,
      endDate: undefined,
    }
    const [selectionRange, setSelectionRange] = useState<RangeSelectionType>(initialDateRange)
    const [selectedTag, setSelectedTag] = useState('')
    const [writtenTag, setWrittenTag] = useState('')
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, 500)

    const dispatch = useAppDispatch()

    useEffect(() => {
      onDateChange(selectionRange)
    }, [selectionRange])

    useEffect(() => {
      onFilterChange('search', debouncedSearch)
    }, [debouncedSearch])

    const {list} = useSelector(({tag}) => ({
      list: tag.tagList,
    }))

    useEffect(() => {
      if (selectedTag) {
        setWrittenTag(selectedTag)
        const slug = list.filter((tag) => tag.name === selectedTag)
        onFilterChange('tag_slug', slug[0].slug)
      }
    }, [selectedTag])

    useEffect(() => {
      dispatch(getTagList({search: writtenTag}))
    }, [writtenTag])

    const onClearTag = () => {
      setSelectedTag('')
      setWrittenTag('')
      onFilterChange('tag_slug', undefined)
    }

    return (
      <div className="c-media-library__filters">
        <div className="c-media-library__filters__lpart">
          <ul className="nav c-tabs-button c-tabs-button--add-news">
            {Array.isArray(tabList) &&
              tabList.map((tabItem) => (
                <li className={`nav-item ${tabItem.command === selectedTab && 'active-gray'}`}>
                  <Button
                    className="nav-link fs-15"
                    data-toggle="tab"
                    onClick={() => {
                      setSelectedTab(tabItem.command)
                      onFilterChange(tabItem.command)
                    }}
                    icon={`${tabItem.icon} mr-2`}
                    title={tabItem.title}
                  />
                </li>
              ))}
          </ul>
          <RangePicker
            initialDateRange={initialDateRange}
            selectionRange={selectionRange}
            setSelectionRange={setSelectionRange}
            maxDate={new Date()}
          />
          <Autocomplete
            writtenTag={writtenTag}
            setWrittenTag={setWrittenTag}
            setSelectedTag={setSelectedTag}
            list={list}
          />
          {selectedTag && (
            <div
              onClick={onClearTag}
              role="button"
              aria-hidden
              className="cleanWrappper tagWrappper"
            >
              <i className="c-icon c-icon--close-red" />
            </div>
          )}
        </div>
        <div className="c-media-library__filters__rpart">
          <div className="c-search h-100">
            <i className="c-icon c-icon--search" />
            <input
              type="text"
              className="search-control"
              placeholder="Search"
              value={search}
              onChange={(e: EventType) => setSearch(e.target.value)}
            />
            {search && (
              <div
                onClick={() => setSearch('')}
                role="button"
                aria-hidden
                className="cleanWrappper searchWrappper"
              >
                <i className="c-icon c-icon--close-red" />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  },
)

export default FilterBar
