import React, {memo, useCallback, useEffect, useRef} from 'react'

interface LazyLoaderProps {
  children: React.ReactNode
  onLoadMore: () => void
}

const LazyLoader: React.FC<LazyLoaderProps> = memo((props) => {
  const {children, onLoadMore} = props
  const loader = useRef(null)

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0]
      if (target.isIntersecting) {
        onLoadMore()
      }
    },
    [onLoadMore],
  )

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '100px',
      threshold: 1,
    }
    const observer = new IntersectionObserver(handleObserver, option)
    if (loader.current) observer.observe(loader.current)
    return () => observer.disconnect()
  }, [handleObserver, onLoadMore])

  return (
    <>
      {children}
      <div ref={loader} />
    </>
  )
})

export default LazyLoader
