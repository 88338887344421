import React, {memo, useCallback, useEffect, useMemo, useState} from 'react'
import {createTable} from '@tanstack/react-table'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {UpdateListType} from '../../../component/type'
import {Button, List as ListView, Popup} from '../../../component'
import ListRight from '../../../component/listRight'
import {PermissionItem} from '../../../store/actions/types'
import {useAppDispatch, useSelector} from '../../../store'
import {deletePermission, getPermissionsList} from '../../../store/actions/security'
import {EditeButtonList, RemoveButtonList} from '../../../component/List/Commands'

const table = createTable().setRowType<PermissionItem>()

const PermissionsList = memo(() => {
  const {t} = useTranslation()
  const navigation = useNavigate()
  const {list, total, from} = useSelector(({permissions}) => ({
    list: permissions.permissions,
    total: permissions.total,
    from: permissions.from,
  }))
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [currentItemId, setCurrentItemId] = useState(0)
  const [loading, setLoading] = useState(false)

  const defaultOptions = useMemo(
    () => ({
      page: 1,
    }),
    [],
  )
  const [options, setOptions] = useState(defaultOptions)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getPermissionsList({page: options.page}))
  }, [options])

  const columns = useMemo(
    () => [
      table.createDataColumn('id', {
        enableResizing: true,
        header: 'ID',
        cell: ({getValue}) => getValue(),
      }),

      table.createDataColumn('name', {
        header: 'Name',
        cell: ({row}) => <span>{row.original?.name}</span>,
      }),
      table.createDataColumn('id', {
        header: '',
        cell: ({row}) =>
          row.original && (
            <ListRight
              row={row.original}
              onClick={onUpdatePress}
              buttonList={[EditeButtonList, RemoveButtonList]}
            />
          ),
      }),
    ],
    [from],
  )

  const onUpdatePress = useCallback(
    async ({id, command}: UpdateListType) => {
      if (command === 'edit') {
        navigation(`/security/permissions/save/${id}`)
      } else if (command === 'remove') {
        setCurrentItemId(id)
        setIsOpenPopup(true)
      } else if (command === 'deleteConfirmed') {
        setLoading(true)
        setIsOpenPopup(false)
        await dispatch(deletePermission(id, options.page))
        setLoading(false)
      }
    },
    [list, options],
  )

  const onPaginate = useCallback(
    (page: number) => {
      setOptions({...options, page})
    },
    [options],
  )

  return (
    <div className="table-outer">
      <div className="table-header">
        <div className="c-carousal__header">
          <div className="c-carousal__header__rpart flex-center">
            <Button
              icon="c-icon--plus"
              title="Add Perm"
              classAddon="c-button--we-160 c-button--icons f-Exo-Medium"
              onClick={() => navigation(`/security/permissions/save`)}
            />
            <Popup
              isOpen={isOpenPopup}
              setIsOpen={setIsOpenPopup}
              onPressDelete={() => onUpdatePress({id: currentItemId, command: 'deleteConfirmed'})}
              title={t('delete')}
              description={t('deleteText')}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <ListView
        columns={columns}
        data={list}
        total={total}
        onPaginate={onPaginate}
        forcePageIndex={options.page - 1}
      />
    </div>
  )
})

export default PermissionsList
