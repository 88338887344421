import Api, {urls} from '../../services/api'
import {ApiResponseList, ApiResponseType, SortingType, CarousalType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {carousalActions} from '../slices'
import {AppDispatch} from '..'
import {apiErrorBlock} from '../../functions/utils'

export const getCarousalList = (params: SortingType) => async (dispatch: AppDispatch) => {
  try {
    const {page} = params
    const response: ApiResponseList<CarousalType[]> = await Api.get(
      `${urls.carousal}?page=${page}${
        params.per_page && params.per_page > 0 ? `&per_page=${params.per_page}` : ''
      }`,
    )
    const {status, message, data} = response.data
    if (status && data?.data) {
      await dispatch(
        carousalActions.updateCarousalList({
          carousalList: data.data,
          total: data.total || 0,
          from: data.from || 0,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
}

export const addCarousal = (params: FormData) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<CarousalType[]> = await Api.post(`${urls.carousal}`, params)
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getCarousalList({page: 1}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const updateCarousal = (id: number, params: FormData) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<CarousalType[]> = await Api.post(
      `${urls.carousal}/${id}`,
      params,
    )
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getCarousalList({page: 1}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}

export const getCarousalDetail = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseType<CarousalType> = await Api.get(`${urls.carousal}/${id}`)
    const {status, message, data} = response.data
    if (status && data) {
      await dispatch(carousalActions.updateCarousalDetail({carousalDetail: data}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const clearCarousalDetail = () => async (dispatch: AppDispatch) => {
  try {
    await dispatch(carousalActions.clearCarousalDetail())
    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const deleteCarousal =
  (id: number, options: SortingType) => async (dispatch: AppDispatch) => {
    try {
      const response: ApiResponseList<CarousalType[]> = await Api.delete(`${urls.carousal}/${id}`)
      const {status, message} = response.data
      if (status && message) {
        if (message) showNotification({title: 'Success', message, type: 'success'})
        await dispatch(getCarousalList(options))
        return true
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return false
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }
