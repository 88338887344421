import Api, {urls} from '../../services/api'
import {ApiResponseList, ClubRequestType, SortingType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {clubRequestActions} from '../slices'
import {AppDispatch} from '..'
import {apiErrorBlock} from '../../functions/utils'

export const getClubRequestList = (params: SortingType) => async (dispatch: AppDispatch) => {
  try {
    const {page} = params
    const response: ApiResponseList<ClubRequestType[]> = await Api.get(
      `${urls.clubRequest}?page=${page}${params.sort_by ? `&sort_by=${params.sort_by}` : ''}${
        params.sort_order
          ? `&sort_order=${params.sort_order}${
              params.per_page && params.per_page > 0 ? `&per_page=${params.per_page}` : ''
            }`
          : ''
      }`,
    )
    const {status, message, data} = response.data
    if (status && data?.data) {
      await dispatch(
        clubRequestActions.updateClubRequestList({
          clubRequestList: data.data,
          total: data.total || 0,
          from: data.from || 0,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
}

export const updateClubStatus =
  (id: number, params: {registration_steps_status: number}) => async () => {
    try {
      const response: ApiResponseList<ClubRequestType[]> = await Api.patch(
        `${urls.clubStatusUpdate}/${id}`,
        params,
      )
      const {status, message} = response.data
      if (status && message) {
        if (message) showNotification({title: 'Success', message, type: 'success'})
        return true
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return false
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }

export const clearClubRequestDetail = () => async (dispatch: AppDispatch) => {
  try {
    await dispatch(clubRequestActions.clearClubRequestDetail())
    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
