import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AppSettingObjectType} from '../actions/types'

export interface AppSettingState {
  playerPositionList: AppSettingObjectType
  commentaryPeriodList: AppSettingObjectType
  moderationReportList: AppSettingObjectType
}

const initialState = {
  playerPositionList: {},
  commentaryPeriodList: {},
  moderationReportList: {},
}

export const appSettingSlice = createSlice({
  name: 'appSetting',
  initialState,
  reducers: {
    updateAppSettingData: (state, action: PayloadAction<Partial<AppSettingState>>) => {
      if (action.payload.playerPositionList) {
        state.playerPositionList = action.payload.playerPositionList
      }
      if (action.payload.commentaryPeriodList) {
        state.commentaryPeriodList = action.payload.commentaryPeriodList
      }
      if (action.payload.moderationReportList) {
        state.moderationReportList = action.payload.moderationReportList
      }
    },
  },
})
