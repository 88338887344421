import React, {memo} from 'react'
import {LabelType} from './type'

const Label = ({label, className, ...props}: LabelType) => (
  <span className={`${className || 'form-lable'}`} {...props}>
    {label}
  </span>
)

export default memo(Label)
