import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {matchLiveCommentary} from '../../actions/types'

export interface MatchLiveCommentaryState {
  matchLiveCommentary: matchLiveCommentary[]
  total: number
  from: number
  loading: boolean
  matchLiveDetail?: {id: number; match_Fixture_id: number}
}

const initialMatchLiveDetail = {
  id: 0,
  match_Fixture_id: 0,
}
const initialState = {
  matchLiveCommentary: {},
  total: 0,
  from: 0,
  loading: false,
  matchLiveDetail: initialMatchLiveDetail,
} as MatchLiveCommentaryState

export const matchLiveCommentarySlice = createSlice({
  name: 'matchLiveCommentaryData',
  initialState,
  reducers: {
    updateMatchLiveCommentary: (state, action: PayloadAction<MatchLiveCommentaryState>) => {
      const {total, from} = action.payload
      state.matchLiveCommentary =
        !from || from === 1
          ? action.payload.matchLiveCommentary
          : [...state.matchLiveCommentary, ...action.payload.matchLiveCommentary]
      state.total = total || state.total
      state.from = from || state.from
      state.loading = false
    },
    updateLiveCommentaryDetalis: (
      state,
      action: PayloadAction<Partial<MatchLiveCommentaryState>>,
    ) => {
      state.matchLiveDetail = action.payload.matchLiveDetail || state.matchLiveDetail
    },
    clearLiveCommentary: (state) => {
      state.matchLiveDetail = initialState.matchLiveDetail
    },
  },
})
