import React, {useState, useEffect, memo, useCallback, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {Button} from '../../component'
import AddPlayerPopUp from './updatePlayerPopUp'
import PlayerCard from './playerCard'
import {useSelector, useAppDispatch} from '../../store'
import {
  getPlayerList,
  getPlayerDetail,
  clearPlayerDetail,
  getAppSettingList,
} from '../../store/actions'
import {UpdateListType} from '../../component/type'
import {getRole} from '../../store/selectors/user'
import {addSettingList} from '../../data'
import LazyLoader from '../../component/lazyloader'

const PlayerManagement = memo(() => {
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const {list, playDetail, total} = useSelector(({player}) => ({
    list: player.playerList,
    total: player.total,
    from: player.from,
    playDetail: player.playerDetail,
  }))
  const dispatch = useAppDispatch()
  const role = useSelector(getRole)
  const isMaster = role === 'master_user'
  const page = useRef(1)
  useEffect(() => {
    dispatch(getPlayerList(page.current))
    dispatch(getAppSettingList(isMaster, addSettingList.playerPositionList))
  }, [])

  useEffect(() => {
    page.current = 1
    loadList()
  }, [])

  const loadList = useCallback(() => {
    dispatch(getPlayerList(page.current))
  }, [])

  const loadMore = useCallback(() => {
    if (list.length < total && page.current < Math.ceil(total / 10)) {
      page.current += 1
      loadList()
    } else {
      page.current = 1
    }
  }, [total, list, loadList])

  const handleModal = async () => {
    await dispatch(clearPlayerDetail())
    setIsOpenPopup(!isOpenPopup)
  }

  const onUpdatePress = useCallback(
    async ({id, command}: UpdateListType) => {
      if (command === 'update') {
        const response = await dispatch(getPlayerDetail(id))
        if (response) setIsOpenPopup(true)
      } else if (command === 'cancel') {
        setIsOpenPopup(false)
      }
    },
    [list],
  )

  const {t} = useTranslation()
  return (
    <div className="table-outer f-1">
      <div className="table-header">
        <div className="c-carousal__header no-border-b">
          <div className="c-carousal__header__lpart v-center">
            <span className="f-Exo-Bold title-color fn-style-i">{t('players').toUpperCase()}</span>
          </div>
          <div className="c-carousal__header__rpart flex-center line-h-1">
            <Button
              icon="c-icon--plus"
              title={t('addPlayer')}
              type="submit"
              classAddon=" c-button--icons f-Exo-Bold mr-2"
              onClick={handleModal}
            />
            <AddPlayerPopUp
              isOpen={isOpenPopup}
              setIsOpen={setIsOpenPopup}
              title={playDetail?.id !== 0 ? t('editAPlayer') : t('addAPlayer')}
              description={t('deleteText')}
              onClick={onUpdatePress}
            />
          </div>
        </div>
      </div>
      <div className="flex-center-v p-4 player-card-outer">
        <LazyLoader onLoadMore={loadMore}>
          {Array.isArray(list) &&
            list.length > 0 &&
            list.map((data) => (
              <PlayerCard item={data} key={`player-${data.id}`} onClick={onUpdatePress} />
            ))}
        </LazyLoader>
      </div>
    </div>
  )
})

export default PlayerManagement
