import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ClubRequestType} from '../actions/types'

export interface ClubRequestState {
  clubRequestList: ClubRequestType[]
  total: number
  from: number
  clubRequestDetail?: any
}
const initialClubRequestDetail = {
  id: 0,
  email: '',
  created_at: '',
  journey_club: {club_name: ''},
}

const initialState = {
  clubRequestList: [],
  total: 0,
  from: 0,
  clubRequestDetail: initialClubRequestDetail,
} as ClubRequestState

export const clubRequestSlice = createSlice({
  name: 'clubRequest',
  initialState,
  reducers: {
    updateClubRequestList: (state, action: PayloadAction<ClubRequestState>) => {
      state.clubRequestList = action.payload.clubRequestList
      state.total = action.payload.total
      state.from = action.payload.from
    },
    updateClubRequestDetail: (state, action: PayloadAction<Partial<ClubRequestState>>) => {
      state.clubRequestDetail = action.payload.clubRequestDetail || state.clubRequestDetail
    },
    clearClubRequestDetail: (state) => {
      state.clubRequestDetail = initialState.clubRequestDetail
    },
  },
})
