import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {TopEarningClubType} from '../actions/types'

export interface TopEarningClubState {
  topEarningClubList: TopEarningClubType[]
  total: number
  from: number
}

const initialState = {
  topEarningClubList: [],
  total: 0,
  from: 0,
} as TopEarningClubState

export const topEarningClubSlice = createSlice({
  name: 'topEarningClub',
  initialState,
  reducers: {
    updateTopEarningClubList: (state, action: PayloadAction<TopEarningClubState>) => {
      state.topEarningClubList = action.payload.topEarningClubList
      state.total = action.payload.total
      state.from = action.payload.from
    },
  },
})
