import React, {memo} from 'react'
import ReactPaginate from 'react-paginate'
import {PageSizeList} from '../configs/paginationSetting'
import {paginationType} from '../container/type'
import PageSizeSelect from './PageSizeSelect'

const Pagination = ({
  lastPage,
  onPageChange,
  forcePageIndex,
  options,
  onLimitChange,
  isPageSize,
}: paginationType) => {
  const handlePageClick = (e: {selected: number}) => {
    onPageChange(e.selected + 1)
  }
  const onLimitValueChange = (pageSize: number) => {
    onLimitChange(pageSize)
  }
  return (
    <div className="c-pagination">
      {isPageSize && (
        <PageSizeSelect
          optionList={PageSizeList}
          onLimitValueChange={onLimitValueChange}
          classAddon="mr-1"
          defaultValue={options?.per_page}
        />
      )}
      <ReactPaginate
        previousLabel="Previous"
        previousLinkClassName="c-button c-button--bold c-button--bg c-button--bg--pink"
        nextLabel="Next"
        nextLinkClassName="c-button c-button--bold c-button--bg c-button--bg--pink"
        pageLinkClassName="page-link"
        pageClassName="page-item"
        activeClassName="page-item active"
        containerClassName="pagination"
        onPageChange={handlePageClick}
        forcePage={forcePageIndex}
        pageCount={lastPage}
      />
    </div>
  )
}

export default memo(Pagination)
