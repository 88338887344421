/* eslint-disable react/jsx-curly-newline */
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {Button} from '../../../component'
import {WidgetFooterType} from '../type'

const WidgetFooter = ({item, updateId}: WidgetFooterType) => {
  const {t} = useTranslation()
  const navigation = useNavigate()
  return (
    <div className="c-card__body c-card__body--ptb-15-20 c-card__footer--mptb-10">
      <div className="c-button-group">
        {item.btn1Title && (
          <Button
            icon={item?.btn1icon}
            title={t(item.btn1Title)}
            classAddon="c-button--bold c-button--icons"
            onClick={() =>
              item?.btn1Href &&
              navigation(`${item.btn1Href}${updateId ? `/${updateId}` : ''}`, {
                state: {stateData: item?.stateDatabtn1 || undefined},
              })
            }
          />
        )}
        {item.btn2Title && (
          <Button
            title={t(item.btn2Title)}
            className="c-button c-button--br c-button--br--gray-ab f-Exo-Medium"
            onClick={() =>
              item?.btn2Href &&
              navigation(`${item.btn2Href}${updateId ? `/${updateId}` : ''}`, {
                state: {stateDatab: item?.stateDatabtn2 || undefined},
              })
            }
          />
        )}
      </div>
    </div>
  )
}
export default WidgetFooter
