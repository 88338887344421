import React, {memo} from 'react'
import {SwitchType} from './type'

const Switch = ({label, checked, isHorizontal, onClick}: SwitchType) => (
  <div className={`c-news-switch ${isHorizontal && 'c-news-switch--hr'}`}>
    {label && !isHorizontal && <div className="c-news-switch__label">{label}</div>}
    <div className="c-switch" role="button" aria-hidden onClick={onClick}>
      <input type="checkbox" checked={checked} onChange={() => undefined} />
      <span className="c-switch__slider c-switch__slider--round" />
    </div>
    {label && isHorizontal && <div className="c-news-switch__label">{label}</div>}
  </div>
)
export default memo(Switch)
