import React from 'react'
import WidgetFooter from './renderItem/widgetFooter'
import WidgetHeader from './renderItem/widgetHeader'
import WidgetMatchAgainst from './renderItem/widgetMatchAgainst'
import {UpcominMatchWidgetType} from './type'

const UpcomingMatchWidget = ({item, nextMatchInfo, clubLogo}: UpcominMatchWidgetType) => {
  return (
    <div className="c-card">
      <WidgetHeader item={item.info} clubLogo={clubLogo} />
      <WidgetMatchAgainst nextMatchInfo={nextMatchInfo} />
      <WidgetFooter item={item.buttonList} />
    </div>
  )
}
export default UpcomingMatchWidget
