/* eslint-disable react/jsx-curly-newline */
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {MatchLiveCommentaryType} from '../type'
import NormalCommentary from './normalCommentary'
import CommentaryWithBg from './commentaryWithBg'
import HighlightCommentary from './highlightCommentary'
import {matchLiveCommentary} from '../../../store/actions/types'
import LazyLoader from '../../../component/lazyloader'
import {commentarySlugList} from '../../../data'
import {Popup} from '../../../component'
import {deleteLiveCommentary, clearLiveCommentary, setLiveCommentary} from '../../../store/actions'
import {useAppDispatch, useSelector} from '../../../store'
import {RootState} from '../../../store/slices'

const LiveCommentaryBox = ({item, loadMore}: MatchLiveCommentaryType) => {
  const dispatch = useAppDispatch()
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const {t} = useTranslation()
  const {matchLiveDetail} = useSelector(({matchLiveCommentaryView}: RootState) => ({
    matchLiveDetail: matchLiveCommentaryView.matchLiveDetail,
  }))

  const handleDeleteCommentary = async (id: number, match_fixtures_id: number) => {
    await dispatch(setLiveCommentary(id, match_fixtures_id))
    setIsOpenPopup(true)
  }
  const onClickListAction = async ({command}: {command: string}) => {
    if (command === 'remove') {
      setIsOpenPopup(true)
    } else if (command === 'deleteConfirmed') {
      await dispatch(
        deleteLiveCommentary(matchLiveDetail?.id || 0, matchLiveDetail?.match_Fixture_id || 0),
      )
      setIsOpenPopup(false)
      dispatch(clearLiveCommentary())
    }
  }

  return (
    <div>
      <Popup
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        onPressDelete={
          () => onClickListAction({command: 'deleteConfirmed'})
          // eslint-disable-next-line react/jsx-curly-newline
        }
        title={t('delete')}
        description={t('deleteText')}
      />
      <LazyLoader onLoadMore={loadMore}>
        {Array.isArray(item) &&
          item.length > 0 &&
          item.map((data: matchLiveCommentary, index) => {
            if (
              data.commentary_type.slug === commentarySlugList.comment ||
              data.commentary_type.slug === commentarySlugList.yellowCard ||
              data.commentary_type.slug === commentarySlugList.playerChange
            ) {
              return (
                <NormalCommentary
                  item={data}
                  isBottomLine={
                    !!(
                      item.length > index + 1 &&
                      (item[index + 1].commentary_type.slug === commentarySlugList.comment ||
                        item[index + 1].commentary_type.slug === commentarySlugList.yellowCard ||
                        item[index + 1].commentary_type.slug === commentarySlugList.playerChange)
                    )
                  }
                  handleDeleteCommentary={handleDeleteCommentary}
                />
              )
            }
            if (
              data.commentary_type.slug === commentarySlugList.end ||
              data.commentary_type.slug === commentarySlugList.start
            ) {
              return <CommentaryWithBg item={data} />
            }
            if (data.commentary_type.slug === commentarySlugList.goal) {
              return (
                <HighlightCommentary item={data} handleDeleteCommentary={handleDeleteCommentary} />
              )
            }
            return null
          })}
      </LazyLoader>
    </div>
  )
}
export default LiveCommentaryBox
