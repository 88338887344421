/* eslint-disable react/no-array-index-key */
import React, {memo, useMemo} from 'react'
import {createTable, getCoreRowModel, useTableInstance} from '@tanstack/react-table'
import {ListViewProps} from '../type'
import {Pagination} from '..'

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const List = <T extends unknown>(props: ListViewProps<T>) => {
  const {
    data,
    columns,
    total,
    showPagination = true,
    onPaginate,
    pageSize,
    forcePageIndex,
    options,
    onLimit,
    isPageSize,
  } = props
  const table = useMemo(() => createTable().setRowType<typeof data[0]>(), [])
  const pageCount = useMemo(
    () => (total ? (pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 10)) : 0),
    [total, pageSize],
  )

  const instance = useTableInstance(table, {
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const onPageChange = (currentPage: number) => {
    onPaginate?.(currentPage)
  }
  const onLimitChange = (per_page: number) => {
    onLimit?.(per_page)
  }
  return (
    <>
      <table>
        <thead>
          {instance.getHeaderGroups().map((headerGroup) => (
            <tr key={`${headerGroup.id}_headerGroup`}>
              {headerGroup.headers.map(
                (header, index) =>
                  header.renderHeader() && (
                    <th
                      key={`${header.id}_header_${index}`}
                      colSpan={header.colSpan}
                      style={{width: header.getSize()}}
                    >
                      {header.renderHeader()}
                    </th>
                  ),
              )}
            </tr>
          ))}
        </thead>
        <tbody>
          {instance.getRowModel().rows.map((row) => (
            <tr key={`rows_id_${row.id}`}>
              {row.getVisibleCells().map((cell, index) => (
                <td key={`cell_id_${row.id}_${cell.id}_${index}`}>{cell.renderCell()}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {(showPagination && pageCount && (
        <div className="pagination-outer">
          <Pagination
            lastPage={pageCount}
            onPageChange={onPageChange}
            forcePageIndex={forcePageIndex}
            options={options}
            onLimitChange={onLimitChange}
            isPageSize={isPageSize}
          />
        </div>
      )) || <div className="text-center mt-2 mb-2">No data found</div>}
    </>
  )
}

export default memo(List)
