import React from 'react'
import {useTranslation} from 'react-i18next'
import RevenueItem from '../renderItem/revenurItem'
import {ClubRevenueFooterType} from '../type'

const ClubRevenueFooter = ({item}: ClubRevenueFooterType) => {
  const {t} = useTranslation()
  return (
    <div className="c-card__footer c-revenue-highest--radius">
      <div className="c-revenue-content">
        <h5 className="c-revenue-content__title f-Exo-Bold">{t(item.title).toUpperCase()}</h5>
        <div className="c-revenue-content__list">
          {Array.isArray(item.revenueList) &&
            item.revenueList.length > 0 &&
            item.revenueList.map((data) => (
              <RevenueItem
                item={data}
                currency={item.currency}
                key={`revenue-list-${data.revunueTitle}`}
              />
            ))}
        </div>
      </div>
    </div>
  )
}
export default ClubRevenueFooter
