import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {mediasModel} from '../../data'
import {TestimonialType} from '../actions/types'

export interface TestimonialState {
  testimonialList: TestimonialType[]
  total: number
  from: number
  testimonialDetail?: TestimonialType
}

const initialTestimonialDetail = {
  id: 0,
  name: '',
  position: '',
  description: '',
  created_at: '',
  medias_model: [mediasModel],
}
const initialState = {
  testimonialList: [],
  total: 0,
  from: 0,
  testimonialDetail: initialTestimonialDetail,
} as TestimonialState

export const testimonialSlice = createSlice({
  name: 'testimonial',
  initialState,
  reducers: {
    updateTestimonialList: (state, action: PayloadAction<TestimonialState>) => {
      state.testimonialList = action.payload.testimonialList
      state.total = action.payload.total
      state.from = action.payload.from
    },
    updateTestimonialDetail: (state, action: PayloadAction<Partial<TestimonialState>>) => {
      state.testimonialDetail = action.payload.testimonialDetail || state.testimonialDetail
    },
    clearTestimonialDetail: (state) => {
      state.testimonialDetail = initialState.testimonialDetail
    },
  },
})
