import {breadcrumbType} from '../container/type'
import {getSidebar} from '../data'

export const getRouteName = (pathname: string, role: string) => {
  const sidebarList = getSidebar(role)
  const currentRoute1: any = sidebarList.filter(
    (route) => route.link === `/${pathname.split('/')[1]}`,
  )
  if (!pathname.split('/')[2]) {
    return currentRoute1[0]?.title
  }
  const currentRoute2: any = currentRoute1[0]?.subList.find((route: any) => route.link === pathname)
  return currentRoute2?.title
}
export const getBreadcrumbs = (location: string, role: string) => {
  const breadcrumbs: breadcrumbType[] = []
  location.split('/').reduce((prev: string, curr: string, index: number, array: string[]) => {
    const currentPathname = `${prev}/${curr}`
    breadcrumbs.push({
      pathname: currentPathname,
      name: getRouteName(currentPathname, role) || '',
      active: index + 1 === array.length,
    })
    return currentPathname
  })
  if (
    Array.isArray(breadcrumbs) &&
    breadcrumbs.length > 2 &&
    breadcrumbs[1].name === 'matchContent'
  ) {
    breadcrumbs[2].name = 'Match details'
  }
  const newBreadcrumbs = breadcrumbs.filter((data) => data.name)
  return newBreadcrumbs
}
