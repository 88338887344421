import React from 'react'
import WidgetFooter from './renderItem/widgetFooter'
import WidgetHeader from './renderItem/widgetHeader'
import WidgetMedia from './renderItem/widgetMedia'
import {LastestVideoWidgetType} from './type'

const LastestVideoWidget = ({item, lastStoryInfo}: LastestVideoWidgetType) => {
  return (
    <div className="c-card">
      <WidgetHeader item={item.info} />
      {Array.isArray(lastStoryInfo?.medias_model) && lastStoryInfo?.medias_model.length > 0 && (
        <WidgetMedia lastStoryInfo={lastStoryInfo} />
      )}
      <WidgetFooter item={item.buttonList} />
    </div>
  )
}
export default LastestVideoWidget
