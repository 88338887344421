import React, {memo, useCallback, useEffect, useMemo, useState} from 'react'
import {createTable} from '@tanstack/react-table'
import {useNavigate} from 'react-router-dom'
import {format} from 'date-fns'
import {UpdateListType} from '../../component/type'
import {List as ListView} from '../../component'
import ListRight from '../../component/listRight'
import {clearMatchReport, getMatchContentList} from '../../store/actions'
import {useSelector, useAppDispatch} from '../../store'
import {MatchContentType} from '../../store/actions/types'
import {MatchDetailsButton} from '../../component/List/Commands'
import {defaultPageSize} from '../../configs/paginationSetting'
import images from '../../assets/images'

const table = createTable().setRowType<MatchContentType>()

const MatchContent = memo(() => {
  const navigation = useNavigate()
  const {list, total, from, userData} = useSelector(({matchContent, user}) => ({
    list: matchContent.matchContentList,
    total: matchContent.total,
    from: matchContent.from,
    userData: user.user,
  }))
  const defaultOptions = useMemo(
    () => ({
      sort_by: 'match_start_time',
      sort_order: 'desc',
      page: 1,
      per_page: defaultPageSize,
    }),
    [],
  )
  const [options, setOptions] = useState(defaultOptions)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(clearMatchReport())
    dispatch(getMatchContentList(options))
  }, [options])

  const columns = useMemo(
    () => [
      table.createDataColumn('id', {
        size: 150,
        header: ' ',
        cell: ({row}) => (
          <div className="flex-1-center-v">
            <span className="f-Exo-Bold f-0-3 fn-30">
              {String(new Date(row.original?.match_start_time || new Date()).getDate()).padStart(
                2,
                '0',
              )}
            </span>
            <div className="flex-1 ml-1 flex-d-column fn-12">
              <span className="f-Exo-Bold">
                {format(new Date(row.original?.match_start_time || new Date()), 'MMM')}
              </span>
              <span>
                {format(new Date(row.original?.match_start_time || new Date()), 'EEEEEE')}
              </span>
            </div>
          </div>
        ),
      }),
      table.createDataColumn('club', {
        size: 185,
        header: ' ',
        cell: ({row}) => {
          const currentClub =
            row.original?.club?.club_name === userData.club_data?.club_name
              ? 'club'
              : 'opponent_club'
          return (
            <div className="flex-1 h-space-bet">
              <div className="club-name-outer">{row.original?.[`${currentClub}`]?.club_name}</div>
              <div className="flex-1 h-flex-end">
                <img
                  src={
                    row.original?.[`${currentClub}`]?.medias_model[0]?.original_url ||
                    images.dummyUser
                  }
                  alt=""
                  className="img_preview-match-content"
                />
              </div>
            </div>
          )
        },
      }),
      table.createDataColumn('club', {
        header: ' ',
        size: 100,
        cell: () => <div className="flex-1 h-center fn-12">VS</div>,
      }),
      table.createDataColumn('opponent_club', {
        header: ' ',
        size: 185,
        cell: ({row}) => {
          const opponentClub =
            row.original?.club?.club_name === userData.club_data?.club_name
              ? 'opponent_club'
              : 'club'
          return (
            <div className="flex-1 h-space-bet">
              <div className="flex mr-4 h-flex-start">
                <img
                  src={
                    row.original?.[`${opponentClub}`]?.medias_model[0]?.original_url ||
                    images.dummyUser
                  }
                  alt=""
                  className="img_preview-match-content"
                />
              </div>
              <div className="club-name-outer f-1">
                {row.original?.[`${opponentClub}`]?.club_name}
              </div>
            </div>
          )
        },
      }),
      table.createDataColumn('opponent_club', {
        header: ' ',
        size: 200,
        cell: ({row}) => (
          <div className="flex flex-d-column fn-12">
            <span>{row.original?.match_vanue}</span>
            <div className="">
              <span>{format(new Date(row.original?.match_start_time || new Date()), 'kk:mm')}</span>
            </div>
          </div>
        ),
      }),
      table.createDataColumn('id', {
        header: undefined,
        cell: ({row}) =>
          row.original && (
            <ListRight
              row={row.original}
              onClick={onUpdatePress}
              buttonList={[MatchDetailsButton]}
            />
          ),
      }),
    ],
    [from],
  )

  const onUpdatePress = useCallback(async ({id, command}: UpdateListType) => {
    if (command === 'matchDetails') {
      navigation(`/content/match-content/${id}`)
    }
  }, [])

  const onPaginate = useCallback(
    (page: number) => {
      setOptions({...options, page})
    },
    [options],
  )

  const onLimit = useCallback(
    (per_page: number) => {
      setOptions({...options, per_page, page: 1})
    },
    [options],
  )

  return (
    <div className="table-outer">
      <ListView
        columns={columns}
        data={list}
        total={total}
        onPaginate={onPaginate}
        forcePageIndex={options.page - 1}
        options={options}
        isPageSize
        onLimit={onLimit}
        pageSize={options?.per_page}
      />
    </div>
  )
})

export default MatchContent
