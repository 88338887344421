import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface TabState {
  activeTab: number
}

const initialState = {activeTab: 0}

export const tabSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    updateActiveTabList: (state, action: PayloadAction<TabState>) => {
      state.activeTab = action.payload.activeTab
    },
  },
})
