/* eslint-disable react/jsx-curly-newline */
import React, {useState, useEffect, useCallback, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {Button, Input, Select} from '../../component'
import {MatchAddEventType} from './type'
import {convertAppSettingResToSelectList, serializeFormData} from '../../functions/utils'
import {hoursListSingleValue} from '../../data/time'
import {EventType} from '../contentManagement/type'
import {getPlayerList, saveLiveCommentary, getCommentaryEventList} from '../../store/actions'
import {useAppDispatch, useSelector} from '../../store'
import {commentarySlugList} from '../../data'
import LazyLoader from '../../component/lazyloader'

const MatchAddEvent = ({setIsEdit, id}: MatchAddEventType) => {
  const [isAddAnother, setisAddAnother] = useState(false)
  const [playerValue, setPlayerValue] = useState('')
  const [periodValue, setPeriodValue] = useState('')
  const [eventValue, setEventValue] = useState({eventName: '', eventSlug: ''})
  const {t} = useTranslation()
  const page = useRef(1)
  const dispatch = useAppDispatch()
  const {list, commentaryEventList, periodList, playerTotal} = useSelector(
    ({player, eventList, appSetting}) => ({
      list: player.playerList,
      playerTotal: player.total,
      commentaryEventList: eventList.eventList,
      periodList: appSetting.commentaryPeriodList,
    }),
  )
  const initialValues = {
    time: '',
    period: '',
    event: '',
    player: '',
    text: '',
  }

  const handleConvertingDropdownListPlayer = (data: any) => {
    const playerListForDropDown = []
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < data.length; index++) {
      playerListForDropDown.push({
        id: index + 1,
        value: data[index].id,
        label: `${data[index].first_name} ${data[index].last_name}`,
      })
    }
    return playerListForDropDown
  }

  const handleConvertingDropdownListEvent = (data: any) => {
    const EventListForDropDown = []
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < data.length; index++) {
      EventListForDropDown.push({
        id: index + 1,
        value: data[index].id,
        label: data[index].title,
      })
    }
    return EventListForDropDown
  }

  const playerList = handleConvertingDropdownListPlayer(list)
  const EventList = handleConvertingDropdownListEvent(commentaryEventList)
  const commentaryPeriodList = convertAppSettingResToSelectList(periodList)

  const onSubmit: any = async (values: any, {resetForm}: any) => {
    const formValues: any = {}
    formValues.club_players_id = values.player || undefined
    formValues.match_fixture_id = id || 0
    formValues.commentary_types_id = values.event || undefined
    formValues.commentary_time = values.time || undefined
    formValues.commentary_period = values.period || undefined
    formValues.commentary_text =
      eventValue.eventSlug === commentarySlugList.comment ||
      eventValue.eventSlug === commentarySlugList.yellowCard ||
      eventValue.eventSlug === commentarySlugList.playerChange
        ? values.text
        : eventValue.eventSlug === commentarySlugList.goal
        ? playerValue
        : `${eventValue.eventName} of ${periodValue}` || undefined
    const formData = await serializeFormData(formValues)
    await dispatch(saveLiveCommentary(id || 0, formData))
    if (isAddAnother) {
      resetForm({values: ''})
      setisAddAnother(false)
    } else {
      setIsEdit(false)
    }
  }

  const validationSchema = Yup.object().shape({
    time: Yup.string().trim().required('Time cannot be empty.'),
    period: Yup.string().trim().required('Period cannot be empty.'),
    event: Yup.string().trim().required('Event cannot be empty.'),
    player: Yup.string().trim().required('Player cannot be empty.'),
    text:
      eventValue.eventSlug === commentarySlugList.comment ||
      eventValue.eventSlug === commentarySlugList.yellowCard ||
      eventValue.eventSlug === commentarySlugList.playerChange
        ? Yup.string().trim().required('Description cannot be empty.')
        : Yup.string(),
  })

  const handleClose = () => {
    setIsEdit(false)
  }

  useEffect(() => {
    dispatch(getPlayerList())
    dispatch(getCommentaryEventList())
  }, [])

  useEffect(() => {
    page.current = 1
    loadList()
  }, [])

  const loadList = useCallback(() => {
    dispatch(getPlayerList(page.current))
  }, [])

  const loadMore = useCallback(() => {
    if (list.length < playerTotal && page.current < Math.ceil(playerTotal / 10)) {
      page.current += 1
      loadList()
    }
  }, [playerTotal, list, loadList])

  return (
    <>
      <div className="c-card__title f-Exo-Bold flex ">
        <h4 className="f-1 mb-0 flex-center-v">{t('addAnEvent')}</h4>
        <div className="flex-center-v">
          <Button icon="c-icon--close" className="" onClick={() => setIsEdit(false)} />
        </div>
      </div>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({touched, errors, setFieldValue, submitForm}) => (
          <Form>
            <div className="mt-4 mb-3 flex flex-d-column">
              <div className="flex-1 flex-d-row">
                <Select
                  name="time"
                  label={t('time')}
                  error={touched.time && errors.time}
                  onChange={(e: EventType) => setFieldValue('time', e.target.value)}
                  className="form-control mt-1"
                  classNameLable="fn-14"
                  optionsList={hoursListSingleValue}
                  addOnLable="'"
                  emptyText={t('selectTime')}
                  classAddonOuter="flex f-0-3 flex-d-column mr-3"
                />
                <Select
                  name="period"
                  label={t('period')}
                  error={touched.period && errors.period}
                  onChange={(e: EventType) => {
                    setFieldValue('period', e.target.value)
                    setPeriodValue(
                      commentaryPeriodList?.filter((data) => data.value === e.target.value)[0]
                        .label,
                    )
                  }}
                  className="form-control mt-1"
                  classNameLable="fn-14"
                  optionsList={commentaryPeriodList}
                  emptyText={t('selectPeriod')}
                  classAddonOuter="flex f-0-7 flex-d-column ml-3"
                />
              </div>
              <div>
                <Select
                  name="event"
                  label={t('event')}
                  error={touched.event && errors.event}
                  onChange={(e: EventType) => {
                    setFieldValue('event', e.target.value)
                    setEventValue({
                      ...eventValue,
                      eventName: commentaryEventList?.filter(
                        (data) => data.id === parseInt(e.target.value, 10),
                      )[0].title,
                      eventSlug: commentaryEventList?.filter(
                        (data) => data.id === parseInt(e.target.value, 10),
                      )[0].slug,
                    })
                  }}
                  className="form-control mt-1"
                  classNameLable="fn-14"
                  optionsList={EventList}
                  emptyText={t('selectEvent')}
                  classAddonOuter="f-1"
                />
              </div>
              <div>
                <LazyLoader onLoadMore={loadMore}>
                  <Select
                    name="player"
                    label={t('player')}
                    error={touched.player && errors.player}
                    onChange={(e: any) => {
                      setFieldValue('player', e.target.value)
                      setPlayerValue(
                        playerList.filter((data) => data.value === parseInt(e.target.value, 10))[0]
                          .label,
                      )
                    }}
                    className="form-control mt-1"
                    classNameLable="fn-14"
                    optionsList={playerList}
                    emptyText={t('selectPlayer')}
                    classAddonOuter="f-1"
                  />
                </LazyLoader>
              </div>
              {(eventValue.eventSlug === commentarySlugList.comment ||
                eventValue.eventSlug === commentarySlugList.yellowCard ||
                eventValue.eventSlug === commentarySlugList.playerChange) && (
                <div>
                  <Input
                    name="text"
                    label={t('description')}
                    onChange={(e: EventType) => {
                      setFieldValue('text', e.target.value)
                    }}
                    component="textarea"
                    placeholder={t('enterDescriptionHere')}
                    error={touched.text && errors.text}
                    className="form-control mt-1"
                    classNameLable="fn-14"
                  />
                </div>
              )}
              <div>
                <Button
                  style={{color: '#CF0B48'}}
                  className="f-Exo-Bold flex-1-center-v mb-3 fn-14"
                  icon="c-icon--plus-pink"
                  title=" Save & add another"
                  onClick={() => {
                    setisAddAnother(true)
                    submitForm()
                  }}
                />
              </div>
              <div className="c-card__footer">
                <Button
                  // icon={loading ? 'c-icon--loader mr-2' : ''}
                  title={t('saveAndClose')}
                  classAddon="mr-3 f-Exo-Bold"
                  onClick={submitForm}
                />
                <Button
                  title={t('cancel')}
                  classAddon="c-button--bg--white f-Exo-Bold"
                  onClick={handleClose}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
export default MatchAddEvent
