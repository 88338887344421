import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {mediasModel} from '../../data'
import {ClubFixtureRequestType} from '../actions/types'

export interface ClubFixtureRequestState {
  clubFixtureRequestList: ClubFixtureRequestType[]
  total: number
  from: number
  clubFixtureRequestDetail?: any
}
const initialClubFixtureRequestDetail = {
  id: 0,
  created_at: '',
  match_vanue: '',
  match_start_time: '',
  club_id: 0,
  opponent_club_id: 0,
  club: {
    id: 0,
    club_name: '',
    medias_model: [mediasModel],
  },
  opponent_club: {
    id: 0,
    club_name: '',
    medias_model: [mediasModel],
  },
}

const initialState = {
  clubFixtureRequestList: [],
  total: 0,
  from: 0,
  clubFixtureRequestDetail: initialClubFixtureRequestDetail,
} as ClubFixtureRequestState

export const clubFixtureRequestSlice = createSlice({
  name: 'clubFixtureRequest',
  initialState,
  reducers: {
    updateClubFixtureRequestList: (state, action: PayloadAction<ClubFixtureRequestState>) => {
      state.clubFixtureRequestList = action.payload.clubFixtureRequestList
      state.total = action.payload.total
      state.from = action.payload.from
    },
    updateClubFixtureRequestDetail: (
      state,
      action: PayloadAction<Partial<ClubFixtureRequestState>>,
    ) => {
      state.clubFixtureRequestDetail =
        action.payload.clubFixtureRequestDetail || state.clubFixtureRequestDetail
    },
    clearClubFixtureRequestDetail: (state) => {
      state.clubFixtureRequestDetail = initialState.clubFixtureRequestDetail
    },
  },
})
