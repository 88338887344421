/* eslint-disable no-unused-expressions */
import React, {memo, useCallback, useEffect, useRef} from 'react'
import {getMedialLibraryList, getMedialLibraryListByFixtureId} from '../../store/actions'
import {useAppDispatch, useSelector} from '../../store'
import MediaItem from '../../component/mediaitem'
import LazyLoader from '../../component/lazyloader'
import {MediaLibraryFilters, MediaLibraryItem} from '../../store/actions/types'

interface MediaListProps {
  options?: MediaLibraryFilters
  isSelectable?: boolean
  onEdit?: (mediaItem: MediaLibraryItem) => void
  selectedMedia?: MediaLibraryItem[]
  isMediaLibraty?: boolean
}

const MediaList: React.FC<MediaListProps> = memo((props) => {
  const {options, onEdit, selectedMedia, isMediaLibraty} = props
  const {mediaList, total} = useSelector((state) => state.mediaLibrary)
  const dispatch = useAppDispatch()
  const page = useRef(1)
  const loading = useRef(true)

  useEffect(() => {
    page.current = 1
    loadList()
  }, [options])

  const loadList = useCallback(() => {
    isMediaLibraty
      ? dispatch(getMedialLibraryList({page: page.current, ...options})).finally(() => {
          loading.current = false
        })
      : dispatch(getMedialLibraryListByFixtureId({page: page.current, ...options})).finally(() => {
          loading.current = false
        })
  }, [options])

  const loadMore = useCallback(() => {
    if (!loading.current && mediaList.length < total && page.current < Math.ceil(total / 10)) {
      loading.current = true
      page.current += 1
      loadList()
    }
  }, [options, total, mediaList, loadList])

  if (!mediaList || mediaList.length <= 0) {
    return (
      <div className="c-media-card">
        <div className="height-fit-content f-1 text-center">Your search returned no results.</div>
      </div>
    )
  }

  return (
    <div className="c-media-card p-0">
      <LazyLoader onLoadMore={loadMore}>
        {mediaList.map((mediaItem) => {
          let selected = false
          Array.isArray(selectedMedia) &&
            selectedMedia.forEach((selectedMediaItem: MediaLibraryItem) => {
              if (selectedMediaItem.id === mediaItem.id) {
                selected = true
              }
            })
          return (
            <div
              className={`c-media-card__image pointer ${selected ? 'selected' : ''}`}
              role="button"
              onClick={() => onEdit?.(mediaItem)}
              onKeyDown={(e) => {
                if (['Enter'].includes(e.key)) {
                  onEdit?.(mediaItem)
                }
              }}
              tabIndex={0}
            >
              <MediaItem
                url={mediaItem.original_url}
                alt={mediaItem.title}
                file_type={mediaItem.file_type}
              />
            </div>
          )
        })}
      </LazyLoader>
    </div>
  )
})

export default MediaList
