import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {MediaLibraryItem} from '../actions/types'

export interface MediaLibraryState {
  mediaList: MediaLibraryItem[]
  total: number
  from: number
  loading: boolean
}

const initialState = {
  mediaList: [],
  total: 0,
  from: 0,
  loading: false,
} as MediaLibraryState

export const mediaLibrarySlice = createSlice({
  name: 'mediaLibrary',
  initialState,
  reducers: {
    updateList: (state, action: PayloadAction<MediaLibraryState>) => {
      const {mediaList, total, from} = action.payload
      state.mediaList = !from || from === 1 ? mediaList : [...state.mediaList, ...mediaList]
      state.total = total || state.total
      state.from = from || state.from
      state.loading = false
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})
