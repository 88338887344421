import axios from 'axios'
import {apiErrorBlock} from '../../functions/utils'
import {urls} from '../../services/api'
import {socialKeys} from '../../services/socialKey'

export const addTwitterTokenInfo = (data: any) => async () => {
  try {
    const params = new URLSearchParams()
    params.append('refresh_token', data.refresh_token)
    params.append('grant_type', 'refresh_token')
    params.append('client_id', socialKeys.twitter.client_id)
    const response: any = await axios.post(`${urls.twitterInfo}/2/oauth2/token`, params)
    return response.data
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
