import React, {memo, useCallback, useEffect, useMemo, useState} from 'react'
import {createTable} from '@tanstack/react-table'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {format} from 'date-fns'
import {UpdateListType} from '../../component/type'
import {Button, List as ListView, Popup} from '../../component'
import ListRight from '../../component/listRight'
import {getAdsList, getAdsDetail, clearAdsDetail, deleteAds} from '../../store/actions'
import {useSelector, useAppDispatch} from '../../store'
import {AdsType} from '../../store/actions/types'
import {EditeButtonList, RemoveButtonList} from '../../component/List/Commands'
import {defaultPageSize} from '../../configs/paginationSetting'
import {adsDevice, adsFormat} from '../../data'

const table = createTable().setRowType<AdsType>()

const AdsManagement = memo(() => {
  const {t} = useTranslation()
  const navigation = useNavigate()
  const {list, total, from} = useSelector(({ads}) => ({
    list: ads.adsList,
    total: ads.total,
    from: ads.from,
  }))
  const [selectedRow, setSelectedRow] = useState<AdsType>()
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [currentItemId, setCurrentItemId] = useState(0)
  const [loading, setLoading] = useState(false)
  const defaultOptions = useMemo(
    () => ({
      active_Status: 0,
      page: 1,
      per_page: defaultPageSize,
    }),
    [],
  )
  const [options, setOptions] = useState(defaultOptions)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getAdsList(options))
  }, [options])

  const columns = useMemo(
    () => [
      table.createDataColumn('id', {
        header: 'NUMBER',
        size: 200,
        cell: ({row}) => <span>{String(row.index + from).padStart(2, '0')}</span>,
      }),
      table.createDataColumn('club', {
        enableResizing: true,
        header: 'CLUB NAME',
        size: 400,
        cell: ({row}) => (
          <div>
            <span>{row.original?.club?.club_name}</span>
          </div>
        ),
      }),
      table.createDataColumn('ads_unit_format', {
        enableResizing: true,
        header: 'FORMAT',
        size: 400,
        cell: ({row}) => (
          <div>
            <span>
              {adsFormat[row.original?.ads_unit_format ? row.original.ads_unit_format : 0]}
            </span>
          </div>
        ),
      }),
      table.createDataColumn('ads_unit_app_platform', {
        enableResizing: true,
        header: 'PLATFORM',
        size: 400,
        cell: ({row}) => (
          <div>
            <span>
              {
                adsDevice[
                  row.original?.ads_unit_app_platform ? row.original?.ads_unit_app_platform : 0
                ]
              }
            </span>
          </div>
        ),
      }),
      table.createDataColumn('created_at', {
        header: 'DATE & TIME',
        size: 300,
        cell: ({row}) => (
          <span>
            {(row.original?.created_at &&
              format(new Date(`${row.original?.created_at}`), 'dd MMM yyyy, hh:mm a')) ||
              format(new Date(), 'dd MMM yyyy, hh:mm a')}
          </span>
        ),
      }),
      table.createDataColumn('id', {
        header: '',
        cell: ({row}) =>
          row.original && (
            <ListRight
              row={row.original}
              onClick={onUpdatePress}
              buttonList={[EditeButtonList, RemoveButtonList]}
            />
          ),
      }),
    ],
    [from],
  )

  const onUpdatePress = useCallback(
    async ({id, command}: UpdateListType) => {
      if (command === 'edit') {
        const response = await dispatch(getAdsDetail(id))
        if (response) navigation(`/ads-management/${id}`)
      } else if (command === 'remove') {
        setCurrentItemId(id)
        setIsOpenPopup(true)
      } else if (command === 'deleteConfirmed') {
        setLoading(true)
        await dispatch(deleteAds(id, options))
        setIsOpenPopup(false)
        setLoading(false)
      }
      setSelectedRow(undefined)
    },
    [selectedRow, list],
  )

  const onPaginate = useCallback(
    (page: number) => {
      setOptions({...options, page})
    },
    [options],
  )
  const onLimit = useCallback(
    (per_page: number) => {
      setOptions({...options, per_page, page: 1})
    },
    [options],
  )
  const onAddNewPress = useCallback(() => {
    dispatch(clearAdsDetail())
    navigation(`/ads-management/add`)
  }, [])

  return (
    <div className="table-outer">
      <div className="table-header">
        <div className="c-carousal__header">
          <div className="c-carousal__header__lpart v-center ">
            <span className="f-Exo-Bold title-color">{t('adsList')}</span>
          </div>
          <div className="c-carousal__header__rpart flex-center">
            <Button
              icon="c-icon--plus"
              title={t('addNewAds')}
              classAddon="c-button--we-160 c-button--icons f-Exo-Medium"
              onClick={onAddNewPress}
            />
            <Popup
              isOpen={isOpenPopup}
              setIsOpen={setIsOpenPopup}
              onPressDelete={() => onUpdatePress({id: currentItemId, command: 'deleteConfirmed'})}
              title={t('delete')}
              description={t('deleteText')}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <ListView
        columns={columns}
        data={list}
        total={total}
        onPaginate={onPaginate}
        forcePageIndex={options.page - 1}
        options={options}
        isPageSize
        onLimit={onLimit}
        pageSize={options?.per_page}
      />
    </div>
  )
})

export default AdsManagement
