import React, {memo, useCallback, useMemo, useState, useEffect} from 'react'
import MediaList from './medialist'
import FilterBar from './filterbar'
import {Button, Modal} from '../../component'
import MediaForm from './mediaform'
import {MediaLibraryFilters, MediaLibraryItem} from '../../store/actions/types'
import {getMediaItemDetails} from '../../store/actions'

const MediaLibrary = memo(() => {
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [selectedTab, setSelectedTab] = useState('all')
  const [editItem, setEditItem] = useState<MediaLibraryItem>()
  const defaultOptions: MediaLibraryFilters = useMemo(
    () => ({
      search: '',
      file_type: undefined,
      from_date: undefined,
      to_date: undefined,
    }),
    [],
  )
  const [options, setOptions] = useState(defaultOptions)

  const onFilterChange = useCallback(
    (command: string, value?: string) => {
      if (command === 'all') {
        setOptions({...options, file_type: undefined})
      } else if (command === 'only_image') {
        setOptions({...options, file_type: 0})
      } else if (command === 'only_video') {
        setOptions({...options, file_type: 1})
      } else if (command === 'search') {
        setOptions({...options, search: value})
      } else if (command === 'tag_slug') {
        setOptions({...options, tag_slug: value})
      }
    },
    [options],
  )
  const onDateChange = useCallback(
    (selectionRange: {startDate: Date | undefined; endDate: Date | undefined}) => {
      if (selectionRange) {
        setOptions({
          ...options,
          from_date: selectionRange.startDate,
          to_date: selectionRange.endDate,
        })
      }
    },
    [options],
  )

  const onEditPress = useCallback(async (mediaItem: MediaLibraryItem) => {
    const item = await getMediaItemDetails(mediaItem.id)
    if (item) {
      setEditItem(item)
      setIsFormOpen(true)
    }
  }, [])

  useEffect(() => {
    return () => {
      setSelectedTab('all')
    }
  }, [window.location.pathname])

  return (
    <>
      <Button
        icon="c-icon--plus"
        title="Upload"
        classAddon="c-button--we-160 c-button--icons mb-30 f-Exo-Bold"
        onClick={() => {
          setEditItem(undefined)
          setIsFormOpen(true)
        }}
      />
      <Modal isOpen={isFormOpen} setIsOpen={setIsFormOpen}>
        <MediaForm
          onClose={() => {
            setIsFormOpen(false)
            onFilterChange('all')
            setSelectedTab('all')
          }}
          editItem={editItem}
        />
      </Modal>
      <div className="c-media-library">
        <FilterBar
          onFilterChange={onFilterChange}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          onDateChange={onDateChange}
        />
        <div
          className="c-media-library__body c-media-library__body--p-14"
          style={{overflow: 'auto'}}
        >
          <div>
            <div id="allMedia">
              <MediaList options={options} onEdit={onEditPress} isMediaLibraty />
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default MediaLibrary
