import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {FixtureType} from '../actions/types'

export interface FixtureState {
  fixtureList: FixtureType[]
  total: number
  from: number
  fixtureDetail: FixtureType
}

const initialFixtureDetail = {
  id: 0,
  match_start_time: '',
  match_vanue: '',
  match_kickoff_time: '',
  match_meet_time: '',
  club_final_score: 0,
  club_id: 0,
  club_penalties: 0,
  club: {
    id: 0,
    club_name: '',
    medias_model: [
      {
        medias_id: 0,
        original_url: '',
        file_name: '',
      },
    ],
  },
  opponent_club_id: 0,
  opponent_final_score: 0,
  opponent_penalties: 0,
  opponent_club: {
    id: 0,
    club_name: '',
    medias_model: [
      {
        medias_id: 0,
        original_url: '',
        file_name: '',
      },
    ],
  },
  match_attend_time: '',
  match_description: '',
}

const initialState = {
  fixtureList: [],
  total: 0,
  from: 0,
  fixtureDetail: initialFixtureDetail,
} as FixtureState

export const fixtureSlice = createSlice({
  name: 'fixture',
  initialState,
  reducers: {
    updateFixtureList: (state, action: PayloadAction<Partial<FixtureState>>) => {
      const {fixtureList, total, from} = action.payload
      state.fixtureList = fixtureList || state.fixtureList
      state.total = total || initialState.total
      state.from = from || initialState.from
    },
    updateFixtureDetail: (state, action: PayloadAction<Partial<FixtureState>>) => {
      state.fixtureDetail = action.payload.fixtureDetail || state.fixtureDetail
    },
    clearFixtureDetail: (state) => {
      state.fixtureDetail = initialState.fixtureDetail
    },
  },
})
