import Api, {urls} from '../../../services/api'
import {ApiResponseList, matchContentMatchInfo} from '../types'
import logException from '../../../services/sentry'
import showNotification from '../../../functions/notification'
import {matchContentMatchInfoAction} from '../../slices'
import {AppDispatch} from '../..'
import {apiErrorBlock} from '../../../functions/utils'

export const getMatchContentMatchInfo = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<matchContentMatchInfo> = await Api.get(
      `${urls.matchContent}/${id}/${urls.matchInfo}`,
    )
    const {status, message, data} = response.data
    if (status && data) {
      await dispatch(
        matchContentMatchInfoAction.updateMatchContentMatchInfo({
          matchContentMatchInfo: data as matchContentMatchInfo,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    // eslint-disable-next-line prettier/prettier
  } catch (e: any) {
    apiErrorBlock(e)
  }
}

export const updateMatchInfo = (id: number, params: FormData) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<matchContentMatchInfo> = await Api.post(
      `${urls.matchContent}/${id}/match_info`,
      params,
    )
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getMatchContentMatchInfo(id))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
