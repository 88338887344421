import React, {memo, useMemo, useCallback} from 'react'
import Modal from 'react-modal'
import {useTranslation} from 'react-i18next'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {Button, Input, Label} from '../../component'
import {PopupType} from './type'
import {customStylesPlayer, mediasModel} from '../../data'
import Select from '../../component/select'
import DragFile from '../../component/dragFile'
import {EventType} from '../contentManagement/type'
import {
  convertAppSettingResToSelectList,
  mapFilesToObject,
  serializeFormData,
} from '../../functions/utils'
import {MediaType} from '../news/type'
import {useSelector, useAppDispatch} from '../../store'
import {RootState} from '../../store/slices'
import {addPlayer, updatePlayer} from '../../store/actions'
import {setFieldValueType} from '../../component/type'

const AddPlayerPopup = ({isOpen, setIsOpen, title, loading, onClick}: PopupType) => {
  const {playerDetail, playerPositionList} = useSelector(({player, appSetting}: RootState) => ({
    playerDetail: player.playerDetail,
    playerPositionList: appSetting.playerPositionList,
  }))
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const positionList = convertAppSettingResToSelectList(playerPositionList)
  const initialValues = {
    firstName: playerDetail?.first_name || '',
    lastName: playerDetail?.last_name || '',
    position: playerDetail?.position ? playerDetail.position : playerDetail?.id ? '0' : '',
    shirtNumber: playerDetail?.shirt_number || '',
    bio: '',
    facebookLink: '',
    twitterLink: '',
    instagramLink: '',
    medias_model:
      (playerDetail?.medias_model &&
        playerDetail.medias_model?.length > 0 &&
        playerDetail.medias_model[0]) ||
      mediasModel,
  }
  const onSubmit = async (values: any) => {
    const formValues = {
      first_name: values.firstName || undefined,
      last_name: values.lastName || undefined,
      position: parseInt(values.position, 10) || 0,
      shirt_number: values.shirtNumber || undefined,
      image: values?.medias_model.file || undefined,
      bio: values.bio || '',
      facebookLink: values.facebookLink || '',
      twitterLink: values.twitterLink || '',
      instagramLink: values.instagramLink || '',
    }
    const formData = serializeFormData(formValues)
    if (playerDetail?.id === 0) {
      await dispatch(addPlayer(formData))
      setIsOpen(false)
    } else {
      await dispatch(updatePlayer(playerDetail?.id || 0, formData))
      setIsOpen(false)
    }
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required('First Name cannot be empty.'),
    lastName: Yup.string().trim().required('Last Name cannot be empty.'),
    position: Yup.string().trim().required('Position cannot be empty.'),
    shirtNumber: Yup.string().trim().required('Shirt Number cannot be empty.'),
  })

  const onFilesSelection = useCallback(
    (acceptedFiles: MediaType[], setFieldValue: setFieldValueType) => {
      const newFiles = mapFilesToObject(acceptedFiles)
      if (newFiles && newFiles.length > 0) {
        setFieldValue('medias_model', newFiles[0])
      }
    },
    [],
  )

  return (
    <Modal
      isOpen={isOpen}
      style={useMemo(() => customStylesPlayer, [customStylesPlayer])}
      onRequestClose={() => setIsOpen(false)}
    >
      <div className="c-card__title f-Exo-Bold flex">
        <h4 className="f-1 mb-0 flex-center-v">{title}</h4>
        <div className="flex-center-v">
          <Button icon="c-icon--close" className="" onClick={() => setIsOpen(false)} />
        </div>
      </div>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({touched, errors, values, setFieldValue, submitForm}) => (
          <Form className="h-100">
            <div className="mt-2 mb-4 flex flex-d-column">
              <div className="flex-1 flex-d-row">
                <div className="mt-4 mr-3 f-0-4">
                  <div className="flex-1 flex-d-column h-100">
                    <div className=" flex-1">
                      <div className="flex-1-center-h flex-d-column">
                        <Label className="fn-14 mb-2" label={t('image')} />
                        <DragFile
                          accept={{'image/*': []}}
                          maxFiles={1}
                          placeholder={t('dragPhotoHere')}
                          btn1Title={t('chooseAFileToUpload')}
                          files={[values.medias_model]}
                          isThumbnail={false}
                          classAddonInner=" h-100"
                          classAddonOuter="m-0 f-1 h-100"
                          error={touched.medias_model && (errors.medias_model as string | boolean)}
                          onChange={(acceptedFiles: MediaType[]) => {
                            onFilesSelection(acceptedFiles, setFieldValue)
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-d-column f-0-4" />
                  </div>
                </div>
                <div className="flex mt-4 flex-d-column ml-3 f-0-6">
                  <div className="f-1">
                    <div className="flex f-0-8">
                      <Input
                        name="firstName"
                        label={t('firstName')}
                        placeholder={t('pleaseEnterFullName')}
                        error={touched.firstName && errors.firstName}
                        onChange={(e: EventType) => setFieldValue('firstName', e.target.value)}
                        className="form-control mt-1 player-name-input-we-l "
                        classNameLable="fn-14"
                        classAddonOuter="f-1"
                      />
                      <div className="flex-1 flex-d-column">
                        <Input
                          name="lastName"
                          label={t('surname')}
                          placeholder={t('pleaseEnterSurname')}
                          error={touched.lastName && errors.lastName}
                          onChange={(e: EventType) => setFieldValue('lastName', e.target.value)}
                          className="form-control mt-1 player-name-input-we-r"
                          classNameLable="fn-14 "
                          classAddonOuter="flex flex-d-column"
                        />
                      </div>
                    </div>
                    <div className="flex f-0-8">
                      <Select
                        name="position"
                        label={t('position')}
                        error={touched.position && errors.position}
                        onChange={(e: EventType) => setFieldValue('position', e.target.value)}
                        className="form-control mt-1  player-name-input-we-l"
                        classNameLable="fn-14"
                        optionsList={positionList}
                        emptyText={t('selectPosition')}
                        classAddonOuter="f-1"
                      />
                      <div className="flex-1 flex-d-column">
                        <Input
                          name="shirtNumber"
                          label={t('shirtNumber')}
                          placeholder={t('pleaseEnterShirtNumber')}
                          error={touched.shirtNumber && errors.shirtNumber}
                          onChange={(e: EventType) => setFieldValue('shirtNumber', e.target.value)}
                          className="form-control mt-1 player-name-input-we-r"
                          classNameLable="fn-14"
                          classAddonOuter="flex flex-d-column"
                        />
                      </div>
                    </div>
                    {/* <Input
                      name="bio"
                      label={t('bio')}
                      component="textarea"
                      placeholder={t('bio')}
                      onChange={(e: EventType) => setFieldValue('bio', e.target.value)}
                      error={touched.bio && errors.bio}
                      className="form-control player-name-input-we-f"
                    /> */}
                  </div>
                  {/* <Label className="fn-14" label={t('socialMedia')} />
                  <div className="flex-center-v mb-3 mt-2">
                    <span className="mr-2">
                      <i className="c-icon c-icon--facebook-gray" />
                    </span>
                    <Input
                      name="facebookLink"
                      placeholder={t('enterFacebookLink')}
                      onChange={(e: EventType) => setFieldValue('facebookLink', e.target.value)}
                      error={touched.facebookLink && errors.facebookLink}
                      className="form-control"
                      classAddonOuter="f-1 mb-0"
                    />
                  </div>
                  <div className="flex-center-v mb-3">
                    <span className="mr-2">
                      <i className="c-icon c-icon--twitter-gray" />
                    </span>
                    <Input
                      name="twitterLink"
                      placeholder={t('enterTwitterLink')}
                      onChange={(e: EventType) => setFieldValue('twitterLink', e.target.value)}
                      error={touched.twitterLink && errors.twitterLink}
                      className="form-control"
                      classAddonOuter="f-1 mb-0"
                    />
                  </div>
                  <div className="flex-center-v mb-5">
                    <span className="mr-2">
                      <i className="c-icon c-icon--instagram-gray" />
                    </span>
                    <Input
                      name="instagramLink"
                      placeholder={t('enterInstagramLink')}
                      onChange={(e: EventType) => setFieldValue('instagramLink', e.target.value)}
                      error={touched.instagramLink && errors.instagramLink}
                      className="form-control"
                      classAddonOuter="f-1 mb-0"
                    />
                  </div> */}
                  <div className="c-card__footer">
                    <Button
                      icon={loading ? 'c-icon--loader mr-2' : ''}
                      title={t('save')}
                      classAddon="mr-3"
                      onClick={submitForm}
                    />
                    <Button
                      title={t('cancel')}
                      classAddon="c-button--bg--black-33"
                      onClick={() => onClick && onClick({id: 0, command: 'cancel'})}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default memo(AddPlayerPopup)
