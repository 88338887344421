import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {matchContentMatchReport} from '../../actions/types'

export interface MatchContentMatchReportState {
  matchContentMatchReport: matchContentMatchReport
}

const initialState = {matchContentMatchReport: {}} as MatchContentMatchReportState

export const matchContentMatchReportSlice = createSlice({
  name: 'matchContentMatchReportData',
  initialState,
  reducers: {
    updateMatchContentMatchReport: (state, action: PayloadAction<MatchContentMatchReportState>) => {
      state.matchContentMatchReport =
        action.payload.matchContentMatchReport || initialState.matchContentMatchReport
    },
    clearMatchReport: (state) => {
      state.matchContentMatchReport = initialState.matchContentMatchReport
    },
  },
})
