import React, {memo, useState, useEffect} from 'react'
import {SortType} from './type'

const Sort = ({optionList, onSortChange, classAddon, classAddonInner, defaultOption}: SortType) => {
  const [selectedItem, setSelectedItem] = useState(
    (defaultOption && defaultOption.length > 0 && defaultOption[0]) || optionList[0],
  )
  const handleChange = (e: any) => {
    onSortChange(e.target.value)
    const currentObject = optionList.filter((data) => data.value === e.target.value)
    setSelectedItem(currentObject[0])
  }
  useEffect(() => {
    setSelectedItem(
      (defaultOption && defaultOption.length > 0 && defaultOption[0]) || optionList[0],
    )
  }, [defaultOption])

  return (
    <div className={`c-card__header c-card__header--align-right ${classAddon}`}>
      <div className="c-card__header__right">
        <div className="c-form-group m-0">
          <select
            value={selectedItem.value}
            className={`form-control we-170 ${classAddonInner}`}
            defaultValue={selectedItem.value}
            onChange={handleChange}
          >
            {Array.isArray(optionList) &&
              optionList.map((option) => (
                <option key={`option-list-${option.label}`} value={option.value}>
                  {option.label}
                </option>
              ))}
          </select>
        </div>
      </div>
    </div>
  )
}
export default memo(Sort)
