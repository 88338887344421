/* eslint-disable react/jsx-curly-newline */
import React, {useCallback, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {Button, DragFile, Input, RichTextInput} from '../../component'
import {MatchContentMatchReportType} from './type'
import {EventType} from '../contentManagement/type'
import {mapFilesToObject} from '../../functions/utils'
import {MediaType} from '../news/type'
import {setFieldValueType} from '../../component/type'

const MatchReport = ({
  initialValue,
  onSubmit,
  handleEditState,
  isEdit,
  setMatchReportValue,
  isRest,
  setIsRest,
}: MatchContentMatchReportType) => {
  const {t} = useTranslation()
  const formikRef = useRef<any>()
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title cannot be empty.'),
    description: Yup.string().required('Intro text cannot be empty.'),
    medias_model: Yup.mixed().test(t('fileRequired'), 'Image cannot be empty.', (value) => {
      if (!value || !value[0].original_url) return false
      return true
    }),
  })

  const onFilesSelection = useCallback(
    (acceptedFiles: MediaType[], setFieldValue: setFieldValueType, values: any) => {
      const newFiles = mapFilesToObject(acceptedFiles)
      if (newFiles && newFiles.length > 0) {
        setFieldValue('medias_model', newFiles)
        setMatchReportValue({...values, medias_model: newFiles})
      }
    },
    [],
  )
  useEffect(() => {
    if (isRest) {
      formikRef.current?.resetForm()
    }
    setIsRest(false)
  }, [isRest])

  useEffect(() => {
    if (
      !formikRef.current?.values?.title &&
      !formikRef.current?.values?.description &&
      !formikRef.current?.values?.medias_model[0]?.original_url
    ) {
      formikRef.current?.setFieldValue('title', initialValue?.title)
      formikRef.current?.setFieldValue('description', initialValue?.description)
      formikRef.current?.setFieldValue('matchDesc', initialValue?.matchDesc)
      if (
        Array.isArray(initialValue?.medias_model) &&
        initialValue?.medias_model.length > 0 &&
        initialValue?.medias_model[0]?.original_url
      ) {
        formikRef.current?.setFieldValue('medias_model', initialValue?.medias_model)
      }
    }
  }, [initialValue])

  return (
    <div className="table-outer f-1">
      <Formik
        innerRef={formikRef}
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({touched, errors, setFieldValue, submitForm, values}) => (
          <Form>
            <div className="table-header">
              <div className="c-carousal__header no-border-b">
                <div className="c-carousal__header__lpart v-center">
                  <span className="f-Exo-Bold title-color">{t('matchReport').toUpperCase()}</span>
                </div>
                <div className="c-carousal__header__rpart flex-center">
                  {isEdit && (
                    <div className="c-carousal__header__rpart flex-center">
                      <Button
                        title={t('save')}
                        type="submit"
                        classAddon=" c-button--icons f-Exo-Bold mr-2"
                        onClick={submitForm}
                      />
                      <div>
                        <Button
                          title={t('cancel')}
                          classAddon="c-button--br c-button--br--gray-ab bg-transparent f-Exo-Bold"
                          onClick={handleEditState}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex-1-center-v flex-d-column bg-gray ">
              {isEdit ? (
                <div className="card-match-content">
                  <div className="flex-1 flex-d-column">
                    <div className="flex-1 flex-d-row">
                      <div className="flex f-0-6 flex-d-column mr-3">
                        <div className="flex-1">
                          <Input
                            name="title"
                            label={t('title')}
                            error={touched.title && errors.title}
                            onChange={(e: EventType) => {
                              setFieldValue('title', e.target.value)
                              setMatchReportValue({...values, title: e.target.value})
                            }}
                            className="form-control mt-1"
                            classNameLable="fs-15"
                            classAddonOuter="flex-1 flex-d-column"
                          />
                        </div>
                        <div className="flex-1">
                          <Input
                            name="description"
                            label={t('introText')}
                            error={touched.description && errors.description}
                            onChange={(e: EventType) => {
                              setFieldValue('description', e.target.value)
                              setMatchReportValue({...values, description: e.target.value})
                            }}
                            className="form-control mt-1"
                            classNameLable="fs-15"
                            classAddonOuter="flex-1 flex-d-column"
                          />
                        </div>
                      </div>
                      <div className="flex f-0-4 ml-2">
                        <div className="flex-1">
                          <DragFile
                            accept={{'image/*': []}}
                            maxFiles={1}
                            label={t('image')}
                            placeholder={t('dragPhotoHere')}
                            btn1Title={t('chooseFileToUpload')}
                            files={
                              Array.isArray(values.medias_model) &&
                              values.medias_model.length > 0 &&
                              values.medias_model[0].original_url
                                ? values.medias_model
                                : initialValue.medias_model
                            }
                            error={
                              touched.medias_model && (errors.medias_model as string | boolean)
                            }
                            isThumbnail={false}
                            onChange={(acceptedFiles: MediaType[]) => {
                              onFilesSelection(acceptedFiles, setFieldValue, values)
                            }}
                            classAddonOuter="flex-1 flex-d-column"
                            isOrButtonLine
                          />
                        </div>
                      </div>
                    </div>
                    <RichTextInput
                      label={t('report')}
                      classAddon="bg-white"
                      value={values.matchDesc || initialValue.matchDesc}
                      onChange={(news_description) => {
                        setFieldValue('matchDesc', news_description)
                        setMatchReportValue({...values, matchDesc: news_description})
                      }}
                      error={touched.matchDesc && (errors.matchDesc as string | boolean)}
                    />
                  </div>
                </div>
              ) : (
                <div className="c-carousal__header__rpart flex-1-center w-100 min-h-150">
                  <Button
                    icon="c-icon--plus"
                    title={t('addMatchReport')}
                    type="submit"
                    classAddon=" c-button--icons f-Exo-Bold"
                    onClick={handleEditState}
                  />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
export default MatchReport
