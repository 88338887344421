const english = {
  translation: {
    counter: 'counter',
    increment: 'INCREMENT',
    decrement: 'DECREMENT',
    analytics: 'Analytics',
    getcookie: 'Get Cookie',
    setcookie: 'Set Cookie',
    checkSentry: 'Check Sentry (Only production)',
    live: 'Live',
    featured: 'Featured',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    cancel: 'Cancel',
    deleteText: 'Are you sure you want to permanently delete?',
    promptTitle: 'Prompt',
    clubNews: 'CLUB NEWS',
    promptText: 'Enter the account URL here',
    remove: 'Remove',
    update: 'Update',
    save: 'Save',
    appStoreLink: 'App Store Link',
    carousal: 'Carousal',
    benefitsOfGDX: 'Benefits of GDX',
    addNew: 'Add New',
    socialMediaLink: 'Social Media Link',
    chooseFileToUpload: 'Choose file to upload',
    editBenefitsOfGDX: 'Edit Benefits of GDX',
    addBenefitsOfGDX: 'Add Benefits of GDX',
    image: 'Image',
    icon: 'Icon',
    heading: 'Heading',
    enterHeadingHere: 'Enter heading here...',
    bodyText: 'Body Text',
    enterPositionHere: 'Enter position here...',
    enterDescriptionHere: 'Enter description here...',
    editCarousal: 'Edit Carousal',
    addCarousal: 'Add Carousal',
    chooseAFileToUpload: 'Choose a file to upload',
    addFromMedialibrary: 'Add from Media library',
    carousalImage: 'Carousal Image',
    marketingVideo: 'Marketing Video',
    links: 'Links',
    pleasePasteYouTubeLinkHere: 'Please paste YouTube link here...',
    enterLinkHere: 'Enter link here...',
    facebook: 'Facebook',
    instagram: 'Instagram',
    twitter: 'Twitter',
    youtube: 'YouTube',
    googlePlayStoreLinks: 'Google Play Store Links',
    appleStoreAppLinks: 'Apple Store App Links',
    testimonials: 'Testimonials',
    editTestimonials: 'Edit Testimonials',
    addTestimonials: 'Add Testimonials',
    name: 'Name',
    enterNameHere: 'Enter name here...',
    position: 'Position',
    addANew: 'Add a new',
    editANew: 'Edit',
    publish: 'Publish',
    headlineCannotBeEmpty: 'Headline cannot be empty',
    titleCannotBeEmpty: 'Title cannot be empty',
    fileRequired: 'file required',
    mediaFilesCannotBeEmpty: 'Media file(s) cannot be empty.',
    descriptionRequired: 'Description required',
    descriptionCannotBeEmpty: 'Description cannot be empty',
    article: 'Article',
    storyImage: 'Story image',
    dragAnImageFileHere: 'Drag an image file here',
    headline: 'Headline',
    featuredStory: 'Featured story',
    gallery: 'Gallery',
    galleryImages: 'Gallery Images',
    dragImageFilesHere: 'Drag image files here',
    chooseFilesToUpload: 'Choose files to upload',
    galleryHeadline: 'Gallery Headline',
    description: 'Description',
    video: 'Video',
    dragAVideoFileHere: 'Drag a video file here',
    videoTitle: 'Video Title',
    preview: 'Preview',
    westAucklandAFC: 'West Auckland AFC',
    alanSmith: 'Alan Smith',
    introText: 'Intro Text',
    content: 'Content',
    briefing: 'Briefing',
    newsStories: 'News stories',
    matchContent: 'Match content',
    events: 'Events',
    mediaLibrary: 'Media library',
    documents: 'Documents',
    teamManagement: 'Team Management',
    fixturesAndResults: 'Fixtures & Results',
    teamSelection: 'Team selection',
    teamAndStaff: 'Team & staff',
    leagueTable: 'League Table',
    competitionsAndCups: 'Competitions & cups',
    training: 'Training',
    messages: 'Messages',
    contentAcademy: 'Content Academy',
    websiteManagement: 'Website Management',
    userManagement: 'User Management',
    clubSettings: 'Club Settings',
    socialAccount: 'Social Account',
    socialMediaAccounts: 'Social Media Accounts',
    teamSettings: 'Team Settings',
    accountSettings: 'Account Settings',
    contentManagement: 'Content Management',
    faqs: 'FAQs',
    editFAQs: 'Edit FAQs',
    addFAQs: 'Add FAQs',
    appStoreLinks: 'App Store links',
    socialMediaLinks: 'Social Media links',
    clubAdminRequests: 'Club Admin Requests',
    clubAdminRequestList: 'Club Admin Request List',
    addNewClub: 'Add New Club',
    addNewUser: 'Add New User',
    addUserDetails: 'Add User Details',
    editUserDetails: 'Edit User Details',
    back: 'Back',
    summary: 'Summary',
    pleaseCheckAndMakeSureTheDetalisBelowAreCoorect:
      'Please check and make sure the details below are correct',
    clubDetails: 'Club Details',
    clubName: 'Club Name',
    emailAddress: 'Email Address',
    dateAndTime: 'Date & Time',
    contactNumber: 'Contact Number',
    userName: 'User Name',
    approve: 'Approve',
    clubManagement: 'Club Management',
    masterDashboard: 'Master Dashboard',
    totalClubList: 'Total Club List',
    fullName: 'Full Name',
    clubAddress: 'Club Address',
    pleaseEnterFullClubName: 'Please enter full club name',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    cityTown: 'City / Town',
    postCode: 'Postcode',
    selectCountry: 'select Country',
    deleteClub: 'Delete Club',
    removeClub: 'Remove Club',
    myDetails: 'My Details',
    id: 'ID',
    email: 'Email',
    phone: 'Phone',
    address: 'Address',
    password: 'Password',
    title: 'Title',
    enterTitleHere: 'Enter title here...',
    buttonText: 'Button Title',
    enterButtonTextHere: 'Enter button title here...',
    buttonUrl: 'Button Link',
    enterButtonUrlHere: 'Enter button link here...',
    results: 'Results',
    security: 'Security',
    deny: 'Deny',
    firstName: 'First Name',
    lastName: 'Last Name',
    confirmPassword: 'Confirm Password',
    oldPassword: 'Old password',
    newPassword: 'New password',
    playerManagement: 'Player Management',
    addPlayer: 'Add Player',
    players: 'Players',
    addAPlayer: 'Add a player',
    dragPhotoHere: 'Drag photo Here',
    surname: 'Surname',
    shirtNumber: 'Shirt number',
    bio: 'Bio',
    pleaseEnterFullName: 'Please enter full name',
    pleaseEnterSurname: 'Please enter surname',
    selectPosition: 'Select position',
    pleaseEnterShirtNumber: 'Please enter shirt number',
    socialMedia: 'Social media',
    enterFacebookLink: 'Enter Facebook link',
    enterTwitterLink: 'Enter Twitter link',
    enterInstagramLink: 'Enter Instagram link',
    editAPlayer: 'Edit a player',
    finalScore: 'Final Score',
    fullTimeScore: 'Full time score',
    penalties: 'Penalties',
    madia: 'Madia',
    addImage: 'Add Image',
    matchInfo: 'Match info',
    meetTime: 'Meet time',
    kickoffTime: 'Kickoff time',
    type: 'Type',
    attendance: 'Attendance',
    additionalInfo: 'Additional info',
    optional: 'optional',
    selectLeague: 'Select league',
    league: 'League',
    matchFixture: 'Match Fixture',
    matchFixtureList: 'Match Fixture List',
    addFixture: 'Add Fixture',
    addFixtureDetails: 'Add Fixture Details',
    editFixtureDetails: 'Edit Fixture Details',
    opponent1: 'Opponent 1',
    opponent2: 'Opponent 2',
    date: 'Date',
    status: 'Status',
    learnAboutWritingGreatArticles: 'Learn about writing great articles',
    newStory: 'New Story',
    selectTeam: 'Select team',
    viewFixture: 'View fixture',
    addMatchcontent: 'Add match content',
    learnHowToWriteMatchdayContent: 'Learn how to write matchday content',
    newVideo: 'New video',
    viewMediaLibrary: 'View media library',
    addScore: 'Add score',
    writeReport: 'Write report',
    clubRevenue: 'Club revenue',
    highestRevenueContent: 'Highest revenue content',
    totalRevenue: 'Total revenue',
    revenuePaid: 'Revenue paid',
    top100EarningClubs: 'Top 100 Earning Clubs',
    dailyRevenue: 'Daily Revenue',
    permissions: 'Permissions',
    roles: 'Roles',
    link: 'Link',
    addMatchReport: 'Add Match Report',
    report: 'Report',
    matchReport: 'Match Report',
    liveCommentary: 'Live Commentary',
    addAnEvent: 'Add an event',
    period: 'Period',
    time: 'Time',
    selectTime: 'Select time',
    selectPeriod: 'Select period',
    saveAndClose: 'Save & close',
    player: 'Player',
    event: 'Event',
    matchResultList: 'Match Result List',
    result: 'Result ',
    fixture: 'Fixture',
    leagueManagement: 'League Management',
    addNewLeague: 'Add New League',
    leagueList: 'LEAGUE LIST',
    addLeagueDetails: 'Add League Details',
    editLeagueDetails: 'Edit League Details',
    deleteLeague: 'Delete League',
    leagueName: 'League Name',
    selectPlayer: 'Select player',
    selectEvent: 'Select event',
    totalClubs: 'Total Clubs',
    saveAllChanges: 'Save all changes',
    awaitingApproval: 'Awaiting approval',
    approveText: 'Are you sure you want to approve this?',
    denyText: 'Are you sure you want to deny this?',
    moderation: 'Moderation',
    matchVenue: 'Match venue',
    startTime: 'Start time',
    adsManagement: 'Ads Management',
    adsList: 'Ads List',
    addNewAds: 'Add New Ads',
    selectClub: 'Select club',
    removeAds: 'Remove Ads',
    unitId: 'Unit id',
    pleaseEnterunitId: 'Please Enter unit id',
    adsDetails: 'Ads details',
    club: 'Club',
    undoDeny: 'Undo Deny',
    undoDenyText: 'Are you sure you want to undo this?',
    yourDetails: 'Your Details',
    clubLocation: 'Club Location',
    city: 'City',
    passcode: 'Passcode',
    country: 'Country',
    matchFixtureRequest: 'Match Fixture Request',
    requestFixtureDetails: 'Request Fixture Details',
    opponent: 'Opponent',
    editRequestFixtureDetails: 'Edit Request Fixture Details',
    request: 'Request',
    matchFixtureRequestList: 'Match Fixture Request List',
  },
}
export default english
