import React from 'react'
import {format} from 'date-fns'
import {WidgetMatchAginstType} from '../type'
import {useSelector} from '../../../store'
import images from '../../../assets/images'

const WidgetMatchAgainst = ({nextMatchInfo}: WidgetMatchAginstType) => {
  const {userData} = useSelector(({user}) => ({
    userData: user.user,
  }))
  const opponentClub =
    nextMatchInfo?.club?.club_slug === userData.club_data?.club_slug ? 'opponent_club' : 'club'
  const currentClub =
    nextMatchInfo?.club?.club_slug === userData.club_data?.club_slug ? 'club' : 'opponent_club'
  const opponentClubMedia = nextMatchInfo?.[`${opponentClub}`]?.medias_model
  const currentClubMedia = nextMatchInfo?.[`${currentClub}`]?.medias_model
  return (
    <div className="c-card__body">
      <div className="c-match">
        <div className="row">
          <div className="col-lg">
            <div className="c-match-card c-match-card--right">
              <div className="c-match-card__text">{nextMatchInfo[`${currentClub}`].club_name}</div>
              <div className="c-match-card__image">
                <img
                  src={
                    Array.isArray(currentClubMedia) && currentClubMedia.length > 0
                      ? currentClubMedia[0].original_url
                      : images.dummyUser
                  }
                  alt=""
                  className="img-fluid border-radius-50-p"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-auto">
            <div className="c-match-versus">
              <div className="c-match-versus__date">
                {nextMatchInfo?.match_start_time &&
                  format(new Date(nextMatchInfo.match_start_time), 'd MMMM uuuu')}
              </div>
              <div className="c-match-versus__time">
                {nextMatchInfo?.match_start_time &&
                  format(new Date(nextMatchInfo.match_start_time), 'kk:mm')}
              </div>
            </div>
          </div>
          <div className="col-lg">
            <div className="c-match-card c-match-card--left">
              <div className="c-match-card__image">
                <img
                  src={
                    Array.isArray(opponentClubMedia) && opponentClubMedia.length > 0
                      ? opponentClubMedia[0].original_url
                      : images.dummyUser
                  }
                  alt=""
                  className="img-fluid border-radius-50-p"
                />
              </div>
              <div className="c-match-card__text">{nextMatchInfo[`${opponentClub}`].club_name}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default WidgetMatchAgainst
