import React, {memo, useState} from 'react'
import {format} from 'date-fns'
import {DatePicker} from '..'
import './style.css'
import {RangeSelectionProps} from '../type'

const RangePicker = memo(
  ({initialDateRange, selectionRange, setSelectionRange, maxDate}: RangeSelectionProps) => {
    const [showStartDatePicker, setShowStartDatePicker] = useState(false)
    const [showEndDatePicker, setShowEndDatePicker] = useState(false)
    return (
      <div className="c-form-group mb-0">
        {selectionRange.startDate || selectionRange.endDate ? (
          <div className="form-control showDateRow">
            <a
              href="#"
              className="dateText"
              onClick={() => {
                setSelectionRange(initialDateRange)
                setShowStartDatePicker(true)
              }}
            >
              {selectionRange.startDate && format(selectionRange.startDate, 'dd/MM/yy')}
              {selectionRange.startDate && selectionRange.endDate && ' - '}
              {selectionRange.endDate && format(selectionRange.endDate, 'dd/MM/yy')}
            </a>
            <a
              href="#"
              onClick={() => setSelectionRange(initialDateRange)}
              className="dateClearBtn"
            >
              <i className="c-icon c-icon--close-red" />
            </a>
          </div>
        ) : (
          <select
            name=""
            id=""
            className="form-control h-100 pointer"
            onFocus={() => setShowStartDatePicker(true)}
          >
            <option value="all">All dates</option>
          </select>
        )}
        {showStartDatePicker && (
          <DatePicker
            setIsOpen={setShowStartDatePicker}
            isOpen={showStartDatePicker}
            label="Select Start Date"
            maxDate={maxDate}
            onChange={(date) => {
              setSelectionRange({...selectionRange, startDate: date})
              setShowStartDatePicker(false)
              setShowEndDatePicker(true)
            }}
          />
        )}
        {showEndDatePicker && (
          <DatePicker
            setIsOpen={setShowEndDatePicker}
            isOpen={showEndDatePicker}
            label="Select End Date"
            minDate={selectionRange.startDate}
            maxDate={maxDate}
            onChange={(date) => {
              setSelectionRange({...selectionRange, endDate: date})
              setShowStartDatePicker(false)
              setShowEndDatePicker(false)
            }}
          />
        )}
      </div>
    )
  },
)

export default RangePicker
