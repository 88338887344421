import React, {memo, useCallback, useEffect, useMemo, useState} from 'react'
import {createTable} from '@tanstack/react-table'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {UpdateListType} from '../../../component/type'
import {Button, List as ListView, Popup} from '../../../component'
import ListRight from '../../../component/listRight'
import {clearFaqDetail, getFaqDetail, getFaqList, deleteFaq} from '../../../store/actions'
import {useSelector, useAppDispatch} from '../../../store'
import {FaqType} from '../../../store/actions/types'
import {UpdateButtonList, RemoveButtonList} from '../../../component/List/Commands'
import {defaultPageSize} from '../../../configs/paginationSetting'

const table = createTable().setRowType<FaqType>()

const FaqsList = memo(() => {
  const navigation = useNavigate()
  const {list, total, from} = useSelector(({faq}) => ({
    list: faq.faqList,
    total: faq.total,
    from: faq.from,
  }))
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [currentItemId, setCurrentItemId] = useState(0)
  const [loading, setLoading] = useState(false)
  const [selectedRow, setSelectedRow] = useState<FaqType>()
  const {t} = useTranslation()
  const defaultOptions = useMemo(() => ({page: 1, per_page: defaultPageSize}), [])
  const [options, setOptions] = useState(defaultOptions)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getFaqList(options))
  }, [options])

  const columns = useMemo(
    () => [
      table.createDataColumn('id', {
        header: 'NUMBER',
        size: 100,
        cell: ({row}) => <span>{String(row.index + from).padStart(2, '0')}</span>,
      }),
      table.createDataColumn('title', {
        size: 250,
        header: 'HEADING',
        cell: ({row}) => <span>{row.original?.title}</span>,
      }),
      table.createDataColumn('description', {
        header: 'BODY TEXT',
        size: 1000,
        cell: ({row}) => <span>{row.original?.description}</span>,
      }),
      table.createDataColumn('id', {
        header: '',
        cell: ({row}) =>
          row.original && (
            <ListRight
              row={row.original}
              onClick={onUpdatePress}
              buttonList={[UpdateButtonList, RemoveButtonList]}
            />
          ),
      }),
    ],
    [from],
  )

  const onUpdatePress = useCallback(
    async ({id, command}: UpdateListType) => {
      if (command === 'update') {
        const response = await dispatch(getFaqDetail(id))
        if (response) navigation(`/content-management/faq/${id}`)
      } else if (command === 'remove') {
        setCurrentItemId(id)
        setIsOpenPopup(true)
      } else if (command === 'deleteConfirmed') {
        setLoading(true)
        await dispatch(deleteFaq(id, options))
        setIsOpenPopup(false)
        setLoading(false)
      }
      setSelectedRow(undefined)
    },
    [selectedRow, list],
  )

  const onPaginate = useCallback(
    (page: number) => {
      setOptions({...options, page})
    },
    [options],
  )

  const onAddNewPress = useCallback(() => {
    dispatch(clearFaqDetail())
    navigation(`/content-management/faq/add`)
  }, [])

  const onLimit = useCallback(
    (per_page: number) => {
      setOptions({...options, per_page, page: 1})
    },
    [options],
  )

  return (
    <div className="table-outer">
      <div className="table-header">
        <div className="c-carousal__header">
          <div className="c-carousal__header__lpart v-center">
            <span className="f-Exo-Bold title-color">{t('faqs').toUpperCase()}</span>
          </div>
          <div className="c-carousal__header__rpart">
            <Button
              icon="c-icon--plus"
              title={t('addNew')}
              classAddon="c-button--icons f-Exo-Medium"
              onClick={onAddNewPress}
            />
            <Popup
              isOpen={isOpenPopup}
              setIsOpen={setIsOpenPopup}
              onPressDelete={() => onUpdatePress({id: currentItemId, command: 'deleteConfirmed'})}
              title={t('delete')}
              description={t('deleteText')}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <ListView
        columns={columns}
        data={list}
        total={total}
        onPaginate={onPaginate}
        forcePageIndex={options.page - 1}
        options={options}
        isPageSize
        onLimit={onLimit}
        pageSize={options?.per_page}
      />
    </div>
  )
})

export default FaqsList
