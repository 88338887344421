import React from 'react'
import {useTranslation} from 'react-i18next'
import {ClubRevenueBodyType} from '../type'

const ClubRevenueBody = ({item}: ClubRevenueBodyType) => {
  const {t} = useTranslation()
  return (
    <div className="c-card__body">
      <div className="c-club-revenue">
        <div className="row no-gutters">
          <div className="col-lg-6">
            <div className="c-club-revenue-card">
              <div className="c-club-revenue-card__icons">
                <i className="c-icon c-icon--triangle c-icon--triangle--up" />
              </div>
              <div className="c-club-revenue-card__price">
                {item.currency}
                {item.totalRevenue}
              </div>
              <div className="c-club-revenue-card__text">{t('totalRevenue')}</div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="c-club-revenue-card">
              <div className="c-club-revenue-card__icons">
                <i className="c-icon c-icon--triangle c-icon--triangle--down" />
              </div>
              <div className="c-club-revenue-card__price">
                {item.currency}
                {item.paidRevenue}
              </div>
              <div className="c-club-revenue-card__text">{t('revenuePaid')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ClubRevenueBody
