import React, {memo} from 'react'
import {RenderLeafProps} from 'slate-react'
// eslint-disable-next-line  import/extensions
import {CustomText} from './types'

const Leaf = memo(
  ({attributes, children, leaf}: Omit<RenderLeafProps, 'leaf'> & {leaf: CustomText}) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>
    }

    if (leaf.code) {
      children = <code>{children}</code>
    }

    if (leaf.italic) {
      children = <em>{children}</em>
    }

    if (leaf.underline) {
      children = <u>{children}</u>
    }
    if (leaf.strikethrough) {
      children = <s>{children}</s>
    }

    return <span {...attributes}>{children}</span>
  },
)

export default Leaf
