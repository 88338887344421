import React, {memo, useState, useRef} from 'react'
import {Input} from '.'
import {EventType} from '../container/contentManagement/type'
import {useOutsideAlerter} from '../functions/hooks/useDebounce'
import {useSelector} from '../store'
import {RootState} from '../store/slices'
import {CountrySelectorType} from './type'

const CountryList = ({
  name,
  error,
  label,
  onChange,
  onSelect,
  countryError,
  defaultCountryId,
  ...props
}: CountrySelectorType) => {
  const wrapperRef = useRef(null)
  const {countryList} = useSelector(({country}: RootState) => ({
    countryList: country.countryList,
  }))
  const initialCountry = Array.isArray(countryList) && countryList.length > 0 && countryList[232]
  if (!initialCountry) return <div />
  const [showList, setShowList] = useState(false)
  const [selected, setSelected] = useState(
    defaultCountryId
      ? countryList.filter((data) => data.id === defaultCountryId)[0]
      : initialCountry,
  )

  useOutsideAlerter(wrapperRef, setShowList)
  return (
    <div ref={wrapperRef}>
      <div
        className="country-code-container"
        role="button"
        aria-hidden
        onClick={() => setShowList(!showList)}
      >
        <img src={selected.flag} className="flag mr-1" alt="loader" />
        <span className="f-Exo-Regular"> +{selected.dialling_code}</span>
        <i className="c-icon c-icon--arrow-down ml-2" />
      </div>
      <Input
        name={name}
        label={label}
        onChange={(e: EventType) => {
          if (e.target.value.match(/^[0-9]+$/) || e.target.value === '') onChange(e)
        }}
        maxLength={15}
        classAddonOuter={countryError ? 'mb-0' : ''}
        error={error}
        className="form-control mt-1"
        classNameLable="fs-15"
        type="tel"
        style={{paddingLeft: `${selected?.dialling_code?.length * 10 + 70}px`}}
        {...props}
      />
      {countryError && <div className="invalid-feedback mb-3">{countryError}</div>}
      {showList && (
        <div
          className="form-control flag-container p-0"
          style={{marginTop: error && countryError ? -56 : error || countryError ? -32 : -16}}
        >
          {Array.isArray(countryList) &&
            countryList.length > 0 &&
            countryList.map((country) => (
              <div
                key={`option-list-${country.full_name}`}
                className="select-item"
                role="button"
                aria-hidden
                onClick={() => {
                  setShowList(false)
                  setSelected(country)
                  onSelect(country)
                }}
              >
                <img src={country.flag} className="flag mr-2" alt="loader" />
                <span className="f-Exo-SemiBold">{country.full_name}</span>
                <span className="f-Exo-Regular"> +{country.dialling_code}</span>
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

export default memo(CountryList)
