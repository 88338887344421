import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Tabs, Button} from '../../component'
import {articlePreview, galleryPreview, videoPreview, tabList} from '../../data'
import Formview from './formview'
import Preview from './preview'
import {AddNewsStoryType, NewsType, PreviewDataType} from './type'
import {useSelector, useAppDispatch} from '../../store'
import {MediaLibraryItem} from '../../store/actions/types'
import {getSocialConfigExpire} from '../../store/actions'

const AddNewsStory = ({
  setIsOpen,
  newsType,
  singleTab,
  setSingleTab,
  handleResetSort,
  setNewType,
}: AddNewsStoryType) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const {newsDetail} = useSelector(({news}) => ({newsDetail: news.newsDetail}))
  const [news_type, setNews_type] = useState(
    newsType || newsDetail.news_type || tabList[0].news_type,
  )
  const [tabData, setTabData] = useState<PreviewDataType>(articlePreview)
  const [files, setFiles] = useState([])
  const [attachMedia, setAttachMedia] = useState<MediaLibraryItem[]>([])
  useEffect(() => {
    dispatch(getSocialConfigExpire())
  }, [])

  useEffect(() => {
    if (news_type === 0) setTabData(articlePreview)
    else if (news_type === 1) setTabData(galleryPreview)
    else if (news_type === 2) setTabData(videoPreview)
    setFiles([])
    setAttachMedia([])
  }, [news_type])

  useEffect(() => {
    setTabData({...tabData, medias_model: files})
  }, [files])
  useEffect(() => {
    setTabData({
      ...tabData,
      attach_media_id: attachMedia.map((mediaItem) => mediaItem.id),
      attach_media: attachMedia,
      medias_model:
        tabData.news_type === NewsType.gallery || tabData.news_type === NewsType.article
          ? tabData.medias_model
          : attachMedia.length > 0
          ? []
          : tabData.medias_model,
    })
  }, [attachMedia])

  useEffect(() => {
    if (news_type === newsDetail.news_type) {
      const newTabData = {
        ...tabData,
        ...newsDetail,
        news_description:
          typeof newsDetail.news_description === 'string'
            ? JSON.parse(newsDetail.news_description)
            : newsDetail.news_description,
        medias_model:
          newsDetail?.medias_model && Array.isArray(newsDetail.medias_model)
            ? newsDetail.medias_model
            : [],
      }
      setTabData(newTabData)
      const mediaFiles =
        newsDetail?.medias_model && Array.isArray(newsDetail.medias_model)
          ? newsDetail.medias_model
          : []
      setFiles(mediaFiles as never[])
    }
  }, [newsDetail, news_type])

  return (
    <div className="col-lg">
      <div className="c-news-card__body p-0 pt-2">
        <Tabs
          title={`${tabData.news_slug ? t('editANew') : t('addANew')}:`}
          tabList={
            tabData.news_slug
              ? tabList.filter((data) => data.news_type === news_type)
              : singleTab || singleTab === 0
              ? tabList.filter((data) => data.news_type === singleTab)
              : tabList
          }
          classAddOnAl={tabData.news_slug || singleTab || singleTab === 0 ? 'tab-border' : ''}
          news_type={news_type}
          setNews_type={setNews_type}
          activeClass={
            tabData.news_slug || singleTab || singleTab === 0
              ? 'active-gray tab-border'
              : 'active-gray'
          }
          isManagement={false}
        />
        <Button
          icon="c-icon--close"
          className=""
          onClick={() => {
            setNewType(0)
            setIsOpen(false)
            setSingleTab(undefined)
          }}
        />
      </div>
      <div className="c-media-library__body">
        <div className="tab-content">
          {Array.isArray(tabList) &&
            tabList.map((tabItem) => (
              <div
                className={`tab-pane fade ${tabItem.news_type === news_type ? 'show active' : ''}`}
                key={`tab-details-${news_type}-${tabItem.title}`}
              >
                <div className="c-article">
                  <div className="row h-space-bet">
                    <Formview
                      tabData={tabData}
                      setTabData={setTabData}
                      files={files}
                      setFiles={setFiles}
                      tabItem={tabItem}
                      setIsOpen={setIsOpen}
                      setSingleTab={setSingleTab}
                      news_type={news_type}
                      attachMedia={attachMedia}
                      setAttachMedia={setAttachMedia}
                      handleResetSort={handleResetSort}
                    />
                    <Preview tabData={tabData} news_type={news_type} />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default AddNewsStory
