/* eslint-disable react/jsx-curly-newline */
import {t} from 'i18next'
import React, {useState, useCallback} from 'react'
import FacebookLogin from 'react-facebook-login'
import {LoginSocialInstagram, IResolveParams, LoginSocialTwitter} from 'reactjs-social-login'
import {Button, Prompt} from '../../component'
import {SocialListItem} from './type'
import {useSelector, useAppDispatch} from '../../store'
import PageList from '../../component/pageList'
import {
  getFacebookPageList,
  getFacebookLongLiveToken,
  addTwitterTokenInfo,
  getInstragramLongLiveToken,
  addSocialConfig,
  clearSocialConfig,
  getInstagramBusinessAccount,
} from '../../store/actions'
import {socialKeys} from '../../services/socialKey'
import {facebookNote} from '../../data'
import {FacebookPageType} from '../../store/actions/types'

const List = ({item, handleStateChange}: SocialListItem) => {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [isFacebookPageOpen, setIsFacebookPageOpen] = useState(false)
  const [isLinkAccount, setIsLinkAccount] = useState(false)
  const [facebookPageData, setFacebookPageData] = useState<FacebookPageType[]>()
  const {socialConfigData} = useSelector(({socialConfig}) => ({
    socialConfigData: socialConfig.socialConfig,
  }))
  const apiRequest = {
    facebook_user_token: socialConfigData?.facebook_user_token,
    facebook_page_token: socialConfigData?.facebook_page_token,
    facebook_page_id: socialConfigData?.facebook_page_id,
    facebook_page_token_expiry: socialConfigData?.facebook_page_token_expiry,
    is_facebook_post_active: socialConfigData?.is_facebook_post_active,
    twiter_page_token: socialConfigData?.twiter_page_token,
    twiter_page_token_expiry: socialConfigData?.twiter_page_token_expiry,
    is_twiter_post_active: socialConfigData?.is_twiter_post_active,
    instagram_page_token: socialConfigData?.instagram_page_token,
    is_instagram_post_active: socialConfigData?.is_instagram_post_active,
    instagram_business_account_id: socialConfigData?.instagram_business_account_id,
  }
  const componentClicked = (data: any) => {
    console.log('data', data)
  }

  const handleLinkAccount = () => {
    setIsLinkAccount(true)
  }
  const responseFacebook = async (response: any) => {
    if (response.accessToken) {
      const longLiveToken = await dispatch(getFacebookLongLiveToken(response.accessToken))
      const newResponse = {...response, tokenType: longLiveToken.token_type}
      newResponse.accessToken = longLiveToken.access_token
      newResponse.expiresIn = longLiveToken.expires_in
      const facebookPageList = await dispatch(
        getFacebookPageList(newResponse.accessToken, newResponse.userID),
      )
      setFacebookPageData(facebookPageList)
      await dispatch(
        addSocialConfig({
          ...apiRequest,
          facebook_user_token: newResponse.accessToken,
          facebook_page_token_expiry: newResponse.data_access_expiration_time,
        }),
      )
      setIsFacebookPageOpen(true)
    }
  }

  const handleSubmitFacebookDetalis = async (selectedPage: any) => {
    if (socialConfigData?.facebook_user_token) {
      const instagramBusinessAccount = await dispatch(
        getInstagramBusinessAccount(socialConfigData.facebook_user_token, selectedPage.id),
      )
      await dispatch(
        addSocialConfig({
          ...apiRequest,
          facebook_page_token: selectedPage.access_token,
          facebook_page_id: selectedPage.id,
          instagram_business_account_id: instagramBusinessAccount?.instagram_business_account?.id,
        }),
      )
    } else {
      await dispatch(
        addSocialConfig({
          ...apiRequest,
          facebook_page_token: selectedPage.access_token,
          facebook_page_id: selectedPage.id,
        }),
      )
    }
  }
  const removeFacebookAccount = async () => {
    await dispatch(
      clearSocialConfig({
        ...socialConfigData,
        facebook_page_token: undefined,
        facebook_page_id: undefined,
        facebook_user_token: undefined,
        facebook_page_token_expiry: undefined,
        instagram_business_account_id: undefined,
      }),
    )
  }
  const onLoginStart = useCallback(() => {}, [])

  const onLogoutSuccess = useCallback(() => {}, [])

  const removeInstragramAccount = async () => {
    await dispatch(
      clearSocialConfig({
        ...socialConfigData,
        instagram_page_token: undefined,
      }),
    )
  }

  const removeTwitterAccount = async () => {
    await dispatch(
      clearSocialConfig({
        ...socialConfigData,
        twiter_page_token: undefined,
        twiter_page_token_expiry: undefined,
      }),
    )
  }

  const responseTwitter = async (response: any) => {
    if (response.refresh_token) {
      const twitterTokenInfo = await dispatch(addTwitterTokenInfo(response))
      await dispatch(
        addSocialConfig({
          ...apiRequest,
          twiter_page_token: twitterTokenInfo.access_token,
          twiter_page_token_expiry: twitterTokenInfo.expires_in,
        }),
      )
    }
  }

  const responseInstragram = async (response: any) => {
    if (response.access_token) {
      const longLiveToken = await dispatch(getInstragramLongLiveToken(response.access_token))
      await dispatch(
        addSocialConfig({...apiRequest, instagram_page_token: longLiveToken.access_token}),
      )
    }
  }

  const {
    title,
    // autoPostVideo,
    buttonIcon,
    buttonTitle,
    description,
    icon,
    publishOnSite,
    id,
    buttonTitle2,
  } = item
  return (
    <div className="c-card">
      <div className="c-social-card-main mb-3">
        <div className="p-3">
          <span className="font-b-i">{title}</span>
        </div>
        <div className="c-social-card">
          <Prompt
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onBtnClick={(promptValue) => console.log(promptValue)}
            description={t('promptText')}
          />
          {Array.isArray(facebookPageData) && facebookPageData.length > 0 && (
            <PageList
              title="Facebook Pages"
              isOpen={isFacebookPageOpen}
              setIsOpen={setIsFacebookPageOpen}
              onBtnClick={(promptValue) => handleSubmitFacebookDetalis(promptValue)}
              pageList={facebookPageData}
            />
          )}
          <div className="c-social-card__body">
            <div className="c-social-card__body__left-part">
              <div className="c-social-card__image">
                <i className={`c-icon c-icon${icon}`} />
              </div>
              <span className="m-3">{description}</span>
            </div>
            {title === 'INSTAGRAM PAGE' && (
              <span className="m-3 w-46">Gentle note :- {facebookNote}</span>
            )}
            <div className="c-social-card__body__right-part v-center">
              <div className="line-h-1">
                {title === 'INSTAGRAM PAGE' ? (
                  socialConfigData?.instagram_page_token ? (
                    <Button
                      title={buttonTitle2}
                      classAddon=" c-button--icons mr-60"
                      icon={`c-icon${buttonIcon}`}
                      onClick={removeInstragramAccount}
                    />
                  ) : (
                    <LoginSocialInstagram
                      client_id={socialKeys.instragram.client_id}
                      client_secret={socialKeys.instragram.client_secret}
                      redirect_uri={window.location.href}
                      onLoginStart={onLoginStart}
                      onLogoutSuccess={onLogoutSuccess}
                      onResolve={({data}: IResolveParams) => {
                        responseInstragram(data)
                      }}
                      scope="user_profile user_media public_profile instagram_basic pages_show_list instagram_manage_insights instagram_manage_comments manage_pages ads_management business_management instagram_content_publish page_read_engagement"
                      onReject={(err: any) => {
                        console.log(err)
                      }}
                    >
                      <Button
                        title={buttonTitle}
                        classAddon=" c-button--icons mr-60"
                        icon={`c-icon${buttonIcon}`}
                      />
                    </LoginSocialInstagram>
                  )
                ) : title === 'FACEBOOK PAGE' ? (
                  <>
                    {socialConfigData?.facebook_page_token ? (
                      <Button
                        title={buttonTitle2}
                        classAddon=" c-button--icons mr-60"
                        icon={`c-icon${buttonIcon}`}
                        onClick={removeFacebookAccount}
                      />
                    ) : (
                      <>
                        {!isLinkAccount ? (
                          <Button
                            title={buttonTitle}
                            classAddon=" c-button--icons mr-60"
                            icon={`c-icon${buttonIcon}`}
                            onClick={handleLinkAccount}
                          />
                        ) : (
                          <FacebookLogin
                            appId={socialKeys.facebook.client_id}
                            autoLoad
                            cssClass="c-button c-button--bg c-button--bg--pink c-button--icons mr-60"
                            fields="name,email,picture"
                            scope="pages_manage_posts,pages_show_list,pages_read_engagement,publish_to_groups,publish_video,instagram_content_publish,business_management,ads_management,instagram_basic"
                            onClick={(e) => componentClicked(e)}
                            callback={(e) =>
                              socialConfigData?.facebook_page_token ? '' : responseFacebook(e)
                            }
                            icon="c-icon c-icon--plus"
                            textButton="Add Page"
                          />
                        )}
                      </>
                    )}
                  </>
                ) : title === 'TWITTER PAGE' ? (
                  socialConfigData?.twiter_page_token ? (
                    <Button
                      title={buttonTitle2}
                      classAddon=" c-button--icons mr-60"
                      icon={`c-icon${buttonIcon}`}
                      onClick={removeTwitterAccount}
                    />
                  ) : (
                    <LoginSocialTwitter
                      client_id={socialKeys.twitter.client_id}
                      redirect_uri={window.location.href}
                      scope="offline.access tweet.read tweet.write users.read"
                      onLoginStart={onLoginStart}
                      onLogoutSuccess={onLogoutSuccess}
                      onResolve={({data}: IResolveParams) => {
                        responseTwitter(data)
                      }}
                      onReject={(err: any) => {
                        console.log('err', err)
                      }}
                    >
                      <Button
                        title={buttonTitle}
                        classAddon=" c-button--icons mr-60"
                        icon={`c-icon${buttonIcon}`}
                      />
                    </LoginSocialTwitter>
                  )
                ) : (
                  <Button
                    title={buttonTitle}
                    classAddon=" c-button--icons mr-60"
                    icon={`c-icon${buttonIcon}`}
                    onClick={() => setIsOpen(true)}
                  />
                )}
              </div>
              <div className="c-news-switch">
                <div className="c-news-switch__label">Publish on site</div>
                <div
                  className="c-switch"
                  role="button"
                  aria-hidden
                  onClick={() => handleStateChange({id, command: 'changePublish'})}
                >
                  <input type="checkbox" checked={publishOnSite} onChange={() => undefined} />
                  <span className="c-switch__slider c-switch__slider--round" />
                </div>
              </div>
              {/* <div className="c-news-switch">
                <div className="c-news-switch__label">Auto post videos</div>
                <div
                  className="c-switch"
                  role="button"
                  aria-hidden
                  onClick={() => handleStateChange({id, command: 'changeAutoPost'})}
                >
                  <input type="checkbox" checked={autoPostVideo} onChange={() => undefined} />
                  <span className="c-switch__slider c-switch__slider--round" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default List
