import React, {memo} from 'react'
import ReactPlayer from 'react-player'
import {ThumbnailType} from './type'
import {MediaType} from '../container/news/type'

const Thumbnail = ({files, type = 'image', handleRemove}: ThumbnailType) => (
  <div className="row m-1 pt-3 pb-2 mb-5">
    {files.map((file: MediaType) => (
      <div key={`file_preview_${file.file_name}_${file.medias_id}`}>
        {type === 'image' ? (
          <img src={file.original_url} alt="" className="img_preview" />
        ) : (
          <ReactPlayer
            playing={false}
            url={file.original_url}
            className="img_preview"
            height={60}
            width={60}
          />
        )}
        <div
          className="img_preview_cancel"
          aria-hidden
          onClick={() => handleRemove(file.medias_id)}
        >
          <i className="c-icon c-icon--close-red" />
        </div>
      </div>
    ))}
  </div>
)
export default memo(Thumbnail)
