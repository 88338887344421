import React, {memo, useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {daysOptionListRevenue, revenueInfoBox} from '../../data'
import {Sort} from '../../component'
import RevenueBody from './dailyRevenuChart'
import {DailyRevenuInfo} from './type'

const DailyRevenue = memo(() => {
  const {t} = useTranslation()
  const defaultOptions = useMemo(
    () => ({
      sort_by: 'last_7_days',
      sort_order: 'desc',
      page: 1,
    }),
    [],
  )
  const [options, setOptions] = useState(defaultOptions)
  const onSortChange = useCallback(
    (option: string) => {
      if (option === 'last_3_days') {
        setOptions({...options, sort_by: 'last_3_days', sort_order: 'desc', page: 1})
      } else if (option === 'last_5_days') {
        setOptions({...options, sort_by: 'last_5_days', sort_order: 'desc', page: 1})
      } else if (option === 'last_7_days') {
        setOptions({...options, sort_by: 'last_7_days', sort_order: 'desc', page: 1})
      }
    },
    [options],
  )

  const handleFooter = (item: DailyRevenuInfo, index: number) => {
    return (
      <>
        <div className="flex-1 pl-3 pt-2 pb-1 pr-3">
          <div className="chart-title-font flex-1">
            <span style={{maxWidth: '5px'}}>{item.title}</span>
          </div>
          <div className="flex-1 flex-d-column h-flex-end" style={{alignItems: 'flex-end'}}>
            <div className="chart-font f-Exo-Bold flex">{item.priceValue}</div>
            <div className="c-club-revenue-card flex-1 v-flex-start">
              <div className="flex v-flex-start mr-1 mt-1">
                <i
                  className={`c-icon c-icon--triangle ${
                    item.profit && 'c-icon--triangle--up--small'
                  } ${item.loss && 'c-icon--triangle--down--small'}`}
                />
              </div>
              <div
                className={`fn-12 ${item.profit && 'f-color-green'} ${item.loss && 'f-color-red'}`}
              >
                {item.percentageValue}
              </div>
            </div>
          </div>
        </div>
        {revenueInfoBox.length > index + 1 && <div style={{border: '1px solid #f5f5f5'}} />}
      </>
    )
  }

  return (
    <div className="c-card bg-white">
      <div className="c-carousal__header">
        <div className="c-carousal__header__lpart v-center ">
          <span className="f-Exo-Bold title-color fn-18">{t('dailyRevenue')}</span>
        </div>
        <div className="c-carousal__header__rpart flex-center">
          <Sort optionList={daysOptionListRevenue} onSortChange={onSortChange} classAddon="p-1" />
        </div>
      </div>
      <RevenueBody />
      <div className="flex-1 flex-d-row">
        {Array.isArray(revenueInfoBox) &&
          revenueInfoBox.length > 0 &&
          revenueInfoBox.map((item, index) => {
            return handleFooter(item, index)
          })}
      </div>
    </div>
  )
})

export default DailyRevenue
