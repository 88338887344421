import React, {memo} from 'react'
import ReactModal from 'react-modal'
import {ModalPropsType} from './type'

const Modal = ({isOpen, setIsOpen, children, ...props}: ModalPropsType) => {
  const customStyles = {
    content: {
      maxWidth: '80%',
      left: '10%',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      zIndex: '9',
    },
  }
  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={() => setIsOpen(false)}
      {...props}
    >
      {children}
    </ReactModal>
  )
}

export default memo(Modal)
