import React from 'react'
import {matchLiveCommentary} from '../../../store/actions/types'

const CommentaryWithBg = ({item}: {item: matchLiveCommentary}) => {
  const {commentary_time, commentary_type, commentary_text} = item

  return (
    <div className="flex-1 flex-d-row p-4 mb-2 mt-2 br-10 bg-gray-dark">
      <div className="flex-center f-0-1 flex-d-row">
        <div className="flex-1-center-v">
          <img
            src={commentary_type.medias_model[0].original_url}
            className="img_preview_commentary"
            alt="loader"
          />
        </div>
        <div className="flex-1-center-v f-Exo-Bold">{`${commentary_time}'`}</div>
      </div>
      <div className="flex f-0-8 f-Exo-Bold">{commentary_text}</div>
    </div>
  )
}

export default CommentaryWithBg
