import React from 'react'
import {RenderElementProps, useSelected} from 'slate-react'
// eslint-disable-next-line  import/extensions
import {LinkElement} from './types'

const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{fontSize: 0}}>
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)

const LinkComponent = ({attributes, children, element}: RenderElementProps) => {
  const selected = useSelected()
  return (
    <a
      {...attributes}
      href={(element as LinkElement).url}
      style={selected ? {boxShadow: '0 0 0 3px #ddd;'} : undefined}
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  )
}

export default LinkComponent
