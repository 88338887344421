import Api, {urls} from '../../services/api'
import {ApiResponseType, AccountSettingType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {accountSeeting} from '../slices'
import {AppDispatch} from '..'
import {apiErrorBlock} from '../../functions/utils'

export const getAccountSettingInfo = (isMaster: boolean) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseType<AccountSettingType> = await Api.get(
      `${isMaster ? urls.masterAccountSeeting : urls.clubAccountSeeting}`,
    )
    const {status, message, data} = response.data
    if (status && data) {
      await dispatch(
        accountSeeting.updateAccountSetting({
          accountSetting: data,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
}
export const updateAccountSettingInfo =
  (isMaster: boolean, params: any) => async (dispatch: AppDispatch) => {
    try {
      const response: ApiResponseType<AccountSettingType> = await Api.post(
        `${isMaster ? urls.masterAccountSeetingUpdate : urls.clubAccountSeetingUpdate}`,
        params,
      )
      const {status, message} = response.data
      if (status && message) {
        if (message) showNotification({title: 'Success', message, type: 'success'})
        await dispatch(getAccountSettingInfo(isMaster))
        return true
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return false
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }
