import React from 'react'
import {Button} from '../../component'

const HeaderLeft = ({gdxLogo}: {gdxLogo: string}) => (
  <div className="col">
    <Button href="/" className="c-logo">
      <img src={gdxLogo} alt="" />
    </Button>
  </div>
)

export default HeaderLeft
