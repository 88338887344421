import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {TagType} from '../actions/types'

export interface TagState {
  tagList: TagType[]
  total: number
  from: number
  tagDetail: TagType
}

const initialTagDetail = {
  id: 0,
  name: '',
  slug: '',
  created_at: '',
}
const initialState = {
  tagList: [],
  total: 0,
  from: 0,
  tagDetail: initialTagDetail,
} as TagState

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    updateTagList: (state, action: PayloadAction<Partial<TagState>>) => {
      const {tagList, total, from} = action.payload
      state.tagList = tagList || state.tagList
      state.total = total || state.total
      state.from = from || state.from
    },
    updateTagDetail: (state, action: PayloadAction<Partial<TagState>>) => {
      state.tagDetail = action.payload.tagDetail || state.tagDetail
    },
    clearTagDetail: (state) => {
      state.tagDetail = initialState.tagDetail
    },
  },
})
