import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {Button} from '../component'
import {getBreadcrumbs} from '../functions/breadcrumb'
import {breadcrumbType} from './type'
import {useSelector} from '../store'
import {getRole} from '../store/selectors/user'

const Breadcrumb = () => {
  const role = useSelector(getRole)
  const {t} = useTranslation()
  const navigation = useNavigate()
  const currentLocation = useLocation().pathname
  const breadcrumbs = getBreadcrumbs(currentLocation, role)
  const handleChnage = (breadcrumb: any) => {
    navigation(breadcrumb)
  }
  return (
    <nav className="c-breadcrumb">
      <ol className="c-breadcrumb__list">
        {(breadcrumbs.length > 0 &&
          breadcrumbs.map((breadcrumb: breadcrumbType, index: number) => (
            <li className="c-breadcrumb__item" key={`breadcrumb-${breadcrumb.name}`}>
              {index === 0 && (
                <span className="c-breadcrumb__item_first">
                  <Button className="" onClick={() => handleChnage('/')} icon=" c-icon--home" />
                </span>
              )}
              {breadcrumb.active ? (
                t(breadcrumb.name)
              ) : (
                <Button
                  buttonType="submit"
                  className=""
                  onClick={() => handleChnage(breadcrumb.pathname)}
                  title={t(breadcrumb.name)}
                />
              )}
            </li>
          ))) ||
          (window.location.pathname !== '/404' && (
            <li className="c-breadcrumb__item" key="breadcrumb-home">
              <span>
                <Button className="" onClick={() => handleChnage('/')} icon="c-icon--home-black" />
              </span>
              <span className="f-Exo-Medium">Home</span>
            </li>
          ))}
      </ol>
    </nav>
  )
}

export default Breadcrumb
