import Api, {urls} from '../../services/api'
import {ApiResponseList, ApiResponseType, PlayerManagementType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {playerAction} from '../slices'
import {AppDispatch} from '..'
import {apiErrorBlock} from '../../functions/utils'

export const getPlayerList = (page?: any) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<PlayerManagementType[]> = await Api.get(`${urls.player}`, {
      params: {page},
    })
    const {status, message, data} = response.data
    if (status && data?.data) {
      dispatch(
        playerAction.updatePlayerList({
          playerList: data.data,
          total: data.total || 0,
          from: data.from || 0,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    logException(e)
    console.log(e)
  }
}
export const addPlayer = (params: FormData) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<PlayerManagementType[]> = await Api.post(
      `${urls.player}`,
      params,
    )
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getPlayerList(1))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const updatePlayer = (id: number, params: FormData) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<PlayerManagementType[]> = await Api.post(
      `${urls.player}/${id}`,
      params,
    )
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getPlayerList(1))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const getPlayerDetail = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseType<PlayerManagementType> = await Api.get(`${urls.player}/${id}`)
    const {status, message, data} = response.data
    if (status && data) {
      await dispatch(playerAction.updatePlayerDetail({playerDetail: data}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const clearPlayerDetail = () => async (dispatch: AppDispatch) => {
  try {
    await dispatch(playerAction.clearPlayerDetail())
    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
