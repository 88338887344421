import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AccountSettingType} from '../actions/types'

const initialState = {accountSetting: {}} as AccountSettingState

export interface AccountSettingState {
  accountSetting: AccountSettingType
}
export const accountSettingSlice = createSlice({
  name: 'accountSetting',
  initialState,
  reducers: {
    updateAccountSetting: (state, action: PayloadAction<AccountSettingState>) => {
      state.accountSetting = action.payload.accountSetting || state.accountSetting
    },
  },
})
