import {
  Editor as SlateEditor,
  Element as SlateElement,
  Transforms,
  Range,
  Descendant,
  Node,
} from 'slate'
// eslint-disable-next-line  import/extensions
import {LinkElement} from './types'

export const LIST_TYPES = ['numbered-list', 'bulleted-list']
export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

export const toggleBlock = (editor: SlateEditor, format: string) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !SlateEditor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties: Partial<SlateElement>
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      // eslint-disable-next-line no-nested-ternary
      type: (isActive ? 'paragraph' : isList ? 'list-item' : format) as SlateElement['type'],
    }
  }
  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = {type: format, children: []}
    Transforms.wrapNodes(editor, block as SlateElement)
  }
}

export const toggleMark = (editor: SlateEditor, format: string) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    SlateEditor.removeMark(editor, format)
  } else {
    SlateEditor.addMark(editor, format, true)
  }
}

export const isBlockActive = (editor: SlateEditor, format: string, blockType = 'type') => {
  const {selection} = editor
  if (!selection) return false
  try {
    const [match] = Array.from(
      SlateEditor.nodes(editor, {
        at: SlateEditor.unhangRange(editor, selection),
        match: (n) =>
          !SlateEditor.isEditor(n) &&
          SlateElement.isElement(n) &&
          (n as Record<string, any>)[blockType] === format,
      }),
    )

    return !!match
  } catch (e) {
    console.log('isBlockActive error: ', e)
    return false
  }
}

export const isMarkActive = (editor: SlateEditor, format: string) => {
  try {
    const marks = SlateEditor.marks(editor) as Record<string, boolean>
    return marks ? marks[format] === true : false
  } catch (e) {
    return false
  }
}

export const isLinkActive = (editor: SlateEditor) => {
  try {
    // @ts-ignore
    const [link] = SlateEditor.nodes(editor, {
      match: (n) => !SlateEditor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
    return !!link
  } catch (e) {
    console.log('isLinkActive error: ', e)
    return false
  }
}

export const unwrapLink = (editor: SlateEditor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !SlateEditor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
}

export const wrapLink = (editor: SlateEditor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const {selection} = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{text: url}] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, {split: true})
    Transforms.collapse(editor, {edge: 'end'})
  }
}

export const insertLink = (editor: SlateEditor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

export const plainTextSerialize = (nodes: Descendant[]) =>
  nodes.map((n) => Node.string(n)).join('\n')
