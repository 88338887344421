import React, {memo, useEffect, useCallback, useRef, useState, useMemo} from 'react'
import {useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import FinalScore from './finalScore'
import {
  getAppSettingList,
  getMatchContentFinalScore,
  getMatchContentMatchInfo,
  getMatchContentMatchReport,
  getMatchLiveCommentary,
  saveMatchReport,
  updateMatchInfo,
  updateMatchReport,
} from '../../store/actions'
import {useAppDispatch, useSelector} from '../../store'
import {RootState} from '../../store/slices'
import MatchInfo from './matchInfo'
import MatchReport from './matchReport'
import MatchMedia from './matchMedia'
import MatchLiveCommentary from './matchLiveCommentary'
import {addSettingList} from '../../data'
import {getRole} from '../../store/selectors/user'
import {MediaLibraryFilters} from '../../store/actions/types'
import {Button} from '../../component'
import {
  clearMatchContentFinalScore,
  updateMatchContentFinalScore,
} from '../../store/actions/matchDetail/matchContentFinalScore'
import {serializeFormData} from '../../functions/utils'
import {FinalScoreInitialValue, MatchInfoInitialValue, MatchReportInitialValue} from './type'

const MatchDetail = memo(() => {
  const [isEditMatchInfo, setIsEditMatchInfo] = useState(false)
  const [isEditMatchReport, setIsEditMatchReport] = useState(false)
  const [isRest, setIsRest] = useState(false)
  const [matchInfoValue, setMatchInfoValue] = useState<MatchInfoInitialValue>()
  const [matchReportValue, setMatchReportValue] = useState<MatchReportInitialValue>()
  const [finalScoreValue, setFinalScoreValue] = useState<FinalScoreInitialValue>()
  const dispatch = useAppDispatch()
  const params = useParams()
  const {t} = useTranslation()
  const role = useSelector(getRole)
  const isMaster = role === 'master_user'
  const id = (params?.id && parseInt(params.id, 10)) || 0
  const defaultOptions: MediaLibraryFilters = useMemo(
    () => ({
      search: '',
      file_type: undefined,
      from_date: undefined,
      to_date: undefined,
    }),
    [],
  )
  const [options] = useState(defaultOptions)
  const {matchLiveCommentary, total} = useSelector((state) => state.matchLiveCommentaryView)
  const page = useRef(1)
  const loading = useRef(true)
  const {matchContentFinalScore, matchContentMatchInfo, matchContentMatchReport} = useSelector(
    ({
      matchContentFinalScoreView,
      matchContentMatchInfoView,
      matchContentMatchReportView,
    }: RootState) => ({
      matchContentFinalScore: matchContentFinalScoreView.matchContentFinalScore,
      matchContentMatchInfo: matchContentMatchInfoView.matchContentMatchInfo,
      matchContentMatchReport: matchContentMatchReportView.matchContentMatchReport,
    }),
  )
  const getFinalScore = async () => {
    await dispatch(clearMatchContentFinalScore())
    await dispatch(getMatchContentFinalScore(id))
  }
  const getMatchInfo = async () => {
    await dispatch(getMatchContentMatchInfo(id))
  }
  const getMatchReport = async () => {
    await dispatch(getMatchContentMatchReport(id))
  }
  const getLiveCommentary = async () => {
    await dispatch(getMatchLiveCommentary(id, {page: page.current}))
  }
  const getCommentaryPeriod = async () => {
    await dispatch(getAppSettingList(isMaster, addSettingList.commentaryPeriod))
  }
  useEffect(() => {
    getFinalScore()
    getMatchInfo()
    getMatchReport()
    getLiveCommentary()
    getCommentaryPeriod()
  }, [])

  useEffect(() => {
    page.current = 1
    loadList()
  }, [options])

  const loadList = useCallback(() => {
    dispatch(getMatchLiveCommentary(id, {page: page.current})).finally(() => {
      loading.current = false
    })
  }, [options])

  const loadMore = useCallback(() => {
    if (
      !loading.current &&
      matchLiveCommentary.length < total &&
      page.current < Math.ceil(total / 10)
    ) {
      loading.current = true
      page.current += 1
      loadList()
    } else {
      page.current = 1
    }
  }, [options, total, matchLiveCommentary, loadList])

  // final score code start
  const initialValueFinalScore = {
    clubScore: matchContentFinalScore.club_final_score || 0,
    opponentScore: matchContentFinalScore.opponent_final_score || 0,
    clubPenaltie: matchContentFinalScore.club_penalties || 0,
    opponentPenaltie: matchContentFinalScore.opponent_penalties || 0,
  }
  const onSubmitFinalScore = async (values: any) => {
    const formValues: any = {}
    formValues.club_final_score = values.clubScore || 0
    formValues.opponent_final_score = values.opponentScore || 0
    formValues.club_penalties = values.clubPenaltie || 0
    formValues.opponent_penalties = values.opponentPenaltie || 0
    const formData = serializeFormData(formValues)
    await dispatch(updateMatchContentFinalScore(id || 0, formData))
  }
  // final score code End

  // Match Info code start
  const initialValueMatchInfo = {
    meetTime: matchContentMatchInfo.match_meet_time,
    kickoffTime: matchContentMatchInfo.match_kickoff_time,
    matchType: matchContentMatchInfo.match_type,
    attendTime: matchContentMatchInfo.match_attend_time,
    matchDesc: (matchContentMatchInfo.match_description &&
      JSON.parse(matchContentMatchInfo.match_description)) || [
      {
        type: 'paragraph',
        children: [{text: ''}],
      },
    ],
  }
  const handleEditStateMatchInfo = () => {
    setIsEditMatchInfo(!isEditMatchInfo)
  }

  const onSubmitMatchInfo = async (values: any) => {
    const formValues: any = {}
    formValues.match_meet_time = values.meetTime || undefined
    formValues.match_kickoff_time = values.kickoffTime || undefined
    formValues.match_type = values.matchType || 0
    formValues.match_attend_time = values.attendTime || undefined
    formValues.match_description = JSON.stringify(values.matchDesc) || undefined
    const formData = serializeFormData(formValues)
    await dispatch(updateMatchInfo(id || 0, formData))
    handleEditStateMatchInfo()
  }
  // Match Info code End

  // Match Report code start
  const ReportData =
    Array.isArray(matchContentMatchReport) && matchContentMatchReport.length > 0
      ? matchContentMatchReport[0]
      : undefined
  const initialValueMatchReport = {
    matchDesc: ReportData?.report_description
      ? JSON.parse(ReportData?.report_description)
      : undefined,
    title: ReportData?.report_title || undefined,
    description: ReportData?.report_intro || undefined,
    medias_model: ReportData?.medias_model || [
      {
        medias_id: 0,
        file_name: '',
        original_url: '',
      },
    ],
  }
  const handleEditStateMatchReport = () => {
    setIsEditMatchReport(!isEditMatchReport)
  }
  const onSubmitMatchReport = async (values: any) => {
    const formValues: any = {}
    formValues.report_title = values.title || undefined
    formValues.report_intro = values.description || undefined
    formValues.image = values.medias_model[0].file || undefined
    formValues.report_description = JSON.stringify(values.matchDesc) || undefined
    formValues.match_fixture_id = id
    const formData = serializeFormData(formValues)
    if (ReportData?.id) {
      await dispatch(updateMatchReport(ReportData.id, formData))
    } else {
      await dispatch(saveMatchReport(formData))
    }
    await getMatchReport()
    setIsRest(true)
    await handleEditStateMatchReport()
  }
  // Match Report code End
  const submitAllForm = () => {
    if (isEditMatchInfo) {
      const updatedMatchInfoValue = {
        meetTime: matchInfoValue?.meetTime || matchContentMatchInfo.match_meet_time,
        kickoffTime: matchInfoValue?.kickoffTime || matchContentMatchInfo.match_attend_time,
        matchType: matchInfoValue?.matchType || matchContentMatchInfo.match_type,
        attendTime: matchInfoValue?.attendTime || matchContentMatchInfo.match_attend_time,
        matchDesc: matchInfoValue?.matchDesc || matchContentMatchInfo.match_description,
      }
      onSubmitMatchInfo(updatedMatchInfoValue)
    }
    if (isEditMatchReport) {
      const updatedMatchReportValue = {
        title: matchReportValue?.title,
        description: matchReportValue?.description,
        medias_model: matchReportValue?.medias_model,
        matchDesc: matchReportValue?.matchDesc,
      }
      onSubmitMatchReport(updatedMatchReportValue)
    }
    onSubmitFinalScore(finalScoreValue)
  }

  const handleAllClose = () => {
    if (isEditMatchInfo) {
      handleEditStateMatchInfo()
    }
    if (isEditMatchReport) {
      handleEditStateMatchReport()
    }
  }

  return (
    <div className="flex-d-column">
      <div className="flex-1 h-space-bet flex-d-row">
        <div className="flex f-0-67">
          <div className="flex-1 flex-d-column">
            <div className="flex-1">
              {Object.keys(matchContentFinalScore).length !== 0 && (
                <FinalScore
                  item={matchContentFinalScore}
                  initialValue={initialValueFinalScore}
                  onSubmit={onSubmitFinalScore}
                  setFinalScoreValue={setFinalScoreValue}
                />
              )}
            </div>
            <div className="flex-1 mt-5">
              {Object.keys(matchContentMatchInfo).length !== 0 && (
                <MatchInfo
                  item={matchContentMatchInfo}
                  initialValue={initialValueMatchInfo}
                  onSubmit={onSubmitMatchInfo}
                  handleEditState={handleEditStateMatchInfo}
                  isEdit={isEditMatchInfo}
                  setMatchInfoValue={setMatchInfoValue}
                />
              )}
            </div>
            <div className="flex-1 mt-5">
              <MatchReport
                initialValue={initialValueMatchReport}
                onSubmit={onSubmitMatchReport}
                handleEditState={handleEditStateMatchReport}
                isEdit={isEditMatchReport}
                setMatchReportValue={setMatchReportValue}
                isRest={isRest}
                setIsRest={setIsRest}
              />
            </div>
            <div className="flex-1 mt-5">
              <MatchLiveCommentary item={matchLiveCommentary} id={id} loadMore={loadMore} />
            </div>
          </div>
        </div>
        <MatchMedia />
      </div>
      <div className="flex mt-5">
        <Button title={t('saveAllChanges')} classAddon="mr-3 f-Exo-Bold" onClick={submitAllForm} />
        <Button
          title={t('cancel')}
          classAddon="c-button--bg--white f-Exo-Bold"
          onClick={handleAllClose}
        />
      </div>
    </div>
  )
})

export default MatchDetail
