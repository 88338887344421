import Api, {urls} from '../../services/api'
import {ApiResponseList, ApiResponseType, NewsStoryType, SortingType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {newsActions} from '../slices'
import {AppDispatch} from '..'
import {apiErrorBlock} from '../../functions/utils'

export const addNewsStory = (params: FormData) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<NewsStoryType[]> = await Api.post(`${urls.newsStory}`, params)
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getNewsStoryList({page: 1}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const updateNewsStory =
  (slug: string, params: FormData) => async (dispatch: AppDispatch) => {
    try {
      const response: ApiResponseList<NewsStoryType[]> = await Api.post(
        `${urls.newsStory}/${slug}`,
        params,
      )
      const {status, message} = response.data
      if (status && message) {
        if (message) showNotification({title: 'Success', message, type: 'success'})
        await dispatch(getNewsStoryList({page: 1}))
        return true
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return false
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }
export const updateNewsStoryStatus =
  (slug: string, params: {status_key: string; status_value: number}) =>
  async (dispatch: AppDispatch) => {
    try {
      const response: ApiResponseList<NewsStoryType[]> = await Api.patch(
        `${urls.newsStoryUpdateStatus}/${slug}`,
        params,
      )
      const {status, message} = response.data
      if (status && message) {
        await dispatch(getNewsStoryList({page: 1}))
        return true
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return false
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }
export const deleteNewsStory = (slug: string) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<NewsStoryType[]> = await Api.delete(`${urls.newsStory}/${slug}`)
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getNewsStoryList({page: 1}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const getNewsStoryList = (params: SortingType) => async (dispatch: AppDispatch) => {
  try {
    const {page} = params
    const response: ApiResponseList<NewsStoryType[]> = await Api.get(
      `${urls.newsStory}?page=${page}${params.sort_by ? `&sort_by=${params.sort_by}` : ''}${
        params.sort_order ? `&sort_order=${params.sort_order}` : ''
      }${params.per_page && params.per_page > 0 ? `&per_page=${params.per_page}` : ''}`,
    )
    const {status, message, data} = response.data
    if (status && data?.data) {
      await dispatch(newsActions.updateNewsList({newsList: data.data, total: data.total || 0}))
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
}

export const getNewsStoryDetail = (slug: string) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseType<NewsStoryType> = await Api.get(`${urls.newsStory}/${slug}`)
    const {status, message, data} = response.data
    if (status && data) {
      await dispatch(newsActions.updateNewsDetail({newsDetail: data}))
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const clearNewsStoryDetail = () => async (dispatch: AppDispatch) => {
  try {
    await dispatch(newsActions.clearNewsDetail())
    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
