import Api, {urls} from '../../services/api'
import {ApiResponseList, ApiResponseType, SortingType, FixtureType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {fixtureActions} from '../slices'
import {AppDispatch} from '..'
import {FixtureUpdateRequestType} from '../../container/matchFixture/type'
import {apiErrorBlock} from '../../functions/utils'
import {defaultPageSize} from '../../configs/paginationSetting'

export const getFixtureList = (params: SortingType) => async (dispatch: AppDispatch) => {
  try {
    const {page} = params
    const response: ApiResponseList<FixtureType[]> = await Api.get(`${urls.fixture}`, {
      params: {
        page,
        per_page: params.per_page || defaultPageSize,
        is_past: params.is_past,
        sort_by: params.sort_by,
        sort_order: params.sort_order,
      },
    })
    const {status, message, data} = response.data
    if (status && data?.data) {
      await dispatch(
        fixtureActions.updateFixtureList({
          fixtureList: data.data,
          total: data.total || 0,
          from: data.from || 0,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
}

export const addFixture = (params: FormData) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<FixtureType[]> = await Api.post(`${urls.fixture}`, params)
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getFixtureList({page: 1, is_past: 0}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const updateFixture =
  (id: number, params: FixtureUpdateRequestType) => async (dispatch: AppDispatch) => {
    try {
      const response: ApiResponseList<FixtureType[]> = await Api.patch(
        `${urls.fixture}/${id}`,
        params,
      )
      const {status, message} = response.data
      if (status && message) {
        if (message) showNotification({title: 'Success', message, type: 'success'})
        await dispatch(getFixtureList({page: 1, is_past: 0}))
        return true
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return false
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }

export const getFixtureDetail = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseType<FixtureType> = await Api.get(`${urls.fixture}/${id}`)
    const {status, message, data} = response.data
    if (status && data) {
      await dispatch(fixtureActions.updateFixtureDetail({fixtureDetail: data}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const clearFixtureDetail = () => async (dispatch: AppDispatch) => {
  try {
    await dispatch(fixtureActions.clearFixtureDetail())
    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const deleteFixture =
  (id: number, options: SortingType) => async (dispatch: AppDispatch) => {
    try {
      const response: ApiResponseList<FixtureType[]> = await Api.delete(`${urls.fixture}/${id}`)
      const {status, message} = response.data
      if (status && message) {
        if (message) showNotification({title: 'Success', message, type: 'success'})
        await dispatch(getFixtureList(options))
        return true
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return false
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }
