import Api, {urls} from '../../services/api'
import {ApiResponseList, MatchContentType, SortingType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {matchContentActions} from '../slices'
import {AppDispatch} from '..'
import {apiErrorBlock} from '../../functions/utils'

export const getMatchContentList = (params: SortingType) => async (dispatch: AppDispatch) => {
  try {
    const {page} = params
    const response: ApiResponseList<MatchContentType[]> = await Api.get(
      `${urls.matchContent}?page=${page}${
        params.per_page && params.per_page > 0 ? `&per_page=${params.per_page}` : ''
      }${params.sort_by ? `&sort_by=${params.sort_by}` : ''}${
        params.sort_order ? `&sort_order=${params.sort_order}` : ''
      }`,
    )
    const {status, message, data} = response.data
    if (status && data?.data) {
      await dispatch(
        matchContentActions.updateMatchContentList({
          matchContentList: data.data,
          total: data.total || 0,
          from: data.from || 0,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
}
