const hoursList = [
  {
    id: 1,
    value: '00',
    label: '00',
  },
  {
    id: 2,
    value: '01',
    label: '01',
  },
  {
    id: 3,
    value: '02',
    label: '02',
  },
  {
    id: 4,
    value: '03',
    label: '03',
  },
  {
    id: 5,
    value: '04',
    label: '04',
  },
  {
    id: 6,
    value: '05',
    label: '05',
  },
  {
    id: 7,
    value: '06',
    label: '06',
  },
  {
    id: 8,
    value: '07',
    label: '07',
  },
  {
    id: 9,
    value: '08',
    label: '08',
  },
  {
    id: 10,
    value: '09',
    label: '09',
  },
  {
    id: 11,
    value: '10',
    label: '10',
  },
  {
    id: 12,
    value: '11',
    label: '11',
  },
  {
    id: 13,
    value: '12',
    label: '12',
  },
  {
    id: 14,
    value: '13',
    label: '13',
  },
  {
    id: 15,
    value: '14',
    label: '14',
  },
  {
    id: 16,
    value: '15',
    label: '15',
  },
  {
    id: 17,
    value: '16',
    label: '16',
  },
  {
    id: 18,
    value: '17',
    label: '17',
  },
  {
    id: 19,
    value: '18',
    label: '18',
  },
  {
    id: 20,
    value: '19',
    label: '19',
  },
  {
    id: 21,
    value: '20',
    label: '20',
  },
  {
    id: 22,
    value: '21',
    label: '21',
  },
  {
    id: 23,
    value: '22',
    label: '22',
  },
  {
    id: 24,
    value: '23',
    label: '23',
  },
]
const minuteList = [
  {
    id: 1,
    value: '00',
    label: '00',
  },
  {
    id: 2,
    value: '01',
    label: '01',
  },
  {
    id: 3,
    value: '02',
    label: '02',
  },
  {
    id: 4,
    value: '03',
    label: '03',
  },
  {
    id: 5,
    value: '04',
    label: '04',
  },
  {
    id: 6,
    value: '05',
    label: '05',
  },
  {
    id: 7,
    value: '06',
    label: '06',
  },
  {
    id: 8,
    value: '07',
    label: '07',
  },
  {
    id: 9,
    value: '08',
    label: '08',
  },
  {
    id: 10,
    value: '09',
    label: '09',
  },
  {
    id: 11,
    value: '10',
    label: '10',
  },
  {
    id: 12,
    value: '11',
    label: '11',
  },
  {
    id: 13,
    value: '12',
    label: '12',
  },
  {
    id: 14,
    value: '13',
    label: '13',
  },
  {
    id: 15,
    value: '14',
    label: '14',
  },
  {
    id: 16,
    value: '15',
    label: '15',
  },
  {
    id: 17,
    value: '16',
    label: '16',
  },
  {
    id: 18,
    value: '17',
    label: '17',
  },
  {
    id: 19,
    value: '18',
    label: '18',
  },
  {
    id: 20,
    value: '19',
    label: '19',
  },
  {
    id: 21,
    value: '20',
    label: '20',
  },
  {
    id: 22,
    value: '21',
    label: '21',
  },
  {
    id: 23,
    value: '22',
    label: '22',
  },
  {
    id: 24,
    value: '23',
    label: '23',
  },
  {
    id: 25,
    value: '24',
    label: '24',
  },
  {
    id: 26,
    value: '25',
    label: '25',
  },
  {
    id: 27,
    value: '26',
    label: '26',
  },
  {
    id: 28,
    value: '27',
    label: '27',
  },
  {
    id: 29,
    value: '28',
    label: '28',
  },
  {
    id: 30,
    value: '29',
    label: '29',
  },
  {
    id: 31,
    value: '30',
    label: '30',
  },
  {
    id: 32,
    value: '31',
    label: '31',
  },
  {
    id: 33,
    value: '32',
    label: '32',
  },
  {
    id: 34,
    value: '33',
    label: '33',
  },
  {
    id: 35,
    value: '34',
    label: '34',
  },
  {
    id: 36,
    value: '35',
    label: '35',
  },
  {
    id: 37,
    value: '36',
    label: '36',
  },
  {
    id: 38,
    value: '37',
    label: '37',
  },
  {
    id: 39,
    value: '38',
    label: '38',
  },
  {
    id: 40,
    value: '39',
    label: '39',
  },
  {
    id: 41,
    value: '40',
    label: '40',
  },
  {
    id: 42,
    value: '41',
    label: '41',
  },
  {
    id: 43,
    value: '42',
    label: '42',
  },
  {
    id: 44,
    value: '43',
    label: '43',
  },
  {
    id: 45,
    value: '44',
    label: '44',
  },
  {
    id: 46,
    value: '45',
    label: '45',
  },
  {
    id: 47,
    value: '46',
    label: '46',
  },
  {
    id: 48,
    value: '47',
    label: '47',
  },
  {
    id: 49,
    value: '48',
    label: '48',
  },
  {
    id: 50,
    value: '49',
    label: '49',
  },
  {
    id: 51,
    value: '50',
    label: '50',
  },
  {
    id: 52,
    value: '51',
    label: '51',
  },
  {
    id: 53,
    value: '52',
    label: '52',
  },
  {
    id: 54,
    value: '53',
    label: '53',
  },
  {
    id: 55,
    value: '54',
    label: '54',
  },
  {
    id: 56,
    value: '55',
    label: '55',
  },
  {
    id: 57,
    value: '56',
    label: '56',
  },
  {
    id: 58,
    value: '57',
    label: '57',
  },
  {
    id: 59,
    value: '58',
    label: '58',
  },
  {
    id: 60,
    value: '59',
    label: '59',
  },
]
const hoursListSingleValue = [
  {
    id: 1,
    value: '0',
    label: '00',
  },
  {
    id: 2,
    value: '1',
    label: '01',
  },
  {
    id: 3,
    value: '2',
    label: '02',
  },
  {
    id: 4,
    value: '3',
    label: '03',
  },
  {
    id: 5,
    value: '4',
    label: '04',
  },
  {
    id: 6,
    value: '5',
    label: '05',
  },
  {
    id: 7,
    value: '6',
    label: '06',
  },
  {
    id: 8,
    value: '7',
    label: '07',
  },
  {
    id: 9,
    value: '8',
    label: '08',
  },
  {
    id: 10,
    value: '9',
    label: '09',
  },
  {
    id: 11,
    value: '10',
    label: '10',
  },
  {
    id: 12,
    value: '11',
    label: '11',
  },
  {
    id: 13,
    value: '12',
    label: '12',
  },
  {
    id: 14,
    value: '13',
    label: '13',
  },
  {
    id: 15,
    value: '14',
    label: '14',
  },
  {
    id: 16,
    value: '15',
    label: '15',
  },
  {
    id: 17,
    value: '16',
    label: '16',
  },
  {
    id: 18,
    value: '17',
    label: '17',
  },
  {
    id: 19,
    value: '18',
    label: '18',
  },
  {
    id: 20,
    value: '19',
    label: '19',
  },
  {
    id: 21,
    value: '20',
    label: '20',
  },
  {
    id: 22,
    value: '21',
    label: '21',
  },
  {
    id: 23,
    value: '22',
    label: '22',
  },
  {
    id: 24,
    value: '23',
    label: '23',
  },
  {
    id: 25,
    value: '24',
    label: '24',
  },
  {
    id: 26,
    value: '25',
    label: '25',
  },
  {
    id: 27,
    value: '26',
    label: '26',
  },
  {
    id: 28,
    value: '27',
    label: '27',
  },
  {
    id: 29,
    value: '28',
    label: '28',
  },
  {
    id: 30,
    value: '29',
    label: '29',
  },
  {
    id: 31,
    value: '30',
    label: '30',
  },
  {
    id: 32,
    value: '31',
    label: '31',
  },
  {
    id: 33,
    value: '32',
    label: '32',
  },
  {
    id: 34,
    value: '33',
    label: '33',
  },
  {
    id: 35,
    value: '34',
    label: '34',
  },
  {
    id: 36,
    value: '35',
    label: '35',
  },
  {
    id: 37,
    value: '36',
    label: '36',
  },
  {
    id: 38,
    value: '37',
    label: '37',
  },
  {
    id: 39,
    value: '38',
    label: '38',
  },
  {
    id: 40,
    value: '39',
    label: '39',
  },
  {
    id: 41,
    value: '40',
    label: '40',
  },
  {
    id: 42,
    value: '41',
    label: '41',
  },
  {
    id: 43,
    value: '42',
    label: '42',
  },
  {
    id: 44,
    value: '43',
    label: '43',
  },
  {
    id: 45,
    value: '44',
    label: '44',
  },
  {
    id: 46,
    value: '45',
    label: '45',
  },
  {
    id: 47,
    value: '46',
    label: '46',
  },
  {
    id: 48,
    value: '47',
    label: '47',
  },
  {
    id: 49,
    value: '48',
    label: '48',
  },
  {
    id: 50,
    value: '49',
    label: '49',
  },
  {
    id: 51,
    value: '50',
    label: '50',
  },
  {
    id: 52,
    value: '51',
    label: '51',
  },
  {
    id: 53,
    value: '52',
    label: '52',
  },
  {
    id: 54,
    value: '53',
    label: '53',
  },
  {
    id: 55,
    value: '54',
    label: '54',
  },
  {
    id: 56,
    value: '55',
    label: '55',
  },
  {
    id: 57,
    value: '56',
    label: '56',
  },
  {
    id: 58,
    value: '57',
    label: '57',
  },
  {
    id: 59,
    value: '58',
    label: '58',
  },
  {
    id: 60,
    value: '59',
    label: '59',
  },
  {
    id: 61,
    value: '60',
    label: '60',
  },
  {
    id: 62,
    value: '61',
    label: '61',
  },
  {
    id: 63,
    value: '62',
    label: '62',
  },
  {
    id: 64,
    value: '63',
    label: '63',
  },
  {
    id: 65,
    value: '64',
    label: '64',
  },
  {
    id: 66,
    value: '65',
    label: '65',
  },
  {
    id: 67,
    value: '66',
    label: '66',
  },
  {
    id: 68,
    value: '67',
    label: '67',
  },
  {
    id: 69,
    value: '68',
    label: '68',
  },
  {
    id: 70,
    value: '69',
    label: '69',
  },
  {
    id: 71,
    value: '70',
    label: '70',
  },
  {
    id: 72,
    value: '71',
    label: '71',
  },
  {
    id: 73,
    value: '72',
    label: '72',
  },
  {
    id: 74,
    value: '73',
    label: '73',
  },
  {
    id: 75,
    value: '74',
    label: '74',
  },
  {
    id: 76,
    value: '75',
    label: '75',
  },
  {
    id: 77,
    value: '76',
    label: '76',
  },
  {
    id: 78,
    value: '77',
    label: '77',
  },
  {
    id: 79,
    value: '78',
    label: '78',
  },
  {
    id: 80,
    value: '79',
    label: '79',
  },
  {
    id: 81,
    value: '80',
    label: '80',
  },
  {
    id: 82,
    value: '81',
    label: '81',
  },
  {
    id: 83,
    value: '82',
    label: '82',
  },
  {
    id: 84,
    value: '83',
    label: '83',
  },
  {
    id: 85,
    value: '84',
    label: '84',
  },
  {
    id: 86,
    value: '85',
    label: '85',
  },
  {
    id: 87,
    value: '86',
    label: '86',
  },
  {
    id: 88,
    value: '87',
    label: '87',
  },
  {
    id: 89,
    value: '88',
    label: '88',
  },
  {
    id: 90,
    value: '89',
    label: '89',
  },
  {
    id: 91,
    value: '90',
    label: '90',
  },
]
export {hoursList, minuteList, hoursListSingleValue}
