import React from 'react'
import Cookies from 'universal-cookie'
import {headerData} from '../../data'
import {Button} from '../../component'

const HeaderRight = () => {
  const {dropDownList} = headerData.profile
  const cookies = new Cookies()
  return (
    <div className="col-auto">
      <div className="c-header__rpart">
        {/* {!isMaster && (
          <div className="c-mail">
            <span className="c-mail__notify">{mailCount}</span>
            <img src={images.msgBox} alt="loader" />
          </div>
        )}
        <div className="c-notification">
          <span className="c-notification__notify">{notificationCount}</span>
          <img
            src={isMaster ? images.notificationBellBlack : images.notificationBell}
            alt="loader"
          />
        </div>
        <Profile />
        <i className="c-icon c-icon--hamburger" /> */}
        <Button
          key={`profile-drop-down-list-${dropDownList[0].id}`}
          href={dropDownList[0].url}
          onClick={() => {
            if (dropDownList[0].title === 'Logout') {
              cookies.remove('user')
            }
          }}
        >
          {dropDownList[0].title}
        </Button>
      </div>
    </div>
  )
}

export default HeaderRight
