/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {memo} from 'react'
import {Formik, Form} from 'formik'
import {useParams, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import {Button, Input} from '../../../component'
import {useAppDispatch, useSelector} from '../../../store'
import {saveRole} from '../../../store/actions/security'
import {RoleItem} from '../../../store/actions/types'

const RolesForm = () => {
  const {t} = useTranslation()
  const navigation = useNavigate()
  const dispatch = useAppDispatch()
  const params = useParams()
  const list = useSelector(({roles}) => roles.list)
  const currentId = (params?.id && parseInt(params.id, 10)) || undefined
  const permission = currentId ? list.find((item) => item.id === currentId) : undefined
  const initialValues = {
    id: permission?.id || 0,
    name: permission?.name || '',
    role_slug: '',
  }

  const onSubmit = async (values: RoleItem) => {
    try {
      const result: boolean = await dispatch(saveRole(values))
      if (result) setTimeout(navigation, 300, -1)
    } catch (e) {
      console.log(e)
    }
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required('Name cannot be empty.'),
  })

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({touched, values, errors, handleChange, handleSubmit}) => (
        <Form className="h-100">
          <div className="table-outer">
            <div className="table-header">
              <div className="c-carousal__header no-border">
                <div className="c-carousal__header__rpart flex-center">
                  <Button
                    classAddon="f-Exo-Bold mr-2"
                    title={t('save')}
                    style={{padding: '0.5rem 1.5rem'}}
                    onClick={(e: unknown) => handleSubmit(e as React.FormEvent<HTMLFormElement>)}
                  />
                  <div>
                    <Button
                      classAddon=" c-button--br c-button--br--gray-ab bg-transparent f-Exo-Bold"
                      title={t('cancel')}
                      style={{padding: '0.5rem 1.5rem'}}
                      onClick={() => navigation(-1)}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-2 mb-4 rolefrom-inner">
                <div className="flex-1 flex-d-row">
                  <div className="flex mt-4 f-0-7">
                    <div className="f-0-5">
                      <Input
                        name="name"
                        value={values.name}
                        label="Name"
                        placeholder="Enter role name"
                        error={touched.name && errors.name}
                        className="form-control mt-1"
                        classNameLable="fs-15"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default memo(RolesForm)
