import React, {memo, useCallback, useMemo, useState, useEffect} from 'react'
import {createTable} from '@tanstack/react-table'
import {useTranslation} from 'react-i18next'
import {format} from 'date-fns'
import {UpdateListType} from '../../component/type'
import {List as ListView, Sort, Popup} from '../../component'
import ListRight from '../../component/listRight'
import {useSelector, useAppDispatch} from '../../store'
import {ClubFixtureRequestType} from '../../store/actions/types'
import {ApproveButtonList, DenyButtonList} from '../../component/List/Commands'
import {userOptionList} from '../../data'
import {defaultPageSize} from '../../configs/paginationSetting'
import {
  getClubFixtureRequestList,
  updateClubFixtureStatus,
} from '../../store/actions/clubFixtureRquest'
import images from '../../assets/images'

const table = createTable().setRowType<ClubFixtureRequestType>()

const ClubFixtureRequest = memo(() => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [commandValue, setCommandValue] = useState('')
  const [requestID, setRequestId] = useState<number>(0)
  const {list, total, from} = useSelector(({clubFixtureRequest}) => ({
    list: clubFixtureRequest.clubFixtureRequestList,
    total: clubFixtureRequest.total,
    from: clubFixtureRequest.from,
  }))
  const defaultOptions = useMemo(
    () => ({
      sort_by: 'created_at',
      sort_order: 'desc',
      page: 1,
      per_page: defaultPageSize,
    }),
    [],
  )
  const [options, setOptions] = useState(defaultOptions)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getClubFixtureRequestList(options))
  }, [options])
  const columns = useMemo(
    () => [
      table.createDataColumn('id', {
        size: 150,
        header: ' ',
        cell: ({row}) => (
          <div className="flex-1-center-v">
            <span className="f-Exo-Bold f-0-3 fn-30">
              {String(new Date(row.original?.match_start_time || new Date()).getDate()).padStart(
                2,
                '0',
              )}
            </span>
            <div className="flex-1 ml-1 flex-d-column fn-12">
              <span className="f-Exo-Bold ">
                {format(new Date(row.original?.match_start_time || new Date()), 'MMM')}
              </span>
              <span>
                {format(new Date(row.original?.match_start_time || new Date()), 'EEEEEE')}
              </span>
            </div>
          </div>
        ),
      }),
      table.createDataColumn('club', {
        size: 185,
        header: ' ',
        cell: ({row}) => (
          <div className="flex-1 h-space-bet">
            <div className="club-name-outer">{row.original?.club?.club_name}</div>
            <div className="flex-1 h-flex-end">
              <img
                src={row.original?.club?.medias_model[0]?.original_url || images.dummyUser}
                alt=""
                className="img_preview-match-content"
              />
              {/* TODO: Club logo is not coming from API */}
            </div>
          </div>
        ),
      }),
      table.createDataColumn('id', {
        header: ' ',
        size: 100,
        cell: () => <div className="flex-1 h-center">VS</div>,
      }),
      table.createDataColumn('opponent_club', {
        header: ' ',
        size: 185,
        cell: ({row}) => (
          <div className="flex-1 h-space-bet">
            <div className="flex mr-4 h-flex-start">
              <img
                src={row.original?.opponent_club?.medias_model[0]?.original_url || images.dummyUser}
                alt=""
                className="img_preview-match-content"
              />
              {/* TODO: Club logo is not coming from API */}
            </div>
            <div className="club-name-outer f-1">{row.original?.opponent_club?.club_name}</div>
          </div>
        ),
      }),
      table.createDataColumn('match_vanue', {
        header: ' ',
        size: 300,
        cell: ({row}) => (
          <div className="flex flex-d-column fn-12">
            <span>{row.original?.match_vanue}</span>
            <div className="">
              <span>{format(new Date(row.original?.match_start_time || new Date()), 'hh:mm')}</span>
              {/* TODO: End date is not coming from API */}
            </div>
          </div>
        ),
      }),
      table.createDataColumn('id', {
        header: '',
        cell: ({row}) =>
          row.original && (
            <ListRight
              row={row.original}
              onClick={onUpdatePress}
              buttonList={[ApproveButtonList, DenyButtonList]}
            />
          ),
      }),
    ],
    [from],
  )

  const onSortChange = useCallback(
    (option: string) => {
      if (option === 'created_at-desc') {
        setOptions({...options, sort_by: 'created_at', sort_order: 'desc', page: 1})
      } else if (option === 'created_at-asc') {
        setOptions({...options, sort_by: 'created_at', sort_order: 'asc', page: 1})
      }
    },
    [options],
  )

  const onUpdatePress = async ({id, command}: UpdateListType) => {
    if (command === 'deny' || command === 'approve') {
      setCommandValue(command)
      setRequestId(id)
      setIsOpenPopup(true)
    } else if (command === 'denyConfirmed') {
      setLoading(true)
      await dispatch(updateClubFixtureStatus({requested_status: 3, club_match_fixture_id: id}))
      await dispatch(getClubFixtureRequestList(options))
      setIsOpenPopup(false)
      setLoading(false)
    } else if (command === 'approveConfirmed') {
      setLoading(true)
      await dispatch(updateClubFixtureStatus({requested_status: 2, club_match_fixture_id: id}))
      await dispatch(getClubFixtureRequestList(options))
      setIsOpenPopup(false)
      setLoading(false)
    }
  }

  const onPaginate = useCallback(
    (page: number) => {
      setOptions({...options, page})
    },
    [options],
  )

  const onLimit = useCallback(
    (per_page: number) => {
      setOptions({...options, per_page, page: 1})
    },
    [options],
  )

  return (
    <div className="table-outer">
      <div className="table-header">
        <div className="c-carousal__header">
          <div className="c-carousal__header__lpart v-center ">
            <span className="f-Exo-Bold title-color">{t('matchFixtureRequestList')}</span>
          </div>
          <Popup
            isOpen={isOpenPopup}
            setIsOpen={setIsOpenPopup}
            onPressDelete={
              () =>
                onUpdatePress({
                  id: requestID,
                  command: `${commandValue === 'deny' ? 'denyConfirmed' : 'approveConfirmed'}`,
                })
              // eslint-disable-next-line react/jsx-curly-newline
            }
            loading={loading}
            btn2={commandValue === 'deny' ? 'deny' : 'approve'}
            title={commandValue === 'deny' ? t('deny') : t('approve')}
            description={commandValue === 'deny' ? t('denyText') : t('approveText')}
          />
          <div className="c-carousal__header__rpart flex-center">
            <div>
              <Sort optionList={userOptionList} onSortChange={onSortChange} classAddon="p-2" />
            </div>
          </div>
        </div>
      </div>
      <ListView
        columns={columns}
        data={list}
        total={total}
        onPaginate={onPaginate}
        forcePageIndex={options.page - 1}
        options={options}
        isPageSize
        onLimit={onLimit}
        pageSize={options?.per_page}
      />
    </div>
  )
})

export default ClubFixtureRequest
