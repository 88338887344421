/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {memo, useState, useCallback, useEffect} from 'react'
import {Formik, Form} from 'formik'
import {useParams, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import {Button, Input, Popup, LeagueSearch, Label, DragFile} from '../../component'
import {mediasModel} from '../../data'
import {setFieldValueType, UpdateListType} from '../../component/type'
import {EventType} from '../contentManagement/type'
import {mapFilesToObject, serializeFormData} from '../../functions/utils'
import {MediaType} from '../news/type'
import {RootState} from '../../store/slices'
import {useAppDispatch, useSelector} from '../../store'
import {
  addClub,
  deleteClub,
  updateClub,
  clearClubDetail,
  getLeagueDetail,
  clearLeagueDetail,
} from '../../store/actions'
import {LeagueType} from '../../store/actions/types'

const ClubUpdate = () => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [selectedLeague, setSelectedLeague] = useState<LeagueType>()
  const navigation = useNavigate()
  const dispatch = useAppDispatch()
  const {clubDetail, leagueDetails} = useSelector(({club, league}: RootState) => ({
    clubDetail: club.clubDetail,
    leagueDetails: league.leagueDetail,
  }))

  const defaultOptions = {
    request_status: 0,
    page: 1,
  }
  const params = useParams()
  const CurrentId = (params?.id && parseInt(params.id, 10) && clubDetail?.id) || undefined
  if (!CurrentId) {
    dispatch(clearClubDetail())
    dispatch(clearLeagueDetail())
  }

  useEffect(() => {
    if (clubDetail?.league_id) {
      dispatch(getLeagueDetail(clubDetail?.league_id))
    }
  }, [])

  const initialValues = {
    club_name: clubDetail?.club_name || '',
    league: clubDetail?.league_id || (selectedLeague && selectedLeague.id) || '',
    club_logo:
      (clubDetail?.medias_model &&
        clubDetail.medias_model?.length > 0 &&
        clubDetail.medias_model[0]) ||
      mediasModel,
  }

  const onSubmit = async (values: any) => {
    setLoading(true)
    const formValues = {
      club_id: clubDetail.id || undefined,
      league_id: values.league || selectedLeague?.id || '',
      club_name: values.club_name || undefined,
      club_logo: values.club_logo.file ? values.club_logo.file : undefined,
    }
    const formData = serializeFormData(formValues)
    if (clubDetail.id === 0) {
      const response = await dispatch(addClub(formData))
      if (response) navigation(`/club-management`)
    } else {
      const response = await dispatch(updateClub(clubDetail.id || 0, formData))
      if (response) navigation(`/club-management`)
    }
    setLoading(false)
  }

  const validationSchema = Yup.object().shape({
    club_name: Yup.string().trim().required('Club Name cannot be empty.'),
    league: Yup.string().trim().required('League cannot be empty.'),
  })

  const onClickListAction = useCallback(async ({id, command}: UpdateListType) => {
    if (command === 'edit') {
      navigation(`/club-management/${id}`)
    } else if (command === 'remove') {
      setIsOpenPopup(true)
    } else if (command === 'deleteConfirmed') {
      setLoading(true)
      await dispatch(deleteClub(id, defaultOptions))
      setIsOpenPopup(false)
      setLoading(false)
      dispatch(clearClubDetail())
      dispatch(clearLeagueDetail())
      navigation(`/club-management`)
    }
  }, [])

  const onFilesSelection = useCallback(
    (acceptedFiles: MediaType[], setFieldValue: setFieldValueType) => {
      const newFiles = mapFilesToObject(acceptedFiles)
      if (newFiles && newFiles.length > 0) {
        setFieldValue('club_logo', newFiles[0])
      }
    },
    [],
  )

  const handleLogoChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: setFieldValueType,
  ) => {
    if (e.target && e.target.files && e.target.files?.length > 0) {
      const newFiles = mapFilesToObject([e.target.files[0]])
      if (newFiles && newFiles.length > 0) {
        setFieldValue('club_logo', newFiles[0])
      }
    }
  }

  const handleleagueChange = (selectedleague: LeagueType, setFieldValue: any) => {
    setSelectedLeague(selectedleague)
    setFieldValue('league', selectedleague.id || '')
  }
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({touched, errors, setFieldValue, values, submitForm}) => (
        <Form className="h-100">
          <div className="table-outer">
            <div className="table-header">
              <div className="c-carousal__header no-border">
                <div className="c-carousal__header__lpart v-center ">
                  <span className="font-b">{t('clubDetails')}</span>
                </div>
                <Popup
                  isOpen={isOpenPopup}
                  setIsOpen={setIsOpenPopup}
                  onPressDelete={
                    () => onClickListAction({id: clubDetail.id || 0, command: 'deleteConfirmed'})
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                  loading={loading}
                  title={t('delete')}
                  description={t('deleteText')}
                />
                <div className="c-carousal__header__rpart flex-center">
                  <Button
                    classAddon="f-Exo-Bold mr-2"
                    title={t('save')}
                    icon={loading ? 'c-icon--loader mr-2' : ''}
                    style={{padding: '0.5rem 1.5rem'}}
                    onClick={submitForm}
                  />
                  <div>
                    <Button
                      classAddon=" c-button--br c-button--br--gray-ab bg-transparent f-Exo-Bold"
                      title={t('cancel')}
                      style={{padding: '0.5rem 1.5rem'}}
                      onClick={() => {
                        dispatch(clearClubDetail())
                        dispatch(clearLeagueDetail())
                        navigation(`/club-management`)
                      }}
                    />
                  </div>
                  {CurrentId && (
                    <div className="ml-3 mb-0">
                      <div className="c-pointer flex-center" onClick={() => setIsOpenPopup(true)}>
                        <span className="icon-edit-text mr-2">{t('deleteClub')}</span>{' '}
                        <i className="c-icon c-icon--delete" />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-2 mb-4 rolefrom-inner">
                <div className="flex-1 flex-d-row">
                  <div className="mt-4 f-0-25">
                    <div className="flex-1 flex-d-column h-100">
                      <div className="mr-3 flex-1">
                        <div className="flex-1-center add-club-outer">
                          <DragFile
                            accept={{'image/*': []}}
                            maxFiles={1}
                            placeholder={t('dragAnImageFileHere')}
                            btn1Title={t('chooseAFileToUpload')}
                            files={[values.club_logo]}
                            isThumbnail={false}
                            classAddonInner="bg-null border-null h-100"
                            classAddonOuter="m-0 f-1 h-100"
                            error={touched.club_logo && (errors.club_logo as string | boolean)}
                            onChange={(acceptedFiles: MediaType[]) => {
                              onFilesSelection(acceptedFiles, setFieldValue)
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex flex-d-column f-0-6">
                        <label htmlFor="formId" className="mt-3 mb-4 f-0-5">
                          <input
                            name=""
                            type="file"
                            id="formId"
                            hidden
                            multiple={false}
                            accept="image/png, image/jpeg"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              handleLogoChange(e, setFieldValue)
                            }
                          />
                          <span className="c-pointer">
                            <span className="icon-edit-text mr-3">
                              {values.club_logo.file_name || t('chooseFileToUpload')}
                            </span>{' '}
                            <i className="c-icon c-icon--edit-red" />
                          </span>
                        </label>
                        {/* <span className="flex mb-2 add-club-time-outer">
                          last login in&nbsp;
                          <span className="f-Exo-Bold"> 2 hours ago</span>
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="flex mt-4 f-0-7">
                    <div className="f-0-5">
                      <Input
                        name="club_name"
                        label={t('fullName')}
                        placeholder={t('pleaseEnterFullClubName')}
                        error={touched.club_name && errors.club_name}
                        onChange={(e: EventType) => setFieldValue('club_name', e.target.value)}
                        className="form-control mt-1 f-Exo-Bold"
                        classNameLable="fs-15"
                      />
                      <Label label={t('league')} />
                      <LeagueSearch
                        handleChange={handleleagueChange}
                        setFieldValue={setFieldValue}
                        defaultValue={leagueDetails.title}
                        error={touched.league && errors.league}
                        classNameOuter="mt-2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default memo(ClubUpdate)
