import React, {memo} from 'react'
import ReactPlayer from 'react-player'
import {DragBackPreviewType} from './type'
import images from '../assets/images'

const DragBackPreview = ({files, classAddonOuter, file_type}: DragBackPreviewType) => {
  return (
    <div className={`c-form-group ${classAddonOuter}`}>
      <div
        className="c-upload-media c-upload-media--hi-auto h-100"
        style={{
          backgroundImage: `url(${files[0]?.original_url})`,
          backgroundPosition: 'center',
        }}
      >
        <ReactPlayer
          playing
          url={files[0]?.original_url}
          height="100%"
          width="99.8%"
          style={{position: 'absolute', zIndex: 1}}
          className="video-preview-border-r"
        />
        {file_type ? (
          <div className="cover-image-top-layer postion-bottom-right br-10 z-index-1">
            <img src={images.video} alt="play-button" className="cover-image-top-layer-img-ml" />
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  )
}
export default memo(DragBackPreview)
