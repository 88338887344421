/* eslint-disable react/jsx-curly-newline */
import React, {useState, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import Modal from 'react-modal'
import {Button} from '../../component'
import {MatchLiveCommentaryType} from './type'
import {customStyles} from '../../data'
import MatchAddEvent from './matchAddEvent'
import LiveCommentaryBox from './common/LiveCommentaryBox'

const MatchLiveCommentary = ({item, id, loadMore}: MatchLiveCommentaryType) => {
  const [isEdit, setIsEdit] = useState(false)
  const {t} = useTranslation()
  const handleEditState = () => {
    setIsEdit(!isEdit)
  }
  return (
    <div className="table-outer f-1">
      <div className="table-header">
        <div className="c-carousal__header no-border-b">
          <div className="c-carousal__header__lpart v-center">
            <span className="f-Exo-Bold title-color">{t('liveCommentary').toUpperCase()}</span>
          </div>
          <div className="c-carousal__header__rpart flex-center">
            <Button
              title={t('addAnEvent')}
              type="submit"
              classAddon=" c-button--icons f-Exo-Bold mr-2"
              onClick={handleEditState}
            />
          </div>
        </div>
      </div>
      <div className="flex-1 flex-d-column bg-gray">
        <Modal
          isOpen={isEdit}
          style={useMemo(() => customStyles, [customStyles])}
          onRequestClose={() => setIsEdit(false)}
        >
          <MatchAddEvent setIsEdit={setIsEdit} id={id} />
        </Modal>
        <div
          className="flex-1 flex-d-column pl-4 pr-4 pb-3 pt-3 max-h-600"
          style={{overflow: 'auto'}}
        >
          <LiveCommentaryBox item={item} loadMore={loadMore} />
        </div>
      </div>
    </div>
  )
}
export default MatchLiveCommentary
