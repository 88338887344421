import React, {memo, useState} from 'react'
import {Formik, Form} from 'formik'
import {useTranslation} from 'react-i18next'
import {Button} from '../../../component'
import {LinkUpdateType} from '../../../component/type'
import LinkForm from './linkForm'
import {addSingleSetting, updateSingleSetting} from '../../../store/actions/setting'

const LinkUpdate = ({metaKey, lable, placeholder}: LinkUpdateType) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const onSubmit = async (values: any) => {
    setLoading(true)
    if (values.id) {
      await updateSingleSetting(values)
    } else {
      await addSingleSetting(values)
    }
    setLoading(false)
  }
  return (
    <Formik initialValues={{meta_value: '', meta_key: metaKey}} onSubmit={onSubmit}>
      {({errors, touched, setFieldValue, submitForm, values, setValues}) => (
        <Form>
          <div className="c-card-training h-center">
            <div className="flex f-0-3">
              <LinkForm
                lable={lable}
                placeholder={placeholder}
                touched={touched}
                errors={errors}
                values={values}
                setFieldValue={setFieldValue}
                setValues={setValues}
                setLoading={setLoading}
              />
              <div className="inline-btn-input">
                <Button
                  title={t('update')}
                  icon={loading ? 'c-icon--loader mr-2' : ''}
                  onClick={submitForm}
                  classAddon="f-Exo-Bold"
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default memo(LinkUpdate)
