import React, {memo} from 'react'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts'
import {ValueType, NameType} from 'recharts/src/component/DefaultTooltipContent'
import {dailyRevenueChartData} from '../../data'

const RevenueBody = memo(() => {
  const barColors = ['#4E5D67', '#CF0B48']

  const CustomTooltip = ({active, payload}: TooltipProps<ValueType, NameType>) => {
    if (active) {
      return (
        <div className="tooltip-box">
          <span style={{fontSize: '13px'}} className="f-Exo-Bold">
            {`£${payload?.[0].payload?.amt}`}
          </span>
          <span style={{fontSize: '13px'}}>Total</span>
        </div>
      )
    }
    return null
  }

  return (
    <div className="c-card__body flex-1 h-center">
      <ResponsiveContainer width="97%" className="h-center" aspect={3.0 / 1.0}>
        <BarChart
          data={dailyRevenueChartData}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" axisLine={false} tickLine={false} fontSize={12} />
          <YAxis
            axisLine={false}
            tickLine={false}
            className="y-axis-chart"
            type="number"
            padding={{top: 50}}
            fontSize={10}
            width={1}
          />
          <CartesianGrid strokeDasharray="3 0" vertical={false} />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <Bar dataKey="pv" fill="#4E5D67" barSize={25} radius={[3, 3, 3, 3]}>
            {dailyRevenueChartData.map((entry) => (
              <Cell
                key={`cell-${entry}`}
                fill={
                  barColors[
                    entry.pv === Math.max(...dailyRevenueChartData.map((o) => o.pv)) ? 1 : 0
                  ]
                }
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
})

export default RevenueBody
