import React, {memo} from 'react'
import {totalCLub} from '../../data'
import DailyRevenue from './dailyRevenue'
import TopEarningClub from './topEarningClub'
import TotalClub from './totalClub'

const MasterDashboard = memo(() => {
  return (
    <div className="flex-1 flex-d-row">
      <div className="flex f-0-7 flex-d-column">
        <DailyRevenue />
        <TopEarningClub />
      </div>
      <div className="flex f-0-3">
        <TotalClub item={totalCLub} />
      </div>
    </div>
  )
})

export default MasterDashboard
