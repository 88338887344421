import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {PermissionItem} from '../actions/types'

export interface PermissionState {
  permissions: PermissionItem[]
  total: number
  from: number
}

const initialState = {permissions: [], total: 0, from: 0} as PermissionState

export const permissionSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    updatePermissionsList: (state, action: PayloadAction<PermissionState>) => {
      state.permissions = action.payload.permissions
      state.total = action.payload.total
      state.from = action.payload.from
    },
  },
})
