import images from '../assets/images'
import {OptionListType} from '../component/type'
import {PreviewDataType, socialMediaType} from '../container/news/type'

const getSidebar = (role: string) => [
  {
    id: '1',
    title: `${role === 'master_user' ? 'masterDashboard' : ''}`,
    icon: 'c-icon--master-dashboard',
    link: '/',
    roles: ['master_user'],
    subList: [],
  },
  {
    id: '2',
    title: 'leagueManagement',
    icon: 'c-icon--league-management',
    link: '/league-management',
    roles: ['master_user'],
    subList: [],
  },
  {
    id: '3',
    title: 'clubManagement',
    icon: 'c-icon--club-management',
    link: '/club-management',
    roles: ['master_user'],
    subList: [],
  },
  {
    id: '4',
    title: 'clubAdminRequests',
    icon: 'c-icon--club-admin-request',
    link: '/club-admin-request',
    roles: ['master_user'],
    subList: [],
  },
  {
    id: '5',
    title: 'userManagement',
    icon: 'c-icon--user-managment',
    link: '/user-management',
    roles: ['master_user'],
    subList: [],
  },
  {
    id: '6',
    title: 'contentManagement',
    icon: 'c-icon--content-management',
    link: '/content-management',
    roles: ['master_user'],
    subList: [],
  },
  {
    id: '7',
    title: 'matchFixture',
    icon: 'c-icon--match-fixture',
    link: '/match-fixture',
    roles: ['master_user'],
    subList: [],
  },
  {
    id: '8',
    title: 'content',
    icon: 'c-icon--content',
    link: '/content',
    roles: ['club_user'],
    subList: [
      // {
      //   id: 'sub1',
      //   title: 'briefing',
      //   icon: 'c-icon--briefing',
      //   link: '/content/briefing',
      // },
      {
        id: 'sub2',
        title: 'newsStories',
        icon: 'c-icon--newspaper',
        link: '/content/news-stories',
      },
      {
        id: 'sub3',
        title: 'matchContent',
        icon: 'c-icon--whistle',
        link: '/content/match-content',
      },
      // {
      //   id: 'sub4',
      //   title: 'events',
      //   icon: 'c-icon--calendar',
      //   link: '/content/events',
      // },
      {
        id: 'sub5',
        title: 'mediaLibrary',
        icon: 'c-icon--image-white',
        link: '/content/media-library',
      },
      // {
      //   id: 'sub6',
      //   title: 'documents',
      //   icon: 'c-icon--document',
      //   link: '/content/docments',
      // },
    ],
  },
  // {
  //   id: '6',
  //   title: 'teamManagement',
  //   icon: 'c-icon--team-managment',
  //   link: '/team-management',
  //   roles: ['club_user', 'master_user'],
  //   subList: [
  //     {
  //       id: 'subteam1',
  //       title: 'briefing',
  //       icon: 'c-icon--image-whit',
  //       link: '/team-management/briefing',
  //     },
  //     {
  //       id: 'subteam2',
  //       title: 'fixturesAndResults',
  //       icon: 'c-icon--image-whit',
  //       link: '/team-management/fixtures-results',
  //     },
  //     {
  //       id: 'subteam3',
  //       title: 'teamSelection',
  //       icon: 'c-icon--image-whit',
  //       link: '/team-management/team-selection',
  //     },
  //     {
  //       id: 'subteam4',
  //       title: 'teamAndStaff',
  //       icon: 'c-icon--image-whit',
  //       link: '/team-management/team-staff',
  //     },
  //     {
  //       id: 'subteam5',
  //       title: 'leagueTable',
  //       icon: 'c-icon--image-whit',
  //       link: '/team-management/league-table',
  //     },
  //     {
  //       id: 'subteam6',
  //       title: 'competitionsAndCups',
  //       icon: 'c-icon--image-whit',
  //       link: '/team-management/competitions-cups',
  //     },
  //     {
  //       id: 'subteam7',
  //       title: 'training',
  //       icon: 'c-icon--image-whit',
  //       link: '/team-management/training',
  //     },
  //     {
  //       id: 'subteam8',
  //       title: 'messages',
  //       icon: 'c-icon--image-whit',
  //       link: '/team-management/messages',
  //     },
  //   ],
  // },
  // {
  //   id: '7',
  //   title: 'contentAcademy',
  //   icon: 'c-icon--cap',
  //   link: '/content-academy',
  //   roles: ['club_user', 'master_user'],
  //   subList: [],
  // },
  // {
  //   id: '8',
  //   title: 'websiteManagement',
  //   icon: 'c-icon--website-managment',
  //   link: '/website-management',
  //   roles: ['club_user', 'master_user'],
  //   subList: [],
  // },
  {
    id: '9',
    title: 'clubSettings',
    icon: 'c-icon--club-setting',
    link: '/club-settings',
    roles: ['club_user'],
    subList: [
      {
        id: 'sub1',
        title: 'socialMediaAccounts',
        icon: 'c-icon--share',
        link: '/club-settings/social-account',
      },
    ],
  },
  // {
  //   id: '10',
  //   title: 'teamSettings',
  //   icon: 'c-icon--team-setting',
  //   link: '/team-settings',
  //   roles: ['club_user', 'master_user'],
  //   subList: [],
  // },
  {
    id: '11',
    title: 'accountSettings',
    icon: 'c-icon--users',
    link: '/account-settings',
    roles: ['club_user', 'master_user'],
    subList: [],
  },
  {
    id: '12',
    title: 'playerManagement',
    icon: 'c-icon--user-managment',
    link: '/player-management',
    roles: ['club_user'],
    subList: [],
  },
  {
    id: '13',
    title: 'moderation',
    icon: 'c-icon--moderation',
    link: '/moderation',
    roles: ['master_user'],
    subList: [],
  },
  // {
  //   id: '14',
  //   title: 'security',
  //   icon: 'c-icon--club-setting',
  //   link: '/security',
  //   roles: ['master_user'],
  //   subList: [
  //     {
  //       id: 'sub1',
  //       title: 'permissions',
  //       icon: 'c-icon--share',
  //       link: '/security/permissions',
  //     },
  //     {
  //       id: 'sub2',
  //       title: 'roles',
  //       icon: 'c-icon--share',
  //       link: '/security/roles',
  //     },
  //   ],
  // },
  {
    id: '15',
    title: 'adsManagement',
    icon: 'c-icon--club-management',
    link: '/ads-management',
    roles: ['master_user'],
    subList: [],
  },
  {
    id: '7',
    title: 'matchFixtureRequest',
    icon: 'c-icon--match-fixture',
    link: '/match-fixture',
    roles: ['club_user'],
    subList: [],
  },
]

const headerData = {
  gdxLogoMaster: images.headerLogoMaster,
  gdxLogo: images.headerLogo,
  mailCount: 5,
  notificationCount: 2,
  profile: {
    url: images.profile,
    dropDownList: [
      {id: '1', icon: '', url: 'https://devdemo.gdxsports.com/login', title: 'Logout'},
    ],
  },
}

const socialMedia: socialMediaType[] = [
  {
    icon: '--facebook-gray',
    name: 'Facebook',
  },
  {
    icon: '--twitter-gray',
    name: 'Twitter',
  },
  {
    icon: '--mail-gray',
    name: 'Mail',
  },
]

const articlePreview: PreviewDataType = {
  created_at: '',
  news_slug: '',
  news_type: 0,
  medias_model: [],
  news_title: '',
  news_description: [{type: 'paragraph', children: [{text: ''}]}],
  attach_media_id: [],
}
const galleryPreview: PreviewDataType = {
  created_at: '',
  news_slug: '',
  news_type: 1,
  medias_model: [],
  news_title: '',
  news_description: [{type: 'paragraph', children: [{text: ''}]}],
  attach_media_id: [],
}
const videoPreview: PreviewDataType = {
  created_at: '',
  news_slug: '',
  news_type: 2,
  medias_model: [],
  news_title: '',
  news_description: [{type: 'paragraph', children: [{text: ''}]}],
  attach_media_id: [],
}
const tabList = [
  {
    title: 'article',
    news_type: 0,
    accept: {'image/*': []},
    maxFiles: 1,
    label: 'storyImage',
    placeholder: 'dragAnImageFileHere',
    btn1Title: 'chooseAFileToUpload',
    btn2Title: 'addFromMedialibrary',
    inputLabel: 'headline',
    editorLabel: 'article',
    featuredTitle: 'featuredStory',
  },
  {
    title: 'gallery',
    news_type: 1,
    accept: {'image/*': []},
    maxFiles: 5,
    label: 'galleryImages',
    placeholder: 'dragImageFilesHere',
    btn1Title: 'chooseFilesToUpload',
    btn2Title: 'addFromMedialibrary',
    inputLabel: 'galleryHeadline',
    editorLabel: 'description',
    featuredTitle: 'featuredStory',
  },
  {
    title: 'video',
    news_type: 2,
    accept: {'video/*': []},
    maxFiles: 1,
    label: 'video',
    placeholder: 'dragAVideoFileHere',
    btn1Title: 'chooseAFileToUpload',
    btn2Title: 'addFromMedialibrary',
    inputLabel: 'videoTitle',
    editorLabel: 'description',
    featuredTitle: 'featuredStory',
  },
]
const socialAccounts = [
  {
    id: '1',
    title: 'FACEBOOK PAGE',
    icon: '--facebook-color',
    description: 'Club account',
    publishOnSite: true,
    autoPostVideo: false,
    buttonTitle: 'Add Page',
    buttonIcon: '--plus',
    buttonTitle2: 'DeLink Page',
  },
  {
    id: '2',
    title: 'TWITTER PAGE',
    icon: '--twitter-color',
    description: 'Club account',
    publishOnSite: false,
    autoPostVideo: true,
    buttonTitle: 'Add Page',
    buttonIcon: '--plus',
    buttonTitle2: 'DeLink Page',
  },
  {
    id: '3',
    title: 'INSTAGRAM PAGE',
    icon: '--instagram-color',
    description: 'Club account',
    publishOnSite: true,
    autoPostVideo: false,
    buttonTitle: 'Add Page',
    buttonIcon: '--plus',
    buttonTitle2: 'DeLink Page',
  },
]

const customStyles = {
  content: {
    minWidth: '30%',
    maxWidth: '350px',
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 10,
  },
}
const calendarModalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    margin: '0px',
    padding: '0px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
}

const customStylesPlayer = {
  content: {
    minWidth: '55%',
    maxWidth: '550px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    overflow: 'auto',
    padding: '40px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
}

const carousal = [
  {
    id: 0,
    url: 'https://cdn.wallpapersafari.com/71/5/8ptAya.jpg',
    buttonList: [
      {
        title: 'Update',
        command: 'Update',
        classAddon: 'c-button--small c-button--br c-button--br--gray-ab bg-transparent mr-2',
      },
      {
        title: 'Remove',
        command: 'Remove',
        classAddon: 'c-button--small',
      },
    ],
  },
  {
    id: 1,
    url: 'https://cdn.wallpapersafari.com/71/5/8ptAya.jpg',
    buttonList: [
      {
        title: 'Update',
        command: 'Update',
        classAddon: 'c-button--small c-button--br c-button--br--gray-ab bg-transparent mr-2',
      },
      {
        title: 'Remove',
        command: 'Remove',
        classAddon: 'c-button--small',
      },
    ],
  },
  {
    id: 2,
    url: 'https://cdn.wallpapersafari.com/71/5/8ptAya.jpg',
    buttonList: [
      {
        title: 'Update',
        command: 'Update',
        classAddon: 'c-button--small c-button--br c-button--br--gray-ab bg-transparent mr-2',
      },
      {
        title: 'Remove',
        command: 'Remove',
        classAddon: 'c-button--small',
      },
    ],
  },
  {
    id: 3,
    url: 'https://cdn.wallpapersafari.com/71/5/8ptAya.jpg',
    buttonList: [
      {
        title: 'Update',
        command: 'Update',
        classAddon: 'c-button--small c-button--br c-button--br--gray-ab bg-transparent mr-2',
      },
      {
        title: 'Remove',
        command: 'Remove',
        classAddon: 'c-button--small',
      },
    ],
  },
]

const contentTabList = [
  {
    title: 'carousal',
    news_type: 0,
  },
  {
    title: 'benefitsOfGDX',
    news_type: 1,
  },
  {
    title: 'appStoreLinks',
    news_type: 2,
  },
  {
    title: 'socialMediaLinks',
    news_type: 3,
  },
  {
    title: 'testimonials',
    news_type: 4,
  },
  {
    title: 'marketingVideo',
    news_type: 5,
  },
  {
    title: 'faqs',
    news_type: 6,
  },
]

const faqs = [
  {
    id: 9,
    title: 'How much will my club earn?',
    description:
      'The amount your club earns depends on the quality and quantity of your content multiplied by the size of your fanbase. You need a few thousand fans to generate reasonable sums. But remember, in a small kids team there are lots of interested mums, dads, grandparents, aunties and uncles and friends - so you quickly get your audience big enough to generate good revenues.',
    created_at: '2022-06-09 06:42:21',
  },
  {
    id: 10,
    title: 'How much will my club earn?',
    description:
      'The amount your club earns depends on the quality and quantity of your content multiplied by the size of your fanbase. You need a few thousand fans to generate reasonable sums. But remember, in a small kids team there are lots of interested mums, dads, grandparents, aunties and uncles and friends - so you quickly get your audience big enough to generate good revenues.',
    created_at: '2022-06-09 06:42:21',
  },
  {
    id: 11,
    title: 'How much will my club earn?',
    description:
      'The amount your club earns depends on the quality and quantity of your content multiplied by the size of your fanbase. You need a few thousand fans to generate reasonable sums. But remember, in a small kids team there are lots of interested mums, dads, grandparents, aunties and uncles and friends - so you quickly get your audience big enough to generate good revenues.',
    created_at: '2022-06-09 06:42:21',
  },
  {
    id: 12,
    title: 'How much will my club earn?',
    description:
      'The amount your club earns depends on the quality and quantity of your content multiplied by the size of your fanbase. You need a few thousand fans to generate reasonable sums. But remember, in a small kids team there are lots of interested mums, dads, grandparents, aunties and uncles and friends - so you quickly get your audience big enough to generate good revenues.',
    created_at: '2022-06-09 06:42:21',
  },
  {
    id: 13,
    title: 'How much will my club earn?',
    description:
      'The amount your club earns depends on the quality and quantity of your content multiplied by the size of your fanbase. You need a few thousand fans to generate reasonable sums. But remember, in a small kids team there are lots of interested mums, dads, grandparents, aunties and uncles and friends - so you quickly get your audience big enough to generate good revenues.',
    created_at: '2022-06-09 06:42:21',
  },
]

const testimonialsList = [
  {
    id: 4,
    name: 'Adam Burnicle',
    position: 'Player, West Auckland AFC',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.',
    created_at: '2022-06-09 06:36:41',
    image: {
      id: 43,
      file_name: 'user-1.png',
      original_url: 'https://tsdev.gdxsports.com/storage/43/user-1.png',
    },
  },
  {
    id: 3,
    name: 'Danny Parker',
    position: 'Player, West Auckland AFC',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.',
    created_at: '2022-06-09 06:36:26',
    image: {
      id: 42,
      file_name: 'user-3.png',
      original_url: 'https://tsdev.gdxsports.com/storage/42/user-3.png',
    },
  },
  {
    id: 2,
    name: 'Michael Roberts',
    position: 'Player, Stockton Town FC',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.',
    created_at: '2022-06-09 06:36:03',
    image: {
      id: 41,
      file_name: 'user-2.png',
      original_url: 'https://tsdev.gdxsports.com/storage/41/user-2.png',
    },
  },
  {
    id: 1,
    name: 'Adam Burnicle',
    position: 'Player, West Auckland AFC',
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.',
    created_at: '2022-06-09 06:35:41',
    image: {
      id: 40,
      file_name: 'user-1.png',
      original_url: 'https://tsdev.gdxsports.com/storage/40/user-1.png',
    },
  },
]

const benefitsList = [
  {
    id: 10,
    title: 'Generate revenue from your content',
    description:
      "Create content, post it to GDX, we place the advertising and share revenue with you. It's that simple.",
    created_at: '2022-06-14 05:16:33',
    icon: {
      id: 69,
      file_name: 'banner-2.jpg',
      original_url: 'https://tsdev.gdxsports.com/storage/69/banner-2.jpg',
    },
  },
  {
    id: 9,
    title: 'Generate revenue from your content',
    description:
      "Create content, post it to GDX, we place the advertising and share revenue with you. It's that simple.",
    created_at: '2022-06-14 05:15:46',
    icon: {
      id: 68,
      file_name: 'user-2.png',
      original_url: 'https://tsdev.gdxsports.com/storage/68/user-2.png',
    },
  },
  {
    id: 8,
    title: 'Generate revenue from your content',
    description:
      'Create content, post it to GDX, we place advertising and share the revenue with you. It’s that simple.',
    created_at: '2022-06-09 06:30:28',
    icon: {
      id: 39,
      file_name: 'icon1.png',
      original_url: 'https://tsdev.gdxsports.com/storage/39/icon1.png',
    },
  },
  {
    id: 7,
    title: 'Manage your league & clubs',
    description:
      'Schedules, fixtures, tables team selection, player availability and team messaging - all includes absolutely free.',
    created_at: '2022-06-09 06:30:06',
    icon: {
      id: 38,
      file_name: 'icon2.png',
      original_url: 'https://tsdev.gdxsports.com/storage/38/icon2.png',
    },
  },
  {
    id: 6,
    title: 'Manage all your content in one place',
    description:
      'No more posting to your website and social media separately. Each post can be distributed to all platforms at once, and use our amazing love cast app to provide immediate commentary to your fanbase.',
    created_at: '2022-06-09 06:29:47',
    icon: {
      id: 37,
      file_name: 'icon3.png',
      original_url: 'https://tsdev.gdxsports.com/storage/37/icon3.png',
    },
  },
  {
    id: 5,
    title: 'Learn how to make better content',
    description:
      'Our GDX Academy teaches you how to interview like a pro, capture and write content that gets fans buzzing, and share to earn revenue for your club.',
    created_at: '2022-06-09 06:29:28',
    icon: {
      id: 36,
      file_name: 'icon4.png',
      original_url: 'https://tsdev.gdxsports.com/storage/36/icon4.png',
    },
  },
  {
    id: 4,
    title: 'It’s completely free',
    description:
      'What’s the best thing about GDX? You can generate revenue with absolutely no cost. The platform, hosting, advise and skills - all absolutely free to clubs who sign up. No contracts - leave any time you want and all the content is yours - forever.',
    created_at: '2022-06-09 06:29:07',
    icon: {
      id: 35,
      file_name: 'icon5.png',
      original_url: 'https://tsdev.gdxsports.com/storage/35/icon5.png',
    },
  },
]

const storeLinks = [
  {
    lable: 'googlePlayStoreLinks',
    meta_key: 'PLAY_STORE',
    meta_value: 'https://play.google.com/store/apps/details?id=com.gdxsports.gdx',
  },
  {
    lable: 'appleStoreAppLinks',
    meta_key: 'APP_STORE',
    meta_value: 'https://itunes.apple.com/us/app/gdx-sports/id1409898984?mt=8',
  },
]

const socialMediaLink = [
  {
    lable: 'facebook',
    meta_key: 'facebook_link',
    meta_value: 'https://www.facebook.com/',
  },
  {
    lable: 'instagram',
    meta_key: 'instagram_link',
    meta_value: 'https://www.instagram.com/',
  },
  {
    lable: 'twitter',
    meta_key: 'twitter_link',
    meta_value: 'https://twitter.com/',
  },
  {
    lable: 'youtube',
    meta_key: 'youTube_link',
    meta_value: 'https://www.youtube.com/',
  },
]

const marketingVideo = [
  {
    lable: 'link',
    meta_key: 'marketing_video',
    meta_value: 'https://itunes.apple.com/us/app/gdx-sports/id1409898984?mt=8',
  },
]

const requestList = [
  {
    id: 1,
    email: 'deep@trentium.com',
    created_at: '23rd Feb 2022',
    club_name: 'Treantium Soltions',
  },
  {id: 2, email: 'deep@trentium.com', created_at: '23rd Feb 2022', club_name: 'Treantium Soltions'},
  {id: 3, email: 'deep@trentium.com', created_at: '23rd Feb 2022', club_name: 'Treantium Soltions'},
  {id: 4, email: 'deep@trentium.com', created_at: '23rd Feb 2022', club_name: 'Treantium Soltions'},
  {id: 5, email: 'deep@trentium.com', created_at: '23rd Feb 2022', club_name: 'Treantium Soltions'},
]
const optionList: OptionListType[] = [
  {
    id: 0,
    label: 'All',
    value: 'all',
  },
  {
    id: 1,
    label: 'Pending',
    value: 'pending',
  },
  {
    id: 2,
    label: 'Requested',
    value: 'requested',
  },
  {
    id: 3,
    label: 'Approved',
    value: 'approved',
  },
]
export const userOptionList: OptionListType[] = [
  {
    id: 0,
    label: 'Newest First',
    value: 'created_at-desc',
  },
  {
    id: 1,
    label: 'Oldest First',
    value: 'created_at-asc',
  },
]

export const valueOptionList: OptionListType[] = [
  {
    id: 0,
    label: 'Highest First',
    value: 'created_at-desc',
  },
  {
    id: 1,
    label: 'Lowest First',
    value: 'created_at-asc',
  },
]

const clubRequestStatus = ['Pending', 'Requested', 'Approved', 'Denied']

const clubList = [
  {
    id: 7,
    club_name: 'West Auckland AFC 3',
    mobile_number: null,
    mobile_country_id: null,
    user_id: null,
    country_id: null,
    request_status: 0,
    created_at: '2022-06-20 05:19:27',
    club_logo: {
      id: 120,
      file_name: 'user-2.png',
      original_url: 'https://tsdev.gdxsports.com/storage/120/user-2.png',
    },
    club_user: null,
    mobile_country: null,
    address_country: null,
  },
  {
    id: 6,
    club_name: 'West Auckland AFC 2',
    mobile_number: null,
    mobile_country_id: null,
    user_id: null,
    country_id: null,
    request_status: 0,
    created_at: '2022-06-20 05:19:22',
    club_logo: {
      id: 119,
      file_name: 'user-2.png',
      original_url: 'https://tsdev.gdxsports.com/storage/119/user-2.png',
    },
    club_user: null,
    mobile_country: null,
    address_country: null,
  },
  {
    id: 5,
    club_name: 'West Auckland AFC 1',
    mobile_number: null,
    mobile_country_id: null,
    user_id: null,
    country_id: null,
    request_status: 0,
    created_at: '2022-06-20 05:19:18',
    club_logo: {
      id: 118,
      file_name: 'user-2.png',
      original_url: 'https://tsdev.gdxsports.com/storage/118/user-2.png',
    },
    club_user: null,
    mobile_country: null,
    address_country: null,
  },
  {
    id: 4,
    club_name: 'West Auckland AFC',
    mobile_number: null,
    mobile_country_id: null,
    user_id: null,
    country_id: null,
    request_status: 0,
    created_at: '2022-06-20 04:44:27',
    club_logo: {
      id: 117,
      file_name: 'user-2.png',
      original_url: 'https://tsdev.gdxsports.com/storage/117/user-2.png',
    },
    club_user: null,
    mobile_country: null,
    address_country: null,
  },
]

const mediasModel = {
  medias_id: 0,
  original_url: '',
  file_name: '',
}

const mediaLibraryTabList = [
  {
    title: 'All media',
    command: 'all',
    news_type: undefined,
  },
  {
    title: 'Photos',
    command: 'only_image',
    icon: 'c-icon--photo',
    news_type: 0,
  },
  {
    title: 'Video',
    command: 'only_video',
    icon: 'c-icon--play',
    news_type: 1,
  },
]

const MatchType = ['Friendly', 'Orignal']

const newsStoryWidget = {
  info: {image: images.info, title: 'Its been {{3weeks}} since you last posted a news article.'},
  buttonList: {
    btn1Title: 'newStory',
    btn1icon: 'c-icon--plus',
    btn1Href: '/content/news-stories',
    btn2Title: 'learnAboutWritingGreatArticles',
    btn2Href: '/content/news-stories',
    stateDatabtn1: {news_type: 0, isPopup: true},
  },
}

const lastNewsWidget = {
  info: {
    image: images.play,
    title: 'Your last news story has generated <span class="--bold"> £48.47 </span> revenue.',
  },
  mediaInfo: {
    imageUrl: images.players,
    title: 'Purewal bags hat-trick as West hit four past Whickham',
    description: 'Whickham FC 1-4 West Auckland Town West Auckland… Town',
    date: '2 Apr 2019, 2:14pm',
  },
}

const matchAgainstWidget = {
  info: {
    image: '',
    title:
      'Upcoming match against {{BishopAucklandAFC}} is in <span class="--bold">{{2days}}.</span>',
  },
  buttonList: {
    btn2Title: 'viewFixture',
    btn2Href: '/content/match-content',
  },
}

const matchAgainstYesterdayWidget = {
  info: {
    image: '',
    title: 'The match against {{AshingtonAFC}} was <span class="--bold">{{yesterday}}.</span>',
  },
  buttonList: {
    btn1Title: 'addMatchcontent',
    btn1icon: 'c-icon--plus',
    btn1Href: '/content/match-content',
  },
}

const lastedtVideoWidget = {
  info: {
    image: images.play,
    title: 'Your latest video was viewed <span class="--bold">{{2560}}</span> times.',
  },
  mediaInfo: {
    videoUrl: 'https://www.youtube.com/watch?v=5ybu3jW1zeg',
    title: 'West crowned league cup champions for first time since 1963',
    description: 'West Auckland Town won the Mitre Brooks Mileson…',
    date: '2 Apr 2019, 2:14pm',
  },
  buttonList: {
    btn1Title: 'newVideo',
    btn1icon: 'c-icon--plus',
    btn1Href: '/content/news-stories',
    btn2Title: 'viewMediaLibrary',
    btn2Href: '/content/media-library',
    stateDatabtn1: {news_type: 2, isPopup: true},
  },
}

const howDidMatchAgainstWidget = {
  info: {
    image: '',
    title:
      'How did the match against {{Dunston}} go? Tell your fans the <span class="--bold">final score.</span>',
  },
  buttonList: {
    btn1Title: 'addScore',
    btn1icon: 'c-icon--plus',
    btn1Href: '/content/match-content',
    btn2Title: 'writeReport',
    btn2Href: '/content/match-content',
  },
}

const clubRevenueWidget = {
  clubRevenueHeader: {
    title: 'clubRevenue',
  },
  clubRevenueBody: {
    totalRevenue: '1275',
    paidRevenue: '678',
    currency: '£',
  },
  clubRevenueFooter: {
    title: 'highestRevenueContent',
    currency: '£',
    revenueList: [
      {
        revunueTitle: 'Purewal bags hat-trick as West hit…',
        revunuePrice: '157.58',
      },
      {
        revunueTitle: 'West crowned league cup champi…',
        revunuePrice: '104.34',
      },
      {
        revunueTitle: 'West rue missed chances in frustr…',
        revunuePrice: '84.60',
      },
    ],
  },
}

const daysOptionList: OptionListType[] = [
  {
    id: 0,
    label: 'Less then 1 day',
    value: 'less_then_1_day',
  },
  {
    id: 1,
    label: 'Last 3 days',
    value: 'last_3_days',
  },
  {
    id: 2,
    label: 'Last 5 days',
    value: 'last_5_days',
  },
  {
    id: 3,
    label: 'Last 7 days',
    value: 'last_7_days',
  },
  {
    id: 4,
    label: 'More the 7 days',
    value: 'more_then_7_days',
  },
]

const daysOptionListRevenue: OptionListType[] = [
  {
    id: 0,
    label: 'Last 1 day',
    value: 'last_1_day',
  },
  {
    id: 1,
    label: 'Last 3 days',
    value: 'last_3_days',
  },
  {
    id: 2,
    label: 'Last 5 days',
    value: 'last_5_days',
  },
  {
    id: 3,
    label: 'Last 7 days',
    value: 'last_7_days',
  },
  {
    id: 4,
    label: 'Last 30 days',
    value: 'last_30_days',
  },
  {
    id: 5,
    label: 'Last 60 days',
    value: 'last_60_days',
  },
  {
    id: 6,
    label: 'Last 90 days',
    value: 'last_90_days',
  },
  {
    id: 7,
    label: 'Last 1 year',
    value: 'last_1_year',
  },
]

const topEarningClubList = [
  {
    id: 1,
    sport: 'Football',
    created_at: '2022-06-09 06:42:21',
    club_logo: {
      medias_id: 120,
      file_name: 'user-2.png',
      original_url: 'https://tsdev.gdxsports.com/storage/120/user-2.png',
    },
    club_name: 'West Auckland AFC 2',
    members: 555,
    revenue: '£234',
  },
  {
    id: 2,
    sport: 'Football',
    created_at: '2022-06-09 06:42:21',
    club_logo: {
      medias_id: 120,
      file_name: 'user-2.png',
      original_url: 'https://tsdev.gdxsports.com/storage/120/user-2.png',
    },
    club_name: 'West Auckland AFC 2',
    members: 555,
    revenue: '£234',
  },
  {
    id: 3,
    sport: 'Football',
    created_at: '2022-06-09 06:42:21',
    club_logo: {
      medias_id: 120,
      file_name: 'user-2.png',
      original_url: 'https://tsdev.gdxsports.com/storage/120/user-2.png',
    },
    club_name: 'West Auckland AFC 2',
    members: 555,
    revenue: '£234',
  },
  {
    id: 4,
    sport: 'Football',
    created_at: '2022-06-09 06:42:21',
    club_logo: {
      medias_id: 120,
      file_name: 'user-2.png',
      original_url: 'https://tsdev.gdxsports.com/storage/120/user-2.png',
    },
    club_name: 'West Auckland AFC 2',
    members: 555,
    revenue: '£234',
  },
  {
    id: 5,
    sport: 'Football',
    created_at: '2022-06-09 06:42:21',
    club_logo: {
      medias_id: 120,
      file_name: 'user-2.png',
      original_url: 'https://tsdev.gdxsports.com/storage/120/user-2.png',
    },
    club_name: 'West Auckland AFC 2',
    members: 555,
    revenue: '£234',
  },
  {
    id: 6,
    sport: 'Football',
    created_at: '2022-06-09 06:42:21',
    club_logo: {
      medias_id: 120,
      file_name: 'user-2.png',
      original_url: 'https://tsdev.gdxsports.com/storage/120/user-2.png',
    },
    club_name: 'West Auckland AFC 2',
    members: 555,
    revenue: '£234',
  },
]

const totalCLub = {
  totalClubCount: '3695',
  chartData: [
    {
      pv: 0,
    },
    {
      pv: 1400,
    },
    {
      pv: 1000,
    },
    {
      pv: 1200,
    },
    {
      pv: 2000,
    },
    {
      pv: 1800,
    },
    {
      pv: 2600,
    },
  ],
}
const dailyRevenueChartData = [
  {
    name: 'Mon 01 Jul',
    pv: 250,
    amt: 250,
  },
  {
    name: 'Tue 02 Jul',
    pv: 170,
    amt: 200,
  },
  {
    name: 'Wed 03 Jul',
    pv: 120,
    amt: 300,
  },
  {
    name: 'Thu 04 Jul',
    pv: 100,
    amt: 400,
  },
  {
    name: 'Fri 05 Jul',
    pv: 200,
    amt: 500,
  },
  {
    name: 'Sat 06 Jul',
    pv: 200,
    amt: 600,
  },
  {
    name: 'Sun 07 Jul',
    pv: 230,
    amt: 700,
  },
]

const revenueInfoBox = [
  {
    title: 'Total Revenue',
    priceValue: '£568596',
    profit: true,
    loss: false,
    percentageValue: '12.6%',
  },
  {
    title: 'Total Session',
    priceValue: '8596',
    profit: true,
    loss: false,
    percentageValue: '13.2%',
  },
  {
    title: 'Bounce Rate',
    priceValue: '7.26%',
    profit: false,
    loss: true,
    percentageValue: '2.24%',
  },
  {
    title: 'Best Day',
    priceValue: 'Saturday',
    profit: false,
    loss: false,
  },
]
const addSettingList = {
  playerPositionList: 'player_position',
  commentaryPeriod: 'commentary_period',
  moderationReportType: 'moderation_report_type',
}

const commentarySlugList = {
  playerChange: 'player-change',
  yellowCard: 'yellow-card',
  comment: 'comment',
  end: 'end',
  start: 'start',
  goal: 'goal',
  startTest: 'start-test',
  halfQuater: 'half-quater',
}
const matchLiveCommentaryData = [
  {
    time: '45',
    period: '',
    event: 0,
    player: '',
    description:
      'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum',
  },
  {
    time: '46',
    period: 'Secon Half',
    event: 1,
    player: '',
    description: '',
  },
  {
    time: '45',
    period: '',
    event: 0,
    player: '',
    description:
      'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum',
  },
  {
    time: '47',
    period: '',
    event: 2,
    player: 'Alan Smith score',
    description: '',
  },
  {
    time: '45',
    period: '',
    event: 0,
    player: '',
    description:
      'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum',
  },
  {
    time: '46',
    period: 'Secon Half',
    event: 1,
    player: '',
    description: '',
  },
]

const matchFixtureTabList = [
  {
    title: 'fixture',
    news_type: 0,
  },
  {
    title: 'result',
    news_type: 1,
  },
  {
    title: 'request',
    news_type: 2,
  },
]

const inModeration = {
  no_issue: 0,
  issue: 1,
}

const adsFormat = ['Banner', 'Rewarded Interstitial']

const adsDevice = ['Android', 'IOS']

const facebookNote =
  'If you want to post on Instagram you must have a business account and link this facebook page that you link here.'

export {
  getSidebar,
  headerData,
  articlePreview,
  galleryPreview,
  videoPreview,
  tabList,
  socialAccounts,
  customStyles,
  carousal,
  contentTabList,
  socialMedia,
  faqs,
  testimonialsList,
  benefitsList,
  storeLinks,
  socialMediaLink,
  marketingVideo,
  requestList,
  clubRequestStatus,
  optionList,
  clubList,
  mediasModel,
  calendarModalStyle,
  mediaLibraryTabList,
  customStylesPlayer,
  MatchType,
  newsStoryWidget,
  lastNewsWidget,
  matchAgainstWidget,
  matchAgainstYesterdayWidget,
  lastedtVideoWidget,
  howDidMatchAgainstWidget,
  clubRevenueWidget,
  daysOptionList,
  topEarningClubList,
  totalCLub,
  dailyRevenueChartData,
  revenueInfoBox,
  addSettingList,
  matchLiveCommentaryData,
  matchFixtureTabList,
  commentarySlugList,
  inModeration,
  adsFormat,
  adsDevice,
  facebookNote,
  daysOptionListRevenue,
}
