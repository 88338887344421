import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {mediasModel} from '../../data'
import {ClubType} from '../actions/types'

export interface ClubState {
  clubList: ClubType[]
  total: number
  from: number
  clubDetail: ClubType
}

const initialClubDetail = {
  id: 0,
  club_name: '',
  request_status: 0,
  created_at: '',
  league_id: 0,
  medias_model: [mediasModel],
}

const initialState = {
  clubList: [],
  total: 0,
  from: 0,
  clubDetail: initialClubDetail,
} as ClubState

export const clubSlice = createSlice({
  name: 'club',
  initialState,
  reducers: {
    updateClubList: (state, action: PayloadAction<Partial<ClubState>>) => {
      const {clubList, total, from} = action.payload
      state.clubList = clubList || state.clubList
      state.total = total || initialState.total
      state.from = from || initialState.from
    },
    updateClubDetail: (state, action: PayloadAction<Partial<ClubState>>) => {
      state.clubDetail = action.payload.clubDetail || state.clubDetail
    },
    clearClubDetail: (state) => {
      state.clubDetail = initialState.clubDetail
    },
  },
})
