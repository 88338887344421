/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import {v4 as uuidv4} from 'uuid'
import logException from '../services/sentry'
import showNotification from './notification'

export const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
export const serializeFormData = (params: any) => {
  const formData = new FormData()
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      if (params[key] || params[key] === 0) {
        if (Array.isArray(params[key])) {
          params[key].forEach((item: string, index: number) => {
            formData.append(`${key}[${index}]`, item)
          })
        } else {
          formData.append(key, params[key])
        }
      }
    }
  }
  return formData
}

export const mapFilesToObject = (files: any) => {
  return files.map((file: any) =>
    Object.assign(file, {
      original_url: URL.createObjectURL(file),
      file_name: file.name,
      file,
      medias_id: uuidv4(),
    }),
  )
}

export const getTitleFromFileName = (fileName: string): string => {
  const list = fileName.split('.')
  if (list.length > 1) list.pop()
  return list.join('.')
}

export const checkRichTextEmpty = (value: any) => {
  let returnValue = false
  if (Array.isArray(value) && value.length > 0) {
    value.forEach((item) => {
      if (item?.children[0]?.text) {
        returnValue = true
      }
      if (Array.isArray(item?.children) && item?.children.length > 0) {
        item?.children.forEach((data: any) => {
          if (data?.children && data?.children[0]?.text) {
            returnValue = true
          }
        })
      }
    })
  }
  return returnValue
}

export const apiErrorBlock = (e: any) => {
  logException(e)
  console.log(e)
  showNotification({
    title: 'Error',
    message: e?.response?.statusText || e?.message,
    type: 'danger',
  })
}

export const convertAppSettingResToSelectList = (list: any) => {
  return Object.entries(list).map(([key, value]) => {
    return {
      id: key + 1,
      value: key,
      label: value as string,
    }
  })
}

export const convertbodyResToSelectList = (list: any) => {
  return list.map((data: any) => {
    return {
      id: data.id,
      value: data.id,
      label: data.title as string,
    }
  })
}

export const getFullDate = (date: any) => {
  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0') // January is 0!
  const yyyy = date.getFullYear()
  return `${mm}/${dd}/${yyyy}`
}

export const calcDate = (date1: string, date2: string) => {
  const dt_date1 = new Date(date1)
  const dt_date2 = new Date(date2)

  const date1_time_stamp = dt_date1.getTime()
  const date2_time_stamp = dt_date2.getTime()

  let calc

  if (date1_time_stamp > date2_time_stamp) {
    calc = new Date(date1_time_stamp - date2_time_stamp)
  } else {
    calc = new Date(date2_time_stamp - date1_time_stamp)
  }

  const calcFormatTmp = `${calc.getDate()}-${calc.getMonth() + 1}-${calc.getFullYear()}`

  const calcFormat = calcFormatTmp.split('-')
  function toNumber(value: string) {
    return Number(value)
  }
  const nums = calcFormat.map(toNumber)

  const days_passed = Number(Math.abs(nums[0]) - 1)
  const months_passed = Number(Math.abs(nums[1]) - 1)
  const years_passed = Number(Math.abs(nums[2]) - 1970)

  const yrsTxt = ['year', 'years']
  const mnthsTxt = ['month', 'months']
  const daysTxt = ['day', 'days']

  const total_days = years_passed * 365 + months_passed * 30.417 + days_passed

  const result =
    (years_passed === 1
      ? ` ${years_passed} ${yrsTxt[0]} `
      : years_passed > 1
      ? ` ${years_passed} ${yrsTxt[1]} `
      : '') +
    (months_passed === 1
      ? ` ${months_passed} ${mnthsTxt[0]}`
      : months_passed > 1
      ? ` ${months_passed} ${mnthsTxt[1]} `
      : '') +
    (days_passed === 1
      ? ` ${days_passed} ${daysTxt[0]}`
      : days_passed > 1
      ? ` ${days_passed} ${daysTxt[1]}`
      : '')

  return {
    total_days: Math.round(total_days),
    result: result.trim(),
  }
}

export const getDateDiff = (date: string) => {
  const latestDate = getFullDate(new Date(date))
  const todatDate = getFullDate(new Date())
  const dateDiff = calcDate(latestDate.toString(), todatDate.toString())
  return dateDiff
}

export const getShortDescription = (
  value: string | undefined,
  min: number,
  max: number,
  more: boolean,
) => {
  const description = value
  const splitDescription = description?.split(' ') || ['']
  const shortDescription = `${splitDescription.splice(min, max).join(' ')}${more ? '...' : ''}`
  return shortDescription
}
