import Api, {urls} from '../../services/api'
import {ApiResponseList, ModearationType, SortingType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {moderationActions} from '../slices'
import {AppDispatch} from '..'
import {apiErrorBlock} from '../../functions/utils'

export const getModerationList = (params: SortingType) => async (dispatch: AppDispatch) => {
  try {
    const {page} = params
    const response: ApiResponseList<ModearationType[]> = await Api.get(
      `${urls.moderation}?page=${page}${params.sort_by ? `&sort_by=${params.sort_by}` : ''}${
        params.sort_order ? `&sort_order=${params.sort_order}` : ''
      }${params.per_page && params.per_page > 0 ? `&per_page=${params.per_page}` : ''}`,
    )
    const {status, message, data} = response.data
    if (status && data?.data) {
      await dispatch(
        moderationActions.updateModearationList({
          modearationList: data.data,
          total: data.total || 0,
          from: data.from || 0,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
}

export const updateModerationStatus = (params: any) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<ModearationType[]> = await Api.post(
      `${urls.moderation}/status-update`,
      params,
    )
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getModerationList({page: 1}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}

export const clearModerationDetail = () => async (dispatch: AppDispatch) => {
  try {
    await dispatch(moderationActions.clearModearationDetail())
    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
