import {AxiosResponse} from 'axios'
// import querystring from 'querystring'
import Api, {urls} from '../../services/api'
import {
  ApiResponse,
  ApiResponseList,
  PermissionItem,
  RoleItem,
  RolePermissionItem,
  RolePermissionsApiResponseList,
} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {permissionsActions, rolesActions} from '../slices'
import {AppDispatch} from '..'
import {apiErrorBlock} from '../../functions/utils'

export const getPermissionsList = (params: {page: number}) => async (dispatch: AppDispatch) => {
  try {
    const {page} = params
    const response: ApiResponseList<PermissionItem[]> = await Api.get(
      `${urls.permission}?page=${page}`,
    )
    const {status, message, data} = response.data
    if (status && data?.data) {
      await dispatch(
        permissionsActions.updatePermissionsList({
          permissions: data.data,
          total: data.total || 0,
          from: data.from || 0,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
}

export const savePermission = (params: PermissionItem) => async (): Promise<boolean> => {
  try {
    const requestType = params.id ? 'patch' : 'post'
    const headers = params.id ? {'Content-Type': 'application/x-www-form-urlencoded'} : undefined
    const data = params.id
      ? (Object.keys({...params, permission_id: params.id}) as [keyof PermissionItem])
          .map((key) => `${key}=${encodeURIComponent(params[key] || params.id)}`)
          .join('&')
      : params
    const response: AxiosResponse<ApiResponse<string>> = await Api[requestType](
      `${urls.permission}${params.id ? `/${params.id}` : ''}`,
      data,
      {
        headers,
      },
    )
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}

export const deletePermission = (id: number, page: number) => async (dispatch: AppDispatch) => {
  try {
    const response: AxiosResponse<ApiResponse<string>> = await Api.delete(
      `${urls.permission}/${id}`,
    )
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      dispatch(getPermissionsList({page}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}

export const getRolesList = (params: {page: number}) => async (dispatch: AppDispatch) => {
  try {
    const {page} = params
    const response: ApiResponseList<RoleItem[]> = await Api.get(`${urls.role}?page=${page}`)
    const {status, message, data} = response.data
    if (status && data?.data) {
      await dispatch(
        rolesActions.updateList({
          list: data.data,
          total: data.total || 0,
          from: data.from || 0,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
}

export const saveRole = (params: PermissionItem) => async (): Promise<boolean> => {
  try {
    const requestType = params.id ? 'patch' : 'post'
    const headers = params.id ? {'Content-Type': 'application/x-www-form-urlencoded'} : undefined
    const data = params.id
      ? (Object.keys({...params, role_id: params.id}) as [keyof PermissionItem])
          .map((key) => `${key}=${encodeURIComponent(params[key] || params.id)}`)
          .join('&')
      : params
    const response: AxiosResponse<ApiResponse<string>> = await Api[requestType](
      `${urls.role}${params.id ? `/${params.id}` : ''}`,
      data,
      {
        headers,
      },
    )
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}

export const deleteRole = (id: number, page: number) => async (dispatch: AppDispatch) => {
  try {
    const response: AxiosResponse<ApiResponse<string>> = await Api.delete(`${urls.role}/${id}`)
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      dispatch(getRolesList({page}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}

export const getPermissionsByRole =
  (params: {roleId: number; page: number}) => async (dispatch: AppDispatch) => {
    try {
      const {page, roleId} = params
      const response: RolePermissionsApiResponseList<RolePermissionItem[]> = await Api.get(
        `${urls.rolespermission}?role_id=${roleId}&page=${page}`,
      )
      const {status, message, data} = response.data
      if (status && data?.role_permissions?.data) {
        await dispatch(
          rolesActions.updateRolePermissions({
            permissionsList: data.role_permissions.data,
            permissionsTotal: data.role_permissions.total || 0,
            permissionsFrom: data.role_permissions.from || 0,
          }),
        )
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
    } catch (e: any) {
      apiErrorBlock(e)
    }
  }

export const changeRolePermissionAssignment =
  (params: {roleId: number; permissionId: number; assign: boolean}) => async () => {
    try {
      const {permissionId, roleId, assign} = params
      const response: AxiosResponse<ApiResponse<string>> = await Api.post(
        `${assign ? urls.rolespermissionAssign : urls.rolespermissionUnassign}`,
        {
          role_id: roleId,
          permission_id: permissionId,
        },
      )
      const {status, message} = response.data
      if (status && message) {
        if (message) showNotification({title: 'Success', message, type: 'success'})
        return true
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return false
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }
