/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {memo, useState} from 'react'
import {Formik, Form} from 'formik'
import {useParams, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import {Input, Popup} from '../../component'
import {EventType} from '../contentManagement/type'
import {serializeFormData} from '../../functions/utils'
import {RootState} from '../../store/slices'
import {useAppDispatch, useSelector} from '../../store'
import {addLeague, deleteLeague, updateLeague, clearLeagueDetail} from '../../store/actions'
import UpdateContainer from '../contentManagement/common/updateContainer'

const LeagueUpdate = () => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const navigation = useNavigate()
  const dispatch = useAppDispatch()
  const {leagueDetail} = useSelector(({league}: RootState) => ({leagueDetail: league.leagueDetail}))
  const defaultOptions = {
    request_status: 0,
    page: 1,
  }
  const params = useParams()
  const CurrentId = (params?.id && parseInt(params.id, 10) && leagueDetail?.id) || undefined
  if (!CurrentId) {
    dispatch(clearLeagueDetail())
  }
  const initialValues = {
    league_name: leagueDetail?.title || '',
  }

  const onSubmit = async (values: any) => {
    setLoading(true)
    const formValues = {
      title: values.league_name || undefined,
    }
    const formData = serializeFormData(formValues)
    if (leagueDetail.id === 0) {
      const response = await dispatch(addLeague(formData))
      if (response) navigation(`/league-management`)
    } else {
      const response = await dispatch(updateLeague(leagueDetail.id || 0, formValues))
      if (response) navigation(`/league-management`)
    }
    setLoading(false)
  }

  const validationSchema = Yup.object().shape({
    league_name: Yup.string().trim().required('League Name cannot be empty.'),
  })

  const onClickListAction = async ({command, submitForm}: any) => {
    if (command === 'cancel') {
      navigation(`/league-management`)
    } else if (command === 'remove') {
      setIsOpenPopup(true)
    } else if (command === 'deleteConfirmed') {
      setLoading(true)
      await dispatch(deleteLeague(leagueDetail.id, defaultOptions))
      setIsOpenPopup(false)
      setLoading(false)
      dispatch(clearLeagueDetail())
      navigation(`/league-management`)
    } else if (command === 'save' || command === 'update') {
      submitForm()
    }
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({touched, errors, setFieldValue, submitForm}) => (
        <Form className="h-100">
          <UpdateContainer
            btn4Title={CurrentId ? 'deleteLeague' : undefined}
            btn2Title="cancel"
            btn1Title={CurrentId ? 'update' : 'save'}
            title={CurrentId ? 'editLeagueDetails' : 'addLeagueDetails'}
            loading={loading}
            onClick={(command: string) => onClickListAction({command, submitForm})}
          >
            <Popup
              isOpen={isOpenPopup}
              setIsOpen={setIsOpenPopup}
              onPressDelete={
                () => onClickListAction({id: leagueDetail.id || 0, command: 'deleteConfirmed'})
                // eslint-disable-next-line react/jsx-curly-newline
              }
              loading={loading}
              title={t('delete')}
              description={t('deleteText')}
            />
            <div className="faqs-edit-box">
              <div className="flex f-0-3">
                <div className="mr-3 f-1">
                  <Input
                    name="league_name"
                    label={t('leagueName')}
                    onChange={(e: EventType) => {
                      setFieldValue('league_name', e.target.value)
                    }}
                    placeholder={t('enterNameHere')}
                    error={touched.league_name && errors.league_name}
                    className="form-control mt-1"
                    classNameLable="fs-15"
                  />
                </div>
              </div>
            </div>
          </UpdateContainer>
        </Form>
      )}
    </Formik>
  )
}

export default memo(LeagueUpdate)
