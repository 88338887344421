import React, {useCallback} from 'react'
import ReactPlayer from 'react-player'
import {Descendant} from 'slate'
import {format} from 'date-fns'
import {WidgetMediaType} from '../type'
import {plainTextSerialize} from '../../../component/Editor/utils'
import {getShortDescription, isJsonString} from '../../../functions/utils'

const WidgetMedia = ({lastStoryInfo}: WidgetMediaType) => {
  const getDescription = useCallback((description: string | undefined): string | undefined => {
    const isJson = description ? isJsonString(description) : false
    const _desc = isJson ? JSON.parse(description as string) : description
    if (isJson && Array.isArray(_desc)) return plainTextSerialize(_desc as Descendant[])
    return _desc
  }, [])
  return (
    <div className="c-card__body">
      <div className="c-card-news">
        <div className="c-card-news__image">
          {Array.isArray(lastStoryInfo?.medias_model) && lastStoryInfo?.medias_model.length > 0 && (
            <ReactPlayer
              key={`video-slider-${lastStoryInfo.medias_model[0].original_url}`}
              className="last-news-video-box"
              controls
              playing={false}
              url={lastStoryInfo.medias_model[0].original_url}
            />
          )}
        </div>
        <div className="c-card-news__body">
          <h5 className="c-card-news__title f-Exo-Bold">{lastStoryInfo.news_title}</h5>
          <p className="c-card-news__text">
            {getShortDescription(
              getDescription(lastStoryInfo.news_description.toString()),
              0,
              30,
              true,
            )}
            {}
          </p>
          <p className="c-card-news__text mt-auto">
            <small className="c-card-news__text-muted">
              {(lastStoryInfo.created_at &&
                format(new Date(`${lastStoryInfo.created_at}`), 'dd MMM yyyy, hh:mm a')) ||
                format(new Date(), 'dd MMM yyyy, hh:mm a')}
            </small>
          </p>
        </div>
      </div>
    </div>
  )
}
export default WidgetMedia
