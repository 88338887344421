import React, {memo} from 'react'
import UpdateContainer from './common/updateContainer'
import LinkUpdate from './common/linkUpdate'
import {marketingVideo} from '../../data'

const MarketingVideo = () => (
  <UpdateContainer
    btn3Title={undefined}
    btn2Title={undefined}
    btn1Title={undefined}
    title="marketingVideo"
  >
    <div className="mt-40">
      {Array.isArray(marketingVideo) &&
        marketingVideo.map((item) => (
          <LinkUpdate
            metaKey={item.meta_key}
            key={`link-update-${item.meta_key}`}
            lable={item.lable}
            placeholder="pleasePasteYouTubeLinkHere"
          />
        ))}
    </div>
  </UpdateContainer>
)

export default memo(MarketingVideo)
