import React, {memo} from 'react'
import {Button, Switch} from '.'
import {UpdateListType, switchListType, buttonListType, moreOptionType} from './type'
import {ListItemRow} from '../store/actions/types'

interface ListRightProps<T> {
  row: T
  onClick: ({id, command}: UpdateListType) => void
  switchList?: switchListType[]
  buttonList?: buttonListType[]
  moreOption?: moreOptionType[]
}

const ListRight = <T extends ListItemRow>(props: ListRightProps<T>) => {
  const {row, onClick, switchList, buttonList, moreOption} = props
  return (
    <div className="c-news-card__body__right-part h-fit-content">
      {switchList &&
        Array.isArray(switchList) &&
        switchList.map((switchItem: any) => (
          <Switch
            label={switchItem.title}
            onClick={() => onClick({id: row.id, command: switchItem.command})}
            checked={switchItem.status || false}
            key={`switch-${switchItem?.command}`}
          />
        ))}
      {buttonList &&
        Array.isArray(buttonList) &&
        buttonList.map((buttonItem: any) => (
          <Button
            classAddon={buttonItem.classAddon}
            title={buttonItem.title}
            onClick={() => onClick({id: row.id, command: buttonItem.command})}
            key={`button-${buttonItem?.command}`}
          />
        ))}
      {Array.isArray(moreOption) && (
        <div className="dropdown c-more-dropdown">
          <i
            className="c-icon c-icon--more c-more-dropdown__button dropdown-toggle"
            data-toggle="dropdown"
          />
          <div className="dropdown-menu c-more-dropdown__menu dropdown-menu-right">
            {moreOption.map((moreItem: any) => (
              <Button
                icon={moreItem.icon}
                className={moreItem.className}
                title={moreItem.title}
                onClick={() => onClick({id: row.id, command: moreItem.command})}
                key={`moreOption-${moreItem?.command}`}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
export default memo(ListRight)
