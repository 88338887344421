import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {FixtureType} from '../actions/types'

export interface ClubFixtureState {
  clubFixtureList: FixtureType[]
  total: number
  from: number
  clubFixtureDetail: FixtureType
}

const initialClubFixtureDetail = {
  id: 0,
  match_start_time: '',
  match_vanue: '',
  match_kickoff_time: '',
  match_meet_time: '',
  club_final_score: 0,
  club_id: 0,
  club_penalties: 0,
  club: {
    id: 0,
    club_name: '',
    medias_model: [
      {
        medias_id: 0,
        original_url: '',
        file_name: '',
      },
    ],
  },
  opponent_club_id: 0,
  opponent_final_score: 0,
  opponent_penalties: 0,
  opponent_club: {
    id: 0,
    club_name: '',
    medias_model: [
      {
        medias_id: 0,
        original_url: '',
        file_name: '',
      },
    ],
  },
  match_attend_time: '',
  match_description: '',
}

const initialState = {
  clubFixtureList: [],
  total: 0,
  from: 0,
  clubFixtureDetail: initialClubFixtureDetail,
} as ClubFixtureState

export const clubFixtureSlice = createSlice({
  name: 'fixture',
  initialState,
  reducers: {
    updateClubFixtureList: (state, action: PayloadAction<Partial<ClubFixtureState>>) => {
      const {clubFixtureList, total, from} = action.payload
      state.clubFixtureList = clubFixtureList || state.clubFixtureList
      state.total = total || initialState.total
      state.from = from || initialState.from
    },
    updateClubFixtureDetail: (state, action: PayloadAction<Partial<ClubFixtureState>>) => {
      state.clubFixtureDetail = action.payload.clubFixtureDetail || state.clubFixtureDetail
    },
    clearClubFixtureDetail: (state) => {
      state.clubFixtureDetail = initialState.clubFixtureDetail
    },
  },
})
