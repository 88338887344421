import Api, {urls} from '../../services/api'
import {ApiResponseList, ClubFixtureRequestType, SortingType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {clubFixtureRequestAction} from '../slices'
import {AppDispatch} from '..'
import {apiErrorBlock} from '../../functions/utils'

export const getClubFixtureRequestList = (params: SortingType) => async (dispatch: AppDispatch) => {
  try {
    const {page} = params
    const response: ApiResponseList<ClubFixtureRequestType[]> = await Api.get(
      `${urls.clubFixtureRequest}`,
      {
        params: {
          page,
          sort_by: params?.sort_by,
          sort_order: params?.sort_order,
          per_page: params?.per_page,
        },
      },
    )
    const {status, message, data} = response.data
    if (status && data?.data) {
      await dispatch(
        clubFixtureRequestAction.updateClubFixtureRequestList({
          clubFixtureRequestList: data.data,
          total: data.total || 0,
          from: data.from || 0,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
}

export const updateClubFixtureStatus =
  (params: {requested_status: number; club_match_fixture_id: number}) => async () => {
    try {
      const response: ApiResponseList<ClubFixtureRequestType[]> = await Api.get(
        `${urls.clubStatusFixtureRequest}`,
        {
          params: {
            club_match_fixture_id: params.club_match_fixture_id,
            requested_status: params.requested_status,
          },
        },
      )
      const {status, message} = response.data
      if (status && message) {
        if (message) showNotification({title: 'Success', message, type: 'success'})
        return true
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return false
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }

export const clearClubFixtureRequestDetail = () => async (dispatch: AppDispatch) => {
  try {
    await dispatch(clubFixtureRequestAction.clearClubFixtureRequestDetail())
    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
