import React, {memo} from 'react'
import SlateEditor from './Editor'
import {Label} from '.'
import {EditorType} from './type'

const Editor = ({label, error, onChange, ...props}: EditorType) => (
  <div className="c-form-group">
    {label && <Label label={label} />}
    <SlateEditor onChange={onChange} error={error as boolean | string} {...props} />
    {error && <div className="invalid-feedback">{error}</div>}
  </div>
)

export default memo(Editor)
