import React from 'react'
import {useTranslation} from 'react-i18next'
import {WidgetHeaderType} from '../type'
import {useSelector} from '../../../store'
import {getDateDiff} from '../../../functions/utils'

const WidgetHeader = ({item, clubLogo}: WidgetHeaderType) => {
  const {t} = useTranslation()
  const {widget, userData} = useSelector(({widgetClub, user}) => ({
    widget: widgetClub.widgetClub,
    userData: user.user,
  }))
  const defaultResult = {result: ''}
  const lastArtical = widget?.latest_artical?.created_at
    ? getDateDiff(widget.latest_artical.created_at)
    : defaultResult
  const upcomingMatch = widget?.next_match_fixture?.match_start_time
    ? getDateDiff(widget.next_match_fixture.match_start_time)
    : defaultResult
  const pastMatch = widget?.past_match_fixture?.match_start_time
    ? getDateDiff(widget.past_match_fixture.match_start_time)
    : defaultResult

  const nextOpponentClub =
    widget?.next_match_fixture?.club?.club_slug === userData.club_data?.club_slug
      ? 'opponent_club'
      : 'club'

  const pastOpponentClub =
    widget?.past_match_fixture?.club?.club_slug === userData.club_data?.club_slug
      ? 'opponent_club'
      : 'club'

  const currentOpponentClub =
    widget?.current_match_fixture?.club?.club_slug === userData.club_data?.club_slug
      ? 'opponent_club'
      : 'club'

  return (
    <div className="c-card__header">
      <div className="c-card__header__left">
        <div className="c-card__header__title">
          <span className="c-card__header__title__icons">
            <img
              src={clubLogo || item?.image}
              alt=""
              className={`${clubLogo ? 'widget-club-logo' : ''}`}
            />
          </span>
          <span
            className="f-Exo-Medium"
            dangerouslySetInnerHTML={{
              __html: t(item.title, {
                '3weeks': lastArtical.result,
                BishopAucklandAFC: widget?.next_match_fixture?.[`${nextOpponentClub}`]?.club_name,
                '2days': upcomingMatch.result,
                AshingtonAFC: widget?.past_match_fixture?.[`${pastOpponentClub}`]?.club_name,
                yesterday: pastMatch.result,
                Dunston: widget?.current_match_fixture?.[`${currentOpponentClub}`]?.club_name,
                '2560': widget.latest_news_video?.news_view_count,
              }),
            }}
          />
        </div>
      </div>
    </div>
  )
}
export default WidgetHeader
