/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {memo, useState, useCallback} from 'react'
import {Formik, Form} from 'formik'
import {useParams, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import Switch from 'react-switch'
import {Button, Input, Popup, Label, ClubSearch} from '../../component'
import {UpdateListType} from '../../component/type'
import {EventType} from '../contentManagement/type'
import {serializeFormData} from '../../functions/utils'
import {RootState} from '../../store/slices'
import {useAppDispatch, useSelector} from '../../store'
import {addAds, deleteAds, updateAds, clearAdsDetail} from '../../store/actions'
import {ClubType} from '../../store/actions/types'

const AdsUpdate = () => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const navigation = useNavigate()
  const dispatch = useAppDispatch()
  const {adsDetail} = useSelector(({ads}: RootState) => ({
    adsDetail: ads.adsDetail,
  }))
  const [formatChecked, setFormatChecked] = useState(!!adsDetail.ads_unit_format)
  const [deviceChecked, setDeviceChecked] = useState(!!adsDetail.ads_unit_app_platform)

  const defaultOptions = {
    is_active: undefined,
    page: 1,
  }
  const params = useParams()
  const CurrentId = (params?.id && parseInt(params.id, 10) && adsDetail?.id) || undefined
  if (!CurrentId) {
    dispatch(clearAdsDetail())
  }
  const initialValues = {
    ads_unit_id: adsDetail?.ads_unit_id || '',
    ads_unit_app_platform: adsDetail?.ads_unit_app_platform || 0, // 0=Android,1=iOS
    ads_unit_format: adsDetail?.ads_unit_format || 0, // 0=BANNER,1=REWARDED_INTERSTITIAL
    clubs_id: adsDetail?.clubs_id || '',
  }

  const onSubmit = async (values: any) => {
    setLoading(true)
    const formValues = {
      clubs_id: values.clubs_id || undefined,
      ads_unit_format: values.ads_unit_format || 0,
      ads_unit_app_platform: values?.ads_unit_app_platform || 0,
      ads_unit_id: values?.ads_unit_id || '',
    }
    const formData = serializeFormData(formValues)
    if (adsDetail.id === 0) {
      const response = await dispatch(addAds(formData))
      if (response) navigation(`/ads-management`)
    } else {
      const response = await dispatch(updateAds(adsDetail.id || 0, formValues))
      if (response) navigation(`/ads-management`)
    }
    setLoading(false)
  }

  const validationSchema = Yup.object().shape({
    ads_unit_id: Yup.string().trim().required('Unit Id cannot be empty.'),
    clubs_id: Yup.string().trim().required('Club cannot be empty.'),
  })

  const onClickListAction = useCallback(async ({id, command}: UpdateListType) => {
    if (command === 'edit') {
      navigation(`/ads-management/${id}`)
    } else if (command === 'remove') {
      setIsOpenPopup(true)
    } else if (command === 'deleteConfirmed') {
      setLoading(true)
      await dispatch(deleteAds(id, defaultOptions))
      setIsOpenPopup(false)
      setLoading(false)
      dispatch(clearAdsDetail())
      navigation(`/ads-management`)
    }
  }, [])

  const handleClubChange = (selectedClub: ClubType, setFieldValue: any) => {
    setFieldValue('clubs_id', selectedClub.id || '')
    setFieldValue('club_name', selectedClub.club_name)
  }
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({touched, errors, setFieldValue, submitForm}) => (
        <Form className="h-100">
          <div className="table-outer">
            <div className="table-header">
              <div className="c-carousal__header no-border">
                <div className="c-carousal__header__lpart v-center ">
                  <span className="font-b">{t('adsDetails')}</span>
                </div>
                <Popup
                  isOpen={isOpenPopup}
                  setIsOpen={setIsOpenPopup}
                  onPressDelete={
                    () => onClickListAction({id: adsDetail.id || 0, command: 'deleteConfirmed'})
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                  loading={loading}
                  title={t('delete')}
                  description={t('deleteText')}
                />
                <div className="c-carousal__header__rpart flex-center">
                  <Button
                    classAddon="f-Exo-Bold mr-2"
                    title={t('save')}
                    icon={loading ? 'c-icon--loader mr-2' : ''}
                    style={{padding: '0.5rem 1.5rem'}}
                    onClick={submitForm}
                  />
                  <div>
                    <Button
                      classAddon=" c-button--br c-button--br--gray-ab bg-transparent f-Exo-Bold"
                      title={t('cancel')}
                      style={{padding: '0.5rem 1.5rem'}}
                      onClick={() => {
                        dispatch(clearAdsDetail())
                        navigation(`/ads-management`)
                      }}
                    />
                  </div>
                  {CurrentId && (
                    <div className="ml-3 mb-0">
                      <div className="c-pointer flex-center" onClick={() => setIsOpenPopup(true)}>
                        <span className="icon-edit-text mr-2">{t('removeAds')}</span>{' '}
                        <i className="c-icon c-icon--delete" />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-2 mb-4 rolefrom-inner">
                <div className="flex-1 flex-d-row">
                  <div className="flex mt-4 f-0-7">
                    <Input
                      name="ads_unit_id"
                      label={t('unitId')}
                      placeholder={t('pleaseEnterunitId')}
                      error={touched.ads_unit_id && errors.ads_unit_id}
                      onChange={(e: EventType) => setFieldValue('ads_unit_id', e.target.value)}
                      className="form-control mt-1 f-Exo-Bold"
                      classNameLable="fs-15"
                      classAddonOuter="w-80"
                    />
                  </div>
                  <div className="mt-4 f-0-7">
                    <Label label={t('club')} />
                    <ClubSearch
                      handleChange={handleClubChange}
                      setFieldValue={setFieldValue}
                      classNameOuter="w-80 mt-1 h-45"
                      defaultValue={adsDetail?.club?.club_name}
                      error={touched.clubs_id && errors.clubs_id}
                    />
                  </div>
                </div>
                <div className="flex-1 flex-d-row">
                  <div className="flex mt-3 mb-3 f-0-7">
                    <div className="switch-container flex">
                      <div id="device-checkbox" className="switch-label mr-2">
                        Android
                      </div>
                      <Switch
                        name="ads_unit_app_platform"
                        data-testid="language-switch"
                        onChange={(value: any) => {
                          setDeviceChecked(value)
                          setFieldValue('ads_unit_app_platform', deviceChecked ? 0 : 1)
                        }}
                        checked={deviceChecked}
                        uncheckedHandleIcon={undefined}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={12}
                        height={20}
                        width={40}
                      />
                      <div id="device-checkbox" className="switch-label ml-2">
                        IOS
                      </div>
                    </div>
                  </div>
                  <div className="flex mt-3 mb-3 f-0-7">
                    <div className="switch-container flex">
                      <div id="device-checkbox" className="switch-label mr-2">
                        Banner
                      </div>
                      <Switch
                        name="ads_unit_format"
                        data-testid="language-switch"
                        onChange={(value: any) => {
                          setFormatChecked(value)
                          setFieldValue('ads_unit_format', formatChecked ? 0 : 1)
                        }}
                        checked={formatChecked}
                        uncheckedHandleIcon={undefined}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={12}
                        height={20}
                        width={40}
                      />
                      <div id="device-checkbox" className="switch-label ml-2">
                        Rewarded Interstitial
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default memo(AdsUpdate)
