import React, {memo, MouseEvent, PropsWithChildren} from 'react'
import {useSlate} from 'slate-react'
import {
  insertLink,
  isBlockActive,
  isLinkActive,
  isMarkActive,
  TEXT_ALIGN_TYPES,
  toggleBlock,
  toggleMark,
  unwrapLink,
} from './utils'

interface BaseProps {
  [key: string]: unknown
}

type ToolbarButtonArgs = {
  format: string
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {title?: string}>
}

const ButtonBase = ({active, ...props}: PropsWithChildren<{active?: boolean} & BaseProps>) => (
  <span {...props} className="button" />
)

ButtonBase.defaultProps = {
  active: false,
}

const Button = memo(ButtonBase)

export const MarkButton = ({format, Icon}: ToolbarButtonArgs) => {
  const editor = useSlate()
  const isActive = isMarkActive(editor, format)
  return (
    <Button
      active={isActive}
      onMouseDown={(event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon className={`img ${isActive ? 'img_active' : ''}`} />
    </Button>
  )
}

export const BlockButton = ({format, Icon}: ToolbarButtonArgs) => {
  const editor = useSlate()
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
  )
  return (
    <Button
      active={isActive}
      onMouseDown={(event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon className={`img ${isActive ? 'img_active' : ''}`} />
    </Button>
  )
}

export const AddLinkButton = ({format, Icon}: ToolbarButtonArgs) => {
  const editor = useSlate()
  const isActive = isLinkActive(editor)
  return (
    <Button
      active={isActive}
      onMouseDown={(event: MouseEvent<HTMLButtonElement>) => {
        console.log(format)
        event.preventDefault()
        if (isActive) {
          unwrapLink(editor)
        } else {
          const url = window.prompt('Enter the URL of the link')
          if (!url) return
          insertLink(editor, url)
        }
      }}
    >
      <Icon className={`img ${isActive ? 'img_active' : ''}`} />
    </Button>
  )
}
