import React, {useState, useEffect} from 'react'
import {Button} from '../../component'
import {socialAccounts} from '../../data'
import {addSocialConfig, getSocialConfig} from '../../store/actions'
import List from './list'
import {UpdateListType, SocialList} from './type'
import {useAppDispatch, useSelector} from '../../store'

const Social = () => {
  const [socialData, setSocialData] = useState(socialAccounts)
  const dispatch = useAppDispatch()
  const {socialConfigData} = useSelector(({socialConfig}) => ({
    socialConfigData: socialConfig.socialConfig,
  }))
  useEffect(() => {
    dispatch(getSocialConfig())
    handleGetStateChange()
  }, [])

  const handleGetStateChange = () => {
    const data = socialData.map((dataItem: SocialList) => {
      if (dataItem.title === 'FACEBOOK PAGE') {
        return {
          ...dataItem,
          publishOnSite: !!socialConfigData?.is_facebook_post_active,
        }
      }
      if (dataItem.title === 'TWITTER PAGE') {
        return {
          ...dataItem,
          publishOnSite: !!socialConfigData?.is_twiter_post_active,
        }
      }
      if (dataItem.title === 'INSTAGRAM PAGE') {
        return {
          ...dataItem,
          publishOnSite: !!socialConfigData?.is_instagram_post_active,
        }
      }
      return dataItem
    })
    setSocialData(data)
  }

  const handleStateChange = ({id, command}: UpdateListType) => {
    const data = socialData.map((dataItem: SocialList) => {
      if (dataItem.id === id) {
        if (dataItem.title === 'FACEBOOK PAGE') {
          dispatch(
            addSocialConfig({
              ...socialConfigData,
              is_facebook_post_active: socialConfigData?.is_facebook_post_active ? 0 : 1,
            }),
          )
        }
        if (dataItem.title === 'TWITTER PAGE') {
          dispatch(
            addSocialConfig({
              ...socialConfigData,
              is_twiter_post_active: socialConfigData?.is_twiter_post_active ? 0 : 1,
            }),
          )
        }
        if (dataItem.title === 'INSTAGRAM PAGE') {
          dispatch(
            addSocialConfig({
              ...socialConfigData,
              is_instagram_post_active: socialConfigData?.is_instagram_post_active ? 0 : 1,
            }),
          )
        }
        if (command === 'changePublish') {
          return {...dataItem, publishOnSite: !dataItem.publishOnSite}
        }
        if (command === 'changeAutoPost') {
          return {...dataItem, autoPostVideo: !dataItem.autoPostVideo}
        }
      }
      return dataItem
    })
    setSocialData(data)
  }
  return (
    <>
      <Button title="Save" classAddon="c-button--we-160 c-button--icons mb-30" />
      {Array.isArray(socialData) &&
        socialData.map((item) => (
          <List key={`social-list-${item.id}`} item={item} handleStateChange={handleStateChange} />
        ))}
    </>
  )
}
export default Social
