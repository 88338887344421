import {combineReducers} from 'redux'
import storage from 'redux-persist/lib/storage'
import {PersistConfig} from 'redux-persist'
import {CounterState, counterSlice} from './counter'
import {NewsState, newsSlice} from './news'
import {UserState, userSlice} from './user'
import {TestimonialState, testimonialSlice} from './testimonial'
import {FaqState, faqSlice} from './faqs'
import {TabState, tabSlice} from './tab'
import {BenefitState, benefitSlice} from './benefit'
import {CarousalState, carousalSlice} from './carousal'
import {MatchContentState, matchContentSlice} from './matchContent'
import {ClubState, clubSlice} from './club'
import {UserManagementState, userManagementSlice} from './userManagement'
import {PermissionState, permissionSlice} from './permissions'
import {RolesState, rolesSlice} from './roles'
import {ClubRequestState, clubRequestSlice} from './clubAdminRquest'
import {MediaLibraryState, mediaLibrarySlice} from './medialibrary'
import {TagState, tagSlice} from './tag'
import {PlayerState, playerSlice} from './playerManagement'
import {
  MatchContentFinalScoreState,
  matchContentFinalScoreSlice,
} from './matchDetail/matchContentFinalScore'
import {
  MatchContentMatchInfoState,
  matchContentMatchInfoSlice,
} from './matchDetail/matchContentMatchInfo'
import {FixtureState, fixtureSlice} from './fixture'
import {CountryState, countrySlice} from './country'
import {TopEarningClubState, topEarningClubSlice} from './topEarningClub'
import {AppSettingState, appSettingSlice} from './appSetting'
import {
  MatchContentMatchReportState,
  matchContentMatchReportSlice,
} from './matchDetail/matchContentMatchReport'
import {MatchLiveCommentaryState, matchLiveCommentarySlice} from './matchDetail/matchLiveCommentary'
import {LeagueState, leagueSlice} from './league'
import {EventState, eventSlice} from './commentaryTypes'
import {ModearationState, modearationSlice} from './moderation'
import {AccountSettingState, accountSettingSlice} from './accountSetting'
import {AdsState, adsSlice} from './ads'
import {WidgetClubState, widgetClubSlice} from './widgetClub'
import {SocialConfigState, socialConfigSlice} from './socialConfig'
import {ClubFixtureState, clubFixtureSlice} from './matchFixtureRequest'
import {ClubFixtureRequestState, clubFixtureRequestSlice} from './clubFixtureRquest'
import {SocialConfigExpireState, socialConfigExpireSlice} from './socialConfigExpire'

export interface RootState {
  counter: CounterState
  news: NewsState
  user: UserState
  testimonial: TestimonialState
  faq: FaqState
  tab: TabState
  benefit: BenefitState
  carousal: CarousalState
  matchContent: MatchContentState
  club: ClubState
  userManagement: UserManagementState
  permissions: PermissionState
  roles: RolesState
  clubRequest: ClubRequestState
  mediaLibrary: MediaLibraryState
  tag: TagState
  player: PlayerState
  matchContentFinalScoreView: MatchContentFinalScoreState
  matchContentMatchInfoView: MatchContentMatchInfoState
  matchContentMatchReportView: MatchContentMatchReportState
  matchLiveCommentaryView: MatchLiveCommentaryState
  fixture: FixtureState
  country: CountryState
  topEarningClub: TopEarningClubState
  appSetting: AppSettingState
  league: LeagueState
  eventList: EventState
  moderation: ModearationState
  accountSetting: AccountSettingState
  ads: AdsState
  widgetClub: WidgetClubState
  socialConfig: SocialConfigState
  matchFixtureRequest: ClubFixtureState
  clubFixtureRequest: ClubFixtureRequestState
  socialConfigExpire: SocialConfigExpireState
}
export const rootReducer = combineReducers<RootState>({
  counter: counterSlice.reducer,
  news: newsSlice.reducer,
  user: userSlice.reducer,
  testimonial: testimonialSlice.reducer,
  faq: faqSlice.reducer,
  tab: tabSlice.reducer,
  benefit: benefitSlice.reducer,
  carousal: carousalSlice.reducer,
  matchContent: matchContentSlice.reducer,
  club: clubSlice.reducer,
  userManagement: userManagementSlice.reducer,
  permissions: permissionSlice.reducer,
  roles: rolesSlice.reducer,
  clubRequest: clubRequestSlice.reducer,
  mediaLibrary: mediaLibrarySlice.reducer,
  tag: tagSlice.reducer,
  player: playerSlice.reducer,
  matchContentFinalScoreView: matchContentFinalScoreSlice.reducer,
  matchContentMatchInfoView: matchContentMatchInfoSlice.reducer,
  matchContentMatchReportView: matchContentMatchReportSlice.reducer,
  matchLiveCommentaryView: matchLiveCommentarySlice.reducer,
  fixture: fixtureSlice.reducer,
  country: countrySlice.reducer,
  topEarningClub: topEarningClubSlice.reducer,
  appSetting: appSettingSlice.reducer,
  league: leagueSlice.reducer,
  eventList: eventSlice.reducer,
  moderation: modearationSlice.reducer,
  accountSetting: accountSettingSlice.reducer,
  ads: adsSlice.reducer,
  widgetClub: widgetClubSlice.reducer,
  socialConfig: socialConfigSlice.reducer,
  matchFixtureRequest: clubFixtureSlice.reducer,
  clubFixtureRequest: clubFixtureRequestSlice.reducer,
  socialConfigExpire: socialConfigExpireSlice.reducer,
})
export const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'user',
    'tab',
    'carousal',
    'benefit',
    'testimonial',
    'faq',
    'country',
    'club',
    'facebookInfo',
    'instragramInfo',
    'twitterInfo',
    'ads',
    'socialConfig',
    'league',
    'clubRequest',
  ],
} as PersistConfig<RootState>

export const conterActions = counterSlice.actions
export const userActions = userSlice.actions
export const newsActions = newsSlice.actions
export const testimonialActions = testimonialSlice.actions
export const faqActions = faqSlice.actions
export const tabActions = tabSlice.actions
export const benefitActions = benefitSlice.actions
export const carousalActions = carousalSlice.actions
export const matchContentActions = matchContentSlice.actions
export const clubActions = clubSlice.actions
export const userManagementActions = userManagementSlice.actions
export const permissionsActions = permissionSlice.actions
export const rolesActions = rolesSlice.actions
export const clubRequestActions = clubRequestSlice.actions
export const mediaLibraryActions = mediaLibrarySlice.actions
export const tagActions = tagSlice.actions
export const playerAction = playerSlice.actions
export const matchContentFinalScoreActions = matchContentFinalScoreSlice.actions
export const matchContentMatchInfoAction = matchContentMatchInfoSlice.actions
export const matchContentMatchReportAction = matchContentMatchReportSlice.actions
export const matchLiveCommentaryAction = matchLiveCommentarySlice.actions
export const fixtureActions = fixtureSlice.actions
export const countryActions = countrySlice.actions
export const topEarningClubAction = topEarningClubSlice.actions
export const appSettingAction = appSettingSlice.actions
export const leagueAction = leagueSlice.actions
export const eventAction = eventSlice.actions
export const moderationActions = modearationSlice.actions
export const accountSeeting = accountSettingSlice.actions
export const adsActions = adsSlice.actions
export const widgetClubAction = widgetClubSlice.actions
export const socialConfigAction = socialConfigSlice.actions
export const matchFixtureRequestAction = clubFixtureSlice.actions
export const clubFixtureRequestAction = clubFixtureRequestSlice.actions
export const socialConfigExpireAction = socialConfigExpireSlice.actions
