export const socialKeys = {
  instragram: {
    client_id: '672488770807620',
    client_secret: 'a900798d5cbe57e9ac0e59768ae00d0e',
  },
  facebook: {
    client_id: '1201729440596554',
    client_secret: '0ab0463eeae92bb83e4d9ea7e9d7133a',
  },
  twitter: {
    client_id: 'REQwOWh6RzBYRmotanFGbmpaVkQ6MTpjaQ',
  },
}
