import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AdsType} from '../actions/types'

export interface AdsState {
  adsList: AdsType[]
  total: number
  from: number
  adsDetail: AdsType
}

const initialAdsDetail = {
  id: 0,
  ads_unit_id: '',
  ads_unit_app_platform: 0,
  ads_unit_format: 0,
  clubs_id: '',
  created_at: '',
}

const initialState = {
  adsList: [],
  total: 0,
  from: 0,
  adsDetail: initialAdsDetail,
} as AdsState

export const adsSlice = createSlice({
  name: 'ads',
  initialState,
  reducers: {
    updateAdsList: (state, action: PayloadAction<Partial<AdsState>>) => {
      const {adsList, total, from} = action.payload
      state.adsList = adsList || state.adsList
      state.total = total || initialState.total
      state.from = from || initialState.from
    },
    updateAdsDetail: (state, action: PayloadAction<Partial<AdsState>>) => {
      state.adsDetail = action.payload.adsDetail || state.adsDetail
    },
    clearAdsDetail: (state) => {
      state.adsDetail = initialState.adsDetail
    },
  },
})
