import React from 'react'
import WidgetFooter from './renderItem/widgetFooter'
import WidgetHeader from './renderItem/widgetHeader'
import {MatchAgainstYesterdayWidgetType} from './type'
import {useSelector} from '../../store'

const MatchAgainstYesterdayWidget = ({item, clubLogo}: MatchAgainstYesterdayWidgetType) => {
  const {widget} = useSelector(({widgetClub}) => ({
    widget: widgetClub.widgetClub,
  }))
  return (
    <div className="c-card">
      <WidgetHeader item={item.info} clubLogo={clubLogo} />
      <WidgetFooter item={item.buttonList} updateId={widget?.past_match_fixture?.id.toString()} />
    </div>
  )
}
export default MatchAgainstYesterdayWidget
