import React, {memo, useCallback, useEffect, useMemo, useState} from 'react'
import {createTable} from '@tanstack/react-table'
import {Descendant} from 'slate'
import {format} from 'date-fns'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'
import {optionList} from '../../data/news'
import {UpdateListType} from '../../component/type'
import {Button, Sort, Modal, List as ListView, Popup} from '../../component'
import AddNewsStory from './addNewsStory'
import ListRight from '../../component/listRight'
import {
  getNewsStoryList,
  getNewsStoryDetail,
  clearNewsStoryDetail,
  deleteNewsStory,
  updateNewsStoryStatus,
} from '../../store/actions'
import {useAppDispatch, useSelector} from '../../store'
import {NewsStoryType} from '../../store/actions/types'
import {
  SwitchChangeLive,
  SwitchChangeFeatured,
  MoreOptionEdit,
  MoreOptionDelete,
} from '../../component/List/Commands'
import NewsMedia from './NewsMedia'
import {plainTextSerialize} from '../../component/Editor/utils'
import {getShortDescription, isJsonString} from '../../functions/utils'
import {defaultPageSize} from '../../configs/paginationSetting'

const table = createTable().setRowType<NewsStoryType>()

const NewsList = memo(() => {
  const {state}: any = useLocation()
  const navigation = useNavigate()
  const [newsType, setNewType] = useState(state?.stateData?.news_type)
  const [singleTab, setSingleTab] = useState(state?.stateData?.news_type)
  const [isOpen, setIsOpen] = useState(state?.stateData?.isPopup || false)
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [currentItemId, setCurrentItemId] = useState(0)
  const [selectedRow, setSelectedRow] = useState<NewsStoryType>()
  const defaultOptions = useMemo(
    () => ({
      sort_by: 'created_at',
      sort_order: 'desc',
      page: 1,
      per_page: defaultPageSize,
    }),
    [],
  )

  useEffect(() => {
    navigation('', {state: {}})
  }, [])

  const [options, setOptions] = useState(defaultOptions)
  const [defaultOption, setdefaultOption] = useState([optionList[0]])
  const {t} = useTranslation()
  const {list, total} = useSelector(({news}) => ({list: news.newsList, total: news.total}))

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getNewsStoryList(options))
  }, [options])

  const getDescription = useCallback((description: string | undefined): string | undefined => {
    const isJson = description ? isJsonString(description) : false
    const _desc = isJson ? JSON.parse(description as string) : description
    if (isJson && Array.isArray(_desc)) return plainTextSerialize(_desc as Descendant[])
    return _desc
  }, [])
  const columns = useMemo(
    () => [
      table.createDataColumn('news_description', {
        header: undefined,
        cell: ({row}) => (
          <div className="flex">
            <div className="c-news-card__image min-w-60">
              {row.original && <NewsMedia news={row.original} />}
            </div>
            <div className="c-news-card__body__left-part">
              <h4 className="c-news-card__title f-Exo-Bold">
                <a
                  href=""
                  onClick={async (e) => {
                    if (row.original?.news_slug) {
                      e.preventDefault()
                      await dispatch(getNewsStoryDetail(row.original.news_slug))
                      setIsOpen(true)
                    }
                  }}
                >
                  {row.original?.news_title}
                </a>
              </h4>
              <p className="c-news-card__text">
                {getShortDescription(
                  row.original && getDescription(row.original.news_description.toString()),
                  0,
                  30,
                  true,
                )}
              </p>
              <div className="c-news-card__date">
                {(row.original?.created_at &&
                  format(new Date(`${row.original?.created_at}`), 'dd MMM yyyy, hh:mm a')) ||
                  format(new Date(), 'dd MMM yyyy, hh:mm a')}
              </div>
            </div>
          </div>
        ),
      }),
      table.createDataColumn('id', {
        header: '',
        cell: ({row}) =>
          row.original && (
            <ListRight
              row={row.original}
              onClick={onUpdatePress}
              switchList={[
                {...SwitchChangeLive, status: row.original?.is_published},
                {...SwitchChangeFeatured, status: row.original?.is_featured_story},
              ]}
              moreOption={[MoreOptionEdit, MoreOptionDelete]}
            />
          ),
      }),
    ],
    [list],
  )

  const onUpdatePress = useCallback(
    async ({id, command}: UpdateListType) => {
      const selectedItem: NewsStoryType[] = list.filter((listItem) => listItem.id === id)
      if (command === 'changeLive') {
        const params = {
          status_key: 'published',
          status_value: !selectedItem[0].is_published ? 1 : 0,
        }
        await dispatch(updateNewsStoryStatus(selectedItem[0].news_slug, params))
      }
      if (command === 'changeFeatured') {
        const params = {
          status_key: 'featured',
          status_value: !selectedItem[0].is_featured_story ? 1 : 0,
        }
        await dispatch(updateNewsStoryStatus(selectedItem[0].news_slug, params))
      }
      if (command === 'deleteConfirmed') {
        await dispatch(deleteNewsStory(selectedItem[0].news_slug))
        setIsOpenPopup(false)
      }
      if (command === 'delete') {
        setCurrentItemId(id)
        setIsOpenPopup(true)
      }
      if (command === 'edit') {
        await dispatch(getNewsStoryDetail(selectedItem[0].news_slug))
        setIsOpen(true)
      }
      setSelectedRow(undefined)
    },
    [selectedRow, list],
  )

  const handleResetSort = () => {
    setdefaultOption([optionList[0]])
    setOptions({...options, sort_by: 'created_at', sort_order: 'desc', page: 1})
  }

  const onSortChange = useCallback(
    (option: string) => {
      if (option === 'created_at-desc') {
        setOptions({...options, sort_by: 'created_at', sort_order: 'desc', page: 1})
      }
      if (option === 'created_at-asc') {
        setOptions({...options, sort_by: 'created_at', sort_order: 'asc', page: 1})
      }
      if (option === 'is_featured_story') {
        setOptions({...options, sort_by: 'is_featured_story', sort_order: 'desc', page: 1})
      }
      if (option === 'is_published') {
        setOptions({...options, sort_by: 'is_published', sort_order: 'desc', page: 1})
      }
    },
    [options],
  )

  const onPaginate = useCallback(
    (page: number) => {
      setOptions({...options, page})
    },
    [options],
  )

  const onLimit = useCallback(
    (per_page: number) => {
      setOptions({...options, per_page, page: 1})
    },
    [options],
  )

  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onRequestClose={() => {
          setNewType(0)
          setIsOpen(false)
          setSingleTab(undefined)
        }}
      >
        <AddNewsStory
          setIsOpen={setIsOpen}
          newsType={newsType}
          setNewType={setNewType}
          singleTab={singleTab}
          setSingleTab={setSingleTab}
          handleResetSort={handleResetSort}
        />
      </Modal>
      <Button
        icon="c-icon--plus"
        title="New Story"
        classAddon="c-button--we-160 c-button--icons mb-30 f-Exo-Bold"
        onClick={() => {
          dispatch(clearNewsStoryDetail())
          setIsOpen(true)
        }}
      />
      <Popup
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        onPressDelete={() => onUpdatePress({id: currentItemId, command: 'deleteConfirmed'})}
        title={t('delete')}
        description={t('deleteText')}
      />
      <div className="table-outer">
        <div className="table-header">
          <Sort
            optionList={optionList}
            onSortChange={onSortChange}
            classAddon="p-3"
            defaultOption={defaultOption}
          />
        </div>
        <ListView
          columns={columns}
          data={list}
          total={total}
          onPaginate={onPaginate}
          forcePageIndex={options.page - 1}
          options={options}
          isPageSize
          onLimit={onLimit}
          pageSize={options?.per_page}
        />
      </div>
    </>
  )
})

export default NewsList
