import React, {useEffect} from 'react'
import {format} from 'date-fns'
import {
  newsStoryWidget,
  lastNewsWidget,
  matchAgainstWidget,
  matchAgainstYesterdayWidget,
  lastedtVideoWidget,
  howDidMatchAgainstWidget,
  clubRevenueWidget,
} from '../../data'
import HowDidMatchAgainstWidget from './howDidMatchAgainstWidget'
import LastestVideoWidget from './lastestVideoWidget'
import LastNewsWidget from './lastNewsWidget'
import MatchAgainstYesterdayWidget from './matchAgainstYesterdayWidget'
import NewStoryWidget from './newStoryWidget'
import UpcomingMatchWidget from './upcomingMatchWidget'
import ClubRevenue from './clubRevenue'
import {useSelector, useAppDispatch} from '../../store'
import {getWidgetClubData} from '../../store/actions'
import images from '../../assets/images'

const Home = () => {
  const {widget, userData} = useSelector(({widgetClub, user}) => ({
    widget: widgetClub.widgetClub,
    userData: user.user,
  }))
  const dispatch = useAppDispatch()

  const nextOpponentClub =
    widget?.next_match_fixture?.club?.club_slug === userData.club_data?.club_slug
      ? 'opponent_club'
      : 'club'

  const pastOpponentClub =
    widget?.past_match_fixture?.club?.club_slug === userData.club_data?.club_slug
      ? 'opponent_club'
      : 'club'

  const currentOpponentClub =
    widget?.current_match_fixture?.club?.club_slug === userData.club_data?.club_slug
      ? 'opponent_club'
      : 'club'

  const pastMatchclubLogoMedia =
    widget &&
    widget.past_match_fixture &&
    widget.past_match_fixture[`${pastOpponentClub}`] &&
    widget.past_match_fixture[`${pastOpponentClub}`].medias_model

  const currentMatchclubLogoMedia =
    widget &&
    widget.current_match_fixture &&
    widget.current_match_fixture[`${currentOpponentClub}`] &&
    widget.current_match_fixture[`${currentOpponentClub}`].medias_model

  const nextMatchclubLogoMedia =
    widget &&
    widget.next_match_fixture &&
    widget.next_match_fixture[`${nextOpponentClub}`] &&
    widget.next_match_fixture[`${nextOpponentClub}`].medias_model

  useEffect(() => {
    dispatch(getWidgetClubData())
  }, [])
  const lastArticalDate = new Date(widget.latest_artical?.created_at).setDate(
    new Date(widget.latest_artical?.created_at).getDate() + 1,
  )
  const pastMatchFixtureDate = new Date(widget.past_match_fixture?.match_start_time).setDate(
    new Date(widget.past_match_fixture?.match_start_time).getDate() + 1,
  )
  return (
    <div className="row">
      <div className="col-lg">
        {widget.latest_artical &&
          format(new Date(lastArticalDate), 'yyyy-MM-dd') < format(new Date(), 'yyyy-MM-dd') && (
            <NewStoryWidget item={newsStoryWidget} />
          )}
        {false && (
          <LastNewsWidget item={lastNewsWidget} lastStoryInfo={widget?.latest_news_video} />
        )}
        {widget.next_match_fixture && (
          <UpcomingMatchWidget
            item={matchAgainstWidget}
            nextMatchInfo={widget.next_match_fixture}
            clubLogo={
              Array.isArray(nextMatchclubLogoMedia) && nextMatchclubLogoMedia.length > 0
                ? nextMatchclubLogoMedia[0].original_url
                : images.dummyUser
            }
          />
        )}
        {widget.past_match_fixture &&
          format(new Date(pastMatchFixtureDate), 'yyyy-MM-dd') <
            format(new Date(), 'yyyy-MM-dd') && (
            <MatchAgainstYesterdayWidget
              item={matchAgainstYesterdayWidget}
              clubLogo={
                Array.isArray(pastMatchclubLogoMedia) && pastMatchclubLogoMedia.length > 0
                  ? pastMatchclubLogoMedia[0].original_url
                  : images.dummyUser
              }
            />
          )}
        {widget.latest_news_video &&
          Array.isArray(widget.latest_news_video.medias_model) &&
          widget.latest_news_video.medias_model.length > 0 && (
            <LastestVideoWidget
              item={lastedtVideoWidget}
              lastStoryInfo={widget?.latest_news_video}
            />
          )}
        {widget.current_match_fixture && (
          <HowDidMatchAgainstWidget
            item={howDidMatchAgainstWidget}
            clubLogo={
              Array.isArray(currentMatchclubLogoMedia) && currentMatchclubLogoMedia.length > 0
                ? currentMatchclubLogoMedia[0].original_url
                : images.dummyUser
            }
            matchContentId={widget.current_match_fixture?.id.toString()}
          />
        )}
      </div>
      <div>
        <ClubRevenue item={clubRevenueWidget} />
      </div>
    </div>
  )
}
export default Home
