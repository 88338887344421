import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {NewsStoryType} from '../actions/types'

export interface NewsState {
  newsList: NewsStoryType[]
  newsDetail: NewsStoryType
  total: number
}
const initialNewsDetail = {
  id: 0,
  news_slug: '',
  news_type: 0,
  medias_model: [],
  news_title: '',
  news_description: [{type: 'paragraph', children: [{text: ''}]}],
  created_at: '',
  published_at: '',
  is_published: false,
  is_featured_story: false,
  feed_type: '0',
  meta_title: '',
  meta_description: '',
}
const initialState = {newsList: [], newsDetail: initialNewsDetail, total: 0} as NewsState

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    updateNewsList: (state, action: PayloadAction<Partial<NewsState>>) => {
      const {newsList, total} = action.payload

      state.newsList = newsList || state.newsList
      state.total = total || state.total
    },
    updateNewsDetail: (state, action: PayloadAction<Partial<NewsState>>) => {
      state.newsDetail = action.payload.newsDetail || state.newsDetail
    },
    clearNewsDetail: (state) => {
      state.newsDetail = initialState.newsDetail
    },
  },
})
