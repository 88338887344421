import Api, {urls} from '../../services/api'
import {ApiResponseType, WidgetClubType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {widgetClubAction} from '../slices'
import {AppDispatch} from '..'
import {apiErrorBlock} from '../../functions/utils'

export const getWidgetClubData = () => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseType<WidgetClubType> = await Api.get(`${urls.widgetClub}`)
    const {status, message, data} = response.data
    if (status && data) {
      await dispatch(
        widgetClubAction.updateWidgetClub({
          widgetClub: data,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
}
