import React from 'react'
import WidgetFooter from './renderItem/widgetFooter'
import WidgetHeader from './renderItem/widgetHeader'
import {NewsStoryWidgetType} from './type'

const NewStoryWidget = ({item}: NewsStoryWidgetType) => {
  return (
    <div className="c-card">
      <WidgetHeader item={item.info} />
      <WidgetFooter item={item.buttonList} />
    </div>
  )
}
export default NewStoryWidget
