import axios, {AxiosInstance} from 'axios'
import baseUrl from '../configs/settings'

const Api: AxiosInstance = axios.create({
  baseURL: baseUrl,
})

export const setAuthorization = (token: string) => {
  Api.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
  }
}

export const urls = {
  newsStory: '/news-story',
  newsStoryUpdateStatus: '/news-story/update-status',
  testmonials: '/testimonials',
  faqs: '/faqs',
  benefits: '/gdx-benefits',
  carousal: '/carousals',
  matchContent: '/match-content',
  club: '/club',
  userManagement: '/user',
  permission: '/permission',
  role: '/role',
  rolespermission: '/role-permissions',
  rolespermissionAssign: '/role-permission-assign',
  rolespermissionUnassign: '/role-permission-unassign',
  appSettings: '/application-settings',
  clubRequest: '/club-pending-approval',
  clubStatusUpdate: '/club/update-request-status',
  fixture: 'match-fixture',
  mediaLibrary: '/media-library',
  tags: '/tags',
  clubTags: '/club-tags',
  player: '/club-player',
  countryList: '/front/country',
  clubSetting: '/club-config-settings',
  masterSetting: '/master-config-settings',
  leagues: '/leagues',
  matchLiveCommentary: '/match-commentary',
  matchCommentaryEvent: '/commentary-type',
  matchInfo: 'match_info',
  matchReport: '/match-report',
  matchFinalScore: 'final_score',
  moderation: '/content-moderation',
  facebookInfo: 'https://graph.facebook.com',
  masterAccountSeeting: '/master/get-user-profile',
  clubAccountSeeting: '/clubs/get-club-profile',
  masterAccountSeetingUpdate: '/master/update-profile',
  clubAccountSeetingUpdate: '/clubs/update-club-profile',
  matchMedia: '/match-media',
  twitterInfo: 'https://api.twitter.com',
  instragramInfo: 'https://graph.instagram.com',
  ads: '/club-ads-unit',
  widgetClub: '/club-dashboard/get-common-widget-data',
  SocialConfigGet: '/get-club-social-config',
  SocialConfigSet: '/set-club-social-config',
  clubFixture: '/club-match-fixture',
  clubFixtureRequest: '/get-club-match-fixture-list',
  clubStatusFixtureRequest: '/status-club-match-fixture-list',
  SocialConfigExpireGet: '/validate-club-social-token',
}

export default Api
