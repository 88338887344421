import React, {memo, useCallback, useEffect, useMemo, useState} from 'react'
import {createTable} from '@tanstack/react-table'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {format} from 'date-fns'
// import {UpdateListType} from '../../component/type'
import {Button, List as ListView} from '../../component'
// import ListRight from '../../component/listRight'
import {
  clearFixtureDetail,
  // deleteFixture,
  // getFixtureDetail,
  getFixtureList,
} from '../../store/actions'
import {useSelector, useAppDispatch} from '../../store'
import {FixtureType} from '../../store/actions/types'
// import {MoreOptionEdit, MoreOptionDelete} from '../../component/List/Commands'
import {defaultPageSize} from '../../configs/paginationSetting'
import images from '../../assets/images'

const table = createTable().setRowType<FixtureType>()

const MatchResultList = memo(() => {
  const {t} = useTranslation()
  // const [isOpenPopup, setIsOpenPopup] = useState(false)
  // const [currentItemId, setCurrentItemId] = useState(0)
  // const [loading, setLoading] = useState(false)
  const {list, total, from} = useSelector(({fixture}) => ({
    list: fixture.fixtureList,
    total: fixture.total,
    from: fixture.from,
  }))
  const defaultOptions = useMemo(
    () => ({
      sort_by: 'match_start_time',
      sort_order: 'desc',
      page: 1,
      per_page: defaultPageSize,
      is_past: 1,
    }),
    [],
  )
  const [options, setOptions] = useState(defaultOptions)
  const dispatch = useAppDispatch()
  const navigation = useNavigate()
  useEffect(() => {
    dispatch(getFixtureList(options))
  }, [options])

  const columns = useMemo(
    () => [
      table.createDataColumn('id', {
        size: 150,
        header: ' ',
        cell: ({row}) => (
          <div className="flex-1-center-v">
            <span className="f-Exo-Bold f-0-3 fn-30">
              {String(new Date(row.original?.match_start_time || new Date()).getDate()).padStart(
                2,
                '0',
              )}
            </span>
            <div className="flex-1 ml-1 flex-d-column fn-12">
              <span className="f-Exo-Bold ">
                {format(new Date(row.original?.match_start_time || new Date()), 'MMM')}
              </span>
              <span>
                {format(new Date(row.original?.match_start_time || new Date()), 'EEEEEE')}
              </span>
            </div>
          </div>
        ),
      }),
      table.createDataColumn('club', {
        size: 185,
        header: ' ',
        cell: ({row}) => (
          <div className="flex-1 h-space-bet">
            <div className="club-name-outer">{row.original?.club?.club_name}</div>
            <div className="flex-1 h-flex-end">
              <img
                src={row.original?.club?.medias_model[0]?.original_url || images.dummyUser}
                alt=""
                className="img_preview-match-content"
              />
              {/* TODO: Club logo is not coming from API */}
            </div>
          </div>
        ),
      }),
      table.createDataColumn('id', {
        header: ' ',
        size: 100,
        cell: ({row}) => (
          <div className="flex-1 h-center">
            <div className="score-box-outer mr-2 f-Exo-Bold ">
              <span className="fn-26">{row.original?.club_final_score}</span>
            </div>
            <div className="score-box-outer f-Exo-Bold ">
              <span className="fn-26">{row.original?.opponent_final_score}</span>
            </div>
          </div>
        ),
      }),
      table.createDataColumn('opponent_club', {
        header: ' ',
        size: 185,
        cell: ({row}) => (
          <div className="flex-1 h-space-bet">
            <div className="flex mr-4 h-flex-start">
              <img
                src={row.original?.opponent_club?.medias_model[0]?.original_url || images.dummyUser}
                alt=""
                className="img_preview-match-content"
              />
              {/* TODO: Club logo is not coming from API */}
            </div>
            <div className="club-name-outer f-1">{row.original?.opponent_club?.club_name}</div>
          </div>
        ),
      }),
      table.createDataColumn('match_vanue', {
        header: ' ',
        size: 200,
        cell: ({row}) => (
          <div className="flex flex-d-column fn-12">
            <span>{row.original?.match_vanue}</span>
            <div className="">
              <span>{format(new Date(row.original?.match_start_time || new Date()), 'hh:mm')}</span>
              {' - '}
              <span>
                {format(new Date(row.original?.match_start_time || new Date()), 'hh:mm')}
              </span>{' '}
              {/* TODO: End date is not coming from API */}
            </div>
          </div>
        ),
      }),
      table.createDataColumn('id', {
        header: undefined,
        cell: ({row}) => row.original && <></>,
      }),
    ],
    [from],
  )

  // const onUpdatePress = useCallback(async ({id, command}: UpdateListType) => {
  //   if (command === 'deleteConfirmed') {
  //     setLoading(true)
  //     await dispatch(deleteFixture(id, options))
  //     setIsOpenPopup(false)
  //     setLoading(false)
  //   }
  //   if (command === 'delete') {
  //     setCurrentItemId(id)
  //     setIsOpenPopup(true)
  //   }
  //   if (command === 'edit') {
  //     const response = await dispatch(getFixtureDetail(id))
  //     if (response) navigation(`/match-fixture/${id}`)
  //   }
  // }, [])

  const onPaginate = useCallback(
    (page: number) => {
      setOptions({...options, page})
    },
    [options],
  )

  const onLimit = useCallback(
    (per_page: number) => {
      setOptions({...options, per_page, page: 1})
    },
    [options],
  )

  const onAddNewPress = useCallback(() => {
    dispatch(clearFixtureDetail())
    navigation(`/match-fixture/add`)
  }, [])
  return (
    <div className="table-outer">
      <div className="table-header">
        <div className="c-carousal__header">
          <div className="c-carousal__header__lpart v-center">
            <span className="f-Exo-Bold title-color">{t('matchResultList')}</span>
          </div>
          <div className="c-carousal__header__rpart display-f v-center h-center">
            <Button
              icon="c-icon--plus"
              title={t('addFixture')}
              classAddon="c-button--we-160 c-button--icons f-Exo-Medium"
              onClick={onAddNewPress}
            />
            {/* <Popup
              isOpen={isOpenPopup}
              setIsOpen={setIsOpenPopup}
              onPressDelete={() => onUpdatePress({id: currentItemId, command: 'deleteConfirmed'})}
              title={t('delete')}
              description={t('deleteText')}
              loading={loading}
            /> */}
          </div>
        </div>
      </div>
      <ListView
        columns={columns}
        data={list}
        total={total}
        onPaginate={onPaginate}
        forcePageIndex={options.page - 1}
        options={options}
        isPageSize
        onLimit={onLimit}
        pageSize={options?.per_page}
      />
    </div>
  )
})

export default MatchResultList
