import axios from 'axios'
import {apiErrorBlock} from '../../functions/utils'
import {urls} from '../../services/api'
import {socialKeys} from '../../services/socialKey'

export const getInstragramLongLiveToken = (access_token: string) => async () => {
  try {
    const response: any = await axios.get(
      `${urls.instragramInfo}/access_token?grant_type=ig_exchange_token&client_secret=${socialKeys.instragram.client_secret}&access_token=${access_token}`,
    )
    return response.data
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
