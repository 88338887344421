import React, {memo} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {Navigation} from 'swiper'
import ReactPlayer from 'react-player'
import {useTranslation} from 'react-i18next'
import {format} from 'date-fns'
import {MediaType, NewsType, PreviewDataType} from './type'
import images from '../../assets/images'
import 'swiper/css/bundle'
import {Button, RichTextInput} from '../../component'
import {socialMedia} from '../../data'
import {checkRichTextEmpty} from '../../functions/utils'
import {useSelector} from '../../store'

SwiperCore.use([Navigation])
const Preview = ({tabData, news_type}: {tabData: PreviewDataType; news_type: NewsType}) => {
  const {name, club_data} = useSelector(({user}) => ({
    name: user.user.name,
    club_data: user.user.club_data,
  }))
  const {medias_model, attach_media} = tabData
  let media: MediaType[] = []
  if (medias_model && medias_model.length > 0) {
    media = [...media, ...medias_model]
  }
  if (attach_media && attach_media.length > 0) {
    media = [...media, ...(attach_media as MediaType[])]
  }
  const {t} = useTranslation()
  const getArticleView = (item: MediaType[]) => {
    if (Array.isArray(item) && item.length === 1) {
      return item.map((image) => (
        <img
          src={image.original_url}
          alt={image.file_name}
          className="preview-media-box"
          key={`image-slider-${image.medias_id}`}
        />
      ))
    }
    return <div className="preview-media-box" />
  }
  const getGalleryView = (item: MediaType[]) => {
    if (Array.isArray(item) && item.length > 0) {
      return (
        <Swiper id="main" navigation slidesPerView={1} spaceBetween={0}>
          {item.length > 0 &&
            item.map((image) => (
              <SwiperSlide key={`image-slider-${image.medias_id}`}>
                <img src={image.original_url} alt={image.file_name} className="preview-media-box" />
              </SwiperSlide>
            ))}
        </Swiper>
      )
    }
    return <img src={images.blankGallery} alt="play-button" className="preview-media-box" />
  }
  const getVideoView = (item: MediaType[]) => {
    if (Array.isArray(item) && item.length === 1) {
      return item.map((image) => (
        <ReactPlayer
          key={`video-slider-${image.original_url}`}
          className="preview-media-box"
          controls
          playing={false}
          url={image.original_url}
        />
      ))
    }
    return <img src={images.playButton} alt="play-button" height={70} width={70} />
  }
  return (
    <div className="col-md-4 mw-350">
      <div className="c-form-group">
        <span className="form-lable">{t('preview')}</span>
        <div className="c-form-group__preview pb-4">
          <div className="text-center mb-1">
            <i className="c-icon c-icon--mobile ml-0" />
          </div>
          <div className="ns-preview-shadow">
            <header className="ns-header pl-0 pr-0">
              <div className="container-fluid">
                <div className="row inner-ns-header font-bold">
                  <div>
                    <Button className="c-logo">
                      <img src={images.wa} alt="club-logo" height={45} width={45} />
                    </Button>
                  </div>
                  <div className="ns-header-title">{club_data?.club_name}</div>
                  <div className="ns-header-menu">
                    <i className="c-icon c-icon--menu" style={{width: '2rem'}} />
                  </div>
                </div>
              </div>
            </header>
            <div className="preview-scrolling ">
              <div className="ns-media-main">
                {news_type === 0 && media && getArticleView(media)}
                {news_type === 1 && media && getGalleryView(media)}
                {news_type === 2 && media && getVideoView(media)}
                {news_type === 0 && (
                  <div className="article-content pl-5 ml-3">
                    <div className="fn-11">{t('clubNews')}</div>
                    <div className="font-bold fn-20">
                      {tabData.news_title && tabData.news_title.length > 30
                        ? `${tabData.news_title.substring(0, 30)}...`
                        : tabData.news_title || t('headline')}
                    </div>
                  </div>
                )}
                <div className="ns-media-back pt-2 pl-5 ml-3">
                  {news_type !== 1 && <i className="c-icon c-icon--arrow-left" />}
                </div>
              </div>
              <div className="container-fluid mt-3">
                <div className="row inner-ns-header">
                  <div>
                    <Button className="c-logo">
                      <img src={images.wa} alt="" height={30} width={30} />
                    </Button>
                  </div>
                  <div className="ns-header-title lh-15">
                    <span className="fs-12 font-bold">{name}</span>
                    <div className="preview-bio-sub-title">
                      <i className="c-icon c-icon--clock" style={{height: '10px', width: '10px'}} />
                      <span className="ml-1">
                        {(tabData?.created_at &&
                          format(new Date(`${tabData.created_at}`), 'dd MMM')) ||
                          format(new Date(), 'dd MMM')}
                      </span>
                    </div>
                  </div>
                  <div className="ns-header-menu pr-0">
                    <span>
                      {Array.isArray(socialMedia) &&
                        socialMedia.map((socialMediaItem) => (
                          <i
                            className={`c-icon c-icon${socialMediaItem.icon} ml-2`}
                            key={`profile-drop-down-list-${socialMediaItem.name}`}
                          />
                        ))}
                    </span>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt-3">
                <div className="mt-3 mb-1 font-bold">
                  {(news_type !== 0 && tabData.news_title) ||
                    (news_type === 0 && t('introText')) ||
                    t('headline')}
                </div>
                <div className="content-desc">
                  <RichTextInput
                    value={
                      checkRichTextEmpty(tabData.news_description)
                        ? tabData.news_description
                        : [
                            {
                              type: 'paragraph',
                              children: [{text: news_type === 0 ? t('article') : t('description')}],
                            },
                          ]
                    }
                    isDisabled
                    onChange={() => undefined}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Preview)
