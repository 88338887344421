import Api, {urls} from '../../services/api'
import {ApiResponseList, ApiResponseType, SortingType, BenefitType} from './types'
import logException from '../../services/sentry'
import showNotification from '../../functions/notification'
import {benefitActions} from '../slices'
import {AppDispatch} from '..'
import {apiErrorBlock} from '../../functions/utils'

export const getBenefitList = (params: SortingType) => async (dispatch: AppDispatch) => {
  try {
    const {page} = params
    const response: ApiResponseList<BenefitType[]> = await Api.get(
      `${urls.benefits}?page=${page}${
        params.per_page && params.per_page > 0 ? `&per_page=${params.per_page}` : ''
      }`,
    )
    const {status, message, data} = response.data
    if (status && data?.data) {
      await dispatch(
        benefitActions.updateBenefitList({
          benefitList: data.data,
          total: data.total || 0,
          from: data.from || 0,
        }),
      )
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
  } catch (e: any) {
    apiErrorBlock(e)
  }
}

export const addBenefit = (params: FormData) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<BenefitType[]> = await Api.post(`${urls.benefits}`, params)
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getBenefitList({page: 1}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const updateBenefit = (id: number, params: FormData) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseList<BenefitType[]> = await Api.post(
      `${urls.benefits}/${id}`,
      params,
    )
    const {status, message} = response.data
    if (status && message) {
      if (message) showNotification({title: 'Success', message, type: 'success'})
      await dispatch(getBenefitList({page: 1}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}

export const getBenefitDetail = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const response: ApiResponseType<BenefitType> = await Api.get(`${urls.benefits}/${id}`)
    const {status, message, data} = response.data
    if (status && data) {
      await dispatch(benefitActions.updateBenefitDetail({benefitDetail: data}))
      return true
    }
    logException(new Error(message))
    if (message) showNotification({title: 'Error', message, type: 'danger'})
    return false
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const clearBenefitDetail = () => async (dispatch: AppDispatch) => {
  try {
    await dispatch(benefitActions.clearBenefitDetail())
    return true
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const deleteBenefit =
  (id: number, options: SortingType) => async (dispatch: AppDispatch) => {
    try {
      const response: ApiResponseList<BenefitType[]> = await Api.delete(`${urls.benefits}/${id}`)
      const {status, message} = response.data
      if (status && message) {
        if (message) showNotification({title: 'Success', message, type: 'success'})
        await dispatch(getBenefitList(options))
        return true
      }
      logException(new Error(message))
      if (message) showNotification({title: 'Error', message, type: 'danger'})
      return false
    } catch (e: any) {
      apiErrorBlock(e)
      return false
    }
  }
