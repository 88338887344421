import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {SocialConfigType} from '../actions/types'

export interface SocialConfigState {
  socialConfig?: SocialConfigType
}

const initialState = {socialConfig: {}} as SocialConfigState

export const socialConfigSlice = createSlice({
  name: 'socialConfig',
  initialState,
  reducers: {
    updateSocialConfig: (state: SocialConfigState, action: PayloadAction<SocialConfigType>) => {
      state.socialConfig = {...state.socialConfig, ...action.payload}
    },
    clearSocialConfig: (state: SocialConfigState, action: PayloadAction<SocialConfigType>) => {
      state.socialConfig = {...state.socialConfig, ...action.payload}
    },
  },
})
