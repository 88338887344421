/* eslint-disable react/no-children-prop */
import React, {memo, useEffect, useState} from 'react'
import REAutocomplete from 'react-autocomplete'
import {ClubType} from '../store/actions/types'
import {EventType} from '../container/contentManagement/type'
import {useAppDispatch} from '../store'
import {getClubListWithoutRedux} from '../store/actions'
import {AutoClubSelect} from './type'
import {defaultPageSize} from '../configs/paginationSetting'

const ClubSearch = memo(
  ({handleChange, setFieldValue, classNameOuter, defaultValue, error}: AutoClubSelect) => {
    const dispatch = useAppDispatch()
    const [clubList, setClubList] = useState<ClubType[]>()
    const [searchTerms, setSearchTerms] = useState<string>(defaultValue || '')

    const handleSelect = (value: string) => {
      const selectedClub =
        clubList && clubList.filter((club) => club.club_name.toString() === value)[0]
      if (selectedClub) {
        handleChange(selectedClub, setFieldValue)
      } else {
        handleChange(
          {club_name: value, id: 0, request_status: 0, created_at: '', league_id: 0},
          setFieldValue,
        )
      }
      setSearchTerms(value)
    }
    const handleFetClub = async () => {
      const totalClubList = await dispatch(
        getClubListWithoutRedux({
          page: 1,
          request_status: 3,
          per_page: defaultPageSize,
          sort_by: 'club_name',
          sort_order: 'asc',
          club_name: searchTerms || '',
        }),
      )
      setClubList(totalClubList)
    }
    useEffect(() => {
      handleFetClub()
    }, [searchTerms])
    useEffect(() => {
      setSearchTerms(defaultValue || '')
    }, [defaultValue])

    return (
      <div className={`c-form-group mb-0 ${classNameOuter || ''}`}>
        {Array.isArray(clubList) && (
          <REAutocomplete
            getItemValue={(item: ClubType) => item.club_name}
            items={clubList}
            renderMenu={(items, value, styles) => (
              <div style={{...styles}} className="dropdown-style" children={items} />
            )}
            renderItem={(item, isHighlighted) => (
              <div
                className="py-1 px-3 dropdown-box"
                style={{
                  background: isHighlighted ? '#CF0B48' : 'white',
                  color: isHighlighted ? '#ffffff' : '#000000',
                }}
              >
                {item.club_name}
              </div>
            )}
            renderInput={(props) => (
              <input placeholder="Search Club" {...props} className="form-control h-100" />
            )}
            wrapperStyle={{height: '100%'}}
            value={searchTerms}
            onChange={(e: EventType) => {
              handleSelect('')
              setSearchTerms(e.target.value)
            }}
            onSelect={(value: string) => {
              handleSelect(value)
            }}
          />
        )}
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    )
  },
)

export default ClubSearch
