import React, {memo, useCallback, useState} from 'react'
import {Form, Formik} from 'formik'
import {t} from 'i18next'
import * as Yup from 'yup'
import {Button, DragFile, Input, DragBackPreview, Popup} from '../../component'
import {mapFilesToObject, serializeFormData} from '../../functions/utils'
import {saveMediaItem, deleteMediaItem, MatchMediaItem} from '../../store/actions'
import {useAppDispatch} from '../../store'
import {MediaFormProps, MediaFormValues} from './types'
import {MediaType} from '../news/type'

const MediaForm: React.FC<MediaFormProps> = memo((props) => {
  const {onClose, editItem, defaultSlug, accept, formTitle, placeHolder} = props
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const initialValues = {
    media_library_id: editItem?.id || undefined,
    title: editItem?.title || '',
    description: editItem?.description || '',
    tags: editItem?.media_tags?.map((tag) => tag.name).join(',') || '',
    file: editItem
      ? [
          {
            medias_id: editItem.id,
            original_url: editItem.original_url,
            file_name: editItem.file_name,
          },
        ]
      : undefined,
    file_type: editItem?.file_type || 0,
  }
  const dispatch = useAppDispatch()
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .required(`${t('titleCannotBeEmpty')}.`),
    file: Yup.mixed().test(t('fileRequired'), `${t('mediaFilesCannotBeEmpty')}.`, (value) => {
      if (!value || !value.length || value.length <= 0) return false
      return true
    }),
  })
  const saveMedia = useCallback(async (formValues: MediaFormValues) => {
    if (defaultSlug && formValues.tags) {
      formValues.tags += `,${defaultSlug}`
    }
    try {
      if (defaultSlug) {
        const mediaItem = serializeFormData({
          match_fixture_id: defaultSlug,
          image:
            formValues.file && formValues.file.length > 0 && !formValues.media_library_id
              ? formValues.file.map((data) => data)
              : undefined,
          file_type: formValues.file_type,
        })
        const isSaved = await dispatch(MatchMediaItem(mediaItem, defaultSlug))
        if (isSaved) onClose()
      } else {
        const mediaItem = serializeFormData({
          ...formValues,
          tags: undefined,
          file:
            formValues.file && formValues.file.length > 0 && !formValues.media_library_id
              ? formValues.file[0]
              : undefined,
          tag_title: formValues?.tags ? formValues.tags.split(',').map((tag) => tag.trim()) : [],
        })
        const isSaved = await dispatch(saveMediaItem(mediaItem, defaultSlug))
        if (isSaved) onClose()
      }
    } catch (e) {
      console.log(e)
    }
  }, [])

  return (
    <Formik
      onSubmit={saveMedia}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({errors, touched, submitForm, setFieldValue, values}) => (
        <Form>
          <div className="c-image-details c-popup">
            <Popup
              isOpen={isOpenPopup}
              setIsOpen={setIsOpenPopup}
              onPressDelete={async () => {
                if (values.media_library_id) {
                  await dispatch(deleteMediaItem(values.media_library_id, defaultSlug))
                  onClose()
                }
              }}
              title={t('delete')}
              description={t('deleteText')}
            />
            <div className="row no-gutters">
              <div className="col-lg-7">
                <div className="c-image-details__image h-100 w-100 m-0">
                  {!values.media_library_id ? (
                    <DragFile
                      accept={accept || {'*/*': []}}
                      maxFiles={1}
                      placeholder={placeHolder || 'Drag an image/video here'}
                      btn1Title="Choose a file to upload"
                      files={values.file ? values.file : []}
                      error={touched.file && (errors.file as string | boolean)}
                      isThumbnail={false}
                      isVideo
                      onChange={(acceptedFiles: MediaType[]) => {
                        const newFiles = mapFilesToObject(acceptedFiles)
                        if (newFiles && newFiles.length > 0) {
                          setFieldValue('file', [newFiles[0]])
                          setFieldValue('title', newFiles[0].name || values.title)
                          setFieldValue(
                            'file_type',
                            newFiles[0]?.file?.type?.includes('video') ? 1 : 0,
                          )
                        }
                      }}
                      classAddonInner="h-100"
                      classAddonOuter="h-100"
                      onRemove={() => {}}
                    />
                  ) : (
                    <DragBackPreview
                      files={values.file ? values.file : []}
                      classAddonOuter="h-100"
                      file_type={values.file_type}
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-5">
                <div className="c-image-details__body">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {values.file_type
                        ? 'Video'
                        : values.file
                        ? 'Image'
                        : formTitle || 'Image/Video'}{' '}
                      details
                    </h5>
                    <Button icon="c-icon--close" className="" onClick={onClose} />
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <Input
                        name="title"
                        label="Title"
                        onChange={(e: {target: {value: string}}) => {
                          setFieldValue('title', e.target.value)
                        }}
                        error={touched.title && errors.title}
                      />
                    </div>
                    <div className="col-lg-12">
                      <Input
                        name="description"
                        label="Description"
                        onChange={(e: {target: {value: string}}) => {
                          setFieldValue('description', e.target.value)
                        }}
                        error={touched.description && errors.description}
                        component="textarea"
                        rows={5}
                      />
                    </div>
                    <div className="col-lg-12">
                      <Input
                        name="tags"
                        label="Tags"
                        onChange={(e: {target: {value: string}}) => {
                          setFieldValue('tags', e.target.value)
                        }}
                        error={touched.tags && errors.tags}
                        placeholder="Separate tags with commas"
                      />
                    </div>
                    <div className="col-lg-12">
                      <div className="c-button-group">
                        <div className="">
                          <Button
                            title={t('save')}
                            classAddon="c-button--br f-Exo-Bold"
                            onClick={submitForm}
                          />
                          <button
                            className="c-button c-button--bold c-button--br c-button--br--gray-ab f-Exo-Bold"
                            type="button"
                            onClick={onClose}
                          >
                            Cancel
                          </button>
                        </div>
                        {values.media_library_id && (
                          <Button
                            buttonMode="button"
                            buttonType="button"
                            className="c-delete pointer"
                            onClick={() => setIsOpenPopup(true)}
                          >
                            <i className="c-icon c-icon--delete" />
                            Delete Image
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
})

export default MediaForm
