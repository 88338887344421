import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {FaqType} from '../actions/types'

export interface FaqState {
  faqList: FaqType[]
  total: number
  from: number
  faqDetail?: FaqType
}

const initialFaqDetail = {id: 0, title: '', description: '', created_at: ''}
const initialState = {
  faqList: [],
  total: 0,
  from: 0,
  faqDetail: initialFaqDetail,
} as FaqState

export const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    updateFaqList: (state, action: PayloadAction<FaqState>) => {
      const {faqList, total, from} = action.payload
      state.faqList = faqList || state.faqList
      state.total = total || state.total
      state.from = from || state.from
    },
    updateFaqDetail: (state, action: PayloadAction<Partial<FaqState>>) => {
      state.faqDetail = action.payload.faqDetail || state.faqDetail
    },
    clearFaqDetail: (state) => {
      state.faqDetail = initialState.faqDetail
    },
  },
})
