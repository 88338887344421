/* eslint-disable react/jsx-curly-newline */
import React, {memo} from 'react'
import {useTranslation} from 'react-i18next'
import {format} from 'date-fns'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {Button, Input} from '../../component'
import {MatchContentFinalScoreType} from './type'
import ClubInfo from './common/clubInfo'
import {EventType} from '../contentManagement/type'
import {useSelector} from '../../store'
import images from '../../assets/images'

const FinalScore = memo(
  ({item, initialValue, onSubmit, setFinalScoreValue}: MatchContentFinalScoreType) => {
    const {t} = useTranslation()
    const {userData} = useSelector(({user}) => ({
      userData: user.user,
    }))

    const opponentClub =
      item?.club?.club_name === userData.club_data?.club_name ? 'opponent_club' : 'club'
    const currentClub =
      item?.club?.club_name === userData.club_data?.club_name ? 'club' : 'opponent_club'
    const opponentClubMedia = item?.[`${opponentClub}`]?.medias_model
    const currentClubMedia = item?.[`${currentClub}`]?.medias_model
    const opponentClubScore =
      item?.club?.club_name === userData.club_data?.club_name ? 'opponentScore' : 'clubScore'
    const currentClubScore =
      item?.club?.club_name === userData.club_data?.club_name ? 'clubScore' : 'opponentScore'
    const opponentClubPenaltie =
      item?.club?.club_name === userData.club_data?.club_name ? 'opponentPenaltie' : 'clubPenaltie'
    const currentClubPenaltie =
      item?.club?.club_name === userData.club_data?.club_name ? 'clubPenaltie' : 'opponentPenaltie'

    const validationSchema = Yup.object().shape({
      [currentClubScore]: Yup.string().trim().required('Club Score cannot be empty.'),
      [opponentClubScore]: Yup.string().trim().required('Opponent Score cannot be empty.'),
      [currentClubPenaltie]: Yup.string().trim().required('Club Penaltie cannot be empty.'),
      [opponentClubPenaltie]: Yup.string().trim().required('Opponent Penaltie cannot be empty.'),
    })

    return (
      <div className="table-outer f-1 final-score-outer">
        <Formik
          initialValues={initialValue}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({touched, errors, setFieldValue, submitForm, values}) => (
            <Form>
              <div className="table-header bg-low-black">
                <div className="c-carousal__header no-border-b">
                  <div className="c-carousal__header__lpart v-center">
                    <span className="f-Exo-Bold f-color-white fn-18">{t('finalScore')}</span>
                  </div>
                  <div className="c-carousal__header__rpart flex-center">
                    <Button
                      title={t('save')}
                      type="submit"
                      classAddon=" c-button--icons f-Exo-Bold mr-2"
                      onClick={submitForm}
                    />
                    <div>
                      <Button
                        title={t('cancel')}
                        classAddon="c-button--br c-button--br--gray-ab bg-transparent f-Exo-Bold f-color-white"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1-center-v  flex-d-column">
                <div className="flex-1 flex-d-column">
                  <span className="f-Exo-Bold flex-1-center-h pt-4 fn-24">
                    {item?.[`${currentClub}`].club_name} vs {item?.[`${opponentClub}`]?.club_name}
                  </span>
                  <span className="flex-1-center-h pt-2 fn-14 visible-50">
                    <span className="f-Exo-Bold">
                      {format(new Date(item.match_start_time), 'd MMMM uuuu')}
                    </span>
                    , {item.match_vanue}, {format(new Date(item.match_start_time), 'kk:mm')}
                  </span>
                </div>
                <div className="flex-1 pt-4 pb-5 flex-d-column w-100">
                  <div className="flex-1 flex-d-row">
                    <div className="flex-1-center-h flex-d-column">
                      <ClubInfo
                        url={
                          (Array.isArray(currentClubMedia) &&
                            currentClubMedia.length > 0 &&
                            currentClubMedia[0].original_url) ||
                          images.dummyUser
                        }
                        clubName={item?.[`${currentClub}`].club_name}
                      />
                    </div>
                    <div className="flex-1-center-h flex-d-column">
                      <div className="flex-center-v flex-d-column">
                        <span className="fn-14">{t('fullTimeScore')}</span>
                        <span className="mt-1 flex flex-d-row">
                          <div className={`mr-2 `}>
                            <Input
                              type="number"
                              name={`${currentClubScore}`}
                              onChange={(e: EventType) => {
                                setFieldValue(`${currentClubScore}`, e.target.value)
                                setFinalScoreValue({
                                  ...values,
                                  [currentClubScore]: parseInt(e.target.value, 10),
                                })
                              }}
                              className={`fn-26 f-Exo-SemiBold final-score-box ${
                                touched[currentClubScore] && errors[currentClubScore]
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              classAddonOuter="mb-0"
                            />
                          </div>
                          <div>
                            <Input
                              type="number"
                              name={`${opponentClubScore}`}
                              onChange={(e: EventType) => {
                                setFieldValue(`${opponentClubScore}`, e.target.value)
                                setFinalScoreValue({
                                  ...values,
                                  [opponentClubScore]: parseInt(e.target.value, 10),
                                })
                              }}
                              className={`fn-26 f-Exo-SemiBold final-score-box ${
                                touched[opponentClubScore] && errors[opponentClubScore]
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              classAddonOuter="mb-0"
                            />
                          </div>
                        </span>
                        {touched[currentClubScore] && errors[currentClubScore] && (
                          <div className="invalid-feedback">
                            <span className="final-score-error">{errors[currentClubScore]}</span>
                          </div>
                        )}
                        {touched[opponentClubScore] && errors[opponentClubScore] && (
                          <div className="invalid-feedback">
                            <span className="final-score-error">{errors[opponentClubScore]}</span>
                          </div>
                        )}
                      </div>
                      <div className="flex-center-v pt-3 flex-d-column">
                        <span className="fn-14">{t('penalties')}</span>
                        <span className="mt-1 flex flex-d-row">
                          <div className={`mr-2 `}>
                            <Input
                              type="number"
                              name={`${currentClubPenaltie}`}
                              onChange={(e: EventType) => {
                                setFieldValue(`${currentClubPenaltie}`, e.target.value)
                                setFinalScoreValue({
                                  ...values,
                                  [currentClubPenaltie]: parseInt(e.target.value, 10),
                                })
                              }}
                              className={`fn-16 f-Exo-SemiBold penaltie-box ${
                                touched[currentClubPenaltie] && errors[currentClubPenaltie]
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              classAddonOuter="mb-0"
                            />
                          </div>
                          <div className={`mr-2 `}>
                            <Input
                              type="number"
                              name={`${opponentClubPenaltie}`}
                              onChange={(e: EventType) => {
                                setFieldValue(`${opponentClubPenaltie}`, e.target.value)
                                setFinalScoreValue({
                                  ...values,
                                  [opponentClubPenaltie]: parseInt(e.target.value, 10),
                                })
                              }}
                              className={`fn-16 f-Exo-SemiBold penaltie-box ${
                                touched[opponentClubPenaltie] && errors[opponentClubPenaltie]
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              classAddonOuter="mb-0"
                            />
                          </div>
                        </span>
                        {touched[currentClubPenaltie] && errors[currentClubPenaltie] && (
                          <div className="invalid-feedback">
                            <span className="final-score-error">{errors[currentClubPenaltie]}</span>
                          </div>
                        )}
                        {touched[opponentClubPenaltie] && errors[opponentClubPenaltie] && (
                          <div className="invalid-feedback">
                            <span className="final-score-error">
                              {errors[opponentClubPenaltie]}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex-1-center-h flex-d-column">
                      <ClubInfo
                        url={
                          (Array.isArray(opponentClubMedia) &&
                            opponentClubMedia.length > 0 &&
                            opponentClubMedia[0].original_url) ||
                          images.dummyUser
                        }
                        clubName={item?.[`${opponentClub}`]?.club_name}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  },
)

export default FinalScore
