import axios from 'axios'
import {urls} from '../../services/api'
import {ApiResponse, FacebookPageType, ResponseListType} from './types'
import {apiErrorBlock} from '../../functions/utils'
import {socialKeys} from '../../services/socialKey'

export const getFacebookPageList = (access_token: string, facebookUserId: string) => async () => {
  try {
    const response: ApiResponse<ResponseListType<FacebookPageType[]>> = await axios.get(
      `${urls.facebookInfo}/v15.0/${facebookUserId}/accounts?access_token=${access_token}`,
    )
    const {data} = response
    if (data?.data) {
      return data.data
      // await dispatch(facebookActions.updateFacebookPageInfo(data.data))
    }
    return []
  } catch (e: any) {
    apiErrorBlock(e)
  }
  return []
}
export const getFacebookLongLiveToken = (access_token: string) => async () => {
  try {
    const response: any = await axios.get(
      `${urls.facebookInfo}/v15.0/oauth/access_token?grant_type=fb_exchange_token&client_id=${socialKeys.facebook.client_id}&client_secret=${socialKeys.facebook.client_secret}&fb_exchange_token=${access_token}`,
    )
    return response.data
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
export const getInstagramBusinessAccount = (access_token: string, pageId: string) => async () => {
  try {
    const response: any = await axios.get(
      `${urls.facebookInfo}/v15.0/${pageId}?fields=instagram_business_account&access_token=${access_token}`,
    )
    return response.data
  } catch (e: any) {
    apiErrorBlock(e)
    return false
  }
}
