/* eslint-disable react/no-children-prop */
import React, {memo} from 'react'
import REAutocomplete from 'react-autocomplete'
import {TagType} from '../../store/actions/types'
import {EventType} from '../contentManagement/type'

const Autocomplete = memo(
  ({
    list,
    writtenTag,
    setWrittenTag,
    setSelectedTag,
  }: {
    list: TagType[]
    writtenTag: string
    setWrittenTag: React.Dispatch<React.SetStateAction<string>>
    setSelectedTag: React.Dispatch<React.SetStateAction<string>>
  }) => {
    return (
      <div className="c-form-group mb-0">
        <REAutocomplete
          getItemValue={(item: TagType) => item.name}
          items={list}
          renderMenu={(items, value, styles) => (
            <div style={{...styles}} className="dropdown-style" children={items} />
          )}
          renderItem={(item, isHighlighted) => (
            <div
              className="py-1 px-3 dropdown-box"
              style={{
                background: isHighlighted ? '#CF0B48' : 'white',
                color: isHighlighted ? '#ffffff' : '#000000',
              }}
            >
              {item.name}
            </div>
          )}
          renderInput={(props) => (
            <input placeholder="Search tags" {...props} className="form-control h-100" />
          )}
          wrapperStyle={{height: '100%'}}
          value={writtenTag}
          onChange={(e: EventType) => setWrittenTag(e.target.value)}
          onSelect={(value: string) => setSelectedTag(value)}
        />
      </div>
    )
  },
)

export default Autocomplete
