import React, {memo, useMemo, useState} from 'react'
import MediaList from './medialist'
import {Button, Modal} from '../../component'
import {MediaLibraryFilters, MediaLibraryItem} from '../../store/actions/types'
import {ModelMediaList} from './types'

const MediaLibrary = memo(
  ({
    isOpen,
    setIsOpen,
    options = {},
    setAttachMedia,
    attachMedia,
    maxLimit = 1,
    isMulti = false,
    outerClassName,
  }: ModelMediaList) => {
    const [error, setError] = useState('')
    const defaultOptions: MediaLibraryFilters = useMemo(
      () => ({
        search: options.search || undefined,
        file_type: options.file_type === 0 ? 0 : options.file_type === 1 ? 1 : undefined,
        from_date: options.from_date || undefined,
        to_date: options.to_date || undefined,
      }),
      [],
    )
    const onSelect = (mediaItem: MediaLibraryItem) => {
      setError('')
      if (maxLimit <= 1 && !isMulti) {
        setAttachMedia([mediaItem])
        setIsOpen(false)
      } else {
        let alreadySelected = false
        attachMedia.forEach((selectedMediaItem: MediaLibraryItem) => {
          if (selectedMediaItem.id === mediaItem.id) alreadySelected = true
        })
        if (alreadySelected) {
          const newSelectedMedia = attachMedia.filter(
            (selectedMediaItem: MediaLibraryItem) => selectedMediaItem.id !== mediaItem.id,
          )
          setAttachMedia(newSelectedMedia)
        } else if (maxLimit > 0) {
          setAttachMedia([...attachMedia, mediaItem])
        } else {
          setError('Too many files selected')
        }
      }
    }
    return (
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} className="modal-outer">
        <div className="table-outer f-1 mb-0">
          <div className="c-carousal__header sticky">
            <div className="c-carousal__header__lpart v-center">
              <span className="f-Exo-Bold title-color">MEDIA LIBRARY</span>
            </div>
            <div className="c-carousal__header__rpart flex-center">
              {isMulti && (
                <Button
                  title="Select"
                  classAddon="mr-3 py-1 my-0"
                  onClick={() => {
                    setAttachMedia(attachMedia)
                    setIsOpen(false)
                  }}
                />
              )}
              <Button icon="c-icon--close" className="" onClick={() => setIsOpen(false)} />
            </div>
          </div>
          {error && (
            <div className="c-carousal__header sticky-error py-0">
              <div className="invalid-feedback f-Exo-SemiBold">{error}</div>
            </div>
          )}
          <div className={`pt-5 ${error ? 'mt-4' : 'mt-1'} ${outerClassName}`}>
            <MediaList
              options={defaultOptions}
              onEdit={onSelect}
              selectedMedia={attachMedia}
              isMediaLibraty
            />
          </div>
        </div>
      </Modal>
    )
  },
)

export default MediaLibrary
