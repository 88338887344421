import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {CountryListType} from '../actions/types'

export interface CountryState {
  countryList: CountryListType[]
}

const initialState = {
  countryList: [],
} as CountryState

export const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    updateCountryList: (state, action: PayloadAction<CountryState>) => {
      state.countryList = action.payload.countryList
    },
  },
})
